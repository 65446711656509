import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useComponentGeneratorStore from "../../../../state/ComponentGeneratorState/componentGeneratorState";
import { FormatEuroToNumber } from "../../../../utils/HelperFunctions";
import SliderWithMarks from "../../../SliderWithMarks/SliderWithMarks";
import UniquePropertyCheckboxes from "../../../UniquePropertyCheckboxes/UniquePropertyCheckboxes";
import "./RackControl.scss";

export default function RackControl() {
  const { t } = useTranslation();
  const [selectedSupplierValues] = useState<Set<string | number>>(new Set());
  const [selectedHeightValues, setSelectedHeightValues] = useState<
    Set<number | string>
  >(new Set());
  const [selectedWidthValues, setSelectedWidthValues] = useState<
    Set<number | string>
  >(new Set());
  const [selectedLengthValues, setSelectedLengthValues] = useState<
    Set<number | string>
  >(new Set());
  const [selectedPowerValues, setSelectedPowerValues] = useState<
    Set<number | string>
  >(new Set());
  const [priceRange, setPriceRange] = useState<number[]>([6500, 8000]);
  const [deliveryTimeRange, setDeliveryTimeRange] = useState<number[]>([
    30, 70,
  ]);
  const [co2ImpactRange, setCo2ImpactRange] = useState<number[]>([40, 90]);
  const { racks, setFilteredRacks } = useComponentGeneratorStore();

  const filterRacks = () => {
    if (racks.length > 0) {
      const newFilteredRacks = racks.filter(
        (rack) =>
          (selectedHeightValues.size === 0 ||
            selectedHeightValues.has(rack.height)) &&
          (selectedWidthValues.size === 0 ||
            selectedWidthValues.has(rack.width)) &&
          (selectedLengthValues.size === 0 ||
            selectedLengthValues.has(rack.length)) &&
          (selectedPowerValues.size === 0 ||
            selectedPowerValues.has(rack.power_in_w))
      );
      setFilteredRacks(newFilteredRacks);
    }
  };

  useEffect(() => {
    filterRacks();
  }, [
    selectedHeightValues,
    selectedWidthValues,
    selectedLengthValues,
    selectedPowerValues,
    selectedSupplierValues,
  ]);

  return (
    <div>
      <Accordion
        disableGutters
        defaultExpanded
        square
        elevation={0}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Supplier")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                checked={false}
                onChange={() => null}
              />
            }
            label={"DSI"}
            sx={{
              height: "20px",
              display: "block",
              marginLeft: "-18px",
              marginTop: "-7px",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                checked={false}
                onChange={() => null}
              />
            }
            label={"Alternate"}
            sx={{
              height: "20px",
              display: "block",
              marginLeft: "-18px",
              marginTop: "3px",
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        defaultExpanded
        square
        elevation={0}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Power")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit="kW"
            items={
              new Set(
                racks
                  .map((item) => item["power_in_w"])
                  .filter(
                    (name): name is number =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedPowerValues}
            setSelectedValues={setSelectedPowerValues}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        defaultExpanded
        square
        elevation={0}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Length")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit="mm"
            items={
              new Set(
                racks
                  .map((item) => item["length"])
                  .filter(
                    (name): name is number =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedLengthValues}
            setSelectedValues={setSelectedLengthValues}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        defaultExpanded
        square
        elevation={0}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Width")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit="mm"
            items={
              new Set(
                racks
                  .map((item) => item["width"])
                  .filter(
                    (name): name is number =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedWidthValues}
            setSelectedValues={setSelectedWidthValues}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        defaultExpanded
        square
        elevation={0}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Height")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit="mm"
            items={
              new Set(
                racks
                  .map((item) => item["height"])
                  .filter(
                    (name): name is number =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedHeightValues}
            setSelectedValues={setSelectedHeightValues}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        defaultExpanded
        square
        elevation={0}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Price")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <Typography>
            {FormatEuroToNumber(priceRange[0])} -{" "}
            {FormatEuroToNumber(priceRange[1])}
          </Typography>
          <SliderWithMarks
            min={0}
            max={10000}
            value={priceRange}
            setValue={setPriceRange}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        defaultExpanded
        square
        elevation={0}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Delivery time")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <Typography>
            {`${deliveryTimeRange[0]} - ${deliveryTimeRange[1]} ${t("day(s)")}`}
          </Typography>
          <SliderWithMarks
            min={0}
            max={100}
            value={deliveryTimeRange}
            setValue={setDeliveryTimeRange}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        disableGutters
        defaultExpanded
        square
        elevation={0}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("CO2 impact")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <Typography>
            {co2ImpactRange[0]} - {co2ImpactRange[1]}
          </Typography>
          <SliderWithMarks
            min={0}
            max={100}
            value={co2ImpactRange}
            setValue={setCo2ImpactRange}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
