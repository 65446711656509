import {
  AbilityBuilder,
  MongoAbility,
  createMongoAbility,
} from "@casl/ability";

type Actions = "create" | "read" | "update" | "delete" | "manage";
export type Subjects =
  | "main"
  | "analysers"
  | "analysers.planning"
  | "analysers.cost"
  | "analysers.mpg/co2"
  | "analysers.nitrogen"
  | "analysers.beng"
  | "calculations"
  | "catalog"
  | "catalog.datacenter"
  | "catalog.stackedhousing"
  | "catalog.datacenter.primarysystems"
  | "catalog.datacenter.spatialtypicals"
  | "catalog.datacenter.secondarysystems"
  | "catalog.datacenter.modules"
  | "catalog.datacenter.components"
  | "catalog.datacenter.components.serverracks"
  | "catalog.datacenter.components.upss"
  | "catalog.datacenter.components.batteries"
  | "catalog.datacenter.components.crah's"
  | "catalog.datacenter.components.structuralfloors"
  | "catalog.datacenter.components.steelprofiles"
  | "catalog.datacenter.components.transformators"
  | "configurators"
  | "configurators.datacenter"
  | "configurators.stackedhousing"
  | "company"
  | "databases"
  | "databases.activities"
  | "databases.productionkeyfigures"
  | "databases.equipmentkeyfigures"
  | "databases.costskeyfigures"
  | "databases.material"
  | "electrotechnics"
  | "electrotechnics.content"
  | "generators"
  | "generators.datacenter"
  | "generators.stackedhousing"
  | "generators.whitespaceandcooling"
  | "generators.lowvoltageroom"
  | "generators.apartments"
  | "generators.mainsupportingstructure"
  | "heijmansintelligence"
  | "heijmansintelligence.stackedhousing"
  | "heijmansintelligence.stackedhousing.corridor"
  | "heijmansintelligence.stackedhousing.porchway"
  | "heijmansintelligence.stackedhousing.gallery"
  | "mechanicalengineering"
  | "mechanicalengineering.content"
  | "overview"
  | "planning"
  | "projects"
  | "all";

export type AppAbility = MongoAbility<[Actions, Subjects]>;

type Role =
  | "Synergy.Admin"
  | "Synergy.CompanyAdmin"
  | "Synergy.ProjectAdmin"
  | "Synergy.ProjectManager"
  | "Synergy.CostExpert"
  | "Synergy.Calculator"
  | "Synergy.PlannerExpert"
  | "Synergy.Planner"
  | "Synergy.ProjectViewer"
  | "Synergy.ElectronicsExpert"
  | "Synergy.ElectronicsContentAdmin"
  | "Synergy.MechanicalEngineeringExpert"
  | "Synergy.MechanicalEngineeringContentAdmin"
  | "Synergy.NitrogenExpert"
  | "Synergy.SustainabilityExpert"
  | "Synergy.CatalogAdmin"
  | "Synergy.LSRViewer"
  | "Synergy.GeneralViewer";

const rolePermissions: Record<Role, [Actions, Subjects][]> = {
  "Synergy.Admin": [["manage", "all"]],
  "Synergy.CompanyAdmin": [["manage", "company"]],
  "Synergy.ProjectAdmin": [["manage", "projects"]],
  "Synergy.ProjectManager": [["manage", "projects"]],
  "Synergy.ProjectViewer": [["read", "projects"]],
  "Synergy.Calculator": [["manage", "calculations"]],
  "Synergy.PlannerExpert": [["manage", "planning"]],
  "Synergy.Planner": [["read", "planning"]],
  "Synergy.ElectronicsExpert": [["manage", "electrotechnics"]],
  "Synergy.ElectronicsContentAdmin": [["manage", "electrotechnics.content"]],
  "Synergy.MechanicalEngineeringExpert": [["manage", "mechanicalengineering"]],
  "Synergy.MechanicalEngineeringContentAdmin": [
    ["manage", "mechanicalengineering.content"],
  ],
  "Synergy.CatalogAdmin": [["manage", "catalog"]],
  "Synergy.NitrogenExpert": [
    ["read", "analysers"],
    ["read", "databases"],
    ["read", "databases.equipmentkeyfigures"],
    ["create", "databases.equipmentkeyfigures"],
    ["update", "databases.equipmentkeyfigures"],
    ["manage", "analysers.nitrogen"],
  ],
  "Synergy.SustainabilityExpert": [
    ["read", "analysers"],
    ["manage", "analysers.mpg/co2"],
  ],
  "Synergy.CostExpert": [
    ["read", "analysers"],
    ["manage", "analysers.cost"],
  ],
  "Synergy.LSRViewer": [
    ["read", "generators"],
    ["read", "generators.lowvoltageroom"],
  ],
  "Synergy.GeneralViewer": [
    ["read", "analysers"],
    ["read", "analysers.nitrogen"],
    ["read", "configurators"],
    ["read", "configurators.datacenter"],
    ["create", "configurators.datacenter"],
    ["read", "configurators.stackedhousing"],
    ["create", "configurators.stackedhousing"],
    ["read", "generators"],
    ["read", "generators.datacenter"],
    ["read", "generators.lowvoltageroom"],
    ["read", "generators.whitespaceandcooling"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["read", "catalog.datacenter.components.serverracks"],
    ["read", "catalog.datacenter.components.batteries"],
    ["read", "catalog.datacenter.components.upss"],
    ["read", "catalog.datacenter.components.transformators"],
    ["read", "catalog.stackedhousing"],
    ["read", "databases"],
    ["read", "databases.equipmentkeyfigures"],
    ["read", "databases.activities"],
    ["read", "databases.costskeyfigures"],
    ["read", "databases.productionkeyfigures"],
    ["read", "heijmansintelligence"],
    ["read", "heijmansintelligence.stackedhousing"],
    ["read", "heijmansintelligence.stackedhousing.corridor"],
    ["read", "heijmansintelligence.stackedhousing.porchway"],
    ["read", "heijmansintelligence.stackedhousing.gallery"],
  ],
};

export function defineAbilityFor(roles: string[]): AppAbility {
  const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);
  if (roles.length !== 0) {
    can("read", "main");
    can("read", "overview");
    roles.forEach((role) => {
      const permissions = rolePermissions[role as Role];
      if (permissions) {
        permissions.forEach(([action, subject]) => {
          can(action as Actions, subject);
        });
      }
    });
  }
  return build();
}
