import { Box, IconButton, InputBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchBox.scss";

export default function SearchBox() {
  const { t } = useTranslation();

  return (
    <div className="search-box">
      <Box component={"div"} sx={{ boxShadow: 1 }}>
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase sx={{ ml: 1 }} placeholder={t("Search")} />
      </Box>
    </div>
  );
}
