import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../../../components/LoadingIndicator/LoadingIndicator";
import { ForgeApiUrl } from "../../../../config";
import DetailedViewSidebar from "../../../sharedLogic/components/DetailedViewSidebar/DetailedViewSidebar";
import FileUpload from "../../../sharedLogic/components/FileUpload/FileUpload";
import PdfViewer from "../../../sharedLogic/components/PdfViewer/PdfViewer";
import DialogLayout from "../../../sharedLogic/layouts/DialogLayout";
import { useGetApiHook } from "../../../sharedLogic/services/forge/useGridApiHook";
import { DetailedInfo } from "../../../sharedLogic/types/InfoField";
import { DimensionsDto } from "../../../sharedLogic/types/api";
import { useModuleDetailsStore } from "../../state/moduleDetailsState";
import { ModuleDto, SupportedValueTypesDto } from "../../types/api";
import {
  convertCentsToEuro,
  convertMilimetersToMeters,
  convertSquareMilimetersToSquareMeters,
} from "../../../sharedLogic/utils/format";
import {
  isSpaceCommonAreaDto,
  isSpaceComponentTypicalDto,
  isSpaceResidentialDto,
} from "../../utils/utils";

interface ModuleDetailedViewProps {
  currentModule?: ModuleDto | undefined;
  currentModuleCode?: string | undefined;
}

export function ModuleDetailedView({
  currentModule,
  currentModuleCode,
}: ModuleDetailedViewProps) {
  const { t } = useTranslation();
  const moduleUrl = "api/v1/modules";
  const {
    file,
    module,
    isEditing,
    validationErrors,
    setModule,
    setModuleCode,
    setFile,
    handlePropertyChange,
  } = useModuleDetailsStore();

  const [supportedValues, setSupportedValues] =
    useState<SupportedValueTypesDto>({} as SupportedValueTypesDto);

  const { data: supportedValuesDto } = useGetApiHook<SupportedValueTypesDto>({
    baseUrl: ForgeApiUrl,
    url: `${moduleUrl}/supported-values/types`,
  });
  const { data: moduleData, loading } = useGetApiHook<ModuleDto>({
    baseUrl: ForgeApiUrl,
    url: currentModuleCode ? `${moduleUrl}/${currentModuleCode}` : "",
  });

  const getModuleSpecificProperties = () => {
    if (module === null) return [];

    if (isSpaceResidentialDto(module)) {
      return [
        {
          label: "StackedHousing.Position",
          property: "residentPosition",
          values: supportedValues.residentPositions,
          required: true,
          type: "select",
        },
        {
          label: "StackedHousing.OutdoorSpace",
          property: "outsideSpaceType",
          values: supportedValues.outdoorSpaceTypes,
          required: true,
          type: "select",
        },
        {
          label: "StackedHousing.Maisonette",
          property: "isMaisonette",
          type: "boolean",
        },
      ];
    }
    return [];
  };

  const getModuleSpecificDimensions = () => {
    if (module === null) return [];

    if (isSpaceResidentialDto(module)) {
      return [
        {
          label: `${t("StackedHousing.BayWidth")} 1`,
          property: "bayWidth1",
          unit: "m",
          required: true,
          type: "number",
        },
        {
          label: `${t("StackedHousing.BayWidth")} 2`,
          property: "bayWidth2",
          unit: "m",
          required: true,
          type: "number",
        },
        {
          label: "GO",
          property: "usableAreaInMm2",
          unit: "m2",
          required: true,
          type: "number",
          editable: true,
        },
        {
          label: "StackedHousing.BVOOutdoor",
          property: "grossFloorAreaOutdoorInMm2",
          unit: "m2",
          required: true,
          type: "number",
          editable: true,
        },
      ];
    } else if (isSpaceCommonAreaDto(module)) {
      return [
        {
          label: `${t("StackedHousing.BayWidth")} 1`,
          property: "bayWidth1",
          unit: "m",
          required: true,
          type: "number",
        },
        {
          label: `${t("StackedHousing.BayWidth")} 2`,
          property: "bayWidth2",
          unit: "m",
          required: true,
          type: "number",
        },
      ];
    }
    return [];
  };

  const getModuleSpecificCosts = () => {
    if (module === null) return [];

    if (isSpaceResidentialDto(module)) {
      return [
        {
          label: "StackedHousing.RentalPoints",
          property: "rentalPoints",
          unit: "st",
          type: "number",
          editable: true,
        },
      ];
    }
    return [];
  };

  const detailedInfo = [
    {
      category: "Properties",
      fields: [
        {
          label: "StackedHousing.TypeHAC",
          property: "hacCode",
          required: true,
          type: "text",
          editable: true,
        },
        {
          label: "Description",
          property: "description",
          required: true,
          type: "text",
          editable: true,
        },
        {
          label: "StackedHousing.AccessibilityType",
          property: "accessType",
          values: supportedValues.generationFlags?.filter((x) => x !== "None"),
          required: true,
          type: "select",
        },
        {
          label: "StackedHousing.SpaceType",
          property: "spaceType",
          values: supportedValues.spaceTypes?.filter((x) => x !== "None"),
          required: true,
          type: "select",
        },
        {
          label: "StackedHousing.BuildingLayer",
          property: "buildingLayer",
          values: supportedValues.buildingLayers,
          required: true,
          type: "select",
        },
        ...getModuleSpecificProperties(),
        {
          label: "StackedHousing.Rotatable",
          property: "isRotatable",
          type: "boolean",
          editable: true,
        },
        {
          label: "StackedHousing.Mirrorable",
          property: "isMirrorable",
          type: "boolean",
          editable: true,
        },
      ],
    },
    {
      category: "Dimensions",
      fields: [
        {
          label: "Width",
          property: "dimensions.xSizeInMm",
          unit: "m",
          required: true,
          type: "number",
        },
        {
          label: "Depth",
          property: "dimensions.ySizeInMm",
          unit: "m",
          required: true,
          type: "number",
        },
        {
          label: "Height",
          property: "dimensions.zSizeInMm",
          unit: "m",
          required: true,
          type: "number",
        },
        {
          label: "BVO",
          property: "grossFloorAreaInMm2",
          unit: "m2",
          type: "number",
          editable: true,
        },
        ...getModuleSpecificDimensions(),
      ],
    },
    {
      category: "Sustainability",
      fields: [
        {
          label: "CO2 (LCA1)",
          property: "cO2LCA1",
          unit: "kg",
          type: "number",
          editable: true,
        },
        {
          label: "CO2 (LCA2)",
          property: "cO2LCA2",
          unit: "kg",
          type: "number",
          editable: true,
        },
        {
          label: "CO2 (DGBC)",
          property: "cO2DGBC",
          unit: "kg",
          type: "number",
          editable: true,
        },
        {
          label: "StackedHousing.LifeSpan",
          property: "lifeSpan",
          unit: t("StackedHousing.Year").toLocaleLowerCase(),
          type: "number",
          editable: true,
        },
      ],
    },
    {
      category: "Costs",
      fields: [
        {
          label: "StackedHousing.ValueAbsolute",
          property: "costInEuroCents",
          unit: "€",
          type: "number",
          editable: true,
        },
        ...getModuleSpecificCosts(),
      ],
    },
    {
      category: "Owner",
      fields: [
        {
          label: "StackedHousing.Internal",
          property: "owner",
          disabled: true,
          type: "text",
        },
      ],
    },
  ] as DetailedInfo[];

  useEffect(() => {
    const moduleDetailed = moduleData as ModuleDto;
    if (moduleDetailed && moduleDetailed.code && moduleDetailed.code !== "") {
      const baseModule = {
        ...moduleDetailed,
        grossFloorAreaInMm2: convertSquareMilimetersToSquareMeters(
          moduleDetailed.grossFloorAreaInMm2
        ),
        costsInEuroCents: convertCentsToEuro(moduleDetailed.costInEuroCents),
        dimensions: {
          xSizeInMm: convertMilimetersToMeters(
            moduleDetailed.dimensions.xSizeInMm
          ),
          ySizeInMm: convertMilimetersToMeters(
            moduleDetailed.dimensions.ySizeInMm
          ),
          zSizeInMm: convertMilimetersToMeters(
            moduleDetailed.dimensions.zSizeInMm
          ),
        } as DimensionsDto,
      };

      if (isSpaceResidentialDto(moduleDetailed)) {
        setModule({
          ...baseModule,
          grossFloorAreaOutdoorInMm2: convertSquareMilimetersToSquareMeters(
            moduleDetailed.grossFloorAreaOutdoorInMm2
          ),
          usableAreaInMm2: convertSquareMilimetersToSquareMeters(
            moduleDetailed.usableAreaInMm2
          ),
          bayWidth1: convertMilimetersToMeters(moduleDetailed.bayWidth1),
          bayWidth2: convertMilimetersToMeters(moduleDetailed.bayWidth2),
        });
      } else if (isSpaceComponentTypicalDto(moduleDetailed)) {
        setModule({
          ...baseModule,
          bayWidth1: convertMilimetersToMeters(moduleDetailed.bayWidth1),
          bayWidth2: convertMilimetersToMeters(moduleDetailed.bayWidth2),
        });
      } else if (isSpaceCommonAreaDto(moduleDetailed)) {
        setModule(baseModule);
      }
    } else {
      setModule(currentModule as ModuleDto);
    }
  }, [moduleData, setModule]);

  useEffect(() => {
    if (currentModuleCode) {
      setModuleCode(currentModuleCode);
    }
  }, [currentModuleCode, setModuleCode]);

  useEffect(() => {
    if (supportedValuesDto) {
      setSupportedValues(supportedValuesDto as SupportedValueTypesDto);
    }
  }, [supportedValuesDto]);

  if (loading || !module) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <DialogLayout
      dialogView={
        file ? (
          <PdfViewer file={file} />
        ) : (
          <FileUpload
            isEditing={isEditing}
            onFilesSelected={(files: File[]) => {
              const nextFile = files?.[0];
              if (nextFile) {
                setFile(nextFile);
              }
            }}
          />
        )
      }
      dialogSidebar={
        <DetailedViewSidebar
          data={module}
          editing={isEditing}
          isNew={!module.code || module.code === ""}
          detailedInfo={detailedInfo}
          validationErrors={validationErrors}
          onPropertyChange={(path: string, newValue: string) =>
            handlePropertyChange(path, newValue)
          }
        />
      }
    />
  );
}
