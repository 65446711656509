import {
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface UniquePropertyCheckboxesProps {
  unit: string;
  items: Set<number | string>;
  selectedValues?: Set<number | string>;
  setSelectedValues?: Dispatch<SetStateAction<Set<number | string>>>;
}

const UniquePropertyCheckboxes: React.FC<UniquePropertyCheckboxesProps> = ({
  unit,
  items,
  selectedValues,
  setSelectedValues,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const uniqueValues = useMemo(() => Array.from(items), [items]);
  const visibleValues = expanded ? uniqueValues : uniqueValues.slice(0, 6);
  const handleCheckboxChange = (value: string | number, checked: boolean) => {
    setSelectedValues
      ? setSelectedValues((prevSelectedValues) => {
          const newSelectedValues = new Set(prevSelectedValues);
          if (checked) {
            newSelectedValues.add(value);
          } else {
            newSelectedValues.delete(value);
          }
          return newSelectedValues;
        })
      : console.log("there is no value");
  };

  return (
    <React.Fragment>
      <Grid container>
        {visibleValues.map((value, index) => (
          <Grid item xs={6}>
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 20 },
                    padding: "2px",
                  }}
                  checked={selectedValues?.has(value)}
                  onChange={(event) =>
                    handleCheckboxChange(value, event.target.checked)
                  }
                />
              }
              label={value + " " + unit}
              sx={{ display: "flex", alignItems: "center" }}
            />
          </Grid>
        ))}
      </Grid>

      {uniqueValues.length > 6 && (
        <Typography component="div">
          <Link
            component="button"
            onClick={() => setExpanded(!expanded)}
            sx={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
              marginTop: "5px",
            }}
          >
            {expanded ? t("Less") : t("More")}
          </Link>
        </Typography>
      )}
    </React.Fragment>
  );
};

export default UniquePropertyCheckboxes;
