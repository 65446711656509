import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { IconButton, Tooltip } from "@mui/material";
import React, { MouseEventHandler } from "react";
import "./AddButton.scss";
import { useTranslation } from "react-i18next";

interface AddButtonProps {
  fontSize: string;
  width: number;
  disabled?: boolean;
  hoverDisabled?: boolean;
  disableRipple?: boolean;
  text?: string | undefined;
  fontStyle?: string | undefined;
  onClick?: MouseEventHandler | undefined;
}

export default function AddButton(props: AddButtonProps) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  return (
    <Tooltip title={t("Add")}>
      <div
        className="add-button"
        style={{
          width: `${props.width}px`,
          pointerEvents: props.disabled ? "none" : undefined,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <IconButton
          disableRipple={props.disableRipple ?? false}
          disabled={props.disabled ?? false}
          onClick={props.onClick}
        >
          {props.disabled ? (
            <AddCircleOutlineOutlinedIcon
              className="disabled"
              style={{ fontSize: props.fontSize }}
            />
          ) : props.hoverDisabled ? (
            <AddCircleOutlinedIcon
              className="enabled"
              style={{ fontSize: props.fontSize }}
            />
          ) : isHovered ? (
            <AddCircleOutlinedIcon
              className="enabled"
              style={{ fontSize: props.fontSize }}
            />
          ) : (
            <AddCircleOutlineOutlinedIcon
              className="enabled"
              style={{ fontSize: props.fontSize }}
            />
          )}
        </IconButton>

        {props.text && (
          <div
            onClick={props.onClick}
            className="titel"
            style={{
              cursor: "pointer",
              fontSize: "12px",
              fontStyle: props.fontStyle ? props.fontStyle : "normal",
              color:
                props.disabled === undefined || props.disabled === false
                  ? "#42413E"
                  : "lightgray",
            }}
          >
            {t(props.text)}
          </div>
        )}
      </div>
    </Tooltip>
  );
}
