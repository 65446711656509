import {
  GridColDef,
  GridColumnGroupingModel,
  GridRowModel,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DeleteActivity,
  DeleteActivityRelation,
  GetActivities,
  GetActivityRelations,
  SaveActivity,
  SaveActivityRelation,
  UpdateActivity,
} from "../../../http/aggregate/ActivitiesService";
import { GetCategories } from "../../../http/aggregate/CategoriesService";
import { GetInputDataByType } from "../../../http/aggregate/InputDataService";
import ActivityDto from "../../../interfaces/aggregate/ActivityDto";
import { ActivityRelationDto } from "../../../interfaces/aggregate/ActivityRelationDto";
import { BaseDatabaseOptionDto } from "../../../interfaces/aggregate/BaseDatabaseOptionDto";
import { AggregatePageEnum } from "../../../interfaces/aggregate/enums/AggregatePageEnum";
import { InputDataCatalogEnum } from "../../../interfaces/aggregate/enums/InputDataCatalogEnum";
import useActivitiesStore from "../../../state/DatabaseState/activitiesState";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import { getOptionKey } from "../../../utils/HelperFunctions";
import { BaseDatabasePage, groupBy } from "../BaseDatabasePage";
import { AutoComplete } from "../components/AutoComplete/AutoComplete";

export function ActivitiesDatabaseView() {
  const { t } = useTranslation();
  const {
    phases,
    activitiesPerCategory,
    allActivities,
    allActivityRelations,
    setPhases,
    setActivitiesPerCategory,
    setAllActivities,
    setAllActivityRelations,
  } = useActivitiesStore();

  const {
    categories,
    departments,
    selectedNode,
    isEditMode,
    setDepartments,
    setCurrentPage,
    setCategories,
    setCurrentDatabaseOptions,
  } = useDatabaseStore();

  const relationTypeOptions: { [key: string]: string } = {
    "start-start": t("start-start"),
    "start-end": t("start-end"),
    "end-start": t("end-start"),
    "end-end": t("end-end"),
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Description"),
      editable: isEditMode,
      flex: 1,
    },
    {
      field: "phase",
      headerName: t("Phase"),
      editable: isEditMode,
      flex: 1,
      getOptionLabel: (value: any) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value: any) => (value as BaseDatabaseOptionDto).id,
      valueFormatter(value, row) {
        return row.phase?.name;
      },
      valueOptions: phases.sort((a, b) => a.name.localeCompare(b.name)),
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={phases.sort((a, b) => a.name.localeCompare(b.name))}
          />
        );
      },
    },
    {
      field: "relationActivities",
      headerName: t("SecondActivity"),
      editable: isEditMode,
      width: 350,
      valueGetter(value, row) {
        let relationActivities = allActivities.find(
          (x) => x.id === row.id
        )?.relationActivities;

        if (!relationActivities && Array.isArray(value)) {
          relationActivities = value as ActivityRelationDto[];
        }
        const relationIds = relationActivities?.map(
          (x: any) => x.activityTwo?.id
        );
        if (relationIds) {
          return allActivities
            .filter((x) => relationIds.includes(x.id))
            .sort((a, b) => a.name?.localeCompare(b.name))
            .map((x) => x.name);
        }
        return [];
      },
      valueOptions: allActivities,
      renderEditCell: (params) => {
        return (
          <AutoComplete
            multiselect={true}
            params={params}
            renderOptions={allActivities
              .filter((x) => x.id !== 0 && x.id !== params.row.id)
              .sort((a, b) => a.name?.localeCompare(b.name))
              .map((x) => x.name)}
          />
        );
      },
    },
    {
      field: "relationType",
      headerName: t("RelationType"),
      editable: isEditMode,
      type: "singleSelect",
      flex: 1,
      valueGetter: (value: string) => {
        return value;
      },
      valueFormatter: (params, row) =>
        t(relationTypeOptions[row?.value] || row?.value),
      renderCell: (params) =>
        t(relationTypeOptions[params?.value] || params?.value),
      renderEditCell: (params) => (
        <AutoComplete
          params={params}
          onChange={(event, newValue) => {
            params.api.setEditCellValue(
              {
                id: params.id,
                field: params.field,
                value: getOptionKey(newValue ?? "", relationTypeOptions),
              },
              event
            );
          }}
          renderOptions={Object.entries(relationTypeOptions).map(
            ([value, label]) => label
          )}
        />
      ),
    },
    {
      field: "deltaInDays",
      headerName: t("MarginActivities"),
      editable: isEditMode,
      flex: 1,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: t("Element data"),
      children: [
        { field: "name" },
        { field: "phase" },
        { field: "relationActivities" },
        { field: "relationType" },
        { field: "deltaInDays" },
      ],
    },
  ];

  async function processRowUpdate(
    gridRowModel: GridRowModel
  ): Promise<ActivityDto> {
    const newActivity = gridRowModel as ActivityDto;
    newActivity.name = gridRowModel.name;
    newActivity.categoryId = Number(selectedNode);
    newActivity.departmentId = newActivity.department?.id;
    newActivity.phaseId = newActivity.phase?.id;

    if (newActivity.id === 0) {
      newActivity.id = await SaveActivity(newActivity);
      setActivitiesPerCategory(
        activitiesPerCategory.map((item: ActivityDto) =>
          item.id === 0 ? newActivity : item
        )
      );
    } else {
      await UpdateActivity(newActivity);
      setActivitiesPerCategory(
        activitiesPerCategory.map((item: ActivityDto) =>
          item.id === newActivity.id ? newActivity : item
        )
      );
    }

    const currentActivity = allActivities.find((x) => x.id === newActivity.id);
    if (currentActivity && currentActivity.relationActivities) {
      for (let i = 0; i < currentActivity.relationActivities.length; i++) {
        await DeleteActivityRelation(currentActivity.relationActivities[i].id);
      }
    }

    if (gridRowModel.relationActivities.length > 0) {
      const relationActivities = [] as ActivityRelationDto[];
      for (let i = 0; i < gridRowModel.relationActivities.length; i++) {
        const activityTwo = allActivities.find(
          (x) => x.name === gridRowModel.relationActivities[i]
        );
        if (activityTwo) {
          const newActivityRelation = {
            id: 0,
            activityOneId: newActivity.id,
            activityTwoId: activityTwo.id,
            relationType: gridRowModel.relationType,
            deltaInDays: Number(gridRowModel.deltaInDays),
          } as ActivityRelationDto;

          newActivityRelation.id = await SaveActivityRelation(
            newActivityRelation
          );
          relationActivities.push(newActivityRelation);
        }
      }

      newActivity.relationActivities = relationActivities;
    }

    setTimeout(() => {
      LoadActivities();
    }, 500);

    return newActivity;
  }

  async function LoadActivities(refresh: boolean = true) {
    let currentActivities = allActivities;
    let currentActivityRelations = allActivityRelations;
    if (refresh) {
      currentActivities = await GetActivities();
      currentActivityRelations = await GetActivityRelations();
    }

    const activitiesPerCategory = allActivities.filter(
      (x) => x.category.id === Number(selectedNode)
    );

    activitiesPerCategory.forEach((activity) => {
      const relations = currentActivityRelations.filter(
        (x) => x.activityOne.id === activity.id
      );
      activity.relationActivities = relations;

      if (relations.length > 0) {
        activity.relationType = relations[0].relationType;
        activity.deltaInDays = relations[0].deltaInDays;
      }
    });

    setAllActivities(currentActivities);
    setAllActivityRelations(currentActivityRelations);
    setActivitiesPerCategory(activitiesPerCategory);
  }

  useEffect(() => {
    setCurrentPage(AggregatePageEnum.Activities);

    (async () => {
      await LoadItems();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedNode !== "") {
        await LoadActivities(false);
      }
    })();
  }, [selectedNode]);

  async function LoadItems() {
    await LoadActivities();

    setCategories(await GetCategories(AggregatePageEnum.Activities));
    setPhases(await GetInputDataByType(InputDataCatalogEnum.InputDataPhase));

    let currentDepartments = departments;
    if (currentDepartments.length === 0) {
      currentDepartments = await GetInputDataByType(
        InputDataCatalogEnum.InputDataDepartment
      );
      setDepartments(currentDepartments);
    }
    setCurrentDatabaseOptions(currentDepartments);
  }

  useEffect(() => {
    setCurrentPage(AggregatePageEnum.Activities);
    (async () => {
      await LoadItems();
    })();
  }, []);

  return (
    <BaseDatabasePage
      columns={columns}
      data={groupBy(activitiesPerCategory, (item) => item.name)}
      columnGroupingModel={columnGroupingModel}
      addNewItem={() => {
        setActivitiesPerCategory([
          ...activitiesPerCategory,
          {
            id: 0,
            category: categories.find((x) => x.id === Number(selectedNode)),
            categoryId: Number(selectedNode),
            relationActivities: [] as ActivityRelationDto[],
          } as ActivityDto,
        ]);
      }}
      baseProcessRowUpdate={processRowUpdate}
      handleCancel={() => {
        setActivitiesPerCategory(
          activitiesPerCategory.filter((x) => x.id !== 0)
        );
      }}
      handleDelete={(params) => {
        DeleteActivity(params.toString());
        setAllActivities(allActivities.filter((x) => x.id !== params));
        setActivitiesPerCategory(
          activitiesPerCategory.filter((x) => x.id !== params)
        );
      }}
    />
  );
}
