import { Collapse } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetSuppliers } from "../../../http/foundry/typicals/SupplierService";
import { BaseTypicalDto } from "../../../interfaces/foundry/typicals/BaseTypicalDto";
import { DistributesComponentDto } from "../../../interfaces/foundry/typicals/DistributesComponentDto";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import FormControlTextField from "../../CustomFormControl/FormControlTextField";
import ExpandMore from "../../ExpandMore/ExpandMore";
import "./SceneDetailComponent.scss";

interface SceneDetailComponentProps {
  type: string;
  component?: BaseTypicalDto | undefined | DistributesComponentDto;
  detailView?: ReactNode;
}

export default function SceneDetailComponent({
  type,
  component,
  detailView,
}: SceneDetailComponentProps) {
  const { t } = useTranslation();
  const { suppliers, setSuppliers } = useGeneralDatacenterObjectsStore();

  const [expanded, setExpanded] = useState<boolean>(false);

  const baseComponent = component as BaseTypicalDto;
  const distributesComponent = component as DistributesComponentDto;

  function getPowerLabel() {
    if (type === "Cooling" || type === "CoolingBlock") {
      return `${t("CoolingCapacity")} (MW)`;
    } else if (type === "Genset") {
      return `${t("Power")} (MW)`;
    } else if (type === "UPS") {
      return `${t("Heatload")} (Kw)`;
    }
    return `${t("Power")} (kW)`;
  }

  function getCustomAttributeLabel() {
    if (type === "Cooling") {
      return t("WhitespacePage.CoolingArea");
    } else if (type === "HallwayArea") {
      return `${t("Area")} (m2)`;
    }
    return "";
  }

  useEffect(() => {
    (async () => {
      setSuppliers(await GetSuppliers());
    })();
  }, []);

  return (
    <div className="component-details">
      <div className="component-header">
        <ExpandMore
          disableRipple
          expand={expanded}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <GridExpandMoreIcon />
        </ExpandMore>
        <div className="title">{`${t(type)}`}</div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {component && type !== "Distributors" ? (
          <div>
            {(baseComponent.id && !baseComponent.type) ||
            (baseComponent.type === "" && baseComponent.id !== 0) ? (
              <FormControlTextField
                labelText={t("ID")}
                value={baseComponent.id}
              />
            ) : null}

            {baseComponent.type && baseComponent.type !== "" && (
              <FormControlTextField
                labelText={t("Type")}
                value={t(baseComponent.type)}
              />
            )}

            {baseComponent.amount && (
              <FormControlTextField
                labelText={t("Amount")}
                value={baseComponent.amount}
              />
            )}

            {baseComponent.power_in_w && baseComponent.power_in_w !== 0 ? (
              <FormControlTextField
                labelText={getPowerLabel()}
                value={baseComponent.power_in_w}
              />
            ) : null}

            {baseComponent.customAttribute ? (
              <FormControlTextField
                labelText={getCustomAttributeLabel()}
                value={baseComponent?.customAttribute}
              />
            ) : null}

            {baseComponent.heatload_in_w &&
            baseComponent.heatload_in_w !== 0 ? (
              <FormControlTextField
                labelText={getPowerLabel()}
                value={baseComponent.heatload_in_w}
              />
            ) : null}

            {baseComponent.supplier ? (
              <FormControlTextField
                labelText={t("Supplier")}
                value={
                  suppliers.find((x) => x.id === baseComponent.supplier)?.name
                }
              />
            ) : null}
          </div>
        ) : type === "Distributors" ? (
          <div>
            <FormControlTextField
              labelText={t("ID")}
              value={distributesComponent.distributors[0].id}
            />
            <FormControlTextField
              labelText={t("Type")}
              value={distributesComponent.distributors[0].display_name}
            />

            <FormControlTextField
              labelText={t("Supplier")}
              value={
                suppliers.find(
                  (x) => x.id === distributesComponent.distributors[0].supplier
                )?.name
              }
            />

            <FormControlTextField
              labelText={t("Amount")}
              value={
                distributesComponent?.lvv_input_amount +
                distributesComponent?.lvv_output_amount -
                distributesComponent?.lvv_big_input_amount
              }
            />
            <br />
            <FormControlTextField
              labelText={t("ID")}
              value={distributesComponent.distributors[1].id}
            />
            <FormControlTextField
              labelText={t("Type")}
              value={distributesComponent.distributors[1].display_name}
            />
            <FormControlTextField
              labelText={t("Supplier")}
              value={
                suppliers.find(
                  (x) => x.id === distributesComponent.distributors[1].supplier
                )?.name
              }
            />
            <FormControlTextField
              labelText={t("Amount")}
              value={distributesComponent?.lvv_big_input_amount}
            />
          </div>
        ) : (
          detailView
        )}
      </Collapse>
    </div>
  );
}
