import { Box, Grid, LinearProgress, Typography } from "@mui/material";

interface LinearProgressWithLabelProps {
  backgroundColor: string;
  value: number;
}
export default function LinearProgressWithLabel({
  backgroundColor,
  value,
}: LinearProgressWithLabelProps) {
  return (
    <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
      <Box component={"div"} sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value ?? 0}
          sx={{
            backgroundColor: "#acacac6e",
            "& .MuiLinearProgress-bar": {
              backgroundColor: backgroundColor,
            },
          }}
        ></LinearProgress>
      </Box>
      <Box component={"div"}>
        <Grid spacing={0} container justifyContent="flext-end">
          <Typography variant="body2">{`${Math.round(
            Number(value ?? 0)
          )}%`}</Typography>
        </Grid>
      </Box>
    </Box>
  );
}
