import { Grid } from "@mui/material";
import { TFunction } from "i18next";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValidationError } from "../../../HSA/state/moduleDetailsState";
import InfoField from "../../types/InfoField";
import BooleanField from "./BooleanField";
import NumberField from "./NumberField";
import SelectField from "./SelectField";
import CustomTextField from "./TextField";
import "./PropertySectionView.scss";
import UnitMix from "../../../HSA/components/UnitMix/UnitMix";
import { formatValue } from "../../utils/format";

interface PropertySectionViewProps {
  sectionTitle: string;
  fields: InfoField[];
  data: any;
  isNew: boolean;
  editing: boolean;
  validationErrors: ValidationError[];
  onPropertyChange?: (path: string, newValue: any) => void;
}

const PropertySectionView = memo(
  ({
    sectionTitle,
    fields,
    data,
    isNew,
    editing,
    onPropertyChange,
    validationErrors,
  }: PropertySectionViewProps) => {
    const { t } = useTranslation();

    return (
      <>
        <div className="property-section-title">{sectionTitle}</div>
        <hr />
        <div className="property-section-properties">
          {fields.map((field, index) => (
            <PropertyField
              key={index}
              field={field}
              data={data}
              isNew={isNew}
              editing={editing}
              onPropertyChange={onPropertyChange}
              validationErrors={validationErrors}
              t={t}
            />
          ))}
        </div>
      </>
    );
  }
);

export default PropertySectionView;

interface PropertyFieldProps {
  field: InfoField;
  data: any;
  isNew: boolean;
  editing: boolean;
  validationErrors: ValidationError[];
  onPropertyChange?: (path: string, newValue: any) => void;
  t: TFunction;
}

const PropertyField = memo(
  ({
    field,
    data,
    isNew,
    editing,
    onPropertyChange,
    validationErrors,
    t,
  }: PropertyFieldProps) => {
    const value = useMemo(() => {
      let current = data;
      const path = field.property.split(".");
      for (const key of path) {
        if (
          current === null ||
          current === undefined ||
          typeof current !== "object"
        ) {
          return null;
        }
        current = current[key];
      }
      return current;
    }, [data, field.property]);

    const error = validationErrors.some(
      (err) => err.property === field.property
    );

    const handleChange = useCallback(
      (newValue: any) => {
        if (!onPropertyChange) {
          return;
        }
        onPropertyChange(field.property, newValue);
      },
      [onPropertyChange, field.property]
    );

    const disabled =
      !editing ||
      field.disabled === true ||
      (!isNew && (field.editable === undefined || !field.editable));

    const renderFieldComponent = () => {
      switch (field.type) {
        case "boolean":
          return (
            <BooleanField
              value={value}
              onChange={handleChange}
              disabled={disabled}
            />
          );
        case "select":
          return (
            <SelectField
              value={value}
              onChange={handleChange}
              disabled={disabled}
              options={field.values ?? []}
              error={error}
            />
          );
        case "number":
          return !editing ? (
            <CustomTextField
              value={formatValue(value, field.unit ?? "")}
              onChange={handleChange}
              disabled={disabled}
              error={error}
              unit={field.unit}
            />
          ) : (
            <NumberField
              value={value}
              onChange={handleChange}
              disabled={disabled}
              error={error}
              unit={field.unit}
            />
          );
        case "unitMix":
          return (
            <div className="mt5">
              <UnitMix
                units={value}
                showSlider={false}
                editPercentage={false}
                setUnits={() => {}}
              />
            </div>
          );
        default:
          return (
            <CustomTextField
              value={value}
              onChange={handleChange}
              disabled={disabled}
              error={error}
              unit={field.unit}
            />
          );
      }
    };

    return (
      <Grid
        sx={{ marginTop: !editing ? "-10px" : "" }}
        container
        columnSpacing={1}
        className="sidebar-section-property"
      >
        <Grid
          item
          xs={4.55}
          className={field.type !== "unitMix" ? "center-center" : ""}
        >
          <div
            className={field.type === "unitMix" ? "mt5" : ""}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "flex",
              justifyItems: "start",
            }}
          >
            {t(field.label)}
          </div>
        </Grid>
        <Grid
          item
          xs={"auto"}
          className={field.type !== "unitMix" ? "center-center" : ""}
        >
          <div className={field.type === "unitMix" ? "mt5" : ""}>{":"}</div>
        </Grid>
        <Grid item xs className="center-center">
          {renderFieldComponent()}
        </Grid>
      </Grid>
    );
  }
);
