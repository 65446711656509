import React, { WheelEvent, useEffect, useRef, useState } from "react";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import "./ZoomImage.scss";

interface SVGInteractiveProps {
  image: string;
  objectType: TypeObject;
  objectScope: string;
  objectWidth: number;
}

const ZoomImage: React.FC<SVGInteractiveProps> = (props) => {
  const [svgContent, setSvgContent] = useState<string>("");
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const svgContainerRef = useRef<HTMLDivElement>(null);
  const [panStartX, setPanStartX] = useState<number>(0);
  const [panStartY, setPanStartY] = useState<number>(0);
  const [panOffsetX, setPanOffsetX] = useState<number>(0);
  const [panOffsetY, setPanOffsetY] = useState<number>(0);
  const isDraggingRef = useRef<boolean>(false);
  const [points] = useState<{
    start: { x: number; y: number } | null;
    end: { x: number; y: number } | null;
  }>({ start: null, end: null });

  const minZoomLevel = 0.1;
  const maxZoomLevel = 5;

  useEffect(() => {
    if (props.image === "") return;

    fetch(props.image)
      .then((response) => response.text())
      .then((content) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(content, "image/svg+xml");
        // Remove the element with id="patch_1" from the SVG content
        const elementToRemove = svgDoc.getElementById("patch_1");
        if (elementToRemove) {
          elementToRemove.remove();
        }

        // Serialize the modified SVG back to a string
        const serializer = new XMLSerializer();
        const serializedSvg = serializer.serializeToString(svgDoc);

        setSvgContent(serializedSvg);
      });
  }, [props.image]);

  const extractControlPoints = (pathData: string) => {
    const commands = pathData.split(/(?=[ML])/);
    const points = commands.map((cmd) => {
      const parts = cmd.split(" ");
      return {
        x: parseFloat(parts[1]),
        y: parseFloat(parts[2]),
      };
    });
    return points;
  };

  useEffect(() => {
    if (svgContainerRef.current) {
      const pathElements = svgContainerRef.current.querySelectorAll("path");
      const originalStyles: Map<
        SVGPathElement,
        { stroke: string; fill: string; strokeWidth: string }
      > = new Map();

      pathElements.forEach((el) => {
        const pathData = el.getAttribute("d") || "";
        const controlPoints = extractControlPoints(pathData);

        // Now, for each control point, create a circle element
        controlPoints.forEach((point) => {
          const circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
          );
          circle.setAttribute("cx", point.x.toString());
          circle.setAttribute("cy", point.y.toString());
          circle.setAttribute("r", "3"); // radius of the circle
          circle.setAttribute("fill", "transparent");
          circle.setAttribute("stroke", "none");
          circle.setAttribute("pointer-events", "visible");

          circle.addEventListener("mouseenter", () => {
            circle.setAttribute("fill", "yellow");
          });

          circle.addEventListener("mouseleave", () => {
            circle.setAttribute("fill", "transparent");
          });

          el.parentNode?.insertBefore(circle, el);
        });
      });

      const mouseEnterHandler = (event: Event) => {
        const svgElement = event.target as SVGPathElement;
        const originalStyle = {
          stroke: svgElement.style.stroke,
          fill: svgElement.style.fill,
          strokeWidth: svgElement.style.strokeWidth,
        };
        originalStyles.set(svgElement, originalStyle);

        svgElement.style.stroke = "blue";
        // svgElement.style.fill = "lightblue";
        svgElement.style.strokeWidth = "2";
      };

      const mouseLeaveHandler = (event: Event) => {
        const svgElement = event.target as SVGPathElement;
        const originalStyle = originalStyles.get(svgElement);

        if (originalStyle) {
          svgElement.style.stroke = originalStyle.stroke;
          svgElement.style.fill = originalStyle.fill;
          svgElement.style.strokeWidth = originalStyle.strokeWidth;
          svgElement.style.pointerEvents = "stroke";
        }

        originalStyles.delete(svgElement);
      };

      pathElements.forEach((el) => {
        el.addEventListener("mouseenter", mouseEnterHandler);
        el.addEventListener("mouseleave", mouseLeaveHandler);
      });

      return () => {
        pathElements.forEach((el) => {
          el.removeEventListener("mouseenter", mouseEnterHandler);
          el.removeEventListener("mouseleave", mouseLeaveHandler);
        });
      };
    }
  }, [svgContent]);

  useEffect(() => {
    if (svgContainerRef.current) {
      svgContainerRef.current.style.setProperty(
        "--zoom-level",
        zoomLevel.toString()
      );
      svgContainerRef.current.style.setProperty(
        "--pan-offset-x",
        `${panOffsetX}px`
      );
      svgContainerRef.current.style.setProperty(
        "--pan-offset-y",
        `${panOffsetY}px`
      );
    }
  }, [zoomLevel, panOffsetX, panOffsetY]);

  useEffect(() => {
    const container = svgContainerRef.current;
    if (container) {
      container.addEventListener(
        "wheel",
        handleWheel as unknown as EventListener
      );
      container.addEventListener("contextmenu", handleContextMenu);
    }
    return () => {
      if (container) {
        container.removeEventListener(
          "wheel",
          handleWheel as unknown as EventListener
        );
        container.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  }, []);

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
  };

  function handleWheel(event: WheelEvent<HTMLDivElement>) {
    const { deltaY } = event;
    const direction = deltaY > 0 ? -1 : 1;
    setZoomLevel((prevZoomLevel) => {
      let newZoomLevel = prevZoomLevel + direction * 0.1;
      newZoomLevel = Math.max(minZoomLevel, newZoomLevel);
      newZoomLevel = Math.min(maxZoomLevel, newZoomLevel);
      return newZoomLevel;
    });
    event.preventDefault();
  }

  const handlePanStart = (event: React.MouseEvent) => {
    if (event.button === 0 || event.button === 1 || event.button === 2) {
      // Middle mouse or panning button
      setPanStartX(event.clientX);
      setPanStartY(event.clientY);
      isDraggingRef.current = true;
      event.preventDefault();
    }
  };

  const handlePanMove = (event: React.MouseEvent) => {
    if (event.buttons !== 0 || isDraggingRef.current) {
      // Middle mouse or panning button and dragging
      const offsetX = event.clientX - panStartX;
      const offsetY = event.clientY - panStartY;
      setPanOffsetX(panOffsetX + offsetX);
      setPanOffsetY(panOffsetY + offsetY);
      setPanStartX(event.clientX);
      setPanStartY(event.clientY);
    }
  };

  const handlePanEnd = (event: React.MouseEvent) => {
    isDraggingRef.current = false;
  };

  return (
    <div className="zoom-image">
      <div
        ref={svgContainerRef}
        onMouseDown={handlePanStart}
        onMouseMove={handlePanMove}
        onMouseUp={handlePanEnd}
        onClick={undefined}
        className="svg-container"
      >
        <div dangerouslySetInnerHTML={{ __html: svgContent }} />

        {/* Render selected points */}
        <svg className="svg">
          {points.start && (
            <circle cx={points.start.x} cy={points.start.y} r={5} fill="red" />
          )}
          {points.end && (
            <circle cx={points.end.x} cy={points.end.y} r={5} fill="red" />
          )}

          {points.start && points.end && (
            <>
              <line
                x1={points.start.x}
                y1={points.start.y}
                x2={points.end.x}
                y2={points.end.y}
                stroke="purple"
                strokeWidth="2"
              />
              <text
                x={(points.start.x + points.end.x) / 2}
                y={(points.start.y + points.end.y) / 2}
                fill="red"
                fontSize="14"
                textAnchor="middle"
              >
                {(
                  (props.objectWidth / 434) *
                  Math.sqrt(
                    Math.pow(points.end.x - points.start.x, 2) +
                      Math.pow(points.end.y - points.start.y, 2)
                  )
                ).toFixed(1)}{" "}
                m
              </text>
              {/* Check if the points are not on a single horizontal or vertical line */}
              {(points.start.x <= points.end.x - 2 ||
                points.start.x >= points.end.x + 2) &&
                (points.start.y <= points.end.y - 2 ||
                  points.start.y >= points.end.y + 2) && (
                  <>
                    {/* Draw the two additional lines */}
                    <line
                      x1={points.start.x}
                      y1={points.start.y}
                      x2={points.start.x}
                      y2={points.end.y}
                      stroke="green"
                      strokeWidth="2"
                    />
                    <line
                      x1={points.start.x}
                      y1={points.end.y}
                      x2={points.end.x}
                      y2={points.end.y}
                      stroke="blue"
                      strokeWidth="2"
                    />
                    {/* Add the lengths of the new lines */}
                    <text
                      x={points.start.x + (points.end.x - points.start.x) / 2}
                      y={points.end.y}
                      fill="red"
                      fontSize="14"
                      textAnchor="middle"
                    >
                      {(
                        (props.objectWidth / 434) *
                        Math.abs(points.end.x - points.start.x)
                      ).toFixed(1)}{" "}
                      m
                    </text>
                    <text
                      x={points.start.x}
                      y={points.start.y + (points.end.y - points.start.y) / 2}
                      fill="red"
                      fontSize="14"
                      textAnchor="middle"
                    >
                      {(
                        (props.objectWidth / 434) *
                        Math.abs(points.end.y - points.start.y)
                      ).toFixed(1)}{" "}
                      m
                    </text>
                  </>
                )}
            </>
          )}
        </svg>
      </div>

      {/* Controls */}

      {/* <ButtonGroup
        orientation="vertical"
        color="primary"
        aria-label="outlined primary button group"
        sx={{
          zIndex: 1000,
          backgroundColor: "white",
          marginTop: "5px",
          marginRight: "5px",
          border: `2px solid ${heijmansBlue}`,
          borderRadius: "20px",
          maxHeight: "165px",
          boxShadow: `0 0 5px #ccc`,
        }}
      >
        <ButtonGroupIconButton onClick={handleZoomIn}>
          <ZoomInIcon sx={{ color: heijmansBlue }} />
        </ButtonGroupIconButton>
        <ButtonGroupIconButton onClick={handleZoomOut}>
          <ZoomOutIcon sx={{ color: heijmansBlue }} />
        </ButtonGroupIconButton>
        <ButtonGroupIconButton
          sx={{
            backgroundColor:
              activeButton === "panning" ? heijmansBlue : "transparent",
            borderRadius: "0px",
          }}
          onClick={handleTogglePanning}
        >
          <PanToolOutlined
            sx={{
              color: activeButton === "panning" ? "white" : heijmansBlue,
            }}
          />
        </ButtonGroupIconButton>
        <ButtonGroupIconButton
          sx={{
            marginTop: "3px",
            backgroundColor:
              activeButton === "measuring" ? heijmansBlue : "transparent",
            borderRadius: "0px 0px 20px 20px",
            "&.hover": {
              backgroundColor:
                activeButton === "measuring" ? heijmansBlue : "transparent",
            },
          }}
          onClick={handleToggleMeasuring}
        >
          <StraightenOutlined
            sx={{
              color: activeButton === "measuring" ? "white" : heijmansBlue,
            }}
          />
        </ButtonGroupIconButton>
      </ButtonGroup> */}
    </div>
  );
};
export default ZoomImage;
