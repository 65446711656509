import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { useEffect, useRef, useState } from "react";
import type { ControlPosition, MapInstance } from "react-map-gl";
import { useControl } from "react-map-gl";
import { StoreApi, UseBoundStore } from "zustand";
import DrawedPlot, {
  GetEmptyDrawedPlot,
} from "../../../../types/DrawedPlot";
import { MapType } from "../../../../types/enums";
import { TrackException } from "../../../../logging/LoggingManager";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
} from "../../../../state/baseConfiguratorState";
import { CalculateDistanceAOrNRoad } from "../../MapService";
import {
  CalculateOffsetGeometry,
  CalculateRdCoordinates,
  GetDrawControlStyles,
} from "./DrawControlManager";

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  userProperties?: boolean;
  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
  mapType: MapType;
  currentStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >;
};

export default function DrawControl(props: DrawControlProps) {
  const { position, onCreate, mapType, currentStore } = props;
  const offsetDistance = -4.8;
  const mapRef = useRef<MapInstance | null>(null);
  const drawControlRef = useRef<MapboxDraw | null>(null);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [buildings, setBuildings] = useState<GeoJSON.FeatureCollection>({
    type: "FeatureCollection",
    features: [],
  });

  const {
    drawedPlot,
    calculateDistance,
    currentLayouts,
    drawControlMode,
    startDrawingPlot,
    editDrawingPlot,
    deleteDrawingPlot,
    selectedExportedBuilingsIds,
    buildings: exportedBuildings,
    setBuildings: setExportedBuildings,
    setDrawedPlot,
    setIsSaveAvailable,
    setNavigateDrawingPlot,
    setStartDrawingPlot,
    setEditDrawingPlot,
    setCalculateDistance,
    setDeleteDrawingPlot,
    setDistanceBetweenPlotAndRoad,
  } = currentStore();

  useEffect(() => {
    if (startDrawingPlot) {
      drawControlRef.current?.changeMode("draw_polygon");
      setStartDrawingPlot(false);
    }
  }, [startDrawingPlot]);

  useEffect(() => {
    if (editDrawingPlot) {
      drawControlRef.current?.changeMode("direct_select", {
        featureId: drawedPlot.id,
      });
      setNavigateDrawingPlot(true);
      setEditDrawingPlot(false);
    }
  }, [editDrawingPlot]);

  useEffect(() => {
    if (deleteDrawingPlot) {
      drawControlRef.current?.deleteAll();

      if (mapRef.current?.getLayer("route"))
        mapRef.current?.removeLayer("route");
      if (mapRef.current?.getSource("route"))
        mapRef.current?.removeSource("route");

      setDistanceBetweenPlotAndRoad(0);
      setDrawedPlot(GetEmptyDrawedPlot());
      setDeleteDrawingPlot(false);
    }
  }, [deleteDrawingPlot]);

  useEffect(() => {
    if (calculateDistance && drawedPlot.buildableCoordinates.length > 0) {
      CalculateDistanceAOrNRoad(
        mapRef!.current!,
        drawedPlot,
        setDistanceBetweenPlotAndRoad
      );
      setCalculateDistance(false);
    }
  }, [calculateDistance]);

  useEffect(() => {
    try {
      if (
        drawControlMode &&
        drawControlMode !== "" &&
        mapRef.current &&
        drawControlRef.current
      ) {
        drawControlRef.current.changeMode(drawControlMode);
      }
    } catch (ex) {
      TrackException(ex);
    }
  }, [drawControlMode]);

  useEffect(() => {
    if (!drawControlRef.current || !mapRef.current || !stylesLoaded) {
      return;
    }

    try {
      if (drawedPlot && drawedPlot.polygon) {
        GenerateBuilding(drawedPlot.polygon);
      }
    } catch (ex) {
      TrackException(ex);
    }
  }, [
    drawControlRef,
    mapRef,
    currentLayouts,
    selectedExportedBuilingsIds,
    stylesLoaded,
    drawedPlot,
  ]);

  useEffect(() => {
    if (
      !stylesLoaded ||
      !mapRef.current ||
      buildings.features.length === 0 ||
      mapType !== MapType.GenerateBuilding
    )
      return;

    const map = mapRef.current;

    if (map.getSource("buildings")) {
      (map.getSource("buildings") as mapboxgl.GeoJSONSource).setData(buildings);
    } else {
      map.addSource("buildings", {
        type: "geojson",
        data: buildings,
      });

      map.addLayer({
        id: "buildings-3d",
        type: "fill-extrusion",
        source: "buildings",
        paint: {
          "fill-extrusion-color": ["get", "color"],
          "fill-extrusion-height": ["get", "height"],
          "fill-extrusion-base": ["get", "baseHeight"],
          "fill-extrusion-opacity": 1,
        },
      });
    }

    setExportedBuildings(buildings);
  }, [buildings, stylesLoaded]);

  function UpdatePolygon(polygon: GeoJSON.Feature<GeoJSON.Geometry>) {
    if (!drawControlRef.current) return;

    const { features } = drawControlRef.current.getAll();
    features
      .filter((x) => x.id !== polygon.id)
      .forEach((feature) => drawControlRef.current?.delete(feature.id + ""));

    drawControlRef.current?.setFeatureProperty(polygon.id + "", "kind", "plot");
    drawControlRef.current?.setFeatureProperty(polygon.id + "", "class_id", 0);

    let unqiueCoordinates: number[][] = [];
    if (polygon.geometry && polygon.geometry.type === "Polygon") {
      unqiueCoordinates = polygon.geometry.coordinates[0];
    }

    const turfPositions: turf.Position[] = [];
    unqiueCoordinates.forEach((point) => {
      turfPositions.push([point[0], point[1]] as turf.Position);
    });

    const positions: number[][] = [];
    const offsetGeometry = CalculateOffsetGeometry(
      turfPositions,
      offsetDistance
    );
    if (!offsetGeometry) return;

    const newFeature: GeoJSON.Feature<GeoJSON.Geometry> = {
      type: "Feature",
      geometry: offsetGeometry,
      properties: {
        kind: "buildablePlot",
        class_id: 1,
      },
    };
    drawControlRef.current?.add(newFeature);

    offsetGeometry.coordinates[0].forEach((position) => {
      positions.push([Number(position[0]), Number(position[1])]);
    });

    const newDrawedPlot = {
      id: polygon.id,
      polygon: polygon,
      lotSurface: Math.round((turf.area(polygon) * 100) / 100),
      lotCoordinates: unqiueCoordinates,
      lotRdCoordinates: CalculateRdCoordinates(unqiueCoordinates),
      buildableSurface:
        drawedPlot.buildableSurface ??
        Math.round((turf.area(offsetGeometry) * 100) / 100),
      buildableRdCoordinates: CalculateRdCoordinates(positions),
      buildableCoordinates: positions,
    } as DrawedPlot;

    setDrawedPlot(newDrawedPlot);
    setIsSaveAvailable(true);
  }

  function GetBuildableLot(
    plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>
  ): turf.helpers.Feature<turf.helpers.Polygon, turf.helpers.Properties> {
    let plotCoordinates: number[][] = [];
    if (plotPolygon.geometry && plotPolygon.geometry.type === "Polygon") {
      plotCoordinates = plotPolygon.geometry.coordinates[0];
    }
    const turfPositions: turf.Position[] = [];
    plotCoordinates.forEach((point) => {
      turfPositions.push([point[0], point[1]] as turf.Position);
    });
    const existingPolygon = turf.polygon([plotCoordinates]);
    const bufferedPolygon = turf.buffer(existingPolygon, offsetDistance, {
      units: "meters",
    });

    return bufferedPolygon;
  }

  function GetPlotOrigin(plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>) {
    const buildableLot = GetBuildableLot(plotPolygon);
    const plotCoordinates = buildableLot.geometry.coordinates[0];
    return [Number(plotCoordinates[0][0]), Number(plotCoordinates[0][1])];
  }

  async function GenerateBuilding(
    plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>
  ) {
    if (!mapRef.current || mapType !== MapType.GenerateBuilding) return;

    const basePoint = GetPlotOrigin(plotPolygon);
    const floorGap = 0.2;
    const scaleDown = 75000000;

    const allFeatures: GeoJSON.Feature[] = [];

    currentLayouts.forEach((layout, layoutIndex) => {
      const buildingId = `building-${layoutIndex}`;
      const features = buildings.features.filter(
        (x) => x.properties?.buildingId === buildingId
      );

      if (features.length > 0) {
        allFeatures.push(...features);
        return;
      }

      layout.floorPlans.forEach((floorPlan, floorIndex) => {
        floorPlan.elements.forEach((element) => {
          const { spatialLocation, dimensions, color } = element;

          const minX = basePoint[0] + spatialLocation.x / scaleDown;
          const minY = basePoint[1] + spatialLocation.y / scaleDown;
          const maxX = dimensions?.xSizeInMm
            ? minX + dimensions.xSizeInMm / scaleDown
            : minX;
          const maxY = dimensions?.ySizeInMm
            ? minY + dimensions.ySizeInMm / scaleDown
            : minY;

          const baseZ = spatialLocation.z / 1500 + floorIndex * floorGap;
          const height =
            baseZ + (dimensions?.zSizeInMm ? dimensions.zSizeInMm / 1500 : 0);

          const elementFeature: GeoJSON.Feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [minX, minY],
                  [maxX, minY],
                  [maxX, maxY],
                  [minX, maxY],
                  [minX, minY],
                ],
              ],
            },
            properties: {
              buildingId,
              spaceId: element.spaceId,
              floorIndex,
              baseHeight: baseZ,
              height,
              color,
            },
          };

          allFeatures.push(elementFeature);

          if (floorIndex > 0) {
            const gapFeature: GeoJSON.Feature = {
              type: "Feature",
              geometry: elementFeature.geometry,
              properties: {
                buildingId,
                spaceId: element.spaceId,
                floorIndex,
                baseHeight: baseZ - floorGap,
                height: baseZ,
                color: "#000000",
                isGap: true,
              },
            };
            allFeatures.push(gapFeature);
          }
        });
      });
    });

    setBuildings({ type: "FeatureCollection", features: allFeatures });
  }

  const customSimpleSelectMode = (() => {
    const simpleSelectMode = { ...MapboxDraw.modes.simple_select };
    let isDragging = false;
    let dragStartCoords: [number, number] | null = null;
    let selectedBuildingId: string | null = null;

    simpleSelectMode.onMouseDown = function (
      _state,
      e: mapboxgl.MapMouseEvent
    ) {
      if (!mapRef.current || e.originalEvent.button !== 0) return;

      const clickedFeatures = mapRef.current.queryRenderedFeatures(e.point, {
        layers: ["buildings-3d"],
      });

      const selectedFeature = clickedFeatures[0];
      if (selectedFeature && selectedFeature.properties?.buildingId) {
        selectedBuildingId = selectedFeature.properties.buildingId;
        isDragging = true;
        dragStartCoords = [e.lngLat.lng, e.lngLat.lat];

        (mapRef.current as mapboxgl.Map).dragPan.disable();
        (mapRef.current as mapboxgl.Map).scrollZoom.disable();
        (mapRef.current as mapboxgl.Map).doubleClickZoom.disable();

        mapRef.current.on("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      }
    };

    const handleMouseMove = (e: mapboxgl.MapMouseEvent) => {
      if (isDragging && dragStartCoords && selectedBuildingId) {
        const deltaX = e.lngLat.lng - dragStartCoords[0];
        const deltaY = e.lngLat.lat - dragStartCoords[1];

        setBuildings(
          (
            prevBuildings: GeoJSON.FeatureCollection<
              GeoJSON.Geometry,
              GeoJSON.GeoJsonProperties
            >
          ) => {
            const newFeatures = prevBuildings.features.map((feature) => {
              if (feature.properties?.buildingId === selectedBuildingId) {
                const newCoordinates = (
                  feature.geometry as GeoJSON.Polygon
                ).coordinates[0].map(([x, y]) => [x + deltaX, y + deltaY]);
                return {
                  ...feature,
                  geometry: {
                    ...feature.geometry,
                    coordinates: [newCoordinates],
                  },
                } as GeoJSON.Feature<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
                >;
              }
              return feature;
            });
            return { ...prevBuildings, features: newFeatures };
          }
        );

        dragStartCoords = [e.lngLat.lng, e.lngLat.lat];
      }
    };

    const handleMouseUp = () => {
      if (isDragging) {
        isDragging = false;
        dragStartCoords = null;
        selectedBuildingId = null;

        // Re-enable map interactions after drag
        (mapRef.current as mapboxgl.Map).dragPan.enable();
        (mapRef.current as mapboxgl.Map).scrollZoom.enable();
        if (mapRef.current) {
          (mapRef.current as mapboxgl.Map).doubleClickZoom.enable();
        }

        if (mapRef.current) {
          mapRef.current.off("mousemove", handleMouseMove);
        }
        document.removeEventListener("mouseup", handleMouseUp);
      }
    };

    return simpleSelectMode;
  })();

  const customRotateMode = (() => {
    const rotateMode = { ...MapboxDraw.modes.simple_select };
    let isRotating = false;
    let initialClickPoint: [number, number] | null = null;
    let selectedBuildingId: string | null = null;
    let centerOfRotation: [number, number] | null = null;

    rotateMode.onMouseDown = function (_state, e: mapboxgl.MapMouseEvent) {
      if (!mapRef.current || e.originalEvent.button !== 0) return;

      const clickedFeatures = mapRef.current.queryRenderedFeatures(e.point, {
        layers: ["buildings-3d"],
      });

      const selectedFeature = clickedFeatures[0];
      if (selectedFeature && selectedFeature.properties?.buildingId) {
        selectedBuildingId = selectedFeature.properties.buildingId;
        isRotating = true;
        initialClickPoint = [e.lngLat.lng, e.lngLat.lat];

        // Calculate center of the building for rotation
        const buildingFeatures = clickedFeatures.filter(
          (f) => f.properties?.buildingId === selectedBuildingId
        );

        if (buildingFeatures.length > 0) {
          try {
            const validPolygonFeatures = buildingFeatures.filter(
              (feature) => feature.geometry.type === "Polygon"
            );
            const buildingPolygon = turf.combine({
              type: "FeatureCollection",
              features: validPolygonFeatures as turf.Feature<turf.Polygon>[],
            });
            const centerPoint = turf.center(buildingPolygon);
            if (
              centerPoint &&
              centerPoint.geometry &&
              Array.isArray(centerPoint.geometry.coordinates)
            ) {
              centerOfRotation = centerPoint.geometry.coordinates as [
                number,
                number
              ];
            } else {
              throw new Error("Invalid center point");
            }
          } catch (error) {
            console.error("Error calculating center of rotation:", error);
            isRotating = false;
            return;
          }
        } else {
          console.error("No features found for the selected building");
          isRotating = false;
          return;
        }

        // Disable map interactions during rotation
        (mapRef.current as mapboxgl.Map).dragPan.disable();
        (mapRef.current as mapboxgl.Map).scrollZoom.disable();
        (mapRef.current as mapboxgl.Map).doubleClickZoom.disable();

        mapRef.current.on("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      }
    };

    const handleMouseMove = (e: mapboxgl.MapMouseEvent) => {
      if (
        isRotating &&
        initialClickPoint &&
        selectedBuildingId &&
        centerOfRotation
      ) {
        const currentPoint: [number, number] = [e.lngLat.lng, e.lngLat.lat];
        try {
          const initialAngle = turf.bearing(
            centerOfRotation,
            initialClickPoint
          );
          const currentAngle = turf.bearing(centerOfRotation, currentPoint);
          const rotationAngle = currentAngle - initialAngle;
          setBuildings(
            (
              prevBuildings: GeoJSON.FeatureCollection<
                GeoJSON.Geometry,
                GeoJSON.GeoJsonProperties
              >
            ) => {
              const newFeatures = prevBuildings.features.map((feature) => {
                if (feature.properties?.buildingId === selectedBuildingId) {
                  try {
                    const rotatedFeature = turf.transformRotate(
                      feature as turf.AllGeoJSON,
                      rotationAngle,
                      {
                        pivot: centerOfRotation as turf.helpers.Coord,
                      }
                    );
                    return rotatedFeature as GeoJSON.Feature<
                      GeoJSON.Geometry,
                      GeoJSON.GeoJsonProperties
                    >;
                  } catch (error) {
                    console.error("Error rotating feature:", error);
                    return feature;
                  }
                }
                return feature;
              });
              return { ...prevBuildings, features: newFeatures };
            }
          );

          initialClickPoint = currentPoint;
        } catch (error) {
          console.error("Error during rotation:", error);
        }
      }
    };

    const handleMouseUp = () => {
      if (isRotating) {
        isRotating = false;
        initialClickPoint = null;
        selectedBuildingId = null;
        centerOfRotation = null;

        // Re-enable map interactions after rotation
        (mapRef.current as mapboxgl.Map).dragPan.enable();
        (mapRef.current as mapboxgl.Map).scrollZoom.enable();
        (mapRef.current as mapboxgl.Map).doubleClickZoom.enable();

        if (mapRef.current) {
          mapRef.current.off("mousemove", handleMouseMove);
        }
        document.removeEventListener("mouseup", handleMouseUp);
      }
    };

    return rotateMode;
  })();

  useControl<MapboxDraw>(
    () => {
      const modes =
        mapType === MapType.GenerateBuilding
          ? {
              ...MapboxDraw.modes,
              simple_select: customSimpleSelectMode,
              rotate_mode: customRotateMode,
            }
          : { ...MapboxDraw.modes };

      const drawControl = new MapboxDraw({
        ...props,
        modes: modes,
        userProperties: true,
        styles: GetDrawControlStyles(),
      });

      drawControlRef.current = drawControl;
      return drawControl;
    },
    ({ map }) => {
      mapRef.current = map.getMap();
      map.on("load", () => {
        if (!drawedPlot || !drawedPlot.polygon) return;
        setDrawedPlot(drawedPlot);
        drawControlRef.current?.add(drawedPlot.polygon);
        UpdatePolygon(drawedPlot.polygon);
        setNavigateDrawingPlot(true);
        setStylesLoaded(true);

        if (mapType === MapType.GenerateBuilding) {
          setBuildings(exportedBuildings);
        }
      });
      map.on(
        "draw.create",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onCreate?.(evt);
          const createdFeature = evt.features[0];
          if (createdFeature) {
            UpdatePolygon(createdFeature);
            setTimeout(() => {
              drawControlRef.current?.changeMode("simple_select", {
                featureIds: [],
              });
            }, 200);
          }
        }
      );
      map.on(
        "draw.update",
        (evt: {
          features: GeoJSON.Feature<GeoJSON.Geometry>[];
          action: string;
        }) => {
          try {
            props.onUpdate?.(evt);
            const updatedFeature = evt.features[0];
            if (updatedFeature.properties?.kind === "plot") {
              UpdatePolygon(updatedFeature);
            }
          } catch (ex) {
            TrackException(ex);
          }
        }
      );
      map.on(
        "draw.delete",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onDelete?.(evt);
          drawControlRef.current?.deleteAll();
          setDrawedPlot(GetEmptyDrawedPlot());
        }
      );
    },
    ({ map }) => {
      map.off(
        "draw.create",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          onCreate?.(evt);
        }
      );
      map.off(
        "draw.update",
        (evt: {
          features: GeoJSON.Feature<GeoJSON.Geometry>[];
          action: string;
        }) => {
          props.onUpdate?.(evt);
        }
      );
      map.off(
        "draw.delete",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onDelete?.(evt);
        }
      );
    },
    {
      position: position,
    }
  );

  return null;
}
