import { TypeObject } from "../../sharedLogic/types/enums";
import { TrackException } from "../../sharedLogic/logging/LoggingManager";
import { forgeApiClient } from "../../sharedLogic/services/AxiosClient";
import {
  ExploreActions,
  ExploreState,
} from "../../sharedLogic/state/ExploreState/ExploreState";
import { SpaceBuildingDto } from "../types/api";
import { getColor } from "../utils/colors";
import {
  convertCentsToEuro,
  convertMilimetersToMeters,
  convertSquareMilimetersToSquareMeters,
} from "../../sharedLogic/utils/format";
import { UnitType } from "../../sharedLogic/types/UnitType";
import { SpaceType } from "../types/enums";

const baseUrl = "/api/v1";

export const GetSpaceBuildings = async (
  generationProcessId: number,
  currentState?: ExploreState<SpaceBuildingDto> &
    ExploreActions<SpaceBuildingDto>
): Promise<SpaceBuildingDto | null> => {
  try {
    const maxRetries = 10000;
    let retryCount = 0;
    let response;
    let buildingResultCode: string[] | null = null;

    // Polling function to check the process status
    while (retryCount < maxRetries) {
      response = await forgeApiClient.get<any>(
        `${baseUrl}/processes/${generationProcessId}`
      );

      if (response.data.status === "Completed") {
        buildingResultCode = response.data.result;

        if (typeof buildingResultCode === "string") {
          try {
            buildingResultCode = JSON.parse(buildingResultCode);
          } catch (error) {
            console.error(
              "Failed to parse buildingResultCode as JSON",
              buildingResultCode
            );
            throw new Error("buildingResultCode is not a valid array");
          }
        }

        if (!Array.isArray(buildingResultCode)) {
          console.error(
            "buildingResultCode is not an array",
            buildingResultCode
          );
          throw new Error("Expected buildingResultCode to be an array");
        }
        break;
      }

      if (response.data.status === "Failed") {
        console.error("Process failed:", response.data);
        return null;
      }

      // Wait for a short interval before polling again (e.g., 2 seconds)
      await new Promise((resolve) => setTimeout(resolve, 2000));

      retryCount++;
    }

    if (retryCount >= maxRetries) {
      console.error("Process did not complete within the allowed time.");
      return null;
    }

    // Once the process is completed, get the apartment building information
    const buildingsShortInfo = await GetSpaceBuildingShortInfo(
      buildingResultCode as string[]
    );

    const buildingsresult = buildingsShortInfo.map(
      (building: any, index: any) => ({
        ...building,
        id: index,
        typeObject: TypeObject.HSABuilding,
        renderType: "image" as "chart" | "image" | "other",
      })
    );

    currentState?.setObjectsTotalCount(buildingsresult.length);
    return buildingsresult;
  } catch (ex) {
    console.error("Error in GetApartmentBuildings:", ex);
    TrackException(ex);
    throw ex;
  }
};

export async function GetSpaceBuilding(
  buildingCode: string
): Promise<SpaceBuildingDto> {
  try {
    const response = await forgeApiClient.get<SpaceBuildingDto>(
      `${baseUrl}/buildings/${buildingCode}`
    );

    const building = MapSpaceBuilding(response.data);
    building.layout?.floorPlans?.forEach((floorPlan) => {
      floorPlan?.elements?.forEach((element) => {
        element.color = getColor(element.spaceType);
      });
    });

    building.unitMix = [
      {
        name: SpaceType.Appartment1K,
        color: getColor(SpaceType.Appartment1K),
        count: (building.ratioAppartment1K / 100) * building.nrOfAppartments,
        percentage: building.ratioAppartment1K,
      },
      {
        name: SpaceType.Appartment2K,
        color: getColor(SpaceType.Appartment2K),
        count: (building.ratioAppartment2K / 100) * building.nrOfAppartments,
        percentage: building.ratioAppartment2K,
      },
      {
        name: SpaceType.Appartment3K,
        color: getColor(SpaceType.Appartment3K),
        count: (building.ratioAppartment3K / 100) * building.nrOfAppartments,
        percentage: building.ratioAppartment3K,
      },
      {
        name: SpaceType.Appartment4K,
        color: getColor(SpaceType.Appartment4K),
        count: (building.ratioAppartment4K / 100) * building.nrOfAppartments,
        percentage: building.ratioAppartment4K,
      },
    ] as UnitType[];

    return building;
  } catch (error) {
    throw error;
  }
}

const GetSpaceBuildingShortInfo = async (
  buildingResultCode: string[]
): Promise<any> => {
  try {
    const formattedCodes = buildingResultCode
      .map((code) => `codes=${encodeURIComponent(code)}`)
      .join("&");

    const response = await forgeApiClient.get<any>(
      `${baseUrl}/buildings?${formattedCodes}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAllSpaceBuildingsShortInfo = async (
  currentState?: ExploreState<SpaceBuildingDto> &
    ExploreActions<SpaceBuildingDto>
): Promise<any> => {
  try {
    const response = await forgeApiClient.get<SpaceBuildingDto[]>(
      `${baseUrl}/buildings`
    );

    const buildingsresult = response.data.map(
      (building: SpaceBuildingDto, index: number) => ({
        ...MapSpaceBuilding(building),
        id: index,
        fsi: 0,
        bvoGo: 0,
        valuePerGO: 0,
        investmentValue: 0,
        directConstructionCosts: 0,
        absoluteValue: 0,
        co2: 0,
        costsVSRevenues: 0,
        renderType: "image" as "chart" | "image" | "other",
        view2d_png: relevantImageSrc(building.accessType),
      })
    );
    currentState?.setObjectsTotalCount(buildingsresult.length);
    return buildingsresult;
  } catch (error) {
    throw error;
  }
};

function relevantImageSrc(accessType: string): string {
  switch (accessType) {
    case "HACGallery":
      return "/assets/tile_images/matrices/galleryMatrix.png";
    case "HACCorridor":
      return "/assets/tile_images/matrices/corridorMatrix.png";
    case "HACPorchway":
      return "/assets/tile_images/matrices/porchMatrix.png";
    default:
      return "none";
  }
}

function MapSpaceBuilding(building: SpaceBuildingDto): SpaceBuildingDto {
  const convertedBuilding = {
    ...building,
    renderType: "image",
    view2d_png: relevantImageSrc(building.accessType),
    typeObject: TypeObject.HSABuilding,
    dimensions: {
      xSizeInM: convertMilimetersToMeters(building.dimensions?.xSizeInMm),
      ySizeInM: convertMilimetersToMeters(building.dimensions?.ySizeInMm),
      zSizeInM: convertMilimetersToMeters(building.dimensions?.zSizeInMm),
    },
    structuralHeightInM: convertMilimetersToMeters(
      building.structuralHeightInMm
    ),
    functionalHeightInM: convertMilimetersToMeters(
      building.functionalHeightInMm
    ),
    grossFloorAreaLivingInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaLivingInMm2
    ),
    grossFloorAreaOutdoorInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaOutdoorInMm2
    ),
    grossFloorAreaInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaInMm2
    ),
    grossFacadeAreaInM2: convertSquareMilimetersToSquareMeters(
      building.grossFacadeAreaInMm2
    ),
    grossRoofAreaInM2: convertSquareMilimetersToSquareMeters(
      building.grossRoofAreaInMm2
    ),
    grossFootprintAreaInM2: convertSquareMilimetersToSquareMeters(
      building.grossFootprintAreaInMm2
    ),
    usableAreaTotalInM2: convertSquareMilimetersToSquareMeters(
      building.usableAreaTotalInMm2
    ),
    grossFloorAreaAppartment1KInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaAppartment1KInMm2
    ),
    grossFloorAreaAppartment2KInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaAppartment2KInMm2
    ),
    grossFloorAreaAppartment3KInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaAppartment3KInMm2
    ),
    grossFloorAreaAppartment4KInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaAppartment4KInMm2
    ),
    costInEuro: convertCentsToEuro(building.costInEuroCents),
  } as SpaceBuildingDto;

  return convertedBuilding;
}
