import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { memo, useMemo } from "react";
import * as THREE from "three";
import { Element } from "../../../../HSA/types/api";
import { TypicalDTO } from "../../../state/ExploreState/ExploreState";

interface SceneCanvasProps {
  currentObject: TypicalDTO;
  setLoadedCanvas: (loaded: boolean) => void;
}

const SceneCanvas: React.FC<SceneCanvasProps> = memo(
  ({ currentObject, setLoadedCanvas }) => {
    const buildingMetrics = useMemo(() => {
      let minX = Infinity,
        maxX = -Infinity;
      let minY = Infinity,
        maxY = -Infinity;
      let minZ = Infinity,
        maxZ = -Infinity;

      currentObject.layout.floorPlans.forEach((floorPlan) => {
        floorPlan.elements.forEach((element) => {
          const { x, y, z } = element.spatialLocation;
          const { xSizeInMm, ySizeInMm, zSizeInMm } = element.dimensions;

          const xEnd = x + (xSizeInMm ?? 0);
          const yEnd = y + (ySizeInMm ?? 0);
          const zEnd = z + (zSizeInMm ?? 0);

          minX = Math.min(minX, x);
          maxX = Math.max(maxX, xEnd);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, yEnd);
          minZ = Math.min(minZ, z);
          maxZ = Math.max(maxZ, zEnd);
        });
      });

      const centerX = (minX + maxX) / 2000;
      const centerY = (minY + maxY) / 2000;
      const centerZ = (minZ + maxZ) / 2000;

      const sizeX = (maxX - minX) / 1000;
      const sizeY = (maxY - minY) / 1000;
      const sizeZ = (maxZ - minZ) / 1000;

      const maxDimension = Math.max(sizeX, sizeY, sizeZ);
      const cameraDistance = maxDimension * 1.25;

      return {
        center: { x: centerX, y: centerY, z: centerZ },
        size: { x: sizeX, y: sizeY, z: sizeZ },
        cameraDistance,
      };
    }, [currentObject]);

    interface BoxComponentProps {
      element: Element;
      buildingCenter: { x: number; y: number; z: number };
    }

    const BoxComponent: React.FC<BoxComponentProps> = ({
      element,
      buildingCenter,
    }) => {
      const { xSizeInMm, ySizeInMm, zSizeInMm } = element.dimensions;
      const { x, y, z } = element.spatialLocation;

      const boxGeometry = new THREE.BoxGeometry(
        (xSizeInMm ?? 0) / 1000,
        (zSizeInMm ?? 0) / 1000,
        (ySizeInMm ?? 0) / 1000
      );

      const position = [
        -(x / 1000 + (xSizeInMm ?? 0) / 2000) + buildingCenter.x,
        z / 1000 + (zSizeInMm ?? 0) / 2000,
        y / 1000 + (ySizeInMm ?? 0) / 2000 - buildingCenter.y,
      ];

      return (
        <mesh
          position={new THREE.Vector3(...position)}
          geometry={boxGeometry}
          scale={[1, 1, 1]}
          rotation={new THREE.Euler(0, 0, 0)}
        >
          <meshBasicMaterial
            attach="material"
            color={element.color}
            opacity={1}
            transparent={false}
          />
          <lineSegments>
            <edgesGeometry args={[boxGeometry]} />
            <lineBasicMaterial color="black" />
          </lineSegments>
        </mesh>
      );
    };

    const ThreeBox: React.FC = () => {
      const camera = new THREE.PerspectiveCamera(
        65,
        window.innerWidth / window.innerHeight,
        0.1,
        10000
      );

      const cameraX = -buildingMetrics.cameraDistance;
      const cameraY = buildingMetrics.cameraDistance * 0.75;
      const cameraZ = -buildingMetrics.cameraDistance;

      camera.position.set(cameraX, cameraY, cameraZ);
      camera.lookAt(new THREE.Vector3(0, 0, 0));

      return (
        <Canvas shadows camera={camera}>
          <OrbitControls
            enableDamping
            dampingFactor={0.1}
            rotateSpeed={0.5}
            target={new THREE.Vector3(0, 0, 0)}
            mouseButtons={{
              RIGHT: THREE.MOUSE.ROTATE,
              MIDDLE: THREE.MOUSE.PAN,
              LEFT: THREE.MOUSE.PAN,
            }}
          />
          <ambientLight intensity={0.5} />
          <directionalLight position={[-10, 10, -5]} intensity={1} />
          {currentObject.layout.floorPlans.map((floorPlan, idx) => (
            <React.Fragment key={idx}>
              {floorPlan.elements.map((element, elemIdx) => (
                <BoxComponent
                  key={elemIdx}
                  element={element}
                  buildingCenter={buildingMetrics.center}
                />
              ))}
            </React.Fragment>
          ))}
        </Canvas>
      );
    };

    return (
      <group rotation={[0, 0, 0]}>
        <ThreeBox />
      </group>
    );
  }
);

export default SceneCanvas;
