import { SprinklerBassinDto } from "../../../interfaces/foundry/typicals/SprinklerBassinDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetSprinklerBassin(
  id: number
): Promise<SprinklerBassinDto> {
  return (await GetSprinklerBassins()).find(
    (x) => x.id === id
  ) as SprinklerBassinDto;
}

export async function GetSprinklerBassins(): Promise<SprinklerBassinDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let sprinklerBassins = store.sprinklerBassins;
  if (sprinklerBassins.length === 0) {
    sprinklerBassins = await GetSprinklerBassinDtos();
    store.setSprinklerBassins(sprinklerBassins);
  }
  return sprinklerBassins;
}

async function GetSprinklerBassinDtos(): Promise<SprinklerBassinDto[]> {
  try {
    const bassins = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/sprinklerbassins"))
        .data.results as SprinklerBassinDto[]
    ).map((item: SprinklerBassinDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return bassins;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
