import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFilePicker } from "use-file-picker";
import AddButton from "../../../../components/AddButton/AddButton";
import { ShowSuccess } from "../../../../http/NotificationService";
import { ExportIcon } from "../../../../icons/ExportIcon";
import { ImportIcon } from "../../../../icons/ImportIcon";
import { BaseDatabaseOptionDto } from "../../../../interfaces/aggregate/BaseDatabaseOptionDto";
import "./AddExportImportView.scss";

interface AddExportImportViewProps {
  currentValues: BaseDatabaseOptionDto[];
  reloadResources: () => void;
  setCurrentValues: (value: BaseDatabaseOptionDto[]) => void;
  exportToCsv: () => void;
  importFromCsv: (formData: FormData) => any;
}

export default function AddExportImportView({
  currentValues,
  exportToCsv,
  setCurrentValues,
  importFromCsv,
  reloadResources,
}: AddExportImportViewProps) {
  const { t } = useTranslation();
  const { openFilePicker } = useFilePicker({
    accept: ".csv",
    onFilesSuccessfullySelected: async ({
      plainFiles,
    }: {
      plainFiles: File[];
    }) => {
      const formData = new FormData();
      formData.append("file", plainFiles[0]);
      const importedData = await importFromCsv(formData);
      if (importedData) {
        ShowSuccess(t("Data imported successfully"));
        reloadResources();
      }
    },
  });

  return (
    <div className="add-export-import-view">
      <AddButton
        width={40}
        hoverDisabled={true}
        disabled={currentValues.find((x) => x.id === 0) !== undefined}
        disableRipple={true}
        onClick={() => {
          setCurrentValues([
            ...currentValues,
            {
              id: 0,
              name: "",
            } as BaseDatabaseOptionDto,
          ]);
        }}
        fontSize="1.5rem"
      />
      <Tooltip title={t("Import")}>
        <IconButton
          sx={{
            zIndex: 1,
            borderRadius: "50%",
            maxHeight: "45px",
            marginLeft: "-5px",
          }}
          onClick={() => {
            openFilePicker();
          }}
        >
          <ImportIcon size={20} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Export")}>
        <IconButton
          sx={{
            zIndex: 1,
            borderRadius: "50%",
            maxHeight: "45px",
          }}
          onClick={async () => {
            await exportToCsv();
          }}
        >
          <ExportIcon className="svg" size={22} />
        </IconButton>
      </Tooltip>
    </div>
  );
}
