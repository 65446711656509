import { TypeObject } from "../../../../../interfaces/enums/TypeObjectEnum";
import useTransformatorStore from "../../../../../state/ComponentGeneratorState/transformatorState";
import BaseSelectComponentView from "../../../../Components/BaseSelectComponentView";

export default function PreselectionTransformatorView() {
  const transformatorStore = useTransformatorStore();

  return (
    <BaseSelectComponentView
      type={TypeObject.Transformator}
      currentState={transformatorStore}
    />
  );
}
