import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomInputLabel from "../../../../components/InputLabel/CustomInputLabel";
import { SpaceBuildingDto } from "../../types/api";
import "./BestFitRate.scss";
import { ExploreConfigStore } from "../../../sharedLogic/types/ExploreConfig";

interface InputPaneProps<T extends SpaceBuildingDto> {
  currentTypicalConfigStore: ExploreConfigStore<T>;
}

export default function BestFitRate<T extends SpaceBuildingDto>({
  currentTypicalConfigStore,
}: InputPaneProps<T>) {
  const { t } = useTranslation();
  const currentState = currentTypicalConfigStore?.currentStore();

  const options = Object.values(
    currentTypicalConfigStore?.bestFitOptionsDictionary ?? []
  );

  const getDisplayName = (value: string) =>
    t(currentTypicalConfigStore.bestFitOptionsDictionaryReversed![value]);

  const handleChangeBestFitRateOptions = (
    event: SelectChangeEvent<typeof options>
  ) => {
    if (!currentState) return;

    const selectedValue = event.target.value as string[];
    let selectedValues: string[] = [];
    if (selectedValue[selectedValue.length - 1] === "all") {
      selectedValues =
        currentState.selectedAxes.length === options.length ? [] : options;
    } else {
      selectedValues = selectedValue.map(
        (key) => currentState.filterControlOptionsDictionary[key] ?? key
      );
    }
    currentState.setSelectedAxes(selectedValues);
  };

  return (
    <FormControl
      fullWidth
      variant="standard"
      style={{ marginTop: "20px", marginBottom: "10px" }}
    >
      <CustomInputLabel
        id="best-fit-rate-label"
        showInfoButton={true}
        labelText={t("Best fit rate")}
        tooltipText={t("BestFitRateTooltip")}
      />
      <Select
        multiple
        style={{ height: "auto" }}
        labelId="type-best-fit-rate-label"
        id="type-best-fit-rate-select"
        value={currentState.selectedAxes}
        onChange={handleChangeBestFitRateOptions}
        label={t("Best fit rate")}
        renderValue={(selected) => (
          <Box
            component={"div"}
            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
          >
            {selected
              .sort((x, y) => (x > y ? 1 : x < y ? -1 : 0))
              .map((value) => (
                <Chip
                  key={getDisplayName(value)}
                  label={getDisplayName(value)}
                />
              ))}
          </Box>
        )}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={
                options.length > 0 &&
                currentState.selectedAxes.length === options.length
              }
              indeterminate={
                currentState.selectedAxes.length > 0 &&
                currentState.selectedAxes.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText primary={t("Select all")} />
        </MenuItem>
        {Object.keys(currentTypicalConfigStore.bestFitOptionsDictionary!)
          .sort((a, b) => t(a).localeCompare(t(b)))
          .map((name) => (
            <MenuItem
              key={t(name)}
              value={t(
                currentTypicalConfigStore.bestFitOptionsDictionary![name]
              )}
            >
              <Checkbox
                checked={currentState?.selectedAxes?.includes(
                  t(currentTypicalConfigStore.bestFitOptionsDictionary![name])
                )}
              />
              <ListItemText primary={t(name)} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
