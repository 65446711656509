import { create } from "zustand";
import { getBaseInitialState } from "../ExploreState/ExploreState";
import SummaryInfoDto from "../../interfaces/SummaryInfoDto";
import { ScenarioDto } from "../../modules/Analysers/types/api";

export interface ScenarioState {
  objects: ScenarioDto[] | null;
  objectsTotalCount: number;
  objectSortProperty: string;
  objectSortAscending: boolean;
  brushedObjects: ScenarioDto[];
  loadedObjects: ScenarioDto[];
  selectedIds: number[];
  filteredObjects: ScenarioDto[];
  filteredWithCoolingTypes: ScenarioDto[];
  isObjectsLoaded: boolean;
  hoveredObject: ScenarioDto | null;
  isReset: boolean;
  currentObject: ScenarioDto | null;
  isOpenedDetailedView: boolean;
  selectedAxes: string[];
  dimensions: string[];
  chartLabels: string[];
  clickableAxes: string[];
  filterControlOptionsDictionary: { [key: string]: string };
  filterControlOptionsDictionaryReversed: { [key: string]: string };
  filterControlSelectedAxesScenario: string[];
  summaryInfo: SummaryInfoDto[];
  sortByOptionsDictionary: { [key: string]: string };
}

interface ScenarioActions {
  setObjects: (newScenario: ScenarioDto[] | null) => void;
  resetScenarioState: () => void;
  setSelectedAxes: (newValue: string[]) => void;
  setObjectsTotalCount: (newValue: number) => void;
  setObjectSortProperty: (newValue: string) => void;
  setObjectSortAscending: (newValue: boolean) => void;
  setBrushedObjects: (newValue: ScenarioDto[]) => void;
  setLoadedObjects: (newValue: ScenarioDto[]) => void;
  setSelectedIds: (newValue: number[]) => void;
  setFilteredObjects: (newValue: ScenarioDto[]) => void;
  setFilteredObjectsWithCoolingTypes: (newValue: ScenarioDto[]) => void;
  setIsObjectsLoaded: (newValue: boolean) => void;
  setHoveredObject: (newValue: ScenarioDto | null) => void;
  setIsReset: (newIsReset: boolean) => void;
  setCurrentObject: (newValue: ScenarioDto | null) => void;
  setIsOpenedDetailedView: (newValue: boolean) => void;
  setFilterControlSelectedAxesScenario: (newValue: string[]) => void;
  setFilterControlOptionsDictionaryReversed: (newValue: {
    [key: string]: string;
  }) => void;
}

const initialState: ScenarioState = GetInitialState();
function GetInitialState(): ScenarioState {
  const baseState = getBaseInitialState<ScenarioDto>();
  return {
    ...baseState,
    objects: [],
    brushedObjects: [],
    loadedObjects: [],
    selectedIds: [],
    filteredObjects: [],
    filteredWithCoolingTypes: [],
    isObjectsLoaded: false,
    hoveredObject: null,
    isReset: false,
    objectsTotalCount: 0,
    objectSortAscending: true,
    objectSortProperty: "Best fit rate",
    currentObject: null,
    isOpenedDetailedView: false,
    selectedAxes: ["totalNitrogenEmission"],
    dimensions: [
      "annualNitrogenLimitExceeded",
      "totalNitrogenEmission",
      "totalAmmoniaEmission",
      "totalCarbonEmission",
      "electricInProcent",
      "fuelConsumption",
    ],
    chartLabels: [
      "NitrogenPage.AnnualLimitExceed",
      "NitrogenPage.TotalNitrogenEmission",
      "NitrogenPage.TotalAmmoniaEmission",
      "NitrogenPage.TotalCarbonEmission",
      "NitrogenPage.ElectricInProcent",
      "NitrogenPage.LitersOfFuel",
    ],
    clickableAxes: [
      "annualNitrogenLimitExceeded",
      "totalAmmoniaEmission",
      "totalNitrogenEmission",
      "totalCarbonEmission",
      "electricInProcent",
      "litersOfFuel",
    ],
    filterControlOptionsDictionary: {
      "NitrogenPage.AnnualLimitExceed": "annualNitrogenLimitExceeded",
      "NitrogenPage.TotalAmmoniaEmission": "totalAmmoniaEmission",
      "NitrogenPage.TotalNitrogenEmission": "totalNitrogenEmission",
      "NitrogenPage.TotalCarbonEmission": "totalCarbonEmission",
      "NitrogenPage.ElectricInProcent": "electricInProcent",
      "NitrogenPage.LitersOfFuel": "litersOfFuel",
    },
    filterControlOptionsDictionaryReversed: {
      annualNitrogenLimitExceeded: "NitrogenPage.AnnualLimitExceed",
      totalAmmoniaEmission: "NitrogenPage.TotalAmmoniaEmission",
      totalNitrogenEmission: "NitrogenPage.TotalNitrogenEmission",
      totalCarbonEmission: "NitrogenPage.TotalCarbonEmission",
      electricInProcent: "NitrogenPage.ElectricInProcent",
      litersOfFuel: "NitrogenPage.LitersOfFuel",
    },
    filterControlSelectedAxesScenario: ["totalNitrogenEmission"],
    summaryInfo: [
      {
        label: "NitrogenPage.AnnualLimitExceed",
        property: "annualNitrogenLimitExceeded",
        unit: "",
      },
      {
        label: "NitrogenPage.LitersOfFuel",
        property: "fuelConsumption",
        unit: "L",
      },
      {
        label: "NitrogenPage.ElectricInProcent",
        property: "electricInProcent",
        unit: "",
      },
    ],
    sortByOptionsDictionary: {
      "Best fit rate": "value",
    },
  };
}

const useScenarioStore = create<ScenarioState & ScenarioActions>()((set) => ({
  ...initialState,
  setSelectedAxes: (newValue) => set({ selectedAxes: newValue }),
  setObjects: (newScenario) => set({ objects: newScenario }),

  setObjectsTotalCount: (newValue) => set({ objectsTotalCount: newValue }),
  setObjectSortProperty: (newValue) => set({ objectSortProperty: newValue }),
  setObjectSortAscending: (newValue) => set({ objectSortAscending: newValue }),
  setBrushedObjects: (newValue) => set({ brushedObjects: newValue }),
  setLoadedObjects: (newScenario) => set({ loadedObjects: newScenario }),
  setSelectedIds: (newValue) => set({ selectedIds: newValue }),
  setFilteredObjects: (newValue) => set({ filteredObjects: newValue }),
  setFilteredObjectsWithCoolingTypes: (newScenario) =>
    set({ filteredWithCoolingTypes: newScenario }),
  setIsObjectsLoaded: (newValue) => set({ isObjectsLoaded: newValue }),
  setHoveredObject: (newValue) => set({ hoveredObject: newValue }),
  setIsReset: (newIsReset) => set({ isReset: newIsReset }),
  setCurrentObject: (newValue) => set({ currentObject: newValue }),
  setIsOpenedDetailedView: (newValue) =>
    set({ isOpenedDetailedView: newValue }),
  resetScenarioState: () => {
    set(GetInitialState());
  },
  setFilterControlSelectedAxesScenario: (newValue) =>
    set({ filterControlSelectedAxesScenario: newValue }),
  setFilterControlOptionsDictionaryReversed: (newValue) =>
    set({ filterControlOptionsDictionaryReversed: newValue }),
}));

export default useScenarioStore;
