import { GensetDto } from "../../../interfaces/foundry/typicals/GensetDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";

export async function GetGenset(id: number): Promise<GensetDto> {
  return (await GetGensets()).find((x) => x.id === id) as GensetDto;
}

export async function GetGensets(): Promise<GensetDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let gensets = store.gensets;
  if (gensets.length === 0) {
    gensets = await GetGensetDtos();
    store.setGensets(gensets);
  }
  return gensets;
}

async function GetGensetDtos(): Promise<GensetDto[]> {
  try {
    return (
      (await foundryApiClient.get<ResponseDto>("/api/general/gensets")).data
        .results as GensetDto[]
    ).map((item: GensetDto) => {
      item = {
        ...item,
        type: item.display_name,
        power_in_w: (item.power_in_w ?? 0) / 1000000,
      };
      return item;
    });
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
