import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Collapse, IconButton } from "@mui/material";
import React from "react";
import "./LeftSidebarViewLayout.scss";
import { ChevronLeft } from "@mui/icons-material";

interface LeftSidebarViewLayoutProps {
  withCollapse: boolean;
  columnOne: React.ReactNode;
  columnTwo: React.ReactNode;
}

export default function LeftSidebarViewLayout({
  columnOne,
  columnTwo,
  withCollapse,
}: LeftSidebarViewLayoutProps) {
  const [isInputPaneOpen, setIsInputPaneOpen] = React.useState<boolean>(true);

  const columnOneClassName = isInputPaneOpen
    ? "first-column"
    : "first-column-pane-closed";

  const columnTwoClassName = isInputPaneOpen
    ? "second-column"
    : "second-column-pane-closed";

  return withCollapse ? (
    <div className="left-sidebar-view-layout">
      <div className={columnOneClassName}>
        <Collapse orientation="horizontal" in={isInputPaneOpen}>
          {columnOne}
          {isInputPaneOpen && (
            <div className="collapse-button">
              <IconButton
                style={{ zIndex: 1 }}
                onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
              >
                <ChevronLeft sx={{ fontSize: "2.5rem" }} />{" "}
              </IconButton>
            </div>
          )}
        </Collapse>
      </div>

      <div className={columnTwoClassName}>{columnTwo}</div>
      {!isInputPaneOpen && (
        <div className="expand-button">
          <IconButton
            style={{ backgroundColor: "transparent", zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronRightIcon sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}
    </div>
  ) : (
    <div className="left-sidebar-view-layout">
      <div className="first-column">{columnOne}</div>
      <div className="second-column">{columnTwo}</div>
    </div>
  );
}
