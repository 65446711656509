import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import {
  GridRenderEditCellParams,
  GridTreeNodeWithRender,
  useGridApiContext,
} from "@mui/x-data-grid";
import { t } from "i18next";
import * as React from "react";
import { BaseDatabaseOptionDto } from "../../../../interfaces/aggregate/BaseDatabaseOptionDto";

interface AutoCompleteProps {
  params: GridRenderEditCellParams<any, any, any, GridTreeNodeWithRender>;
  renderOptions: BaseDatabaseOptionDto[] | string[];
  multiselect?: boolean;
  onChange?: (event: React.SyntheticEvent, newValue: string | null) => void;
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  params,
  renderOptions,
  multiselect,
  onChange,
}) => {
  const [open, setOpen] = React.useState(false);
  const { id, value, field } = params;

  const currentValue = multiselect && value === "" ? [] : value;  
  const apiRef = useGridApiContext();

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: string | null) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
      setOpen(false);
      if (onChange) {
        onChange(event, newValue);
      }
    },
    [apiRef, field, id, onChange]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason !== "selectOption") {
      setOpen(false);
    }
  };

  return (
    <Autocomplete
      sx={{
        height: "100%",
        [`& .${autocompleteClasses.inputRoot}`]: {
          padding: "1px 0",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          "& input": {
            padding: "0 16px",
            height: "100%",
          },
        },
      }}
      multiple={multiselect ?? false}
      noOptionsText={t("No options")}
      open={open}
      value={currentValue}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      options={renderOptions}
      getOptionLabel={(option) =>
        option?.name === undefined ? option : option.name
      }
      autoHighlight
      fullWidth
      disableClearable
      renderInput={(params) => (
        <InputBase
          fullWidth
          id={params.id}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
          {...params.InputProps}
        />
      )}
    />
  );
};
