import { TypeObject } from "../../../../../interfaces/enums/TypeObjectEnum";
import useRackStore from "../../../../../state/ComponentGeneratorState/rackState";
import BaseSelectComponentView from "../../../../Components/BaseSelectComponentView";

export default function PreselectionRackView() {
  const rackStore = useRackStore();

  return (
    <BaseSelectComponentView type={TypeObject.Rack} currentState={rackStore} />
  );
}
