import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetCoolingPrinciples,
  GetDesiredPowerWattages,
  GetPowerRackWattages,
} from "../../../http/foundry/InputPaneService";
import { GetWhitespaces } from "../../../http/foundry/WhitespaceService";
import { BuildingTypeEnum } from "../../../interfaces/enums/BuildingTypeEnum";
import WhitespaceDto from "../../../interfaces/foundry/WhitespaceDto";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { GetUnqiueNumbers } from "../../../utils/HelperFunctions";
import { createExploreStore } from "../../../state/ExploreState/ExploreState";

interface GenerateDataDatacenterWhitespaceProps {
  selectedWhitespaces: number[];
  setSelectedWhitespaces(whitespaces: number[]): void;
}

export default function GenerateDataDatacenterWhitespace({
  selectedWhitespaces,
  setSelectedWhitespaces,
}: GenerateDataDatacenterWhitespaceProps) {
  const { t } = useTranslation();

  const [powerRack, setPowerRack] = useState<number[]>([]);
  const [whitespaces, setWhitespaces] = useState<WhitespaceDto[]>([]);
  const [filteredWhitespaces, setFilteredWhitespaces] = useState<
    WhitespaceDto[]
  >([]);
  const [coolingPrinciple, setCoolingPrinciple] = useState<string[]>([]);
  const [powerWhitespaces, setPowerWhitespaces] = useState<number[]>([]);

  const whitespaceState = createExploreStore<WhitespaceDto>();
  const { objects, selectedIds } = whitespaceState();
  const {
    powerRackOptions,
    desiredPowerOptions,
    coolingPrincipleOptions,
    setCoolingPrincipleOptions,
    setDesiredPowerOptions,
    setPowerRackOptions,
  } = useGeneralDatacenterObjectsStore();

  const handleChangeWhitespace = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[];
    if (selectedIds.includes("all")) {
      setSelectedWhitespaces(
        selectedWhitespaces.length === filteredWhitespaces.length
          ? []
          : filteredWhitespaces.map((x) => x.id)
      );
    } else {
      setSelectedWhitespaces(
        filteredWhitespaces
          .map((x) => x.id)
          .filter((id) => GetUnqiueNumbers(selectedIds).includes(id))
      );
    }
  };

  const handleChangePowerWhitespaces = (
    event: SelectChangeEvent<typeof powerWhitespaces>
  ) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all")
      setPowerWhitespaces(
        powerWhitespaces.length === desiredPowerOptions.length
          ? []
          : desiredPowerOptions
      );
    else
      setPowerWhitespaces(
        typeof value === "string" ? value.split(",").map(Number) : value
      );
  };

  const handleChangeCoolingPrincible = (
    event: SelectChangeEvent<typeof coolingPrinciple>
  ) => {
    const value = event.target.value;
    const valuesCooling = Object.values(coolingPrincipleOptions);

    if (value[value.length - 1] === "all")
      setCoolingPrinciple(
        coolingPrinciple.length === valuesCooling.length ? [] : valuesCooling
      );
    else
      setCoolingPrinciple(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangePowerRack = (
    event: SelectChangeEvent<typeof powerRack>
  ) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all")
      setPowerRack(
        powerRack.length === powerRackOptions.length ? [] : powerRackOptions
      );
    else
      setPowerRack(
        typeof value === "string" ? value.split(",").map(Number) : value
      );
  };

  useEffect(() => {
    const filteredWhitespaces = whitespaces.filter(
      (whitespace) =>
        (powerWhitespaces.length === 0 ||
          powerWhitespaces.includes(whitespace.desired_power_in_w)) &&
        (powerRack.length === 0 ||
          powerRack.includes(whitespace.rack_power_in_w)) &&
        (coolingPrinciple.length === 0 ||
          coolingPrinciple.includes(whitespace.cooling_type_string))
    );

    setFilteredWhitespaces(filteredWhitespaces);
    setSelectedWhitespaces(
      selectedWhitespaces.filter((x) =>
        filteredWhitespaces.map((x) => x.id).includes(x)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powerRack, coolingPrinciple, powerWhitespaces]);

  useEffect(() => {
    (async () => {
      try {
        if (desiredPowerOptions.length === 0) {
          setDesiredPowerOptions(await GetDesiredPowerWattages());
        }

        if (powerRackOptions.length === 0) {
          setPowerRackOptions(await GetPowerRackWattages());
        }

        if (coolingPrincipleOptions.length === 0) {
          setCoolingPrincipleOptions(await GetCoolingPrinciples());
        }

        let currentWhitespaces = [] as WhitespaceDto[];
        if (objects === null || objects.length === 0) {
          currentWhitespaces = await GetWhitespaces(
            new AbortController().signal,
            undefined,
            BuildingTypeEnum.Datacenter
          );
        } else {
          currentWhitespaces = (objects as WhitespaceDto[]).filter((x) =>
            x.building_types.some(
              (lvrBt) => lvrBt.id === BuildingTypeEnum.Datacenter
            )
          );

          setSelectedWhitespaces(
            selectedIds.filter((x) =>
              currentWhitespaces.map((x) => x.id).includes(x)
            )
          );
        }

        setWhitespaces(currentWhitespaces);
        setFilteredWhitespaces(currentWhitespaces);
      } catch (ex) {}
    })();
  }, []);

  return (
    <div className="pr10">
      <div className="title mt20">{t("Filters")}</div>
      <Grid
        container
        rowSpacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={5}>
          <div>{`${t("WhitespacePage.PowerWhitespace")}:`}</div>
        </Grid>
        <Grid item xs={7} sx={{ alignContent: "center" }}>
          <FormControl fullWidth variant="standard">
            <Select
              multiple
              value={powerWhitespaces}
              onChange={handleChangePowerWhitespaces}
              style={{ height: "auto" }}
              labelId="type-power-whitespaces-label"
              id="type-power-whitespaces-select"
              renderValue={(selected) => (
                <Box
                  component={"div"}
                  className="chip-container"
                  sx={{ gap: 0.5 }}
                >
                  {selected
                    .sort((n1, n2) => n1 - n2)
                    .map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                </Box>
              )}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      desiredPowerOptions.length > 0 &&
                      powerWhitespaces.length === desiredPowerOptions.length
                    }
                    indeterminate={
                      powerWhitespaces.length > 0 &&
                      powerWhitespaces.length < desiredPowerOptions.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={t("Select all")} />
              </MenuItem>
              {desiredPowerOptions
                .sort((n1, n2) => n1 - n2)
                .map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={powerWhitespaces.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={5}>
          <div>{`${t("WhitespacePage.PowerRack")}:`}</div>
        </Grid>
        <Grid item xs={7} sx={{ alignContent: "center" }}>
          <FormControl fullWidth variant="standard">
            <Select
              multiple
              value={powerRack}
              onChange={handleChangePowerRack}
              style={{ height: "auto" }}
              labelId="type-power-rack-label"
              id="type-power-rack-select"
              renderValue={(selected) => (
                <Box
                  component={"div"}
                  className="chip-container"
                  sx={{ gap: 0.5 }}
                >
                  {selected
                    .sort((n1, n2) => n1 - n2)
                    .map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                </Box>
              )}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      powerRackOptions.length > 0 &&
                      powerRack.length === powerRackOptions.length
                    }
                    indeterminate={
                      powerRack.length > 0 &&
                      powerRack.length < powerRackOptions.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={t("Select all")} />
              </MenuItem>
              {powerRackOptions
                .sort((n1, n2) => n1 - n2)
                .map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={powerRack.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={5}>
          <div>{`${t("WhitespacePage.CoolingPrinciple")}:`}</div>
        </Grid>
        <Grid item xs={7} sx={{ alignContent: "center" }}>
          <FormControl fullWidth variant="standard">
            <Select
              multiple
              style={{ height: "auto" }}
              labelId="type-cooling-principle-label"
              id="type-cooling-principle-select"
              value={coolingPrinciple}
              onChange={handleChangeCoolingPrincible}
              renderValue={(selected) => (
                <Box
                  component={"div"}
                  className="chip-container"
                  sx={{ gap: 0.5 }}
                >
                  {selected
                    .sort((x, y) => (x > y ? 1 : x < y ? -1 : 0))
                    .map((value) => (
                      <Chip key={value} label={t(value)} />
                    ))}
                </Box>
              )}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      Object.values(coolingPrincipleOptions).length > 0 &&
                      coolingPrinciple.length ===
                        Object.values(coolingPrincipleOptions).length
                    }
                    indeterminate={
                      coolingPrinciple.length > 0 &&
                      coolingPrinciple.length <
                        Object.values(coolingPrincipleOptions).length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={t("Select all")} />
              </MenuItem>
              {Object.values(coolingPrincipleOptions)
                .sort((x, y) => (x > y ? 1 : x < y ? -1 : 0))
                .map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={coolingPrinciple.includes(name)} />
                    <ListItemText primary={t(name)} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <div className="title mt20">
        {`${t("Choose")} ${t("Whitespaces").toLowerCase()}`}
      </div>

      <Grid
        container
        rowSpacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={5}>
          <div>{`${t("Whitespaces")}:`}</div>
        </Grid>
        <Grid item xs={7} sx={{ alignContent: "center" }}>
          <FormControl fullWidth variant="standard">
            <Select
              multiple
              style={{ height: "auto" }}
              labelId="whitespace-label"
              id="whitespace-select"
              value={selectedWhitespaces.map((t) => t.toString())}
              onChange={handleChangeWhitespace}
              renderValue={(selectedIds: string[]) => (
                <Box
                  component={"div"}
                  className="chip-container"
                  sx={{ gap: 0.5 }}
                >
                  {selectedIds.map((id) => {
                    return <Chip key={id} label={`${t("Whitespace")} ${id}`} />;
                  })}
                </Box>
              )}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      whitespaces.length > 0 &&
                      selectedWhitespaces.length === whitespaces.length
                    }
                    indeterminate={
                      selectedWhitespaces.length > 0 &&
                      selectedWhitespaces.length < whitespaces.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={t("Select all")} />
              </MenuItem>
              {filteredWhitespaces.map((whitespace) => (
                <MenuItem key={whitespace.id} value={whitespace.id}>
                  <Checkbox
                    checked={selectedWhitespaces.some(
                      (t) => t === whitespace.id
                    )}
                  />
                  <ListItemText
                    primary={`${t("Whitespace")} ${whitespace.id}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
