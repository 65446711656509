import { TypeObject } from "../../../../../interfaces/enums/TypeObjectEnum";
import useBatteryStore from "../../../../../state/ComponentGeneratorState/batteryState";
import BaseSelectComponentView from "../../../../Components/BaseSelectComponentView";

export default function PreselectionBatteryView() {
  const batteryStore = useBatteryStore();

  return (
    <BaseSelectComponentView
      type={TypeObject.Battery}
      currentState={batteryStore}
    />
  );
}
