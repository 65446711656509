import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
} from "@mui/material";
import colors from "../../styles/colors.module.scss";
import CancelButton from "../CancelButton/CancelButton";
import { useTranslation } from "react-i18next";
import { BuildingTypeEnum } from "../../interfaces/enums/BuildingTypeEnum";
import {
  GetWhitespaceGenerationCombinations,
  StartGenerationWhitespaces,
} from "../../http/foundry/WhitespaceService";
import {
  GetLVRGenerationCombinations,
  StartGenerationLVRs,
} from "../../http/foundry/LowVoltageRoomService";
import { ScrewDriverIcon } from "../../icons/ScrewDriverIcon";
import GenerateDataDatacenter from "./GenerateDataDatacenter/GenerateDataDatacenter";

interface GenerateDataFloatingActionButtonProps {
  buildingTypeEnum: BuildingTypeEnum;
}

export default function GenerateDataFloatingActionButton({
  buildingTypeEnum,
}: GenerateDataFloatingActionButtonProps) {
  const { t } = useTranslation();
  const { heijmansBlue } = colors;
  const [isHovered, setIsHovered] = useState(false);
  const [combinations, setCombinations] = useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleOpen = async () => {
    switch (buildingTypeEnum) {
      case BuildingTypeEnum.Whitespace:
        setCombinations(
          (await GetWhitespaceGenerationCombinations()).combinations_count
        );
        break;
      case BuildingTypeEnum.LVR:
        setCombinations(
          (await GetLVRGenerationCombinations()).combinations_count
        );
        break;
    }
    setDialogOpen(true);
  };

  const handleContinue = async () => {
    switch (buildingTypeEnum) {
      case BuildingTypeEnum.Whitespace:
        await StartGenerationWhitespaces();
        break;
      case BuildingTypeEnum.LVR:
        await StartGenerationLVRs();
        break;
    }

    handleClose();
  };

  const handleClose = () => {
    setDialogOpen(false);
    setCombinations(0);
  };

  function GetTitle() {
    switch (buildingTypeEnum) {
      case BuildingTypeEnum.Datacenter:
        return t("Datacenter generation");
      case BuildingTypeEnum.Whitespace:
        return t("Whitespace generation");
      case BuildingTypeEnum.LVR:
        return t("LSR generation");
    }
  }

  function GetType() {
    switch (buildingTypeEnum) {
      case BuildingTypeEnum.Datacenter:
        return t("datacenters");
      case BuildingTypeEnum.Whitespace:
        return t("whitespaces");
      case BuildingTypeEnum.LVR:
        return t("lowvoltagerooms");
    }
  }

  return (
    <div>
      <Dialog
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={dialogOpen}
        PaperProps={{
          style: {
            borderRadius: 20,
            width: "40%",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 16,
            fontWeight: 900,
          }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {GetTitle()}
          <Box component={"div"} sx={{ ml: "auto" }}>
            <CancelButton onClick={handleClose} />
          </Box>
        </DialogTitle>

        {buildingTypeEnum === BuildingTypeEnum.Datacenter ? (
          <GenerateDataDatacenter handleClose={handleClose} />
        ) : (
          <div>
            <DialogContent sx={{ padding: "25px", marginTop: "-20px" }}>
              <div>{`${t(
                "GenerateDataMessage"
              )} ${GetType()} (≈${combinations}).`}</div>
              <br />
              <div>{t("ProceedMessage")}</div>
            </DialogContent>
            <DialogActions sx={{ padding: "0px 25px 10px 20px !important" }}>
              <Button onClick={() => handleClose()}>{t("Cancel")}</Button>
              <Button onClick={() => handleContinue()}>{t("Continue")}</Button>
            </DialogActions>
          </div>
        )}
      </Dialog>

      <Fab
        className="fab"
        aria-label="add"
        sx={{
          zIndex: 100000,
          border: "3px solid white",
          borderColor: heijmansBlue,
          backgroundColor: "white",
          position: "fixed",
          height: 70,
          width: 70,
          bottom: 20,
          right: 20,
          "&:hover": {
            backgroundColor: heijmansBlue,
            "& .icon": {
              color: "white",
            },
          },
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => handleOpen()}
      >
        <ScrewDriverIcon
          className={isHovered ? "icon-hovered" : "icon"}
          size={45}
        />
      </Fab>
    </div>
  );
}
