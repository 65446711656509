import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { GetProjects } from "./http/foundry/ProjectService";
import { ProjectTypeEnum } from "./interfaces/enums/ProjectTypeEnum";
import NitrogenDashboardPage from "./modules/Analysers/components/NitrogenDashboardPage";
import ConfiguratorPage from "./modules/HSA/components/ConfiguratorPage/ConfiguratorPage";
import { CorridorMatrix } from "./modules/HSA/components/SwitchMatrix/CorridorMatrix/CorridorMatrix";
import { GalleryMatrix } from "./modules/HSA/components/SwitchMatrix/GalleryMatrix/GalleryMatrix";
import { PorchMatrix } from "./modules/HSA/components/SwitchMatrix/PorchMatrix/PorchMatrix";
import { ModuleCatalog } from "./modules/HSA/components/ModuleCatalog";
import { useModuleDetailsStore } from "./modules/HSA/state/moduleDetailsState";
import BaseProjectsPage from "./modules/sharedLogic/components/Projects/BaseProjectsPage";
import useProjectsStore from "./modules/sharedLogic/state/projectsState";
import useUserStore from "./modules/sharedLogic/state/userState";
import { GetEmptyProjectDto } from "./modules/sharedLogic/types/api";
import BatteriesDashboardPage from "./pages/Catalog/Datacenter/BatteriesDashboardPage";
import RacksDashboardPage from "./pages/Catalog/Datacenter/RacksDashboardPage";
import TransformatorsDashboardPage from "./pages/Catalog/Datacenter/TransformatorsDashboardPage";
import UpsDashboardPage from "./pages/Catalog/Datacenter/UpsDashboardPage";
import ComponentsPage from "./pages/Components/ComponentsPage";
import DatacenterGeneratorDashboardPage from "./pages/Configurators/Datacenter/DatacenterDashboardPage";
import DatacenterGeneratorProjectsPage from "./pages/Configurators/Datacenter/DatacenterProjectsPage";
import SettingsPage from "./pages/Databases/components/SettingsPage";
import { CostKeyFiguresPage } from "./pages/Databases/CostKeyFigures/CostKeyFiguresPage";
import { EquipmentKeyFiguresPage } from "./pages/Databases/EquipmentKeyFigures/EquipmentKeyFiguresPage";
import { ProductionKeyFiguresPage } from "./pages/Databases/ProductionKeyFigures/ProductionKeyFiguresPage";
import NotFoundPage from "./pages/Error/NotFoundPage";
import LowVoltageRoomToolingDashboardPage from "./pages/Generators/LowVoltageRoom/LowVoltageRoomToolingDashboardPage";
import WhitespaceToolingDashboardPage from "./pages/Generators/Whitespace/WhitespaceToolingDashboardPage";
import OverviewPage from "./pages/Overview/OverviewPage";
import useAvailableComponentsStore from "./state/availableComponentsState";
import useDatabaseStore from "./state/DatabaseState/databaseState";
import useDatacenterGeneratorStore from "./state/DatacenterState/datacenterGeneratorState";
import useInputPaneStore from "./state/DatacenterState/inputPaneState";
import useLayoutStore from "./state/layoutState";
import useNitrogenInputPaneStore from "./state/NitrogenState/nitrogenInputPaneState";
import useNitrogenStore from "./state/NitrogenState/nitrogenState";
import useScenarioStore from "./state/NitrogenState/scenarioState";
import { IsGuid } from "./utils/HelperFunctions";

interface routerType {
  prefix: string;
  path: string;
  element: JSX.Element;
}

export default function Router() {
  const { t } = useTranslation();
  const location = useLocation();

  const { appAbility } = useUserStore();
  const {
    availableGenerators,
    availableDatabases,
    availableConfigurators,
    availableAnalysers,
    availableCatalog,
    availableHeijmansIntelligence,
    availableStackedHousingHeijmansIntelligence,
    availableDatacenterCatalog,
    availableDatacenterCatalogComponents,
  } = useAvailableComponentsStore();

  const { setIsSidebarOpen, setIsSidebarPersistent, setShowBreadcrumb } =
    useLayoutStore();

  const { resetDatabaseState } = useDatabaseStore();

  const { resetInputPaneState } = useInputPaneStore();
  const { resetScenarioState } = useScenarioStore();
  const {
    projects,
    setProjects,
    setCurrentProject,
    resetDatacenterGeneratorState,
  } = useDatacenterGeneratorStore();
  const { setModuleCode: setTypicalCode } = useModuleDetailsStore();

  const pagesData: routerType[] = [
    {
      path: "/overview",
      element: <OverviewPage />,
      prefix: "overview",
    },
    {
      path: "/configurators",
      element: (
        <ComponentsPage
          prefix="configurators"
          items={availableConfigurators}
          title={t("Configurators")}
          subTitle={t("ConfiguratorsPageSubtitle")}
        />
      ),
      prefix: "configurators",
    },
    {
      path: "/generators",
      element: (
        <ComponentsPage
          prefix="generators"
          items={availableGenerators}
          title={t("Generators")}
          subTitle={t("GeneratorsPageSubtitle")}
        />
      ),
      prefix: "generators",
    },
    {
      path: "/analysers",
      element: (
        <ComponentsPage
          prefix="analysers"
          items={availableAnalysers}
          title={t("Analysers")}
          subTitle={t("AnalysersPageSubtitle")}
        />
      ),
      prefix: "analysers",
    },
    {
      path: "/catalog",
      element: (
        <ComponentsPage
          prefix="catalog"
          items={availableCatalog}
          title={t("Catalog")}
          subTitle={t("CatalogPageSubtitle")}
        />
      ),
      prefix: "catalog",
    },
    {
      path: "/databases",
      element: (
        <ComponentsPage
          prefix="databases"
          items={availableDatabases}
          title={t("Databases")}
          subTitle={t("DatabasesPageSubtitle")}
        />
      ),
      prefix: "databases",
    },
    {
      path: "/heijmansintelligence",
      element: (
        <ComponentsPage
          prefix="heijmansintelligence"
          items={availableHeijmansIntelligence}
          title={t("Heijmansintelligence")}
          subTitle={t("HeijmansIntelligencePageSubtitle")}
        />
      ),
      prefix: "heijmansintelligence",
    },
    {
      path: "/heijmansintelligence/stackedhousing",
      element: (
        <ComponentsPage
          prefix="heijmansintelligence.stackedhousing"
          items={availableStackedHousingHeijmansIntelligence}
          title={t("StackedHousing.SwitchMatrix.SwitchMatrices")}
          subTitle={t("StackedHousing.SwitchMatrix.SwitchMatricesPageSubtitle")}
        />
      ),
      prefix: "heijmansintelligence.stackedhousing",
    },
    {
      path: "/generators/lowvoltagerooms",
      element: <LowVoltageRoomToolingDashboardPage />,
      prefix: "generators.lowvoltageroom",
    },
    {
      path: "/generators/whitespace&cooling",
      element: <WhitespaceToolingDashboardPage />,
      prefix: "generators.whitespaceandcooling",
    },
    {
      path: "/configurators/datacenter",
      element: <DatacenterGeneratorProjectsPage />,
      prefix: "configurators.datacenter",
    },
    {
      path: "/configurators/datacenter/:projectName",
      element: <DatacenterGeneratorDashboardPage />,
      prefix: "configurators.datacenter",
    },
    {
      path: "/configurators/stackedhousing",
      element: (
        <BaseProjectsPage
          type={ProjectTypeEnum.HSA}
          subject={"configurators.stackedhousing"}
        />
      ),
      prefix: "configurators.stackedhousing",
    },
    {
      path: "/configurators/stackedhousing/:projectId",
      element: <ConfiguratorPage />,
      prefix: "configurators.stackedhousing",
    },
    {
      path: "/catalog/stackedhousing",
      element: <ModuleCatalog />,
      prefix: "catalog.stackedhousing",
    },
    {
      path: "/catalog/datacenter",
      element: (
        <ComponentsPage
          prefix="catalog.datacenter"
          items={availableDatacenterCatalog}
          title={t("Catalog")}
          subTitle={t("CatalogPageSubtitle")}
        />
      ),
      prefix: "catalog.datacenter",
    },
    {
      path: "/catalog/datacenter/components",
      element: (
        <ComponentsPage
          prefix="catalog.datacenter.components"
          items={availableDatacenterCatalogComponents}
          title={t("Components")}
        />
      ),
      prefix: "catalog.datacenter.components",
    },
    {
      path: "/catalog/datacenter/components/serverracks",
      element: <RacksDashboardPage />,
      prefix: "catalog.datacenter.components.serverracks",
    },
    {
      path: "/catalog/datacenter/components/transformators",
      element: <TransformatorsDashboardPage />,
      prefix: "catalog.datacenter.components.transformators",
    },
    {
      path: "/catalog/datacenter/components/upss",
      element: <UpsDashboardPage />,
      prefix: "catalog.datacenter.components.upss",
    },
    {
      path: "/catalog/datacenter/components/batteries",
      element: <BatteriesDashboardPage />,
      prefix: "catalog.datacenter.components.batteries",
    },
    {
      path: "/analysers/nitrogen",
      element: (
        <BaseProjectsPage
          type={ProjectTypeEnum.Analyser}
          subject={"analysers.nitrogen"}
        />
      ),
      prefix: "analysers.nitrogen",
    },
    {
      path: "/analysers/nitrogen/:projectId",
      element: <NitrogenDashboardPage />,
      prefix: "analysers.nitrogen",
    },
    {
      path: "/databases/productionkeyfigures",
      element: <ProductionKeyFiguresPage />,
      prefix: "databases.productionkeyfigures",
    },
    {
      path: "/databases/productionkeyfigures/settings",
      element: <SettingsPage />,
      prefix: "databases.productionkeyfigures",
    },
    {
      path: "/databases/costskeyfigures",
      element: <CostKeyFiguresPage />,
      prefix: "databases.costskeyfigures",
    },
    {
      path: "/databases/costskeyfigures/settings",
      element: <SettingsPage />,
      prefix: "databases.costskeyfigures",
    },
    {
      path: "/databases/equipmentkeyfigures",
      element: <EquipmentKeyFiguresPage />,
      prefix: "databases.equipmentkeyfigures",
    },
    {
      path: "/databases/equipmentkeyfigures/settings",
      element: <SettingsPage />,
      prefix: "databases.equipmentkeyfigures",
    },
    {
      path: "",
      element: <Navigate to="/overview" replace />,
      prefix: "overview",
    },
    {
      path: "*",
      element: <NotFoundPage />,
      prefix: "Not found",
    },
    {
      path: "/heijmansintelligence/stackedhousing/gallery",
      element: <GalleryMatrix />,
      prefix: "heijmansintelligence.stackedhousing.gallery",
    },
    {
      path: "/heijmansintelligence/stackedhousing/corridor",
      element: <CorridorMatrix />,
      prefix: "heijmansintelligence.stackedhousing.corridor",
    },
    {
      path: "/heijmansintelligence/stackedhousing/porchway",
      element: <PorchMatrix />,
      prefix: "heijmansintelligence.stackedhousing.porchway",
    },
  ];

  const pageRoutes = pagesData
    .filter(
      (page) =>
        !page.path.startsWith("/") ||
        appAbility?.can("read", page.prefix as any)
    )
    .map(({ path, prefix: title, element }: routerType) => {
      return <Route key={title} path={`/${path}`} element={element} />;
    });

  async function LoadProject(projectName: string) {
    if (IsGuid(projectName)) {
      setCurrentProject(GetEmptyProjectDto());
      return;
    }

    let tempProjects = projects;
    if (tempProjects.length === 0) {
      tempProjects = await GetProjects();
      setProjects(tempProjects);
    }
    const project = tempProjects.find((x) => x.name === projectName);
    if (project) {
      setCurrentProject(project);
    }
  }

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const isOverviewPage = location.pathname.endsWith("/overview");
    const isConfiguratorPage = location.pathname.endsWith("/configurators");
    const isDatacenterPage = location.pathname.endsWith("/datacenter");
    const isDatacenterProjectPage =
      pathnames.length > 2 &&
      pathnames[pathnames.length - 2].includes("datacenter");
    const isGeneratorPage = location.pathname.endsWith("/generators");

    const isAnalysersPage = location.pathname.endsWith("/analysers");
    const isCataloguesPage = location.pathname.endsWith("/catalog");
    const isComponentsPage = location.pathname.endsWith("/components");
    const isDatabasesPage = location.pathname.endsWith("/databases");
    const isNitrogenPage = location.pathname.endsWith("/nitrogen");
    const isHeijmansIntelligencesPage =
      location.pathname.endsWith("/heijmansintelligence") ||
      location.pathname.endsWith("/heijmansintelligence/stackedhousing");

    const isMainpage =
      isOverviewPage ||
      isConfiguratorPage ||
      isDatacenterPage ||
      isGeneratorPage ||
      isAnalysersPage ||
      isCataloguesPage ||
      isComponentsPage ||
      isHeijmansIntelligencesPage ||
      isDatabasesPage;

    setIsSidebarOpen(isMainpage);
    setIsSidebarPersistent(isMainpage);
    if (isMainpage) {
      resetDatacenterGeneratorState();
      resetInputPaneState();
      resetScenarioState();
    }

    if (isDatacenterProjectPage) {
      (async () => {
        const projectName = pathnames[pathnames.length - 1];
        await LoadProject(projectName);
      })();
    }

    if (isDatabasesPage) {
      resetDatabaseState();
    }

    if (isNitrogenPage) {
      useProjectsStore.getState().resetProjectsState();
      useNitrogenInputPaneStore.getState().resetNitrogenInputPaneState();
      useNitrogenStore.getState().resetNitrogenState();
    }

    if (isHeijmansIntelligencesPage) {
      setTypicalCode("");
    }

    const count =
      location.pathname.length - location.pathname.replaceAll("/", "").length;
    setShowBreadcrumb(count > 1);
  }, [location.pathname]);

  return <Routes>{pageRoutes}</Routes>;
}
