import { useTranslation } from "react-i18next";
import { BuildingTypeDto } from "../../../interfaces/foundry/BuildingTypeDto";
import { LowVoltageRoomDto } from "../../../interfaces/foundry/typicals/LowVoltageRoomDto";
import FormControlTextField from "../../CustomFormControl/FormControlTextField";
import SceneDetailComponent from "./SceneDetailComponent";
import { FormatNumberToEuro } from "../../../modules/sharedLogic/utils/HelperFunctions";
import { IconButton } from "@mui/material";
import { ExportIcon } from "../../../icons/ExportIcon";
import { ExportLVRCO2Details, ExportLVRCostDetails } from "../../../http/foundry/LowVoltageRoomService";

interface SceneDetailLowVoltageRoomProps {
  lowVoltageRoom: LowVoltageRoomDto;
  amount?: number | null;
}

export default function SceneDetailLowVoltageRoom({
  lowVoltageRoom,
  amount = null,
}: SceneDetailLowVoltageRoomProps) {
  const { t } = useTranslation();

  const formatBuildingTypes = (buildingTypes: BuildingTypeDto[]) => {
    return buildingTypes.map((type) => type.name).join(", ");
  };

  const components = [
    { type: "Battery", component: lowVoltageRoom.battery },
    { type: "Cooling", component: lowVoltageRoom.cooling },
    { type: "Genset", component: lowVoltageRoom.genset },
    { type: "RMU", component: lowVoltageRoom.rmu },
    { type: "STS", component: lowVoltageRoom.sts },
    { type: "Transformator", component: lowVoltageRoom.transformator },
    { type: "UPS", component: lowVoltageRoom.ups },
    { type: "Distributors", component: lowVoltageRoom.distributors },
  ].sort((a, b) => t(a.type).localeCompare(t(b.type)));

  return (
    <div>
      <div className="title">{t("General")}</div>
      <FormControlTextField
        labelText={t("Feature")}
        value={lowVoltageRoom?.id}
      />
      <FormControlTextField
        labelText={t("Total power")}
        value={lowVoltageRoom?.total_power_in_w + "MW"}
      />
      <FormControlTextField
        labelText={t("Power IT")}
        value={lowVoltageRoom?.it_power_in_w + "MW"}
      />
      <FormControlTextField
        labelText={t("Power mechanical and other")}
        value={lowVoltageRoom?.mech_power_in_w + "MW"}
      />
      <FormControlTextField
        labelText={t("Power batteries (emergency)")}
        value={lowVoltageRoom?.emergency_power_in_w + "MW"}
      />
      <FormControlTextField
        labelText={t("Minutes of autonomy")}
        value={lowVoltageRoom?.autonomy_in_min}
      />

      {amount !== null && (
        <FormControlTextField labelText={t("Amount")} value={amount} />
      )}

      <div className="title mt20">{t("Dimensions")}</div>
      <FormControlTextField
        labelText={t("Net width(m)")}
        value={lowVoltageRoom?.length}
      />
      <FormControlTextField
        labelText={t("Net length(m)")}
        value={lowVoltageRoom?.width}
      />
      <FormControlTextField
        labelText={t("Height(m)")}
        value={lowVoltageRoom?.height}
      />
      <FormControlTextField
        labelText={t("Area(m2)")}
        value={lowVoltageRoom?.area?.toFixed()}
      />
      <FormControlTextField
        labelText={t("Volume(m3)")}
        value={lowVoltageRoom?.volume?.toFixed()}
      />

      {amount === null && (
        <div>
          <div className="title mt20">{t("Efficiency")}</div>
          <FormControlTextField
            labelText={t("kW/m2")}
            value={lowVoltageRoom?.kw_m2}
          />
          <FormControlTextField
            labelText={t("kW/m3")}
            value={lowVoltageRoom?.kw_m3}
          />

          <div className="title mt20">{t("Labels")}</div>
          <FormControlTextField
            labelText={t("Building types")}
            showInfoButton={true}
            toolTipText={t("BuildingTypesLVRTooltip")}
            value={
              lowVoltageRoom
                ? formatBuildingTypes(lowVoltageRoom.building_types)
                : ""
            }
          />
        </div>
      )}

      <div className="title mt20">{t("Valuation")}</div>
      <div className="scene-detail-export-view">
        <FormControlTextField
          labelText={`${t("Costs")} (€)`}
          value={FormatNumberToEuro(lowVoltageRoom?.cost, false) || ""}
        />

        <IconButton
          className="scene-detail-export-button"
          onClick={async () => {
            await ExportLVRCostDetails(lowVoltageRoom.id);
          }}
        >
          <ExportIcon className="svg" size={22} />
        </IconButton>
      </div>
      <div className="scene-detail-export-view">
        <FormControlTextField
          labelText={`${t("LowVoltageRoomPage.CO2")}`}
          value={lowVoltageRoom?.co2_footprint_in_euros}
        />

        <IconButton
          className="scene-detail-export-button"
          onClick={async () => {
            await ExportLVRCO2Details(lowVoltageRoom.id);
          }}
        >
          <ExportIcon className="svg" size={22} />
        </IconButton>
      </div>

      <div className="title mt20">{t("Components")}</div>
      {components.map(
        ({ type, component }) =>
          component && (
            <SceneDetailComponent
              key={type}
              type={type}
              component={component}
            />
          )
      )}
    </div>
  );
}
