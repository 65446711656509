import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Collapse } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import GenerateDataFloatingActionButton from "../../../components/GenerateDataFloatingActionButton/GenerateDataFloatingActionButton";
import InputPane from "../../../components/InputPane/InputPane";
import { BuildingTypeEnum } from "../../../interfaces/enums/BuildingTypeEnum";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import useUpsStore from "../../../state/ComponentGeneratorState/upsState";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import PreselectionUpsView from "./Components/PreselectionUpsView/PreselectionUpsView";

export default function UpsDashboardPage() {
  const { appAbility } = useUserStore();
  const { setCurrentPageType, isInputPaneOpen, setIsInputPaneOpen } =
    useDatacenterGeneratorStore();
  const { setBuildingTypeDtoOptions } = useUpsStore();
  useEffect(() => {
    setBuildingTypeDtoOptions([
      {
        id: 2,
        name: "Lowvoltagerooms",
      },
      {
        id: -1,
        name: "Empty",
      },
    ]);
    setCurrentPageType(TypeObject.Ups);
  }, [setBuildingTypeDtoOptions, setCurrentPageType]);

  return (
    <div className="datacenter-generator-page">
      {!isInputPaneOpen && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "135px",
            left: "-15px",
          }}
        >
          <IconButton
            style={{ backgroundColor: "white", zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronRightIcon sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}

      <div className="dashboard-view">
        <Collapse orientation="horizontal" in={isInputPaneOpen}>
          <InputPane />
        </Collapse>

        <div>
          <PreselectionUpsView />
        </div>

        {appAbility?.can("manage", "generators.lowvoltageroom") && (
          <GenerateDataFloatingActionButton
            buildingTypeEnum={BuildingTypeEnum.LVR}
          />
        )}
      </div>
    </div>
  );
}
