import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  TextField,
  Typography
} from "@mui/material";
import { GridExpandMoreIcon, GridFilterItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SliderWithMarks from "../../../../components/SliderWithMarks/SliderWithMarks";
import { FormatEuroToNumber } from "../../../../utils/HelperFunctions";
import { FilterDefinition } from "../FilterableDataGrid/FilterableDataGrid";
import "./FilterPanel.scss";
import FilterCheckboxes from "../FilterCheckboxes/FilterCheckboxes";

interface FilterPanelProps {
  filters: FilterDefinition[];
  setCurrentFilterItems: (filterItems: GridFilterItem[]) => void;
}

export function FilterPanel({
  filters,
  setCurrentFilterItems,
}: FilterPanelProps) {
  const { t } = useTranslation();
  const [filterItems, setFilterItems] = useState<GridFilterItem[]>([]);
  const [rangeValues, setRangeValues] = useState<{ [key: string]: number[] }>(
    {}
  );

  useEffect(() => {
    // Initialize range values
    const initialRangeValues: { [key: string]: number[] } = {};
    filters.forEach((filter) => {
      if (filter.type === "range" && filter.options) {
        initialRangeValues[filter.key] = [
          Number(filter.options[0]),
          Number(filter.options[1]),
        ];
      }
    });
    setRangeValues(initialRangeValues);
  }, [filters]);

  const updateFilterItems = (
    newFilterItem: GridFilterItem | null,
    field: string
  ) => {
    setFilterItems((prevItems) => {
      let updatedItems: GridFilterItem[];
      if (newFilterItem === null) {
        updatedItems = prevItems.filter((item) => item.field !== field);
      } else {
        const index = prevItems.findIndex((item) => item.field === field);
        if (index !== -1) {
          updatedItems = [...prevItems];
          updatedItems[index] = newFilterItem;
        } else {
          updatedItems = [...prevItems, newFilterItem];
        }
      }
      setCurrentFilterItems(updatedItems);
      return updatedItems;
    });
  };

  return (
    <div className="full-width">
      <div className="filter-header">
        <Typography variant="h6" fontSize="14px" fontWeight="800">
          {t("Filters")}
        </Typography>
      </div>
      <div className="filter-panel ">
        {filters.map((filter) => (
          <Accordion
            key={filter.key}
            disableGutters
            defaultExpanded
            square
            elevation={0}
          >
            <AccordionSummary
              sx={{
                minHeight: "10px",
                height: "30px",
              }}
              expandIcon={<GridExpandMoreIcon />}
            >
              <Typography
                variant="h6"
                sx={{ color: "#003366", fontWeight: "bold" }}
              >
                {t(filter.label)}
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails
              sx={{ flexDirection: "column", paddingTop: "5px" }}
            >
              {filter.type === "checkbox" && filter.options && (
                <FilterCheckboxes
                  items={filter.options}
                  selectedValues={
                    filterItems.find((item) => item.field === filter.key)
                      ?.value || []
                  }
                  onChange={(newSelectedValues) => {
                    if (newSelectedValues.length === 0) {
                      updateFilterItems(null, filter.key);
                    } else {
                      updateFilterItems(
                        {
                          field: filter.key,
                          operator: "is",
                          value: newSelectedValues,
                        },
                        filter.key
                      );
                    }
                  }}
                />
              )}
              {filter.type === "range" && filter.options && (
                <div>
                  <Typography>
                    {FormatEuroToNumber(
                      rangeValues[filter.key]?.[0] ?? Number(filter.options[0])
                    )}{" "}
                    -{" "}
                    {FormatEuroToNumber(
                      rangeValues[filter.key]?.[1] ?? Number(filter.options[1])
                    )}
                  </Typography>
                  <SliderWithMarks
                    value={
                      rangeValues[filter.key] ?? [
                        Number(filter.options[0]),
                        Number(filter.options[1]),
                      ]
                    }
                    min={Number(filter.options[0])}
                    max={Number(filter.options[1])}
                    setValue={(newValue: number[]) => {
                      setRangeValues((prev) => ({
                        ...prev,
                        [filter.key]: newValue,
                      }));
                      updateFilterItems(
                        {
                          field: filter.key,
                          operator: "between",
                          value: newValue,
                        },
                        filter.key
                      );
                    }}
                  />
                </div>
              )}
              {filter.type === "text" && (
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(event) => {
                    if (event.target.value) {
                      updateFilterItems(
                        {
                          field: filter.key,
                          operator: "contains",
                          value: event.target.value,
                        },
                        filter.key
                      );
                    } else {
                      updateFilterItems(null, filter.key);
                    }
                  }}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
