import AlleyDto from "../../../interfaces/foundry/typicals/AlleyDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetAlley(id: number): Promise<AlleyDto> {
  return (await GetAlleys()).find((x) => x.id === id) as AlleyDto;
}

export async function GetAlleys(): Promise<AlleyDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let alleys = store.alleys;
  if (alleys.length === 0) {
    alleys = await GetAlleyDtos();
    store.setAlleys(alleys);
  }
  return alleys;
}

async function GetAlleyDtos(): Promise<AlleyDto[]> {
  try {
    const coolings = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/alleys")).data
        .results as AlleyDto[]
    ).map((item: AlleyDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
