import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Collapse } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import GenerateDataFloatingActionButton from "../../../components/GenerateDataFloatingActionButton/GenerateDataFloatingActionButton";
import InputPane from "../../../components/InputPane/InputPane";
import { GetBuildingTypesOptions } from "../../../http/foundry/BuildingTypeService";
import { GetTransformators } from "../../../http/foundry/typicals/TransformatorService";
import { BuildingTypeEnum } from "../../../interfaces/enums/BuildingTypeEnum";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { ExploreConfigStore } from "../../../interfaces/exploreConfig";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { TypicalDTO } from "../../../state/ExploreState/ExploreState";
import BaseExploreView from "../../Configurators/Datacenter/components/BaseExploreView/BaseExploreView";
import { useLowVoltageRoomConfigStore } from "../../Configurators/Datacenter/components/TypicalsConfig/LowVoltageRoomConfig";
import "./LowVoltageRoomToolingDashboardPage.scss";

export default function LowVoltageRoomToolingDashboardPage() {
  const { appAbility } = useUserStore();
  const { isInputPaneOpen, setCurrentPageType, setIsInputPaneOpen } =
    useDatacenterGeneratorStore();
  const { setTranfoTypeOptions, setBuildingTypeDtoOptions } =
    useGeneralDatacenterObjectsStore();
  const lowVoltageRoomConfigStore = useLowVoltageRoomConfigStore();

  useEffect(() => {
    setCurrentPageType(TypeObject.LowVoltageRoomTooling);

    (async () => {
      setTranfoTypeOptions(await GetTransformators());
      setBuildingTypeDtoOptions(await GetBuildingTypesOptions());
    })();
  }, []);

  return (
    <div className="datacenter-generator-page">
      {!isInputPaneOpen && (
        <div className="low-voltage-room-button">
          <IconButton
            style={{ backgroundColor: "white", zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronRightIcon sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}

      <div className="dashboard-view">
        <Collapse orientation="horizontal" in={isInputPaneOpen}>
          <InputPane lowVoltageRoomConfigStore={lowVoltageRoomConfigStore} />
        </Collapse>

        <div>
          <BaseExploreView
            type={TypeObject.LowVoltageRoomTooling}
            currentTypicalConfigStore={
              lowVoltageRoomConfigStore as unknown as ExploreConfigStore<TypicalDTO>
            }
          />
        </div>

        {appAbility?.can("manage", "generators.lowvoltageroom") && (
          <GenerateDataFloatingActionButton
            buildingTypeEnum={BuildingTypeEnum.Datacenter}
          />
        )}
      </div>
    </div>
  );
}
