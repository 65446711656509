import * as turf from "@turf/turf";
import proj4 from "proj4";
import wellknown from "wellknown";
import DrawedPlot from "../../../../types/DrawedPlot";
import { GenerateGuid } from "../../../../utils/HelperFunctions";

const polygonColor = "#000";
const polygonSelectedColor = "#FEB800";
const polygonPlotWithOffsetColor = "#FFFFFF";

proj4.defs(
  "EPSG:28992",
  "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs"
);

export function CalculateOffsetGeometry(
  existingGeometry: turf.Position[],
  offsetDistance: number
): turf.Polygon {
  const existingPolygon = turf.polygon([existingGeometry]);
  const bufferedPolygon = turf.buffer(existingPolygon, offsetDistance, {
    units: "meters",
  });
  return bufferedPolygon?.geometry;
}

export function CalculateRdCoordinates(coordinates: number[][]): number[][] {
  const rdCoordinates: number[][] = [];
  coordinates.forEach((point) => {
    const rdPoint = proj4("EPSG:4326", "EPSG:28992", [point[0], point[1]]);
    rdCoordinates.push(rdPoint);
  });

  return rdCoordinates;
}

export function GetDrawedPlot(
  drawedPlot: DrawedPlot,
  plot: string
): DrawedPlot {
  const tempDrawedPlot = drawedPlot;
  if (plot) {
    const geojsonGeometry = wellknown.parse(plot);
    tempDrawedPlot.polygon = {
      id: GenerateGuid(),
      type: "Feature",
      geometry: geojsonGeometry,
      properties: {},
    } as GeoJSON.Feature<GeoJSON.Geometry>;
  }

  return tempDrawedPlot;
}

export function GetDrawControlStyles() {
  return [
    {
      id: "gl-draw-line",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": polygonSelectedColor,
        "line-dasharray": [0.2, 2],
      },
    },
    {
      id: "gl-draw-polygon-fill",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
      paint: {
        "fill-color": polygonSelectedColor,
        "fill-outline-color": polygonSelectedColor,
        "fill-opacity": 0.1,
      },
    },
    {
      id: "gl-draw-polygon-midpoint",
      type: "circle",
      filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
      paint: {
        "circle-radius": 3,
        "circle-color": polygonSelectedColor,
      },
    },
    {
      id: "gl-draw-polygon-stroke-active",
      type: "line",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": polygonSelectedColor,
        "line-dasharray": [0.2, 2],
      },
    },
    {
      id: "gl-draw-polygon-and-line-vertex-halo-active",
      type: "circle",
      filter: [
        "all",
        ["==", "meta", "vertex"],
        ["==", "$type", "Point"],
        ["!=", "type_component", "datacenter"],
      ],
      paint: {
        "circle-radius": 5,
        "circle-color": "#FFF",
      },
    },
    {
      id: "gl-draw-polygon-and-line-vertex-active",
      type: "circle",
      filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
      paint: {
        "circle-radius": 5,
        "circle-color": polygonSelectedColor,
      },
    },
    {
      id: "gl-draw-polygon-fill-inactive",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
      paint: {
        "fill-color": [
          "case",
          ["==", ["get", "user_class_id"], 0],
          polygonColor,
          ["==", ["get", "user_class_id"], 1],
          polygonPlotWithOffsetColor,
          ["==", ["get", "user_class_id"], 2],
          "#0000ff",
          "#ff0000",
        ],
        "fill-outline-color": [
          "case",
          ["==", ["get", "user_class_id"], 0],
          polygonColor,
          ["==", ["get", "user_class_id"], 1],
          polygonPlotWithOffsetColor,
          ["==", ["get", "user_class_id"], 2],
          "#0000ff",
          "#ff0000",
        ],
        "fill-opacity": 0.1,
      },
    },
    {
      id: "gl-draw-polygon-fill-inactive.hot",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
      paint: {
        "fill-color": "#000000",
        "fill-outline-color": "#000000",
        "fill-opacity": 0.1,
      },
    },
    {
      id: "gl-draw-polygon-stroke-inactive",
      type: "line",
      filter: [
        "all",
        ["==", "$type", "Polygon"],
        ["==", "active", "false"],
        ["==", "user_kind", "plot"],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          ["==", ["get", "user_class_id"], 0],
          polygonColor,
          ["==", ["get", "user_class_id"], 1],
          polygonPlotWithOffsetColor,
          ["==", ["get", "user_class_id"], 2],
          "#0000ff",
          "#ff0000",
        ],
      },
    },
    {
      id: "gl-draw-polygon-stroke-plot-inactive",
      type: "line",
      filter: [
        "all",
        ["==", "$type", "Polygon"],
        ["==", "active", "false"],
        ["==", "user_kind", "buildablePlot"],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          ["==", ["get", "user_class_id"], 0],
          polygonColor,
          ["==", ["get", "user_class_id"], 1],
          polygonPlotWithOffsetColor,
          ["==", ["get", "user_class_id"], 2],
          "#0000ff",
          "#ff0000",
        ],
      },
    },
  ];
}
