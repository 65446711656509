import CoolingTowerDto from "../../../interfaces/foundry/typicals/CoolingTowerDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetCoolingTower(id: number): Promise<CoolingTowerDto> {
  return (await GetCoolingTowers()).find((x) => x.id === id) as CoolingTowerDto;
}

export async function GetCoolingTowers(): Promise<CoolingTowerDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let coolingTowers = store.coolingTowers;
  if (coolingTowers.length === 0) {
    coolingTowers = await GetCoolingTowerDtos();
    store.setCoolingTowers(coolingTowers);
  }
  return coolingTowers;
}

async function GetCoolingTowerDtos(): Promise<CoolingTowerDto[]> {
  try {
    const coolings = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/coolingtower")).data
        .results as CoolingTowerDto[]
    ).map((item: CoolingTowerDto) => {
      item = {
        ...item,
        type: item.display_name,
        power_in_w: (item.cooling_capacity_in_w ?? 0) / 1000,
      };
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
