import CancelIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { randomId } from "@mui/x-data-grid-generator";
import {
  TreeItem2,
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Props,
  TreeItem2Provider,
  TreeItem2Root,
  UseTreeItem2ContentSlotOwnProps,
  useTreeItem2Utils
} from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import clsx from "clsx";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../../../../components/AddButton/AddButton";
import CancelButton from "../../../../components/CancelButton/CancelButton";
import {
  DeleteCategory,
  GetCategory,
  SaveCategory,
  UpdateCategory,
} from "../../../../http/aggregate/CategoriesService";
import { PenIcon } from "../../../../icons/PenIcon";
import { SaveIcon } from "../../../../icons/SaveIcon";
import { TrashIcon } from "../../../../icons/TrashIcon";
import { CategoryDto } from "../../../../interfaces/aggregate/CategoryDto";
import TreeNodeDto from "../../../../interfaces/aggregate/TreeNodeDto";
import { AggregatePageEnum } from "../../../../interfaces/aggregate/enums/AggregatePageEnum";
import useDatabaseStore from "../../../../state/DatabaseState/databaseState";
import { IsStringNumber } from "../../../../utils/HelperFunctions";
import {
  FindTreeNodeById,
  RemoveTreeNodeById,
} from "../../../../utils/TreeNodeManager";
import "./CustomTreeItem.scss";

interface CustomTreeItemProps extends TreeItem2Props {
  customContent: boolean;
}

const EditableTreeItem2 = React.forwardRef(function CustomTreeItem(
  props: TreeItem2Props,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    classes,
    className,
    ...other
  } = props;

  const { t } = useTranslation();

  const {
    currentPage,
    currentTreeNodeData,
    treeNodeData,
    categories,
    setCategories,
    setTreeNodeData,
    setCurrentTreeNodeData,
  } = useDatabaseStore();

  const node = FindTreeNodeById(treeNodeData, itemId);

  const [newCategoryName, setNewCategoryName] = React.useState<string>("");

  const [openVerificationDialog, setOpenVerificationDialog] =
    React.useState<boolean>(false);

  const handleClose = () => {
    setOpenVerificationDialog(false);
  };

  const isValidNode =
    (currentTreeNodeData.id !== itemId && label !== "") ||
    (currentTreeNodeData.id === itemId &&
      (currentTreeNodeData.isEdit === false ||
        currentTreeNodeData.isNew === false));

  const isEditOrNewNode =
    currentTreeNodeData.id === itemId &&
    (currentTreeNodeData.isEdit === true || currentTreeNodeData.isNew === true);

  const handleSaveCategory = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (newCategoryName === "") return;

    if (IsStringNumber(currentTreeNodeData.id)) {
      const category = categories.find(
        (x) => x.id === Number(currentTreeNodeData.id)
      );
      if (category) {
        category.name = newCategoryName;
        await UpdateCategory(category);
        setCategories([...categories]);
      }
    } else {
      const category = {
        id: 0,
        name: newCategoryName,
        categoryType: AggregatePageEnum[currentPage],
        parentId: currentTreeNodeData.parentId
          ? Number(currentTreeNodeData.parentId)
          : null,
      } as CategoryDto;

      await SaveCategory(category).then(async (savedCategoryId) => {
        const newCategory = await GetCategory(savedCategoryId);
        setCategories([...categories, newCategory]);
      });
    }

    setCurrentTreeNodeData({} as TreeNodeDto);
  };

  const handleCancelSaveCategory = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (currentTreeNodeData.isNew) {
      setTreeNodeData(RemoveTreeNodeById(treeNodeData, currentTreeNodeData.id));
    }
    setCurrentTreeNodeData({} as TreeNodeDto);
  };

  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const handleContentClick: UseTreeItem2ContentSlotOwnProps["onClick"] = (
    event
  ) => {
    event.defaultMuiPrevented = true;
    interactions.handleSelection(event);
  };

  const handleIconContainerClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
  };

  const {
    getContentProps,
    getIconContainerProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({
    id,
    itemId,
    children,
    label,
    disabled,
    rootRef: ref,
  });

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root>
        <TreeItem2Content
          {...getContentProps({
            className: clsx("content", {
              expanded: status.expanded,
              selected: status.selected,
              focused: status.focused,
            }),
          })}
          onClick={handleContentClick}
        >
          <TreeItem2IconContainer
            {...getIconContainerProps()}
            onClick={handleIconContainerClick}
          >
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <div className="editable-tree-item">
            {isEditOrNewNode && (
              <div className="editable-node">
                <TextField
                  fullWidth
                  value={newCategoryName === "" ? label : newCategoryName}
                  onChange={(event) => setNewCategoryName(event.target.value)}
                  variant="standard"
                  sx={{
                    width: "300px",
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                    "& .MuiInputBase-root.Mui-disabled:before": {
                      borderBottomStyle: "solid",
                    },
                  }}
                ></TextField>
                <IconButton onClick={handleSaveCategory}>
                  <SaveIcon />
                </IconButton>
                <IconButton
                  sx={{ marginLeft: "-5px" }}
                  onClick={handleCancelSaveCategory}
                >
                  <CancelIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </div>
            )}

            {isValidNode && !isEditOrNewNode && (
              <Typography sx={{ width: "100% !important" }} component="div">
                {label}
              </Typography>
            )}

            {isValidNode && !isEditOrNewNode && (
              <ButtonGroup
                sx={{
                  minWidth: "100px",
                  marginLeft: "20px",
                  marginTop: "-5px",
                  marginBottom: "-5px",
                }}
                disableElevation
              >
                <AddButton
                  width={40}
                  hoverDisabled={true}
                  disableRipple={true}
                  onClick={(event) => {
                    event.stopPropagation();

                    const category = categories.find(
                      (x) => x.id === Number(itemId)
                    );
                    if (category && node) {
                      if (!node.children) {
                        node.children = [];
                      }

                      const newItem = {
                        id: randomId(),
                        label: "",
                        isNew: true,
                        parentId: node.id,
                      } as TreeNodeDto;

                      node.children!.push(newItem);
                      setCurrentTreeNodeData(newItem);
                    }
                    setTreeNodeData(treeNodeData);
                  }}
                  fontSize="1.5rem"
                />

                <Tooltip title={t("Edit")}>
                  <IconButton
                    sx={{ marginLeft: "-5px" }}
                    onClick={(event) => {
                      event.stopPropagation();

                      if (!node) return;
                      node.isEdit = true;
                      setCurrentTreeNodeData(node);
                      setNewCategoryName(node.label);
                    }}
                  >
                    <PenIcon size={18} className="icon" />
                  </IconButton>
                </Tooltip>

                {node!.children === undefined && (
                  <Tooltip title={t("Delete")}>
                    <IconButton
                      sx={{ marginLeft: "-5px" }}
                      onClick={async (event) => {
                        event.stopPropagation();

                        if (!node) return;
                        node.isEdit = node.isNew = false;
                        setCurrentTreeNodeData(node);
                        setOpenVerificationDialog(true);
                      }}
                    >
                      <TrashIcon size={18} className="icon" />
                    </IconButton>
                  </Tooltip>
                )}
              </ButtonGroup>
            )}

            <Dialog
              fullWidth={true}
              maxWidth="xl"
              open={openVerificationDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  borderRadius: 20,
                  width: "28%",
                },
              }}
            >
              <DialogTitle
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                  fontWeight: 900,
                }}
              >
                {t("Delete category")}
                <Box component={"div"} sx={{ ml: "auto" }}>
                  <CancelButton onClick={handleClose} />
                </Box>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ marginTop: "-10px" }}
                  id="alert-dialog-description"
                >
                  {t("Are you sure you want to delete the category") +
                    " '" +
                    currentTreeNodeData.label +
                    "'?"}
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose}> {t("No")}</Button>
                <Button
                  onClick={() => {
                    const node = FindTreeNodeById(
                      treeNodeData,
                      currentTreeNodeData.id
                    );
                    if (!node) return;
                    handleClose();
                    DeleteCategory(node.id).then(() => {
                      setCategories(
                        categories.filter((x) => x.id !== Number(node!.id))
                      );
                    });
                  }}
                  autoFocus
                >
                  {t("Yes")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </TreeItem2Content>
        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

const CustomTreeItem = React.forwardRef(function MyTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const handleContentClick: UseTreeItem2ContentSlotOwnProps["onClick"] = (
    event
  ) => {
    event.defaultMuiPrevented = true;
    interactions.handleSelection(event);
  };

  const handleIconContainerClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
  };

  return props.customContent ? (
    <EditableTreeItem2 {...props} ref={ref} />
  ) : (
    <TreeItem2
      {...props}
      ref={ref}
      slotProps={{
        content: { onClick: handleContentClick },
        iconContainer: { onClick: handleIconContainerClick },
      }}
    />
  );
});

export default CustomTreeItem;
