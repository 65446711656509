import { SaveAs } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Save from "@mui/icons-material/Save";
import { Box, Collapse, Tab, Tabs, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomPopover from "../../../components/CustomPopover/CustomPopover";
import InputPane from "../../../components/InputPane/InputPane";
import TabPanel from "../../../components/TabPanel/TabPanel";
import { ShowError } from "../../../http/NotificationService";
import { GetDatacenter } from "../../../http/foundry/DatacenterService";
import { GetLayout } from "../../../http/foundry/GeometryService";
import {
  GetCoolingPrinciples,
  GetDcScopes,
  GetDesiredPowerWattages,
  GetDestinationPlans,
  GetPowerRackWattages,
} from "../../../http/foundry/InputPaneService";
import { LayoutDto } from "../../../interfaces/LayoutDto";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { ExploreConfigStore } from "../../../interfaces/exploreConfig";
import {
  DatacenterDto,
  GetEmptyDatacenter,
} from "../../../interfaces/foundry/DatacenterDto";
import { SelectedBuildingsDto } from "../../../interfaces/foundry/SelectedBuildingsDto";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { DTO } from "../../../state/ExploreState/ExploreState";
import colors from "../../../styles/colors.module.scss";
import "./DatacenterDashboardPage.scss";
import BaseExploreView from "./components/BaseExploreView/BaseExploreView";
import CreateOrUpdateProjectView from "./components/CreateOrUpdateProjectView/CreateOrUpdateProjectView";
import { GenerateDatacenterView } from "./components/GenerateDatacenterView/GenerateDatacenterView";
import InputsView from "./components/InputsView/InputsView";
import { useDatacenterConfigStore } from "./components/TypicalsConfig/DatacenterConfig";
import { useLowVoltageRoomConfigStore } from "./components/TypicalsConfig/LowVoltageRoomConfig";
import { useWhitespaceConfigStore } from "./components/TypicalsConfig/WhitespaceConfig";

export default function DatacenterGeneratorDashboardPage() {
  const { t } = useTranslation();
  const { emailAddress, appAbility } = useUserStore();
  const { heijmansBlue } = colors;

  const {
    drawedPlot,
    currentProject,
    isInputPaneOpen,
    currentTabPageIndex,
    isSaveAvailable,
    currentDatacenters,
    currentLayouts,
    setIsSaveAvailable,
    setCurrentProject,
    setIsInputPaneOpen,
    setCurrentTabPageIndex,
    setCurrentPageType,
    setCurrentDatacenters,
    setCurrentLayouts,
  } = useDatacenterGeneratorStore();

  const {
    dcScopes,
    destinationPlans,
    desiredPowerOptions,
    coolingPrincipleOptions,
    powerRackOptions,
    setDcScopes,
    setDestinationPlans,
    setDesiredPowerOptions,
    setPowerRackOptions,
    setCoolingPrincipleOptions,
  } = useGeneralDatacenterObjectsStore();

  const whitespaceConfigStore = useWhitespaceConfigStore();
  const lowVoltageRoomConfigStore = useLowVoltageRoomConfigStore();
  const datacenterConfigStore = useDatacenterConfigStore();

  const {
    selectedIds: selectedLowVoltageRoomIds,
    setSelectedIds: setSelectedLowVoltageRoomIds,
  } = lowVoltageRoomConfigStore.currentStore();

  const {
    selectedIds: selectedWhitespaceIds,
    setSelectedIds: setSelectedWhitespaceIds,
  } = whitespaceConfigStore.currentStore();

  const {
    setSelectedIds: setSelectedDatacenterIds,
    selectedIds: selectedDatacenterIds,
  } = datacenterConfigStore.currentStore();

  const [isProjectViewOpen, setIsProjectViewOpen] =
    React.useState<boolean>(false);

  const tabConfigurations = [
    {
      index: 1,
      type: TypeObject.Whitespace,
      currentTypicalConfigStore: whitespaceConfigStore,
    },
    {
      index: 2,
      type: TypeObject.LowVoltageRoom,
      currentTypicalConfigStore: lowVoltageRoomConfigStore,
    },
    {
      index: 3,
      type: TypeObject.Datacenter,
      currentTypicalConfigStore: datacenterConfigStore,
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabPageIndex(newValue);
  };

  useEffect(() => {
    (async () => {
      if (dcScopes.length === 0) {
        setDcScopes(await GetDcScopes());
      }

      if (destinationPlans.length === 0) {
        setDestinationPlans(await GetDestinationPlans());
      }

      if (coolingPrincipleOptions.length === 0) {
        setCoolingPrincipleOptions(await GetCoolingPrinciples());
      }

      if (powerRackOptions.length === 0) {
        setPowerRackOptions(await GetPowerRackWattages());
      }

      if (desiredPowerOptions.length === 0) {
        setDesiredPowerOptions(await GetDesiredPowerWattages());
      }
    })();
  }, []);

  useEffect(() => {
    switch (currentTabPageIndex) {
      case 0:
        setCurrentPageType(TypeObject.MapView);
        return;
      case 1:
        setCurrentPageType(TypeObject.Whitespace);
        return;
      case 2:
        setCurrentPageType(TypeObject.LowVoltageRoom);
        return;
      case 3:
        setCurrentPageType(TypeObject.Datacenter);
        return;
      case 4:
        setCurrentPageType(TypeObject.Export);
        return;
      default:
        setCurrentPageType(TypeObject.MapView);
    }
  }, [currentTabPageIndex, setCurrentPageType]);

  useEffect(() => {
    if (selectedDatacenterIds.length === 0) {
      setCurrentDatacenters(GetEmptyDatacenter());
      setCurrentLayouts([] as LayoutDto[]);
    } else {
      (async () => {
        try {
          const tempCurrentLayouts: LayoutDto[] = currentLayouts;
          const tempCurrentDatacenters: DatacenterDto[] = currentDatacenters;
          for (let i = 0; i < selectedDatacenterIds.length; i++) {
            if (
              !tempCurrentDatacenters.find(
                (x) => x.id === selectedDatacenterIds[i]
              )
            ) {
              const datacenter = await GetDatacenter(selectedDatacenterIds[i]);
              const layout = await GetLayout(datacenter?.layout);
              tempCurrentLayouts.push(layout);
              tempCurrentDatacenters.push(datacenter);
            }
          }
          setCurrentLayouts(tempCurrentLayouts);
          setCurrentDatacenters(tempCurrentDatacenters);
        } catch (ex) {
          ShowError(t("Failed to get datacenter"));
        }
      })();
    }
  }, [selectedDatacenterIds]);

  useEffect(() => {
    if (currentProject?.buildings) {
      const selectedBuildings = JSON.parse(
        currentProject.buildings
      ) as SelectedBuildingsDto;

      if (selectedBuildings.whitespaces) {
        setSelectedWhitespaceIds(selectedBuildings.whitespaces);
      }
      if (selectedBuildings.lowVoltageRooms) {
        setSelectedLowVoltageRoomIds(selectedBuildings.lowVoltageRooms);
      }
      if (selectedBuildings.datacenters) {
        setSelectedDatacenterIds(selectedBuildings.datacenters);
      }
    }
  }, [currentProject?.buildings]);

  useEffect(() => {
    if (!currentProject) return;

    const currentBuildings = currentProject.buildings
      ? (JSON.parse(currentProject.buildings) as SelectedBuildingsDto)
      : {};

    const newBuildings = {
      ...currentBuildings,
      whitespaces: selectedWhitespaceIds,
      lowVoltageRooms: selectedLowVoltageRoomIds,
      datacenters: selectedDatacenterIds,
    };

    const newBuildingsString = JSON.stringify(newBuildings);
    if (newBuildingsString !== currentProject.buildings) {
      setCurrentProject({
        ...currentProject,
        buildings: newBuildingsString,
      });
    }
  }, [selectedWhitespaceIds, selectedLowVoltageRoomIds, selectedDatacenterIds]);

  useEffect(() => {
    if (isSaveAvailable) {
      setIsSaveAvailable(
        currentProject.created_by === emailAddress ||
          (appAbility?.can("update", "configurators.datacenter") ?? false)
      );
    }
  }, [isSaveAvailable]);

  return (
    <div className="datacenter-generator-page">
      <Box
        component={"div"}
        sx={{
          boxShadow: 3,
          display: "flex",
          paddingRight: "20px",
          alignItems: "center",
        }}
      >
        <Tabs
          sx={{ flexGrow: 1 }}
          value={currentTabPageIndex}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <Tab label={t("Basic principles")} />
          <Tab
            label={
              <Typography>
                {t("Preselection")}
                <br />
                {t("Whitespace")}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                {t("Preselection")}
                <br />
                {t("Low voltage room")}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                {t("Select")}
                <br />
                {t("Datacenter")}
              </Typography>
            }
          />
          <Tab
            disabled={
              selectedDatacenterIds.length === 0 ||
              !drawedPlot ||
              drawedPlot.buildableCoordinates.length === 0
            }
            label={<Typography>{t("Generate datacenter")}</Typography>}
          />
        </Tabs>

        <CustomPopover
          content={
            <IconButton
              disabled={!isSaveAvailable}
              onClick={() => {
                setCurrentProject({ ...currentProject, id: 0 });
                setIsProjectViewOpen(true);
              }}
            >
              <SaveAs
                sx={{ fontSize: "2rem", color: heijmansBlue }}
                className={isSaveAvailable ? "svg" : "save-icon-disabled"}
              />
            </IconButton>
          }
          popoverTitle={t("SaveAsProjectTitle")}
          popoverText={
            isSaveAvailable
              ? t("SaveAsDatacenterGeneratorProjectCaption")
              : t("SaveAsDatacenterGeneratorProjectCaptionNotAvailable")
          }
        ></CustomPopover>

        <CustomPopover
          content={
            <IconButton
              disabled={!isSaveAvailable}
              onClick={() => setIsProjectViewOpen(true)}
            >
              <Save
                sx={{ fontSize: "2rem", color: heijmansBlue }}
                className={isSaveAvailable ? "svg" : "save-icon-disabled"}
              />
            </IconButton>
          }
          popoverTitle={t("SaveProjectTitle")}
          popoverText={
            isSaveAvailable
              ? t("SavingDatacenterGeneratorProjectCaption")
              : t("SavingDatacenterGeneratorProjectCaptionNotAvailable")
          }
        ></CustomPopover>
      </Box>

      {!isInputPaneOpen && (
        <div className="datacenter-input-pane-button">
          <IconButton
            style={{ backgroundColor: "white", zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronRightIcon sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}

      <div className="dashboard-view">
        <Collapse orientation="horizontal" in={isInputPaneOpen}>
          <InputPane
            datacenterConfigStore={datacenterConfigStore}
            lowVoltageRoomConfigStore={lowVoltageRoomConfigStore}
            whitespaceConfigStore={whitespaceConfigStore}
          />
        </Collapse>

        <div>
          <TabPanel value={currentTabPageIndex} index={0}>
            <InputsView />
          </TabPanel>
          {tabConfigurations.map((config) => (
            <TabPanel
              key={config.index}
              value={currentTabPageIndex}
              index={config.index}
            >
              <BaseExploreView
                type={config.type}
                currentTypicalConfigStore={
                  config.currentTypicalConfigStore as unknown as ExploreConfigStore<DTO>
                }
                datacenterConfigStore={datacenterConfigStore}
              />
            </TabPanel>
          ))}
          <TabPanel value={currentTabPageIndex} index={4}>
            <GenerateDatacenterView />
          </TabPanel>
        </div>
      </div>

      <CreateOrUpdateProjectView
        rename={false}
        isProjectViewOpen={isProjectViewOpen}
        setIsProjectViewOpen={setIsProjectViewOpen}
        onSumbit={() => setIsProjectViewOpen(false)}
        datacenterConfigStore={datacenterConfigStore}
        lowVoltageRoomConfigStore={lowVoltageRoomConfigStore}
        whitespaceConfigStore={whitespaceConfigStore}
      />
    </div>
  );
}
