import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Collapse } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import GenerateDataFloatingActionButton from "../../../components/GenerateDataFloatingActionButton/GenerateDataFloatingActionButton";
import InputPane from "../../../components/InputPane/InputPane";
import { GetBuildingTypesOptions } from "../../../http/foundry/BuildingTypeService";
import {
  GetCoolingPrinciples,
  GetDcScopes,
  GetDestinationPlans,
  GetPowerRackWattages,
} from "../../../http/foundry/InputPaneService";
import { BuildingTypeEnum } from "../../../interfaces/enums/BuildingTypeEnum";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { ExploreConfigStore } from "../../../interfaces/exploreConfig";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { TypicalDTO } from "../../../state/ExploreState/ExploreState";
import BaseExploreView from "../../Configurators/Datacenter/components/BaseExploreView/BaseExploreView";
import { useWhitespaceConfigStore } from "../../Configurators/Datacenter/components/TypicalsConfig/WhitespaceConfig";

export default function WhitespaceToolingDashboardPage() {
  const { appAbility } = useUserStore();
  const { isInputPaneOpen, setIsInputPaneOpen } = useDatacenterGeneratorStore();

  const {
    setBuildingTypeDtoOptions,
    setPowerRackOptions,
    setCoolingPrincipleOptions,
    setDcScopes,
    setDestinationPlans,
  } = useGeneralDatacenterObjectsStore();

  const { setCurrentPageType } = useDatacenterGeneratorStore();
  const whitespaceConfigStore = useWhitespaceConfigStore();

  useEffect(() => {
    setCurrentPageType(TypeObject.WhitespaceTooling);

    (async () => {
      setDcScopes(await GetDcScopes());
      setDestinationPlans(await GetDestinationPlans());
      setPowerRackOptions(await GetPowerRackWattages());
      setCoolingPrincipleOptions(await GetCoolingPrinciples());
      setBuildingTypeDtoOptions(await GetBuildingTypesOptions());
    })();
  }, []);

  return (
    <div className="datacenter-generator-page">
      {!isInputPaneOpen && (
        <div className="whitespace-tooling-button">
          <IconButton
            style={{ backgroundColor: "white", zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronRightIcon sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}

      <div className="dashboard-view">
        <Collapse orientation="horizontal" in={isInputPaneOpen}>
          <InputPane whitespaceConfigStore={whitespaceConfigStore} />
        </Collapse>

        <div>
          <BaseExploreView
            type={TypeObject.WhitespaceTooling}
            currentTypicalConfigStore={
              whitespaceConfigStore as unknown as ExploreConfigStore<TypicalDTO>
            }
          />
        </div>

        {appAbility?.can("manage", "generators.whitespaceandcooling") && (
          <GenerateDataFloatingActionButton
            buildingTypeEnum={BuildingTypeEnum.Datacenter}
          />
        )}
      </div>
    </div>
  );
}
