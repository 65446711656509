import * as d3 from "d3";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TypeObject } from "../interfaces/enums/TypeObjectEnum";
import { SelectedBuildingsDto } from "../interfaces/foundry/SelectedBuildingsDto";
import useDatacenterGeneratorStore from "../state/DatacenterState/datacenterGeneratorState";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../state/ExploreState/ExploreState";
import useScenarioStore from "../state/NitrogenState/scenarioState";
import "./ParallelCoordinatesPlot.scss";
import { useHover } from "./Scene/HoverContext";

interface ParallelCoordinatesPlotProps<T extends DTO> {
  currentState: ExploreState<T> & ExploreActions<T>;
  dimensions: string[];
  chartLabels: string[];
  clickableAxes: string[];
  onBrushObjects: (
    brushedObjects: T[],
    sortProperty: string,
    isSortPropertyAscending: boolean
  ) => void;
}

export default function ParallelCoordinatesPlot<T extends DTO>({
  currentState,
  dimensions,
  chartLabels,
  clickableAxes,
  onBrushObjects,
}: ParallelCoordinatesPlotProps<T>) {
  const { hovered } = useHover<T>();
  const { t } = useTranslation();
  const {
    currentProject,
    currentPageType,
    filterControlSelectedAxes,
    isInputPaneOpen,
    setCurrentProject,
  } = useDatacenterGeneratorStore();

  const { filterControlSelectedAxesScenario } = useScenarioStore();
  let currentFilterControlSelectedAxes = filterControlSelectedAxes;

  if (currentPageType === TypeObject.NitrogenAnalyser) {
    currentFilterControlSelectedAxes = filterControlSelectedAxesScenario;
  }

  const plotRef = useRef<HTMLInputElement>();
  const foregroundRef = useRef<any>();

  const height = useRef<number>(0);
  const x = useRef(d3.scalePoint().range([0, 0]));
  const plot = useRef<any>();
  const y = useRef<{
    [key: string]: d3.AxisScale<d3.AxisDomain> & {
      brushSelectionValue?: any;
      invert?: any;
      brush?: any;
    };
  }>({});
  const prevHoveredRef = useRef<T>();
  const prevSelecedRef = useRef<number[]>([]);

  const selectedColor = "RGB(0, 51, 102)";
  const colors = useMemo(
    () =>
      d3.scaleSequential(
        d3.interpolateRgbBasis([
          "RGB(255, 124, 128)",
          "RGB(253, 220, 117)",
          "RGB(120, 177, 79)",
        ])
      ),
    []
  );

  const axisMinMaxValues = useRef<{
    [key: string]: { min: number; max: number };
  }>({});

  useMemo(() => {
    currentFilterControlSelectedAxes?.forEach((axis) => {
      const col: number[] = currentState?.filteredObjects?.map(
        (item: T) => +item[axis as keyof T] // Convert the value to a number using the unary plus operator
      );
      if (col && col.length > 0)
        axisMinMaxValues.current[axis] = {
          min: Math.min(...col),
          max: Math.max(...col),
        };
    });
  }, [currentFilterControlSelectedAxes, currentState?.filteredObjects]);

  const getColor = useCallback(
    (d: T): number => {
      if (!currentFilterControlSelectedAxes.length) {
        return 0;
      }

      const avgColorValue: number =
        currentFilterControlSelectedAxes.reduce((sum: number, axis: string) => {
          const { min, max } = axisMinMaxValues.current[axis];
          return max !== min
            ? sum + ((d[axis as keyof T] as number) - min) / (max - min)
            : sum + 1 / 2;
        }, 0) / currentFilterControlSelectedAxes.length;

      return avgColorValue;
    },
    [currentFilterControlSelectedAxes]
  );

  const calculateChartDimensions = useCallback(
    (data: T[]) => {
      dimensions?.forEach((dim: string) => {
        if (y.current) {
          const yAs = d3
            .scaleLinear()
            .range([height.current, 0])
            .domain(
              d3.extent(data, function (d: T) {
                return +(d[dim as keyof T] ?? 0);
              }) as [number, number]
            );

          y.current[dim] = yAs as d3.AxisScale<d3.AxisDomain>;
        }
      });
    },
    [dimensions]
  );

  const path = useCallback(
    (d: T) => {
      return d3.line()(
        dimensions.map(function (p: string) {
          return [
            x.current(p) ?? 0,
            y.current[p](Number(d[p as keyof T])) || 0,
          ];
        })
      );
    },
    [dimensions]
  );

  const drawGraphs = useCallback(
    (data: T[]) => {
      return plot.current
        .append("g")
        .attr("class", "foreground selection-cursor")
        .selectAll("path")
        .data(data)
        .enter()
        .append("path")
        .attr("d", path)
        .style("fill", "none")
        .style("opacity", 0);
    },
    [path]
  );

  const updateLabelStyles = useCallback(() => {
    d3.selectAll<SVGTextElement, string>("text").style(
      "font-weight",
      (d: string) =>
        currentFilterControlSelectedAxes.includes(d) ? "bold" : "normal"
    );

    d3.selectAll<SVGTextElement, string>("rect").style("fill", (d) =>
      currentFilterControlSelectedAxes.includes(d) ? "black" : "none"
    );
  }, [currentFilterControlSelectedAxes]);

  const displayAxesLabels = useCallback(
    (verticalAxes: any) => {
      verticalAxes
        .append("text")
        .style("text-anchor", "middle")
        .attr("y", -9)
        .text((d: number, i: number) => t(chartLabels[i]))
        .style("fill", "black")
        .style("font-size", "10px")
        .call(wrapText, 100);

      updateLabelStyles();
    },
    [chartLabels, clickableAxes, dimensions, t, updateLabelStyles]
  );

  const drawVerticalAxes = useCallback(() => {
    return plot.current
      .append("g")
      .attr("class", "dimension")
      .selectAll("g")
      .data(dimensions)
      .enter()
      .append("g")
      .attr("class", "axis")
      .attr("transform", function (d: string) {
        return "translate(" + x.current(d) + ")";
      })
      .each(function (this: SVGGElement, d: string) {
        const axis = d3.axisLeft(y.current[d]);

        if (
          d === "transformator_amount" ||
          d === "building_layers" ||
          d === "whitespace_amount"
        ) {
          const domain = y.current[d].domain();
          if (typeof domain[0] === "number" && typeof domain[1] === "number") {
            const tickValues = d3.range(
              Math.ceil(domain[0]),
              Math.floor(domain[1]) + 1
            );

            axis
              .tickValues(tickValues)
              .tickFormat((d) => (Number.isInteger(d) ? d.toString() : ""));
          }
        }

        d3.select(this).call(axis);
      });
  }, [dimensions]);

  const brushChart = (
    verticalAxes: {
      append: (arg0: string) => {
        (): T;
        new (): T;
        attr: {
          (arg0: string, arg1: string): {
            (): T;
            new (): T;
            each: {
              (arg0: (this: SVGGElement, d: number) => void): void;
              new (): T;
            };
          };
          new (): T;
        };
      };
    },
    brush: d3.BrushBehavior<unknown>
  ) => {
    verticalAxes
      .append("g")
      .attr("class", "brush")
      .each(function (this: SVGGElement, d: number) {
        d3.select(this).call((y.current[d].brush = brush));
      });
  };

  const brushMove = useCallback(() => {
    const actives = getGraphActivesFromState();
    plot.current
      .selectAll(".brush")
      .filter(function (this: SVGGElement, d: number) {
        y.current[d].brushSelectionValue = d3.brushSelection(this);
        return d3.brushSelection(this);
      })
      .each(function (this: SVGGElement, d: number) {
        const index = actives.findIndex((active) => active.dimension === d);
        if (index !== -1) {
          actives.splice(index, 1);
        }

        actives.push({
          dimension: d,
          extent: d3.brushSelection(this)?.map(y.current[d].invert),
        });
      });

    setCurrentBrushedObjects(actives);
    currentState.setFilterOnAxes(actives);
  }, [currentState.filteredWithCoolingTypes]);

  const reAppendAxes = () => {
    const axes = plot.current.selectAll(".axis").nodes();
    axes.forEach((axis: Element) => {
      plot.current.node().appendChild(axis);
    });
  };

  const setCurrentBrushedObjects = (
    actives: { dimension: number; extent: number[] }[]
  ) => {
    const selected = foregroundRef.current
      ?.data()
      .filter(function (d: number[]) {
        return actives.every(function (active: {
          dimension: number;
          extent: number[];
        }) {
          return (
            active.extent[1] <= d[active.dimension] &&
            d[active.dimension] <= active.extent[0]
          );
        });
      });

    const brushed: T[] = selected.filter((d: T) =>
      currentState.filteredWithCoolingTypes.includes(d)
    );

    if (brushed.length !== 0) {
      currentState.setBrushedObjects(brushed);
    }
  };

  const makeSkeleton = useCallback(
    (allData: T[]) => {
      if (plotRef.current) {
        d3.select(plotRef.current).selectAll("svg").remove();
      }

      const margin = { top: 20, right: 10, bottom: 30, left: 0 };
      const width = plotRef.current?.clientWidth
        ? plotRef.current?.clientWidth - margin.left - margin.right
        : 0;
      height.current = plotRef.current?.clientHeight
        ? plotRef.current?.clientHeight / 1.05 - margin.top - margin.bottom
        : 0;

      plot.current = plotRef.current
        ? d3
            .select(plotRef.current)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + 27 + ")")
        : null;

      y.current = {};
      calculateChartDimensions(allData);
      x.current = d3
        .scalePoint()
        .range([0, width])
        .padding(1)
        .domain(dimensions);

      const verticalAxes = drawVerticalAxes();
      const brush = d3
        .brushY()
        .extent([
          [-10, 0],
          [10, height.current],
        ])
        .on("brush", brushMove);

      brushChart(verticalAxes, brush);
      displayAxesLabels(verticalAxes);
    },
    [
      calculateChartDimensions,
      dimensions,
      drawVerticalAxes,
      brushMove,
      displayAxesLabels,
    ]
  );

  const styleGraphsOnChart = useCallback(() => {
    foregroundRef.current
      .style("stroke", function (d: T) {
        const graphColor = getColor(d);
        d.value = graphColor * 100;
        d.color = colors(graphColor);
        if (currentState.brushedObjects.length !== 0) {
          return currentState.brushedObjects.includes(d) &&
            currentState.filteredWithCoolingTypes.includes(d)
            ? currentState?.selectedIds?.find((x) => x === d.id)
              ? selectedColor
              : colors(graphColor)
            : "grey";
        } else {
          return "grey";
        }
      })
      .style("opacity", function (d: T) {
        if (currentState.brushedObjects.length !== 0) {
          return currentState.brushedObjects.includes(d) &&
            currentState.filteredWithCoolingTypes.includes(d)
            ? 1
            : 0.4;
        } else {
          return 1;
        }
      })
      .style("stroke-width", function (d: T) {
        if (currentState.brushedObjects.length !== 0) {
          return currentState.brushedObjects.includes(d) &&
            currentState.filteredWithCoolingTypes.includes(d)
            ? currentState?.selectedIds?.find((x) => x === d.id)
              ? 3
              : 1
            : 0.2;
        } else {
          return 1;
        }
      });

    reAppendAxes();
  }, [
    colors,
    getColor,
    currentState.brushedObjects,
    currentState.filteredWithCoolingTypes,
  ]);

  function getGraphActivesFromState() {
    if (!currentProject.buildings) {
      return [];
    }

    const buildingsDto = JSON.parse(
      currentProject.buildings
    ) as SelectedBuildingsDto;
    if (currentPageType === TypeObject.Whitespace)
      return buildingsDto.selectedItemsWhitespaceGraph ?? [];
    if (currentPageType === TypeObject.LowVoltageRoom)
      return buildingsDto.selectedItemsLVRGraph ?? [];
    if (currentPageType === TypeObject.Datacenter)
      return buildingsDto.selectedItemsDatacenterGraph ?? [];

    return [];
  }

  useEffect(() => {
    const prevSelectedItemsOnGraphSet = new Set(
      prevSelecedRef.current?.map((item) => item)
    );
    const prevSelectedOrHoveredItemsOnGraph = foregroundRef?.current?.filter(
      (item: T) =>
        prevSelectedItemsOnGraphSet.has(item.id) ||
        item.id === prevHoveredRef.current?.id
    );

    prevSelectedOrHoveredItemsOnGraph
      ?.style("stroke", function (d: T) {
        return colors(getColor(d));
      })
      .style("stroke-width", function () {
        return 1;
      });

    const selectedItemsSet = new Set(
      currentState.selectedIds.map((item) => item)
    );
    const selectedItemsOnGraph = foregroundRef?.current?.filter((item: T) =>
      selectedItemsSet.has(item.id)
    );

    const hoveredItemOnGraph = foregroundRef?.current?.filter(
      (item: T) => item.id === hovered?.id
    );
    hoveredItemOnGraph
      ?.raise()
      .style("stroke", function (d: T) {
        return "black";
      })
      .style("stroke-width", function (d: T) {
        return 3;
      });

    if (hovered) {
      prevHoveredRef.current = hovered;
    }

    selectedItemsOnGraph
      ?.raise()
      ?.style("stroke", function (d: T) {
        if (d.id === hovered?.id) return "black";
        else return "RGB(0, 51, 102)";
      })
      ?.style("stroke-width", function (d: T) {
        return 3;
      });

    prevSelecedRef.current = currentState.selectedIds.map((item) => item);
  }, [colors, getColor, currentState.selectedIds, hovered]);

  useEffect(() => {
    updateLabelStyles();
  }, [currentFilterControlSelectedAxes, updateLabelStyles]);

  useEffect(() => {
    if (foregroundRef.current) {
      styleGraphsOnChart();
    }
  }, [styleGraphsOnChart, isInputPaneOpen]);

  useEffect(() => {
    if (currentState.brushedObjects && currentState.brushedObjects.length > 0) {
      onBrushObjects(
        currentState.brushedObjects,
        currentState.objectSortProperty,
        currentState.objectSortAscending
      );
    }
  }, [
    currentState.brushedObjects,
    currentState.objectSortProperty,
    currentState.objectSortAscending,
    onBrushObjects,
  ]);

  useEffect(() => {
    drawGraph();

    function handleWindowWidthChange() {
      drawGraph();
    }
    window.addEventListener("resize", handleWindowWidthChange);
    return () => {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  }, [currentState.filteredObjects, currentState.isObjectsLoaded]);

  useEffect(() => {
    if (
      currentState.isReset ||
      currentState.filteredObjects ||
      currentState.filteredWithCoolingTypes
    ) {
      drawGraph();
    }
  }, [
    currentState.isReset,
    currentState.filteredObjects,
    currentState.filteredWithCoolingTypes,
  ]);

  function wrapText(
    text: d3.Selection<SVGTextElement, string, any, any>,
    width: number
  ): void {
    text.each(function () {
      const textElement = d3.select(this);
      const words = textElement.text().split(/\s+/).reverse();
      let word: string | undefined;
      let line: string[] = [];
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      const y = parseFloat(textElement.attr("y") || "0");
      const dy = parseFloat(textElement.attr("dy") || "0");
      let tspan = textElement
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y - 10)
        .attr("dy", dy + "em");

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node()!.getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = textElement
            .append("tspan")
            .attr("x", 0)
            .attr("y", y - 10)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  function drawGraph() {
    if (currentState.isObjectsLoaded) {
      makeSkeleton(currentState.filteredObjects);
      foregroundRef.current = drawGraphs(currentState.filteredObjects);
      currentState.setBrushedObjects([
        ...currentState.filteredWithCoolingTypes,
      ]);

      const actives = getGraphActivesFromState();
      if (actives.length > 0) {
        actives.forEach((active) => {
          try {
            const dimension = active.dimension;
            const extent = active.extent;
            if (!y.current[dimension] || isNaN(extent[0]) || isNaN(extent[1])) {
              return;
            }

            const brushMoveValues = extent.map((value: number) =>
              y.current[dimension](value)
            );
            if (
              !brushMoveValues ||
              brushMoveValues.some((value: number) => isNaN(value))
            ) {
              return;
            }

            plot.current
              .selectAll(".brush")
              .filter(function (d: number) {
                return d === dimension;
              })
              ?.call(y.current[dimension]?.brush?.move, brushMoveValues);
          } catch (ex) {}
        });
      }
    }
  }

  if (currentState.isObjectsLoaded) {
    return (
      <div
        ref={plotRef as React.MutableRefObject<HTMLDivElement | null>}
        className="noselect"
        style={{ width: "100%", height: "90%", cursor: "crosshair" }}
      ></div>
    );
  }
}
