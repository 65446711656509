import ColorPalette from "../../interfaces/ColorPalette";
import { TrackException } from "../../logging/LoggingManager";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import useGeneralDatacenterObjectsStore from "../../state/DatacenterState/generalDatacenterObjectsState";

export async function GetColorPalette(): Promise<ColorPalette> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let colorPalette = store.colorPalette;
  if (!colorPalette) {
    colorPalette = await GetColorPaletteDto();
    store.setColorPalette(colorPalette);
  }
  return colorPalette;
}

async function GetColorPaletteDto(): Promise<ColorPalette> {
  try {
    const response = await foundryApiClient.get<ColorPalette>(
      "/api/geometry/color_palette"
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
  }

  return {} as ColorPalette;
}
