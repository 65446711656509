import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Fade
} from "@mui/material";
import React, { ReactNode, createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CancelButton from "../../../../components/CancelButton/CancelButton";

export interface OpenDialogOptions {
  title: string;
  content: ReactNode;
  actionButtons?: ReactNode[];
  dialogCustomHeader?: ReactNode;
  width?: string;
  height?: string;
  save?: () => boolean;
}

interface DialogContextType {
  openDialog: (dialogOptions: OpenDialogOptions) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

interface CustomDialogProps extends Omit<DialogProps, "open"> {
  children: ReactNode;
}

export const DialogProvider: React.FC<CustomDialogProps> = ({
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState<OpenDialogOptions>(
    {} as OpenDialogOptions
  );
  const [dialogSize, setDialogSize] = useState({
    width: "auto",
    height: "auto",
  });

  const openDialog = (options: OpenDialogOptions) => {
    setOpen(true);
    setDialogOptions(options);
    setDialogSize({
      width: options.width ?? "auto",
      height: options.height ?? "auto",
    });
  };

  const closeDialog = () => {
    const dialogElement = document.getElementById("dialog-paper");
    if (dialogElement) {
      const { offsetWidth, offsetHeight } = dialogElement;
      setDialogSize({ width: `${offsetWidth}px`, height: `${offsetHeight}px` });
    }

    setOpen(false);
    setDialogOptions({} as OpenDialogOptions);
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <Dialog
        {...props}
        maxWidth="xl"
        open={open}
        keepMounted
        onClose={closeDialog}
        TransitionComponent={Fade}
        TransitionProps={{
          timeout: 300,
        }}
        PaperProps={{
          id: "dialog-paper",
          style: {
            borderRadius: 20,
            width: dialogOptions?.width ?? "auto",
            height: dialogOptions?.height ?? "auto",
            minWidth:
              dialogSize.width !== "auto" ? dialogSize.width : undefined,
            minHeight:
              dialogSize.height !== "auto" ? dialogSize.height : undefined,
          },
        }}
      >
        {dialogOptions.dialogCustomHeader ? (
          dialogOptions.dialogCustomHeader
        ) : (
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 18,
              fontWeight: 900,
              color: "rgba(0, 52, 102, 1)",
            }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {t(dialogOptions.title)}
            <Box component={"div"} sx={{ ml: "auto", display: "flex" }}>
              <CancelButton onClick={closeDialog} />
            </Box>
          </DialogTitle>
        )}
        <Divider sx={{ marginTop: "-10px" }} />
        <DialogContent sx={{ overflow: "hidden", padding: "0px !important" }}>
          {dialogOptions.content}
        </DialogContent>
        {dialogOptions.actionButtons && (
          <DialogActions>{dialogOptions.actionButtons}</DialogActions>
        )}
      </Dialog>
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};
