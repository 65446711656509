import { Fab, Popover, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Maps from "../../../../../components/Maps/Maps";
import { EllipseIcon } from "../../../../../icons/EllipseIcon";
import { MapType } from "../../../../../interfaces/enums/MapTypeEnum";
import useNitrogenInputPaneStore from "../../../../../state/NitrogenState/nitrogenInputPaneState";
import useNitrogenStore from "../../../../../state/NitrogenState/nitrogenState";
import "./PlanningAssumptionsView.scss";

export default function PlanningAssumptionsView() {
  const { t } = useTranslation();
  const { drawedMarker } = useNitrogenInputPaneStore();
  const { setCalculateDistance } = useNitrogenStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="inputs-view">
      <Maps mapType={MapType.CreatingMarker} />
      <div className="floating-action-button-layout">
        <Fab
          id="fab"
          className="floating-action-button"
          aria-label="add"
          disabled={!drawedMarker || drawedMarker.lotCoordinates.length === 0}
          onClick={handleClick}
        >
          <EllipseIcon size={25} />
        </Fab>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <div className="popover-content">
            <Typography
              sx={{ p: 2 }}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
                setCalculateDistance(true);
              }}
            >
              {t("Measure distance between drawed Marker to A/N road")}
            </Typography>
          </div>
        </Popover>
      </div>
    </div>
  );
}
