import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { GetScenario } from "../../modules/Analysers/services/ScenarioService";
import { GetDatacenter } from "../../http/foundry/DatacenterService";
import { GetLayout } from "../../http/foundry/GeometryService";
import { GetLowVoltageRoom } from "../../http/foundry/LowVoltageRoomService";
import { GetWhitespace } from "../../http/foundry/WhitespaceService";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import WhitespaceDto from "../../interfaces/foundry/WhitespaceDto";
import { LowVoltageRoomDto } from "../../interfaces/foundry/typicals/LowVoltageRoomDto";
import { TrackException } from "../../logging/LoggingManager";
import { DTO } from "../../state/ExploreState/ExploreState";

// Mapping of typeObject to data fetching functions
export const typeObjectFetchMap: {
  [key in TypeObject]: (id: number) => Promise<any>;
} = {
  [TypeObject.LowVoltageRoom]: GetLowVoltageRoom,
  [TypeObject.LowVoltageRoomTooling]: GetLowVoltageRoom,
  [TypeObject.Whitespace]: GetWhitespace,
  [TypeObject.WhitespaceTooling]: GetWhitespace,
  [TypeObject.Datacenter]: GetDatacenter,
  [TypeObject.NitrogenAnalyser]: GetScenario,
  [TypeObject.MapView]: function (id: number): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.Rack]: function (id: number): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.Transformator]: function (id: number): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.Component]: function (id: number): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.Export]: function (id: number): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.Ups]: function (id: number): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.Battery]: function (id: number): Promise<any> {
    throw new Error("Function not implemented.");
  },
};

async function getCurrentObjectDetails<T>(
  typeObject: TypeObject,
  object: T,
  setCurrentObject: (newValue: T | null) => void
) {
  try {
    setCurrentObject({
      ...object,
      typeObject: typeObject,
    });

    if (typeObject !== TypeObject.Datacenter) {
      const layoutId = (object as { layout: number })?.layout;
      const layout = layoutId ? await GetLayout(layoutId) : null;

      setCurrentObject({
        ...object,
        typeObject: typeObject,
        view2d_png: layout?.view2d,
      });
    }
  } catch (ex) {
    TrackException(ex);
  }
}

export async function openDetailView<T extends DTO>(
  currentObject: T,
  setIsOpenedDetailedView: (isOpen: boolean) => void,
  setCurrentObject: (newValue: T | null) => void
) {
  setIsOpenedDetailedView(true);
  try {
    const object = currentObject as { id: number; typeObject: TypeObject };
    const fetchFunction = typeObjectFetchMap[object.typeObject];
    if (fetchFunction) {
      var details = await fetchFunction(object.id);
      if (!details.id) {
        details = currentObject;
      }

      getCurrentObjectDetails(object.typeObject, details, setCurrentObject);
    } else {
      throw new Error("Unsupported typeObject");
    }
  } catch (ex) {
    TrackException(ex);
  }
}

function getLabelColor(buildingType: string) {
  switch (buildingType) {
    case "Hospitals":
      return "green";
    case "Datacenters":
      return "orange";
    case "Offices":
      return "red";
    default:
      return "none";
  }
}

export function getObjectsBuildingTypes<T>(
  currentObject: T,
  isInOriginalList: boolean
): JSX.Element[] {
  const object = currentObject as { typeObject: TypeObject };
  let wsORLvr: LowVoltageRoomDto | WhitespaceDto | null = null;
  if (object.typeObject === TypeObject.LowVoltageRoomTooling) {
    wsORLvr = currentObject as LowVoltageRoomDto;
  } else if (object.typeObject === TypeObject.WhitespaceTooling) {
    wsORLvr = currentObject as WhitespaceDto;
  }

  const BuildingTypeLabels: JSX.Element[] = [];

  if (wsORLvr && wsORLvr.building_types) {
    for (let i = 0; i < wsORLvr.building_types.length; i++) {
      const style = {
        minWidth: isInOriginalList ? "10px !important" : "7px !important",
        maxWidth: isInOriginalList ? "10px !important" : "7px !important",
        height: isInOriginalList ? "10px !important" : "7px !important",
        backgroundColor: getLabelColor(wsORLvr.building_types[i].name),
        borderRadius: "50%", // Makes the div a circle
        display: "inline-block",
        margin: isInOriginalList ? "3px !important" : "1px !important",
      };
      const tooltipTitle = wsORLvr.building_types[i].name;
      BuildingTypeLabels.push(
        <Tooltip title={tooltipTitle} key={`buildingType-${i}`}>
          <Grid item xs={1} sx={style}></Grid>
        </Tooltip>
      );
    }
  }

  return BuildingTypeLabels;
}
