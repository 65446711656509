export enum InputDataCatalogEnum {
  "InputDataBrand" = 0,
  "InputDataCostCategory" = 1,
  "InputDataCostCode" = 2,
  "InputDataCostUnit" = 3,
  "InputDataDepartment" = 4,
  "InputDataDimensionUnit" = 5,
  "InputDataEquipmentType" = 6,
  "InputDataObjectType" = 7,
  "InputDataOwner" = 8,
  "InputDataProductionUnit" = 9,
  "InputDataPhase" = 10,
}
