import { SpaceType } from "../types/enums";

export function getColor(spaceType: SpaceType): string {
  switch (spaceType) {
    case SpaceType.Appartment1K:
      return "#ffffb3";
    case SpaceType.Appartment2K:
      return "#efb881";
    case SpaceType.Appartment3K:
      return "#b4d5b3";
    case SpaceType.Appartment4K:
      return "#a8d3ff";
    case SpaceType.Appartment5K:
    case SpaceType.Appartment6K:
    case SpaceType.Appartment7K:
      return "#d0a2df";
    case SpaceType.Entrance:
      return "#408080";
  }

  return "#c0c0c0";
}
