import React from "react";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 110px)",
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <div className="titel" style={{ fontSize: "35px", textAlign: "center" }}>
        {`${t("NotFoundTitle")}`}
      </div>
      <div style={{ fontSize: "14px", textAlign: "center" }}>
        {t("NotFoundDetail")}
      </div>
    </div>
  );
}
