import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import Maps from "../../../../../components/Maps/Maps";
import Notification from "../../../../../components/Notification/Notification";
import GenertedDatacentersDetails from "../../../../../components/Scene/SceneDetail/GenertedDatacentersDetails";
import { MoveIcon } from "../../../../../icons/MoveIcon";
import { RotateIcon } from "../../../../../icons/RotateIcon";
import { MapType } from "../../../../../interfaces/enums/MapTypeEnum";
import useDatacenterGeneratorStore from "../../../../../state/DatacenterState/datacenterGeneratorState";
import { GetMapAndDatacenterLegandaItems } from "../LegendaItems";
import "./GenerateDatacenterView.scss";

export function GenerateDatacenterView() {
  const { currentDatacenters, setDrawControlMode } =
    useDatacenterGeneratorStore();

  const [view, setView] = useState("move");
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };

  return (
    <div className="generate-datacenter-view">
      <div className="rotate-buttons">
        <ToggleButtonGroup
          sx={{ backgroundColor: "white" }}
          orientation="vertical"
          value={view}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton
            value="move"
            aria-label="move"
            onClick={() => setDrawControlMode("simple_select")}
          >
            <MoveIcon className="custom-icon" size={15} />
          </ToggleButton>
          <ToggleButton
            value="rotate"
            aria-label="rotate"
            onClick={() => setDrawControlMode("rotate_mode")}
          >
            <RotateIcon className="custom-icon" size={15} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="map-detail-view">
        <div className="map-view">
          <Maps
            mapType={MapType.GenerateDatacenter}
            legendaItems={GetMapAndDatacenterLegandaItems()}
          />
          <Notification
            message="GenerateDatacenterPage.NotificationMessage"
            bottom={10}
            left={200}
          />
        </div>
        <div className="datacenter-detail-view">
          <GenertedDatacentersDetails datacenters={currentDatacenters} />
        </div>
      </div>
    </div>
  );
}
