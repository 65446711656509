import FiberDistributionRoomDto from "../../../interfaces/foundry/typicals/FiberDistributionRoomDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetFiberDistributionRoom(
  id: number
): Promise<FiberDistributionRoomDto> {
  return (await GetFiberDistributionRooms()).find(
    (x) => x.id === id
  ) as FiberDistributionRoomDto;
}

export async function GetFiberDistributionRooms(): Promise<FiberDistributionRoomDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let fiberDistributionRooms = store.fiberDistributionRooms;
  if (fiberDistributionRooms.length === 0) {
    fiberDistributionRooms = await GetFiberDistributionRoomDtos();
    store.setFiberDistributionRooms(fiberDistributionRooms);
  }
  return fiberDistributionRooms;
}

async function GetFiberDistributionRoomDtos(): Promise<FiberDistributionRoomDto[]> {
  try {
    const coolings = (
      (await foundryApiClient.get<ResponseDto>("/api/general/fiber_distribution_rooms"))
        .data.results as FiberDistributionRoomDto[]
    ).map((item: FiberDistributionRoomDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
