import { BaseTypicalDto } from "./typicals/BaseTypicalDto";
import { BaseExploreDto } from "./BaseExploreDto";
import { LowVoltageRoomDto } from "./typicals/LowVoltageRoomDto";
import { GensetDto } from "./typicals/GensetDto";
import { RackDto } from "./typicals/RackDto";
import WhitespaceDto from "./WhitespaceDto";

export interface DatacenterDto extends BaseExploreDto {
  length: number;
  width: number;
  height: number;
  view2d_png: string;
  layout: number;
  scope: string;
  objectsTotalCount: number;
  trafo_display_name: string;
  cost_value: number;
  construction_time: number;
  cooling_area_effectivity: number;
  id: number;
  destination_plan: string;
  dc_scope: string;
  building_height_mm: number;
  building_layers: number;
  ground_footprint: number;
  total_floor_space: number;
  total_building_height_mm: number;
  cost: number;
  cost_per_kw: number;
  construction_time_in_days: number;
  construction_time_in_months: number;
  co2_footprint_in_euros: number;
  desired_power_in_w: number;
  true_power_in_w: number;
  whitespace_true_power_in_w: number;
  whitespace_type: number;
  whitespace_amount: number;
  rack_type: number;
  lsr_type: number;
  lsr_amount: number;
  area: number;
  volume: number;
  total_bvo: number;
  kw_m2: number;
  kw_m3: number;
  genset_amount: number;
  genset_type: number;
  energysupplystation_type: number;
  energysupplystation_amount: number;
  fdr_type: number;
  fdr_amount: number;
  loadbank_type: number;
  loadbank_amount: number;
  valveroom_type: number;
  watertreatment_type: number;
  pumproom_type: number;
  fireriser_type: number;
  building_kg: number;
  powerstation_amount: number;
  chiller_type: number;
  chiller_amount: number;
  coolingblock_type: number;
  coolingblock_amount: number;
  coolingtower_type: number;
  coolingtower_amount: number;
  whitespace_cooling_efficiency: number;
  cooling_type: string;
  valveroom_amount: number;
  sprinklerbassin_type: number;
  sprinklerbassin_amount: number;
  waterbuffer_type: number;
  waterbuffer_amount: number;
  watertreatment_amount: number;
  pumproom_amount: number;
  fireriser_amount: number;
  admin_amount: number;
  admin_type: number;
  whitespace_cooling_type: string;
  facade_area: number;
  hallway_area: number;
  persons_elevator: number;
  freight_elevator: number;
  stairwells: number;
  sanitary: number;
  lsr_shafts: number;
  utility_shafts: number;
  rack_type_watts: number;
  lsr_trafo_type: number;
  lsr_autonomy_in_min: number;
  
  whitespace: WhitespaceDto;
  lowVoltageRoom: LowVoltageRoomDto;
  genset: GensetDto | undefined;
  energySupplyStationComponent: BaseTypicalDto | undefined;
  fdrComponent: BaseTypicalDto | undefined;
  loadBankComponent: BaseTypicalDto | undefined;
  chillerComponent: BaseTypicalDto | undefined;
  coolingBlockComponent: BaseTypicalDto | undefined;
  coolingTowerComponent: BaseTypicalDto | undefined;
  valveRoomComponent: BaseTypicalDto | undefined;
  sprinklerBassinComponent: BaseTypicalDto | undefined;
  waterBufferComponent: BaseTypicalDto | undefined;
  waterTreatmentComponent: BaseTypicalDto | undefined;
  pumpRoomComponent: BaseTypicalDto | undefined;
  fireRiserComponent: BaseTypicalDto | undefined;
  powerStationComponent: BaseTypicalDto | undefined;
  adminComponent: BaseTypicalDto | undefined;
  rackComponent: RackDto | undefined;
  hallwayAreaComponent: BaseTypicalDto | undefined;
  personsElevatorComponent: BaseTypicalDto | undefined;
  freightElevatorComponent: BaseTypicalDto | undefined;
  stairwellsComponent: BaseTypicalDto | undefined;
  sanitaryComponent: BaseTypicalDto | undefined;
  lsrShaftsComponent: BaseTypicalDto | undefined;
  utilityShaftsComponent: BaseTypicalDto | undefined;
}

export function GetEmptyDatacenter(): DatacenterDto[] {
  return [] as DatacenterDto[];
}
