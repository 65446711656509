import { ForgeApiUrl } from "../../../config";
import { TrackException } from "../../../logging/LoggingManager";
import { sanitizeFilename } from "../../../utils/HelperFunctions";
import { HttpService } from "../../sharedLogic/services/forge/httpService";
import useUserStore from "../../sharedLogic/state/userState";
import { ModuleDto } from "../types/api";
import {
  convertEuroToCents,
  convertMetersToMilimeters,
  convertSquareMetersToSquareMilimeters,
} from "../../sharedLogic/utils/format";
import {
  isSpaceCommonAreaDto,
  isSpaceComponentTypicalDto,
  isSpaceResidentialDto,
} from "../utils/utils";

class ModuleService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService(ForgeApiUrl);
  }

  async saveModule(
    module: ModuleDto,
    file: string | File | null
  ): Promise<string> {
    try {
      if (module.code === undefined) {
        module.code = "";
      }
      if (module.owner === undefined) {
        module.owner = useUserStore.getState().emailAddress;
      }

      const moduleToSave = this.prepareModuleForSave(module);

      let endpoint = "api/v1/modules";
      if (isSpaceResidentialDto(moduleToSave)) {
        endpoint = `${endpoint}/residential`;
      } else if (isSpaceCommonAreaDto(moduleToSave)) {
        endpoint = `${endpoint}/communal`;
      } else if (isSpaceComponentTypicalDto(moduleToSave)) {
        endpoint = `${endpoint}/structural`;
      }

      const response =
        moduleToSave.code !== ""
          ? await this.httpService.put<string>(
              `${endpoint}/${moduleToSave.code}`,
              moduleToSave
            )
          : await this.httpService.post<string>(endpoint, moduleToSave);

      const moduleCode = response;
      if (moduleCode !== "" && file) {
        await this.uploadFile(moduleCode, file);
      }

      return moduleCode;
    } catch (error) {
      TrackException(error);
      console.error("Error in saveModule:", error);
      throw error;
    }
  }

  private prepareModuleForSave(module: ModuleDto): ModuleDto {
    const moduleToSave = JSON.parse(JSON.stringify(module));
    moduleToSave.grossFloorAreaInMm2 = convertSquareMetersToSquareMilimeters(
      moduleToSave.grossFloorAreaInMm2
    );
    moduleToSave.grossFloorAreaOutdoorInMm2 =
      convertSquareMetersToSquareMilimeters(
        moduleToSave.grossFloorAreaOutdoorInMm2
      );
    moduleToSave.usableAreaInMm2 = convertSquareMetersToSquareMilimeters(
      moduleToSave.usableAreaInMm2
    );
    moduleToSave.dimensions.xSizeInMm = convertMetersToMilimeters(
      moduleToSave.dimensions.xSizeInMm
    );
    moduleToSave.dimensions.ySizeInMm = convertMetersToMilimeters(
      moduleToSave.dimensions.ySizeInMm
    );
    moduleToSave.dimensions.zSizeInMm = convertMetersToMilimeters(
      moduleToSave.dimensions.zSizeInMm
    );
    moduleToSave.bayWidth1 = convertMetersToMilimeters(moduleToSave.bayWidth1);
    moduleToSave.bayWidth2 = convertMetersToMilimeters(moduleToSave.bayWidth2);
    moduleToSave.costInEuroCents = convertEuroToCents(
      moduleToSave.costInEuroCents
    );
    return moduleToSave;
  }

  private async uploadFile(
    typicalCode: string,
    file: string | File
  ): Promise<void> {
    const formData = new FormData();
    if (typeof file === "string") {
      const fileName = file.split("/").pop() || "document.pdf";
      const sanitizedFilename = sanitizeFilename(fileName);
      formData.append("file", file);
      formData.append("fileName", sanitizedFilename);
    } else if (file instanceof File) {
      const sanitizedFilename = sanitizeFilename(file.name);
      const sanitizedFile = new File([file], sanitizedFilename, {
        type: file.type,
      });
      formData.append("file", sanitizedFile);
    } else {
      return;
    }

    await this.httpService.put(
      `api/v1/modules/${typicalCode}/file`,
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  }
}

export const moduleService = new ModuleService();
