import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as turf from "@turf/turf";
import * as L from "leaflet";
import { LngLat, LngLatBounds } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { ControlPosition, MapInstance, useControl } from "react-map-gl";
import { CalculateDinstanceBetweenPlotAndRoad } from "../../../../http/foundry/MapService";
import DrawedMarker, {
  GetEmptyDrawedMarker,
} from "../../../../interfaces/DrawedMarker";
import { MapType } from "../../../../interfaces/enums/MapTypeEnum";
import { TrackException } from "../../../../logging/LoggingManager";
import { getAddressByLongLat } from "../../../../modules/sharedLogic/components/Maps/MapService";
import useNitrogenInputPaneStore from "../../../../state/NitrogenState/nitrogenInputPaneState";
import useNitrogenStore from "../../../../state/NitrogenState/nitrogenState";
import { CalculateRdCoordinates } from "../../../../utils/DrawControlManager";

type MarkerDrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  userProperties?: boolean;
  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
  mapType: MapType;
};

export default function MarkerDrawControl(props: MarkerDrawControlProps) {
  const mapRef = useRef<MapInstance | null>(null);
  const drawControlRef = useRef<MapboxDraw | null>(null);
  const { drawedMarker, setDrawedMarker, setProjectAddress } =
    useNitrogenInputPaneStore();

  const {
    startDrawingMarker,
    deleteDrawingMarker,
    drawControlMode,
    calculateDistance,
    navigateDrawingMarker,
    setDeleteDrawingMarker,
    setStartDrawingMarker,
    setCalculateDistance,
    setNavigateDrawingMarker,
    setDistanceBetweenMarkerAndRoad,
  } = useNitrogenStore();

  useEffect(() => {
    if (deleteDrawingMarker) {
      drawControlRef.current?.deleteAll();
      setDrawedMarker(GetEmptyDrawedMarker());
      setDeleteDrawingMarker(false);
      setDistanceBetweenMarkerAndRoad(0);
    }
  }, [deleteDrawingMarker]);

  useEffect(() => {
    if (startDrawingMarker) {
      drawControlRef.current?.changeMode("draw_point");
      setStartDrawingMarker(false);
    }
  }, [startDrawingMarker]);

  useEffect(() => {
    if (calculateDistance && drawedMarker.marker) {
      calculateDistanceToRoad(drawedMarker);
      setCalculateDistance(false);
    }
  }, [calculateDistance]);

  useEffect(() => {
    try {
      if (drawControlMode && mapRef.current && drawControlRef.current) {
        drawControlRef.current.changeMode(drawControlMode);
      }
    } catch (ex) {
      TrackException(ex);
    }
  }, [drawControlMode]);

  useEffect(() => {
    if (navigateDrawingMarker) {
      if (
        mapRef.current &&
        drawedMarker &&
        drawedMarker.lotCoordinates.length > 0
      ) {
        const latLngCoordinates: L.LatLngExpression[] =
          drawedMarker.lotCoordinates.map((coordinate) => {
            return [coordinate[0], coordinate[1]];
          });

        const fit = new L.Polygon(latLngCoordinates).getBounds();
        const southWest = new LngLat(
          fit.getSouthWest().lat,
          fit.getSouthWest().lng
        );
        const northEast = new LngLat(
          fit.getNorthEast().lat,
          fit.getNorthEast().lng
        );

        mapRef.current.fitBounds(new LngLatBounds(southWest, northEast), {
          zoom: 15,
        });
      }
      setNavigateDrawingMarker(false);
    }
  }, [navigateDrawingMarker]);

  useEffect(() => {
    if (mapRef.current && drawedMarker && drawedMarker.marker) {
      drawControlRef.current?.add(drawedMarker.marker);
      calculateDistanceToRoad(drawedMarker);
      setNavigateDrawingMarker(true);

      (async () => {
        const address = await getAddressByLongLat(
          drawedMarker.lotCoordinates[0][0],
          drawedMarker.lotCoordinates[0][1]
        );
        setProjectAddress(address);
      })();
    }
  }, [drawedMarker]);

  useControl<MapboxDraw>(
    () => {
      const drawControl = new MapboxDraw({
        ...props,
        userProperties: true,
        modes: { ...MapboxDraw.modes },
        styles: [
          {
            id: "points",
            type: "symbol",
            filter: ["all", ["==", "$type", "Point"]],
            layout: {
              "icon-image": "marker",
              "icon-size": 0.75,
              "icon-allow-overlap": true,
            },
          },
        ],
      });

      drawControlRef.current = drawControl;
      return drawControl;
    },
    ({ map }) => {
      mapRef.current = map.getMap();
      map.on("load", () => {
        if (drawedMarker && drawedMarker.marker) {
          drawControlRef.current?.add(drawedMarker.marker);
          calculateDistanceToRoad(drawedMarker);
          setNavigateDrawingMarker(true);
        }
      });
      map.on(
        "draw.create",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          drawControlRef.current?.getAll().features.forEach((feature) => {
            if (feature.id !== evt.features[0].id) {
              drawControlRef.current?.delete(feature.id as string);
            }
          });
          props.onCreate?.(evt);
          const createdFeature = evt.features[0];
          if (createdFeature) {
            updateMarker(createdFeature);
          }
        }
      );
      map.on(
        "draw.delete",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onDelete?.(evt);
          setDrawedMarker(GetEmptyDrawedMarker());
        }
      );
    },
    ({ map }) => {
      map.off(
        "draw.create",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onCreate?.(evt);
        }
      );
      map.off(
        "draw.delete",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onDelete?.(evt);
        }
      );
    },
    {
      position: props.position,
    }
  );

  function updateMarker(marker: GeoJSON.Feature<GeoJSON.Geometry>) {
    if (!drawControlRef.current) return;

    const coordinates =
      marker.geometry.type === "Point" ? [marker.geometry.coordinates] : [];

    const newDrawedMarker: DrawedMarker = {
      id: marker.id as string,
      marker: marker,
      lotCoordinates: coordinates,
      lotRdCoordinates: CalculateRdCoordinates(
        coordinates.map(([lng, lat]) => [lng, lat])
      ),
    };

    setDrawedMarker(newDrawedMarker);
    calculateDistanceToRoad(newDrawedMarker);
  }

  async function calculateDistanceToRoad(drawedMarker: DrawedMarker) {
    if (!drawedMarker.marker) return;

    const coordinates = (drawedMarker.marker.geometry as turf.Geometry)
      .coordinates as number[];
    const [lng, lat] = coordinates;
    try {
      const data = await CalculateDinstanceBetweenPlotAndRoad(lat, lng);
      if (!data || data.routes.length === 0) return;
      const route = data.routes[0];
      const geojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: route.geometry.coordinates,
        },
      };
      if (mapRef.current?.getSource("route")) {
        mapRef.current?.getSource("route").setData(geojson);
      } else {
        mapRef.current?.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-opacity": 0.75,
            "line-width": 5,
          },
        });
      }
      setDistanceBetweenMarkerAndRoad(route.distance);
    } catch (error) {
      TrackException(error);
    }
  }

  return null;
}
