import { Popover, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface CustomPopoverProps {
  content: ReactNode;
  popoverText: string;
  popoverTitle?: string;
}

const CustomPopover: React.FC<CustomPopoverProps> = ({
  content,
  popoverTitle,
  popoverText,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <div
      className="popover"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {content}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {popoverTitle && (
          <Typography
            sx={{
              p: 1,
              fontSize: "12px",
              fontWeight: "bold",
              maxWidth: "350px",
            }}
          >
            {popoverTitle}
          </Typography>
        )}        
        <Typography
          sx={{
            p: popoverTitle ? "0px 0px 5px 0px" : 1,
            ml: popoverTitle ? 1 : 0,
            mt: popoverTitle ? -1 : 0,
            fontSize: "12px",
            maxWidth: "350px",
          }}
        >
          {popoverText}
        </Typography>
      </Popover>
    </div>
  );
};

export default CustomPopover;
