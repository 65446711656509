import { create } from "zustand";
import AvailableComponentDto from "../interfaces/AvailableComponentDto";

interface AvailableComponentsState {
  availableOverview: AvailableComponentDto[];
  availableConfigurators: AvailableComponentDto[];
  availableDatabases: AvailableComponentDto[];
  availableGenerators: AvailableComponentDto[];
  availableAnalysers: AvailableComponentDto[];
  availableCatalog: AvailableComponentDto[];
  availableHeijmansIntelligence: AvailableComponentDto[];
  availableStackedHousingHeijmansIntelligence: AvailableComponentDto[];
  availableDatacenterCatalog: AvailableComponentDto[];
  availableDatacenterCatalogComponents: AvailableComponentDto[];
  setAvailableOverview: (newValue: AvailableComponentDto[]) => void;
  setAvailableConfigurators: (newValue: AvailableComponentDto[]) => void;
  setAvailableDatabases: (newValue: AvailableComponentDto[]) => void;
  setAvailableGenerators: (newValue: AvailableComponentDto[]) => void;
  setAvailableAnalysers: (newValue: AvailableComponentDto[]) => void;
  setAvailableCatalog: (newValue: AvailableComponentDto[]) => void;
  setAvailableHeijmansIntelligence: (newValue: AvailableComponentDto[]) => void;
  setAvailableStackedHousingHeijmansIntelligence: (
    newValue: AvailableComponentDto[]
  ) => void;
  setAvailableDatacenterCatalog: (newValue: AvailableComponentDto[]) => void;
  setAvailableDatacenterCatalogComponents: (
    newValue: AvailableComponentDto[]
  ) => void;
}

const useAvailableComponentsStore = create<AvailableComponentsState>()(
  (set) => ({
    availableOverview: [],
    availableConfigurators: [],
    availableDatabases: [],
    availableGenerators: [],
    availableAnalysers: [],
    availableCatalog: [],
    availableHeijmansIntelligence: [],
    availableStackedHousingHeijmansIntelligence: [],
    availableDatacenterCatalog: [],
    availableDatacenterCatalogComponents: [],
    setAvailableOverview: (newValue) => set({ availableOverview: newValue }),
    setAvailableConfigurators: (newValue) =>
      set({ availableConfigurators: newValue }),
    setAvailableDatabases: (newValue) => set({ availableDatabases: newValue }),
    setAvailableGenerators: (newValue) =>
      set({ availableGenerators: newValue }),
    setAvailableAnalysers: (newValue) => set({ availableAnalysers: newValue }),
    setAvailableCatalog: (newValue) => set({ availableCatalog: newValue }),
    setAvailableHeijmansIntelligence: (newValue) =>
      set({ availableHeijmansIntelligence: newValue }),
    setAvailableStackedHousingHeijmansIntelligence: (newValue) =>
      set({ availableStackedHousingHeijmansIntelligence: newValue }),
    setAvailableDatacenterCatalog: (newValue) =>
      set({ availableDatacenterCatalog: newValue }),
    setAvailableDatacenterCatalogComponents: (newValue) =>
      set({ availableDatacenterCatalogComponents: newValue }),
  })
);

export default useAvailableComponentsStore;
