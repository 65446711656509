import { create } from "zustand";
import { EquipmentKeyFigureDto } from "../../interfaces/aggregate/EquipmentKeyFigureDto";
import { BrandDto } from "../../interfaces/aggregate/BrandDto";
import { EquipmentTypeDto } from "../../interfaces/aggregate/EquipmentTypeDto";

interface EquipmentKeyFiguresState {
  brands: BrandDto[];
  equipmentTypes: EquipmentTypeDto[];
  equipmentKeyFigures: EquipmentKeyFigureDto[];
}

interface EquipmentKeyFiguresActions {
  setBrands: (value: BrandDto[]) => void;  
  setEquipmentTypes: (value: EquipmentTypeDto[]) => void;
  setEquipmentKeyFigures: (value: EquipmentKeyFigureDto[]) => void;
}

const useEquipmentKeyFiguresStore = create<
  EquipmentKeyFiguresState & EquipmentKeyFiguresActions
>()((set) => ({
  brands: [],  
  equipmentTypes: [],
  equipmentKeyFigures: [],
  setBrands: (value) => set({ brands: value }),
  setEquipmentTypes: (value) => set({ equipmentTypes: value }),
  setEquipmentKeyFigures: (value) => set({ equipmentKeyFigures: value }),
}));
export default useEquipmentKeyFiguresStore;
