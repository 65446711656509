import { useTranslation } from "react-i18next";
import WhitespaceDto from "../../../interfaces/foundry/WhitespaceDto";
import FormControlTextField from "../../CustomFormControl/FormControlTextField";
import SceneDetailComponent from "./SceneDetailComponent";

interface SceneDetailWhitespaceProps {
  whitespace: WhitespaceDto;
  amount?: number | null;
}

export default function SceneDetailWhitespace({
  whitespace,
  amount = null,
}: SceneDetailWhitespaceProps) {
  const { t } = useTranslation();

  const components = [
    { type: "Cooling", component: whitespace.cooling },
    { type: "Cold alley", component: whitespace.coldAlley },
    { type: "Hot alley", component: whitespace.hotAlley },
    { type: "Server rack", component: whitespace.rack },
    { type: "Row", component: whitespace.row },
    { type: "MDB", component: whitespace.mdb },
  ];
  components.sort((a, b) => t(a.type).localeCompare(t(b.type)));

  return (
    <div>
      <div className="title">{t("General")}</div>
      <FormControlTextField labelText={t("Feature")} value={whitespace?.id} />
      <FormControlTextField
        labelText="MW"
        value={whitespace?.true_power_in_w}
      />

      {amount !== null && (
        <FormControlTextField labelText={t("Amount")} value={amount} />
      )}

      <div className="title mt20">{t("Dimensions")}</div>
      <FormControlTextField
        labelText={t("Net width(m)")}
        value={whitespace?.length}
      />
      <FormControlTextField
        labelText={t("Net length(m)")}
        value={whitespace?.width}
      />
      <FormControlTextField
        labelText={t("Net height(m)")}
        value={whitespace?.height}
      />
      <FormControlTextField
        labelText={t("WhitespacePage.SurfaceArea")}
        value={whitespace?.area?.toFixed()}
      />
      <FormControlTextField
        labelText={t("WhitespacePage.SurfaceAreaTotal")}
        value={whitespace?.ws_and_cooling_area?.toFixed()}
      />
      {amount === null && (
        <div>
          <div className="title mt20">{t("Racks")}</div>
          <FormControlTextField
            labelText={t("Total")}
            showInfoButton={true}
            toolTipText={t("RackAmountTooltip")}
            value={whitespace?.rack_amount}
          />
          <FormControlTextField
            labelText={t("Used")}
            value={Math.ceil(
              (whitespace?.rack_amount! * whitespace?.rack_utilization!) / 100
            )}
            showInfoButton={true}
            toolTipText={t("UsedAmountTooltip")}
          />
          <FormControlTextField
            labelText={t("Rack power(kW)")}
            value={whitespace?.rack_power_in_w}
          />
          <div className="title mt20">{t("Efficiency")}</div>
          <FormControlTextField
            labelText={t("Unappointed space (m2)")}
            value={Math.round(
              whitespace?.area *
                (1 - whitespace?.cooling_area_effectivity / 100)
            )}
            showInfoButton={true}
            toolTipText={t("UnappointedSpaceTooltip")}
          />
          <FormControlTextField
            labelText={`${t("Racks count")} (%)`}
            value={whitespace?.rack_utilization}
          />
          <FormControlTextField
            labelText={t("kW/m2")}
            value={whitespace?.kw_m2}
          />
          <FormControlTextField
            labelText={t("kW/m3")}
            value={whitespace?.kw_m3}
          />
        </div>
      )}

      <div className="title mt20">{t("Components")}</div>
      {components.map(
        ({ type, component }) =>
          component && (
            <SceneDetailComponent
              key={type}
              type={type}
              component={component}
            />
          )
      )}
    </div>
  );
}
