import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface LocalizedNumericInputProps extends Omit<TextFieldProps, "onChange"> {
  onChange: (value: number | string | null) => void;
  value?: number | undefined | null;
}

const LocalizedNumericInput: React.FC<LocalizedNumericInputProps> = ({
  onChange,
  value,
  ...props
}) => {
  const { i18n } = useTranslation();

  const formatNumber = (num: number | null | undefined): string => {
    if (num === undefined || num === null) return "";
    return i18n.language.startsWith("nl")
      ? num.toString().replace(".", ",")
      : num.toString();
  };

  const parseNumber = (str: string): number | string | null => {
    if (str === "") return null;
    const normalized = i18n.language.startsWith("nl")
      ? str.replace(",", ".")
      : str;

    if (normalized.endsWith(".") || normalized.endsWith(",")) {
      return normalized;
    }

    const parsed = parseFloat(normalized);
    return isNaN(parsed) ? null : parsed;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseNumber(event.target.value);
    onChange(newValue);
  };

  return (
    <TextField
      {...props}
      value={formatNumber(value)}
      onChange={handleChange}
      inputProps={{
        ...props.inputProps,
        inputMode: "decimal",
        pattern: i18n.language.startsWith("nl")
          ? "[0-9]*,?[0-9]*"
          : "[0-9]*\\.?[0-9]*",
      }}
    />
  );
};

export default LocalizedNumericInput;
