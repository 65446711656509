import { Create, Save } from "@mui/icons-material";
import { Box, DialogTitle, IconButton } from "@mui/material";
import CancelButton from "../../../../components/CancelButton/CancelButton";
import useUserStore from "../../../sharedLogic/state/userState";
import { useModuleDetailsStore } from "../../state/moduleDetailsState";

interface ModuleDetailedDialogHeaderProps {
  onClose: () => void;
  onSave: (accessType: string) => void;
}

export function ModuleDetailedDialogHeader({
  onClose,
  onSave,
}: ModuleDetailedDialogHeaderProps) {
  const { appAbility } = useUserStore();
  const {
    module,
    moduleCode,
    isEditing,
    setIsEditing,
    saveModule,
    validateModule,
    clearValidationErrors,
    setModuleCode,
  } = useModuleDetailsStore();

  const handleEditSave = async () => {
    if (isEditing) {
      clearValidationErrors();
      if (validateModule()) {
        const isUpdating = module!.code !== undefined;
        const typicalCode = await saveModule();

        if (typicalCode === "Error") return;

        setModuleCode(typicalCode);
        if (!isUpdating) {
          onClose();
          onSave(module!.accessType);
        }
      }
    } else {
      setIsEditing(true);
    }
  };

  const disabled =
    appAbility?.cannot("create", "catalog.stackedhousing") ?? true;

  return (
    <DialogTitle
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: 18,
        fontWeight: 900,
        color: "rgba(0, 52, 102, 1)",
      }}
    >
      {moduleCode}
      <Box component={"div"} sx={{ ml: "auto", display: "flex" }}>
        <IconButton disabled={disabled} onClick={handleEditSave} size="small">
          {isEditing ? (
            <Save style={{ fontSize: "2rem", fill: "rgba(0, 52, 102, 1)" }} />
          ) : (
            <Create
              style={{
                fontSize: "2rem",
                fill: isEditing || disabled ? "#ACACAC" : "rgba(0, 52, 102, 1)",
              }}
            />
          )}
        </IconButton>
        <CancelButton onClick={onClose} />
      </Box>
    </DialogTitle>
  );
}
