import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { ExploreConfigStore } from "../../interfaces/exploreConfig";
import { DatacenterDto } from "../../interfaces/foundry/DatacenterDto";
import { LowVoltageRoomDto } from "../../interfaces/foundry/typicals/LowVoltageRoomDto";
import WhitespaceDto from "../../interfaces/foundry/WhitespaceDto";
import { ScenarioDto } from "../../modules/Analysers/types/api";
import useDatacenterGeneratorStore from "../../state/DatacenterState/datacenterGeneratorState";
import useScenarioStore from "../../state/NitrogenState/scenarioState";
import CustomInputLabel from "../InputLabel/CustomInputLabel";
import "./FilterControl.scss";

interface FilterControlProps {
  datacenterConfigStore?: ExploreConfigStore<DatacenterDto>;
  whitespaceConfigStore?: ExploreConfigStore<WhitespaceDto>;
  lowVoltageRoomConfigStore?: ExploreConfigStore<LowVoltageRoomDto>;
  nitrogenAnalyserConfigStore?: ExploreConfigStore<ScenarioDto>;
}

export default function FilterControl({
  datacenterConfigStore,
  whitespaceConfigStore,
  lowVoltageRoomConfigStore,
  nitrogenAnalyserConfigStore,
}: FilterControlProps) {
  const { t } = useTranslation();
  const { setFilterControlSelectedAxes, currentPageType } =
    useDatacenterGeneratorStore();
  const { setFilterControlSelectedAxesScenario } = useScenarioStore();

  const configStore =
    currentPageType === TypeObject.NitrogenAnalyser
      ? nitrogenAnalyserConfigStore
      : currentPageType === TypeObject.Whitespace ||
        currentPageType === TypeObject.WhitespaceTooling
      ? whitespaceConfigStore
      : currentPageType === TypeObject.LowVoltageRoom ||
        currentPageType === TypeObject.LowVoltageRoomTooling
      ? lowVoltageRoomConfigStore
      : datacenterConfigStore;

  const currentState = configStore?.currentStore();
  const options = Object.values(
    currentState?.filterControlOptionsDictionary ?? []
  );

  const handleChangeFilterOptions = (
    event: SelectChangeEvent<typeof options>
  ) => {
    if (!currentState) return;

    const selectedValue = event.target.value as string[];
    let selectedValues: string[] = [];
    if (selectedValue[selectedValue.length - 1] === "all") {
      selectedValues =
        currentState.selectedAxes.length === options.length ? [] : options;
    } else {
      selectedValues = selectedValue.map(
        (key) => currentState.filterControlOptionsDictionary[key] ?? key
      );
    }

    setFilterControlSelectedAxes(selectedValues);
    setFilterControlSelectedAxesScenario(selectedValues);
    currentState.setSelectedAxes(selectedValues);
  };

  const getDisplayName = (value: string) =>
    t(configStore!.filterControlOptionsDictionaryReversed![value]);

  useEffect(() => {
    if (!currentState) return;

    currentState.setSelectedAxes(
      currentState.selectedAxes && currentState.selectedAxes.length > 0
        ? currentState.selectedAxes
        : configStore!.defaultSelectedAxes ?? []
    );
  }, [currentPageType]);

  return (
    currentState &&
    configStore && (
      <div className="filter-control-panel">
        <span className="title">{t("Input pane")}</span>
        <FormControl
          fullWidth
          variant="standard"
          style={{ marginTop: "20px", marginBottom: "10px" }}
        >
          <CustomInputLabel
            id="best-fit-rate-label"
            showInfoButton={true}
            labelText={t("Best fit rate")}
            tooltipText={t("BestFitRateTooltip")}
          />
          <Select
            multiple
            style={{ height: "auto" }}
            labelId="type-best-fit-rate-label"
            id="type-best-fit-rate-select"
            value={currentState.selectedAxes}
            onChange={handleChangeFilterOptions}
            label={t("Best fit rate")}
            renderValue={(selected) => (
              <Box
                component={"div"}
                sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
              >
                {selected
                  .sort((x, y) => (x > y ? 1 : x < y ? -1 : 0))
                  .map((value) => (
                    <Chip
                      key={getDisplayName(value)}
                      label={getDisplayName(value)}
                    />
                  ))}
              </Box>
            )}
          >
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  checked={
                    options.length > 0 &&
                    currentState.selectedAxes.length === options.length
                  }
                  indeterminate={
                    currentState.selectedAxes.length > 0 &&
                    currentState.selectedAxes.length < options.length
                  }
                />
              </ListItemIcon>
              <ListItemText primary={t("Select all")} />
            </MenuItem>
            {Object.keys(configStore!.filterControlOptionsDictionary!)
              .sort((a, b) => t(a).localeCompare(t(b)))
              .map((name) => (
                <MenuItem
                  key={t(name)}
                  value={t(configStore!.filterControlOptionsDictionary![name])}
                >
                  <Checkbox
                    checked={currentState.selectedAxes.includes(
                      t(configStore!.filterControlOptionsDictionary![name])
                    )}
                  />
                  <ListItemText primary={t(name)} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    )
  );
}
