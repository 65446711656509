import { ForgeApiUrl } from "../../../config";
import { HttpService } from "../../sharedLogic/services/forge/httpService";
import {
    GenerationDto,
    SupportedValueNumbersDto,
    SupportedValueTypesDto,
} from "../types/api";

export class GenerationService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService(ForgeApiUrl);
  }

  async getSupportedValueTypes(): Promise<SupportedValueTypesDto> {
    return await this.httpService.get<SupportedValueTypesDto>(
      "api/v1/modules/supported-values/types"
    );
  }

  async getSupportedValueNumbers(
    accessibilityType: string
  ): Promise<SupportedValueNumbersDto> {
    return await this.httpService.get<SupportedValueNumbersDto>(
      `api/v1/modules/supported-values/numbers/${accessibilityType}`
    );
  }

  async startGeneration(generationDto: GenerationDto): Promise<number> {
    return await this.httpService.post<number>(
      "api/v1/processes/generation",
      generationDto
    );
  }
}
