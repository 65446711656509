import { CalendarMonth, Save, SaveAs } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomFormControl from "../../../components/CustomFormControl/CustomFormControl";
import CustomPopover from "../../../components/CustomPopover/CustomPopover";
import { CustomTask } from "../../../components/GanttChart/public-types";
import InputPane from "../../../components/InputPane/InputPane";
import TabPanel from "../../../components/TabPanel/TabPanel";
import { GetInputDataByType } from "../../../http/aggregate/InputDataService";
import { InputDataCatalogEnum } from "../../../interfaces/aggregate/enums/InputDataCatalogEnum";
import { ProjectTypeEnum } from "../../../interfaces/enums/ProjectTypeEnum";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { ExploreConfigStore } from "../../../interfaces/exploreConfig";
import BaseExploreView from "../../../pages/Configurators/Datacenter/components/BaseExploreView/BaseExploreView";
import PlanningAssumptionsView from "../../../pages/Configurators/Datacenter/components/PlanningAssumptionsView/PlanningAssumptionsView";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import useNitrogenInputPaneStore, {
  NitrogenInputPaneState,
} from "../../../state/NitrogenState/nitrogenInputPaneState";
import useNitrogenStore from "../../../state/NitrogenState/nitrogenState";
import useScenarioStore from "../../../state/NitrogenState/scenarioState";
import colors from "../../../styles/colors.module.scss";
import CreateOrUpdateProjectView from "../../sharedLogic/components/Projects/CreateOrUpdateProjectView";
import { GetProject } from "../../sharedLogic/services/ProjectService";
import useProjectsStore from "../../sharedLogic/state/projectsState";
import useUserStore from "../../sharedLogic/state/userState";
import { PlanningDto } from "../../sharedLogic/types/api";
import { ScenarioDto } from "../types/api";
import EquipmentSelectionView from "./EquipmentSelectionView/EquipmentSelectionView";
import { useNitrogenAnalysisConfigStore } from "./NitrogenAnalysisConfig";
import "./NitrogenDashboardPage.scss";
import PlanningView from "./PlanningView/PlanningView";

export default function NitrogenDashboardPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const { heijmansBlue } = colors;
  const { emailAddress } = useUserStore();
  const { isInputPaneOpen, setIsInputPaneOpen, setCurrentPageType } =
    useDatacenterGeneratorStore();
  const scenarioStore = useScenarioStore();
  const nitrogenAnalysisConfigStore = useNitrogenAnalysisConfigStore();
  const {
    mainPhases,
    activities,
    workingDays,
    currentTabPageIndex,
    setWorkingDays,
    setActivities,
    setMainPhases,
    setCurrentTabPageIndex,
    setNavigateDrawingMarker,
  } = useNitrogenStore();

  const {
    annualNOxLimit,
    bvoProject,
    drawedMarker,
    setNitrogenInputPaneState,
  } = useNitrogenInputPaneStore();

  const { currentProject, setIsProjectSavingAs, setIsProjectViewOpen } =
    useProjectsStore();

  const {
    categories,
    selectedNode,
    setSelectedNode,
    departments,
    setDepartments,
  } = useDatabaseStore();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const allDays: string[] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSaveAvailable =
    drawedMarker !== undefined &&
    drawedMarker.marker !== undefined &&
    drawedMarker.marker?.geometry !== undefined &&
    (currentProject?.createdBy === undefined ||
      currentProject?.createdBy === "" ||
      currentProject?.createdBy === emailAddress);

  useEffect(() => {
    if (currentProject?.name !== "") {
      const pathnames = location.pathname.split("/").filter((x) => x);
      const externalProjectId = pathnames[pathnames.length - 1];
      if (externalProjectId) {
        const project = useProjectsStore
          .getState()
          .projects.find((x) => x.externalId === externalProjectId);

        if (!project) {
          (async () => {
            useProjectsStore
              .getState()
              .setCurrentProject(await GetProject(project!));
            setNavigateDrawingMarker(true);
          })();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (selectedNode === "" && categories.length > 0) {
      setSelectedNode(categories[0]?.id.toString());
    }
  }, [categories, selectedNode, setSelectedNode]);

  useEffect(() => {
    setCurrentPageType(TypeObject.NitrogenAnalyser);

    if (currentTabPageIndex == 3) {
      setIsInputPaneOpen(true);
    }
  }, [currentTabPageIndex]);

  useEffect(() => {
    if (!nitrogenAnalysisConfigStore) return;
    const state = nitrogenAnalysisConfigStore.currentStore.getState();
    state.resetState();
  }, [activities, bvoProject, annualNOxLimit]);

  useEffect(() => {
    if (departments.length === 0) {
      (async () => {
        setDepartments(
          await GetInputDataByType(InputDataCatalogEnum.InputDataDepartment)
        );
      })();
    }

    if (currentProject && currentProject.configuration) {
      const currentInputPaneState = JSON.parse(
        currentProject.configuration
      ) as NitrogenInputPaneState;
      setNitrogenInputPaneState(currentInputPaneState);
    }
    if (currentProject && currentProject.planning) {
      const planning = JSON.parse(
        currentProject.planning.planningAsJson
      ) as CustomTask[];
      planning.forEach((task) => {
        task.start = new Date(task.start);
        task.end = new Date(task.end);
      });
      setActivities(planning);
    }
  }, [currentProject]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    (async () => {
      if (mainPhases.length === 0) {
        const planning = (await GetInputDataByType(
          InputDataCatalogEnum.InputDataPhase
        )) as unknown as CustomTask[];
        setMainPhases(planning);
      }
      setCurrentTabPageIndex(newValue);
      scenarioStore.setSelectedAxes(
        scenarioStore.filterControlSelectedAxesScenario
      );
      scenarioStore.setFilterControlOptionsDictionaryReversed(
        scenarioStore.filterControlOptionsDictionaryReversed
      );
      scenarioStore.setFilterControlSelectedAxesScenario(
        scenarioStore.selectedAxes
      );
    })();
  };

  const handleChangeWorkingDays = (
    event: SelectChangeEvent<typeof workingDays>
  ) => {
    const newWorkingDays = event.target.value as string[];
    if (newWorkingDays.length === 0) {
      return;
    }

    setWorkingDays(newWorkingDays);
  };

  const handleSave = async () => {
    const state = useNitrogenInputPaneStore.getState();
    const planning: PlanningDto | undefined =
      activities.length === 0
        ? undefined
        : {
            id: 0,
            planningAsJson: JSON.stringify(activities),
            createdBy: emailAddress,
            createdAt: new Date().toISOString(),
          };

    currentProject.projectAddress = state.projectAddress;      
    currentProject.configuration = JSON.stringify(state);
    currentProject.planning = planning;
  };

  return (
    <div className="nitrogen-page">
      <Box
        component={"div"}
        sx={{
          boxShadow: 3,
          display: "flex",
          paddingRight: "20px",
          alignItems: "center",
        }}
      >
        <Tabs
          sx={{ flexGrow: 1 }}
          value={currentTabPageIndex}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <Tab label={t("Basic principles")} />
          <Tab label={<Typography>{t("NitrogenPage.Planning")}</Typography>} />
          <Tab
            label={
              <Typography>{t("NitrogenPage.EquipmentSelection")}</Typography>
            }
          />
          <Tab
            label={
              <Typography>{t("NitrogenPage.ScenarioAnalysis")}</Typography>
            }
          />
        </Tabs>

        <CustomPopover
          content={
            <IconButton
              aria-describedby={id}
              onClick={handleClick}
              disabled={currentTabPageIndex !== 1}
            >
              <CalendarMonth
                sx={{ fontSize: "2rem", color: heijmansBlue }}
                className={
                  currentTabPageIndex === 1 ? "svg" : "save-icon-disabled"
                }
              />
            </IconButton>
          }
          popoverTitle={t("Working days")}
          popoverText={t("NitrogenPage.WorkingDaysCaption")}
        ></CustomPopover>

        <CustomPopover
          content={
            <IconButton
              disabled={!isSaveAvailable}
              onClick={() => setIsProjectSavingAs(true)}
            >
              <SaveAs
                sx={{ fontSize: "2rem", color: heijmansBlue }}
                className={isSaveAvailable ? "svg" : "save-icon-disabled"}
              />
            </IconButton>
          }
          popoverTitle={t("SaveAsProjectTitle")}
          popoverText={t("NitrogenPage.SaveAsProjectCaption")}
        ></CustomPopover>

        <CustomPopover
          content={
            <IconButton
              disabled={!isSaveAvailable}
              onClick={() => setIsProjectViewOpen(true)}
            >
              <Save
                sx={{ fontSize: "2rem", color: heijmansBlue }}
                className={isSaveAvailable ? "svg" : "save-icon-disabled"}
              />
            </IconButton>
          }
          popoverTitle={t("SaveProjectTitle")}
          popoverText={t("NitrogenPage.SaveProjectCaption")}
        ></CustomPopover>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div style={{ padding: "0px 10px 10px 10px" }}>
            <CustomFormControl labelText={t("Working days")}>
              <Select
                multiple
                style={{ height: "auto" }}
                labelId="working-days-label"
                id="working-days-select"
                value={workingDays}
                onChange={handleChangeWorkingDays}
                label={t("Working days")}
                renderValue={(selected) => (
                  <Box
                    component={"div"}
                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                  >
                    {selected.map((value) => (
                      <Chip key={value} label={t(value)} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        Object.values(allDays).length > 0 &&
                        workingDays.length === Object.values(allDays).length
                      }
                      indeterminate={
                        workingDays.length > 0 &&
                        workingDays.length < Object.values(allDays).length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("Select all")} />
                </MenuItem>
                {Object.values(allDays).map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={workingDays.includes(name)} />
                    <ListItemText primary={t(name)} />
                  </MenuItem>
                ))}
              </Select>
            </CustomFormControl>
          </div>
        </Popover>
      </Box>
      {!isInputPaneOpen &&
        (currentTabPageIndex === 0 || currentTabPageIndex === 3) && (
          <div className="nitrogen-input-pane-button">
            <IconButton
              style={{ backgroundColor: "white", zIndex: 1 }}
              onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
            >
              <ChevronRightIcon sx={{ fontSize: "2.5rem" }} />{" "}
            </IconButton>
          </div>
        )}

      <div className="dashboard-view">
        <Collapse
          orientation="horizontal"
          in={
            isInputPaneOpen &&
            (currentTabPageIndex === 0 || currentTabPageIndex === 3)
          }
        >
          <InputPane
            nitrogenAnalyserConfigStore={nitrogenAnalysisConfigStore}
          />
        </Collapse>
        <div>
          <TabPanel value={currentTabPageIndex} index={0}>
            <PlanningAssumptionsView />
          </TabPanel>
          <TabPanel value={currentTabPageIndex} index={1}>
            <PlanningView withGantt={true} />
          </TabPanel>
          <TabPanel value={currentTabPageIndex} index={2}>
            <EquipmentSelectionView />
          </TabPanel>
          <TabPanel value={currentTabPageIndex} index={3}>
            <BaseExploreView
              type={TypeObject.NitrogenAnalyser}
              currentTypicalConfigStore={
                nitrogenAnalysisConfigStore as ExploreConfigStore<ScenarioDto>
              }
            />
          </TabPanel>
        </div>
      </div>

      <CreateOrUpdateProjectView
        typeProject={ProjectTypeEnum.Analyser}
        handleSave={handleSave}
      />
    </div>
  );
}
