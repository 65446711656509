import axios from "axios";
import * as L from "leaflet";
import { LngLat, LngLatBounds } from "mapbox-gl";
import { MapInstance } from "react-map-gl";
import { MapboxAccessToken } from "../../../../config";
import DrawedPlot from "../../types/DrawedPlot";
import {
  PdokDocDto,
  PdokResponse,
} from "../../types/pdok/PdokResponseDto";
import {
  PdokRouteCalculationDto,
  Step,
} from "../../types/pdok/PdokRouteCalculationDto";
import { TrackException } from "../../logging/LoggingManager";

const mapboxUrl = "https://api.mapbox.com/directions/v5/mapbox/driving/";
const pdokApiUrl = "https://api.pdok.nl/bzk/locatieserver/search/v3_1/";

export async function getAddressByLongLat(long: number, lat: number) {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?types=address&access_token=${MapboxAccessToken}&language=nl-NL`;
  const response = await axios.get(url);
  const address = response.data.features[0].place_name;
  return address;
}

export function CalculateDistanceAOrNRoad(
  mapRef: MapInstance,
  drawedPlot: DrawedPlot,
  setDistanceBetweenPlotAndRoad: any
) {
  const latLngCoordinates: L.LatLngExpression[] = drawedPlot.lotCoordinates.map(
    (coordinate) => {
      return [coordinate[0], coordinate[1]];
    }
  );

  const fit = new L.Polygon(latLngCoordinates).getBounds();
  const southWest = new LngLat(fit.getSouthWest().lat, fit.getSouthWest().lng);
  const northEast = new LngLat(fit.getNorthEast().lat, fit.getNorthEast().lng);
  const center = new LngLatBounds(southWest, northEast).getCenter();

  CalculateDinstanceBetweenPlotAndRoad(center.lat, center.lng).then((data) => {
    if (!data || data.routes.length === 0) return;

    const route = data.routes[0];
    const geojson = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: route.geometry.coordinates,
      },
    };

    if (mapRef.getSource("route")) {
      mapRef.getSource("route").setData(geojson);
    } else {
      mapRef.addLayer({
        id: "route",
        type: "line",
        source: {
          type: "geojson",
          data: geojson,
        },
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#3887be",
          "line-opacity": 0.75,
          "line-width": 5,
        },
      });
    }
    setDistanceBetweenPlotAndRoad(route.distance);
  });

  return null;
}

export async function CalculateDinstanceBetweenPlotAndRoad(
  lat: number,
  lon: number
): Promise<any> {
  try {
    let pdokDocsA: PdokDocDto[] = [];
    let pdokDocsN: PdokDocDto[] = [];

    try {
      const { data: pdokResponse } = await axios.get<PdokResponse>(
        `${pdokApiUrl}reverse?lat=${lat}&lon=${lon}&type=hectometerpaal&rows=20&distance=5000`
      );

      if (pdokResponse.response.docs.length !== 0) {
        const newDocsA = pdokResponse.response.docs.filter((doc) =>
          doc.weergavenaam.startsWith("Hectometerpaal A")
        );
        const newDocsN = pdokResponse.response.docs.filter((doc) =>
          doc.weergavenaam.startsWith("Hectometerpaal N")
        );

        pdokDocsA.push(...newDocsA);
        pdokDocsN.push(...newDocsN);
      }
    } catch (ex) {
      TrackException(ex);
    }
    pdokDocsA = pdokDocsA.sort((a, b) => a.afstand - b.afstand).slice(0, 10);
    pdokDocsN = pdokDocsN.sort((a, b) => a.afstand - b.afstand).slice(0, 10);
    const pdokDocs = [...pdokDocsA, ...pdokDocsN];
    if (pdokDocs === null) {
      return 0;
    }

    const resolvedData: PdokRouteCalculationDto[] = await Promise.all(
      pdokDocs.map(async (doc) => {
        const { data: hectometerPaalResponse } = await axios.get<PdokResponse>(
          `${pdokApiUrl}lookup?id=${doc.id}`
        );
        if (hectometerPaalResponse.response.docs.length > 0) {
          const hectometerPaal = hectometerPaalResponse.response.docs[0];
          const match = hectometerPaal.centroide_ll.match(
            /POINT\(([-+]?\d*\.\d+) ([-+]?\d*\.\d+)\)/
          );
          if (match) {
            const convertLon: number = parseFloat(match[1]);
            const convertLat: number = parseFloat(match[2]);

            const response = await axios.get(
              `${mapboxUrl}${lon},${lat};${convertLon},${convertLat}`,
              {
                params: {
                  steps: true,
                  geometries: "geojson",
                  access_token: MapboxAccessToken,
                },
              }
            );

            response.data.hectometerPaal = [convertLon, convertLat];
            response.data.hectometerPaalRoadNumber = hectometerPaal.wegnummer;
            return response.data;
          }
        }
      })
    );

    const closestEntry = resolvedData.reduce((closest, entry) => {
      if (entry.routes[0].distance < closest.routes[0].distance) {
        return entry;
      }
      return closest;
    }) as PdokRouteCalculationDto;

    if (!closestEntry) {
      return 0;
    }

    var slicedRoute = [] as Step[];
    for (let i = 0; i < closestEntry.routes[0].legs[0].steps.length - 1; i++) {
      const step = closestEntry.routes[0].legs[0].steps[i];
      if (step.ref === closestEntry.hectometerPaalRoadNumber) {
        slicedRoute = closestEntry.routes[0].legs[0].steps.slice(0, i);
        break;
      }
    }

    if (slicedRoute.length > 0) {
      closestEntry.routes[0].geometry.coordinates = [];
      slicedRoute.forEach((correctStep) => {
        correctStep.geometry.coordinates.forEach((cor) => {
          closestEntry.routes[0].geometry.coordinates.push(cor);
        });
      });

      closestEntry.routes[0].distance = slicedRoute
        .map((step) => step.distance)
        .reduce((sum, current) => sum + current, 0);
    }

    return closestEntry;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
