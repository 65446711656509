import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Fab, Popover, Typography } from "@mui/material";
import * as L from "leaflet";
import mapboxgl, { LngLat, LngLatBounds } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { MapRef } from "react-map-gl";
import Map, { NavigationControl, ScaleControl, ViewState } from "react-map-gl";
import { StoreApi, UseBoundStore } from "zustand";
import Legend from "../../../../components/Legend/Legend";
import { MapboxAccessToken } from "../../../../config";
import { EllipseIcon } from "../../../../icons/EllipseIcon";
import { MapType } from "../../types/enums";
import { TrackException } from "../../logging/LoggingManager";
import { BaseConfiguratorActions, BaseConfiguratorState } from "../../state/baseConfiguratorState";
import DrawControl from "./Components/DrawControl/DrawControl";
import "./Maps.scss";

interface MapsProps {
  mapType: MapType;
  legendaItems?: any;
  showFloatingActionButton?: boolean;
  currentStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >;
}

export default function Maps({
  mapType,
  showFloatingActionButton = false,
  legendaItems,
  currentStore,
}: MapsProps) {
  const { t } = useTranslation();
  const mapRef = useRef<MapRef>(null);
  const [viewport] = useState<ViewState>({
    bearing: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
    pitch: 0,
    longitude: 5.52,
    latitude: 52.2,
    zoom: 6.4,
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    drawedPlot,
    projectAddressFeature,
    navigateDrawingPlot,
    setCalculateDistance,
    setNavigateDrawingPlot,
  } = currentStore();

  useEffect(() => {
    if (
      projectAddressFeature &&
      projectAddressFeature.geometry.coordinates &&
      projectAddressFeature.geometry.coordinates.length > 0 &&
      mapRef.current
    ) {
      let zoom = 1;
      switch (projectAddressFeature?.properties.feature_type) {
        case "country":
          zoom = 5;
          break;
        case "district":
        case "region":
          zoom = 8;
          break;
        case "neighborhood":
        case "postcode":
        case "locality":
        case "place":
          zoom = 13.5;
          break;
        case "poi":
        case "address":
          zoom = 19;
          break;
      }

      const lngLat: mapboxgl.LngLatLike = {
        lng: projectAddressFeature.geometry.coordinates[0],
        lat: projectAddressFeature.geometry.coordinates[1],
      };
      mapRef.current.flyTo({ center: lngLat, zoom: zoom, speed: 2 });
    }
  }, [projectAddressFeature]);

  useEffect(() => {
    if (navigateDrawingPlot) {
      NavigateToDrawingPlot();
    }
  }, [navigateDrawingPlot]);

  function NavigateToDrawingPlot() {
    try {
      if (
        mapRef.current &&
        drawedPlot &&
        drawedPlot.lotCoordinates.length > 0
      ) {
        const latLngCoordinates: L.LatLngExpression[] =
          drawedPlot.lotCoordinates.map((coordinate) => {
            return [coordinate[0], coordinate[1]];
          });

        const fit = new L.Polygon(latLngCoordinates).getBounds();
        const southWest = new LngLat(
          fit.getSouthWest().lat,
          fit.getSouthWest().lng
        );
        const northEast = new LngLat(
          fit.getNorthEast().lat,
          fit.getNorthEast().lng
        );

        mapRef.current.fitBounds(new LngLatBounds(southWest, northEast), {
          padding: { top: 75, bottom: 75, left: 75, right: 75 },
        });
      }
    } catch (ex) {
      TrackException(ex);
    } finally {
      setNavigateDrawingPlot(false);
    }
  }

  return (
    <div className="map-view">
      <div className="map-container">
        <Map
          ref={mapRef}
          initialViewState={viewport}
          style={{ position: "relative" }}
          mapboxAccessToken={MapboxAccessToken}
          mapStyle="mapbox://styles/mapbox/standard"
        >
          {mapType !== MapType.CreatingMarker && (
            <DrawControl
              mapType={mapType}
              controls={{}}
              position="top-left"
              displayControlsDefault={false}
              currentStore={currentStore}
            />
          )}
          <ScaleControl position="bottom-right" />
          <NavigationControl position="bottom-right" />
          {legendaItems && (
            <Legend objectScope={""} items={legendaItems}></Legend>
          )}
        </Map>
      </div>

      {showFloatingActionButton && (
        <div className="floating-action-button-layout">
          <Fab
            id="fab"
            className="floating-action-button"
            aria-label="add"
            disabled={!drawedPlot || drawedPlot.lotCoordinates.length === 0}
            onClick={handleClick}
          >
            <EllipseIcon size={25} />
          </Fab>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <div className="popover-content">
              <Typography
                sx={{ p: 2 }}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                  setCalculateDistance(true);
                }}
              >
                {t("Measure distance between drawed plot to A/N road")}
              </Typography>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
}
