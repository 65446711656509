import { ListItemText, MenuItem, Select } from "@mui/material";
import CustomFormControl from "./CustomFormControl";
import { useTranslation } from "react-i18next";

interface FormControlSelectProps {
  value: any;
  labelText?: string;
  toolTipText?: string;
  disabled?: boolean;
  showInfoButton?: boolean;
  selectItems: string[];
  setValue?: (value: string) => void;
}

export default function FormControlSelect({
  value,
  labelText,
  selectItems,
  disabled,
  showInfoButton,
  toolTipText,
  setValue,
}: FormControlSelectProps) {
  const { t } = useTranslation();  
  return (
    <CustomFormControl
      labelText={labelText}
      showInfoButton={showInfoButton}
      toolTipText={toolTipText}
    >
      <Select
        disabled={disabled ? disabled : false}
        style={{ height: "auto", minWidth: "150px"}}
        value={value}
        onChange={(event) => {
          setValue!(event.target.value as string);
        }}
        label={labelText}
      >
        {selectItems.map((name) => (
          <MenuItem key={name} value={name}>
            <ListItemText primary={t(name)} />
          </MenuItem>
        ))}
      </Select>
    </CustomFormControl>
  );
}
