import { FormControlLabel } from "@mui/material";
import CustomFormControl from "./CustomFormControl";
import AndroidSwitch from "../AndroidSwitch/AndroidSwitch";

interface FormControlSwitchProps {
  value: any;
  labelText: string;
  toolTipText?: string;
  showInfoButton?: boolean;
  setValue?: (value: boolean) => void;
}

export default function FormControlSwitch(props: FormControlSwitchProps) {
  return (
    <div style={{ marginTop: "-10px" }}>
      <CustomFormControl
        labelText={props.labelText}
        showInfoButton={props.showInfoButton}
        toolTipText={props.toolTipText}
      >
        <FormControlLabel
          label=""
          control={
            <AndroidSwitch
              sx={{ m: 1 }}
              checked={props.value}
              onChange={() => props.setValue!(!props.value)}
            />
          }
        />
      </CustomFormControl>
    </div>
  );
}
