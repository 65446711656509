import SummaryInfoDto from "../../../interfaces/SummaryInfoDto";
import {
  ExploreConfigStore,
  FilterCondition,
} from "../../../interfaces/exploreConfig";
import { createExploreStore } from "../../../state/ExploreState/ExploreState";
import { useMemo } from "react";
import { ScenarioDto } from "../types/api";

export const useNitrogenAnalysisConfigStore =
  (): ExploreConfigStore<ScenarioDto> => {
    const currentStore = useMemo(() => createExploreStore<ScenarioDto>(), []);
    const {
      setFilteredObjects,
      setFilteredObjectsWithCoolingTypes,
      brushedObjects,
    } = currentStore();

    const sortByOptionsDictionary: { [key: string]: string } = {
      "Best fit rate": "value",
    };

    const dimensions = [
      "annualNitrogenLimitExceeded",
      "totalNitrogenEmission",
      "totalAmmoniaEmission",
      "totalCarbonEmission",
      "electricInProcent",
      "fuelConsumption",
    ];

    const chartLabels = [
      "NitrogenPage.AnnualLimitExceed",
      "NitrogenPage.TotalNitrogenEmission",
      "NitrogenPage.TotalAmmoniaEmission",
      "NitrogenPage.TotalCarbonEmission",
      "NitrogenPage.ElectricInProcent",
      "NitrogenPage.LitersOfFuel",
    ];

    const clickableAxes = [
      "annualNitrogenLimitExceeded",
      "totalAmmoniaEmission",
      "totalNitrogenEmission",
      "totalCarbonEmission",
      "electricInProcent",
      "litersOfFuel",
    ];
    const filterControlOptionsDictionary = {
      "NitrogenPage.AnnualLimitExceed": "annualNitrogenLimitExceeded",
      "NitrogenPage.TotalAmmoniaEmission": "totalAmmoniaEmission",
      "NitrogenPage.TotalNitrogenEmission": "totalNitrogenEmission",
      "NitrogenPage.TotalCarbonEmission": "totalCarbonEmission",
      "NitrogenPage.ElectricInProcent": "electricInProcent",
      "NitrogenPage.LitersOfFuel": "litersOfFuel",
    };
    const filterControlOptionsDictionaryReversed = {
      annualNitrogenLimitExceeded: "NitrogenPage.AnnualLimitExceed",
      totalAmmoniaEmission: "NitrogenPage.TotalAmmoniaEmission",
      totalNitrogenEmission: "NitrogenPage.TotalNitrogenEmission",
      totalCarbonEmission: "NitrogenPage.TotalCarbonEmission",
      electricInProcent: "NitrogenPage.ElectricInProcent",
      litersOfFuel: "NitrogenPage.LitersOfFuel",
    };

    const defaultSelectedAxes = ["totalNitrogenEmission"];

    const summaryInfo: SummaryInfoDto[] = [
      {
        label: "NitrogenPage.AnnualLimitExceed",
        property: "annualNitrogenLimitExceeded",
        unit: "",
      },
      {
        label: "NitrogenPage.LitersOfFuel",
        property: "fuelConsumption",
        unit: "L",
      },
      {
        label: "NitrogenPage.ElectricInProcent",
        property: "electricInProcent",
        unit: "",
      },
    ];

    const filterConditions: FilterCondition<ScenarioDto>[] = [
      {
        key: "typeObject",
        condition: (value) => {
          return true;
        },
      },
    ];

    const handleObjectFilters = (objects: ScenarioDto[]) => {
      const scenarios = objects;

      setFilteredObjects(objects);
      setFilteredObjectsWithCoolingTypes(objects);

      return objects;
    };

    const handleCustomFilter = () => {
      const tempScenarios = [...brushedObjects];
      return tempScenarios;
    };

    return {
      sortByOptionsDictionary,
      filterConditions,
      currentStore,
      summaryInfo,
      dimensions,
      chartLabels,
      clickableAxes,
      filterControlOptionsDictionary,
      filterControlOptionsDictionaryReversed,
      handleCustomFilter,
      handleObjectFilters,
      defaultSelectedAxes,
    };
  };
