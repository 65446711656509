export function GetMapLegandaItems() {
  return  [
    { color: "#000000", label: "MapViewPage.BuildablePlot", opacity: 1 },
    { color: "#FFFFFF", label: "MapViewPage.BuildableArea", opacity: 1, },
  ];
}

export function GetMapAndDatacenterLegandaItems() {
  return  [
    { color: "#000000", label: "MapViewPage.BuildablePlot", opacity: 1 },
    { color: "#FFFFFF", label: "MapViewPage.BuildableArea", opacity: 1, },  
    { color: "#E2F0D9", label: "Low voltage room", opacity: 1,  },
    { color: "#FF8000", label: "Genset", opacity: 1 },
    { color: "#66CCF8", label: "Cooling", opacity: 1 },
    { color: "#FFFFFF", label: "Whitespace", opacity: 1,  },
  ];
}