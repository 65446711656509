import React, { useState } from "react";
import {
  Collapse,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./ExpandableListItemButton.scss";

interface Item {
  iconComponent: React.ReactNode;
  title: string;
  to: string;
  underConstruction: boolean;
  children?: Item[];
}

export default function ExpandableListItemButton({
  items,
  iconComponent,
  title,
  navigation: to,
  fontSize = 13,
  marginLeft = 20,
}: {
  items: Item[];
  iconComponent: React.ReactNode;
  navigation: string;
  title: string;
  fontSize?: number;
  marginLeft?: number;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <div className="expandable-list-item-button">
      <div className="main-button">
        <ListItemButton onClick={() => navigate(`../${to}`)}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={1}>
              <div className="expandable-button-icon">
                <ListItemIcon sx={{ minWidth: "0px" }}>
                  {iconComponent}
                </ListItemIcon>
              </div>
            </Grid>
            <Grid item xs={11}>
              <ListItemText
                sx={{ marginLeft: `${marginLeft}px` }}
                primaryTypographyProps={{
                  fontSize: `${fontSize}px`,
                  color: "black",
                  fontWeight: location.pathname.includes(
                    title.toLowerCase().replace(/\s/g, "")
                  )
                    ? "bold"
                    : "normal",
                }}
                primary={t(title)}
              />
            </Grid>
          </Grid>
        </ListItemButton>

        {items.length > 0 && (
          <IconButton
            style={{ position: "absolute", right: "0px", top: "2px" }}
            disableRipple
            onClick={() => setOpen(!open)}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </div>

      {items.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((item, index) =>
              item.children ? (
                <ExpandableListItemButton
                  key={index}
                  items={item.children.sort((a, b) => {
                    if (a.underConstruction !== b.underConstruction) {
                      return (
                        Number(a.underConstruction) -
                        Number(b.underConstruction)
                      );
                    } else {
                      return a.title.localeCompare(b.title);
                    }
                  })}
                  navigation={item.to}
                  iconComponent={item.iconComponent}
                  title={item.title}
                  fontSize={12}
                  marginLeft={35}
                />
              ) : (
                <ListItemButton
                  key={index}                  
                  disabled={item.underConstruction}
                  sx={{ pl: 6, minWidth: "0px" }}
                  onClick={() => navigate(`../${item.to}`)}
                >
                  <ListItemIcon sx={{ minWidth: "0px" }}>
                    {item.iconComponent}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ marginLeft: `${marginLeft + 10}px` }}
                    primary={t(item.title)}
                    primaryTypographyProps={{
                      fontWeight: location.pathname.endsWith(
                        item.title.toLowerCase().replace(/\s/g, "")
                      )
                        ? "bold"
                        : "normal",
                    }}
                  />
                </ListItemButton>
              )
            )}
          </List>
        </Collapse>
      )}
    </div>
  );
}
