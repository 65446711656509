import { TFunction } from "i18next";
import { GetAllSpaceBuildingsShortInfo } from "../../../HSA/services/SpaceBuildingService";
import { SpaceBuildingDto } from "../../../HSA/types/api";
import { TypeObject } from "../../types/enums";
import { TrackException } from "../../logging/LoggingManager";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";

interface LoadItemsParams<T extends DTO> {
  currentState: ExploreState<T> & ExploreActions<T>;
  type: TypeObject;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleObjectFilters: (objects: T[]) => void;
  t: TFunction<"translation", undefined>;
  generationProcessId: number;
}

export async function LoadItems<T extends DTO>({
  currentState,
  type,
  setIsSidebarOpen,
  handleObjectFilters,
  t,
  generationProcessId,
}: LoadItemsParams<T>) {
  try {
    let tempObjects: T[] = currentState.objects ?? [];
    let selectedObjects: T[] = [];
    if (tempObjects.length === 0) {
      switch (type) {
        case TypeObject.HSABuilding:
          tempObjects = (await GetAllSpaceBuildingsShortInfo(
            currentState as unknown as ExploreState<SpaceBuildingDto> &
              ExploreActions<SpaceBuildingDto>
          ))! as unknown as T[];
          break;
        default:
          break;
      }
    }

    setIsSidebarOpen(selectedObjects.length > 0);
    currentState.setObjects([...tempObjects]);

    const objectsToFilter: T[] =
      currentState.brushedObjects.length > 0
        ? currentState.brushedObjects
        : tempObjects;

    handleObjectFilters(objectsToFilter);
    currentState.setIsObjectsLoaded(true);
  } catch (ex) {
    TrackException(ex);
  }
}
