import { WaterThreatmentDto } from "../../../interfaces/foundry/typicals/WaterThreatmentDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";

export async function GetWaterThreatmentRoom(
  id: number
): Promise<WaterThreatmentDto> {
  return (await GetWaterThreatmentRooms()).find(
    (x) => x.id === id
  ) as WaterThreatmentDto;
}

export async function GetWaterThreatmentRooms(): Promise<WaterThreatmentDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let waterThreatmentRooms = store.waterThreatmentRooms;
  if (waterThreatmentRooms.length === 0) {
    waterThreatmentRooms = await GetWaterThreatmentRoomDtos();
    store.setWaterThreatmentRooms(waterThreatmentRooms);
  }
  return waterThreatmentRooms;
}

async function GetWaterThreatmentRoomDtos(): Promise<WaterThreatmentDto[]> {
  try {
    const bassins = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/watertreatmentrooms"))
        .data.results as WaterThreatmentDto[]
    ).map((item: WaterThreatmentDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return bassins;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
