import "./DialogLayout.scss";

interface DialogLayoutProps {
  dialogView: React.ReactNode;
  dialogSidebar: React.ReactNode;
}

export default function DialogLayout({
  dialogView,
  dialogSidebar,
}: DialogLayoutProps) {
  return (
    <div className="dialog-layout">
      <div className="dialog-view">{dialogView}</div>
      <div className="dialog-sidebar">{dialogSidebar}</div>
    </div>
  );
}
