// CostSection.tsx
import { Grid, Typography } from "@mui/material";
import React from "react";
import { formatValue } from "../../utils/format";
import "./MetricsSection.scss";

interface MetricItem {
  name: string;
  quantity?: number;
  amount: number;
  unit: string;
}

interface MetricsSectionProps {
  items: MetricItem[];
  title?: string;
  subtitle?: string;
  total?: number;
  intentItems?: boolean;
}

const MetricsSection: React.FC<MetricsSectionProps> = ({
  title,
  subtitle,
  items,
  total,
  intentItems = false,
}) => {
  return (
    <div className="cost-section">
      <Typography variant="h5" className="title">
        {title}
      </Typography>

      {subtitle && total && (
        <Grid container className="subtitle-row">
          <Grid item xs={6}>
            <Typography variant="subtitle1" className="subtitle">
              {subtitle}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography align="right" className="value-container">
              <span className="amount">{formatValue(total, "€")}</span>
              <span className="unit">{"€"}</span>
            </Typography>
          </Grid>
        </Grid>
      )}

      <div className={intentItems ? "ml20" : ""}>
        {items.map((item, index) => (
          <Grid container key={index} className="cost-row">
            <Grid item xs={4}>
              <Typography noWrap>{item.name}</Typography>
            </Grid>
            {item.quantity && (
              <Grid item xs={4}>
                <Typography align="center">{item.quantity} st</Typography>
              </Grid>
            )}
            <Grid item xs>
              <Typography align="right" className="value-container">
                <span className="amount">
                  {formatValue(item.amount, item.unit)}
                </span>
                <span className="unit">{item.unit}</span>
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  );
};

export default MetricsSection;
