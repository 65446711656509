import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import FormControlTextField from "../../../../components/CustomFormControl/FormControlTextField";
import CustomInputLabel from "../../../../components/InputLabel/CustomInputLabel";
import LoadingIndicator from "../../../../components/LoadingIndicator/LoadingIndicator";
import { ArrowDown } from "../../../../icons/ArrowDownIcon";
import { ArrowUp } from "../../../../icons/ArrowUpIcon";
import { theme } from "../../../../utils/HelperFunctions";
import { useModuleDetailsStore } from "../../../HSA/state/moduleDetailsState";
import { SpaceBuildingDto } from "../../../HSA/types/api";
import { TypeObject } from "../../types/enums";
import { ExploreConfigStore } from "../../types/ExploreConfig";
import InfoField, { DetailedInfo } from "../../types/InfoField";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";
import AddToSetDialog from "../BaseExploreView/AddToSetDialog";
import ParallelCoordinatesPlot from "../ParralelCoordinatesPlot/ParallelCoordinatesPlot";
import HoverContext from "../Scene/HoverContext";
import Scene from "../Scene/Scene";
import SceneDetail from "../Scene/SceneDetail/SceneDetail";
import { LoadItems } from "./ExploreHelper";
import "./ExploreView.scss";

interface ExploreViewProps<T extends DTO> {
  type: TypeObject;
  summaryInfo: InfoField[];
  detailedInfo?: DetailedInfo[];
  currentState: ExploreState<T> & ExploreActions<T>;
  sortByOptionsDictionary: { [key: string]: string };
  displayParallelCoordinatesPlot?: boolean;
  dimensions?: string[];
  chartLabels?: string[];
  clickableAxes?: string[];
  bestFitOptionsDictionary?: {
    [key: string]: string;
  };
  bestFitOptionsDictionaryReversed?: {
    [key: string]: string;
  };
  handleCustomFilter: () => T[];
  handleObjectFilters: (objects: T[]) => T[];
  orderObjects: (
    objects: T[],
    sortProperty: string,
    isSortOrderAscending: boolean
  ) => void;
  apartmentConfigStore?: ExploreConfigStore<SpaceBuildingDto>;
}

export default function ExploreView<T extends SpaceBuildingDto>({
  type,
  summaryInfo,
  detailedInfo,
  currentState,
  sortByOptionsDictionary,
  displayParallelCoordinatesPlot = true,
  dimensions,
  chartLabels,
  clickableAxes,
  orderObjects,
  handleCustomFilter,
  handleObjectFilters,
}: ExploreViewProps<T>) {
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const rafRef = useRef<number | null>(null);

  const {
    selectedIds: selectedTypicalIds,
    isInputPaneOpen,
    setSelectedExportedBuildingsIds,
  } = currentState;

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [hasMoreData, setHasMoreData] = useState(true);
  const [addToSetOpen, setAddToSetOpen] = useState(false);
  const [hoverdObject, setHoveredObject] = useState<T | null>(null);
  const { generationProcessId } = useModuleDetailsStore();

  useEffect(() => {
    if (currentState.objects !== null) return;

    const abortController = new AbortController();

    (async () => {
      await LoadItems({
        currentState,
        type,
        setIsSidebarOpen,
        handleObjectFilters,
        t,
        generationProcessId,
      });
    })();

    return () => {
      if (rafRef.current !== null) {
        cancelAnimationFrame(rafRef.current);
      }

      abortController.abort();
    };
  }, [currentState.objects]);

  useEffect(() => {
    if (currentState?.currentPageType === null) {
      currentState.currentPageType = type;
    }
  }, []);

  useEffect(() => {
    if (type === TypeObject.HSABuilding)
      setSelectedExportedBuildingsIds(
        selectedTypicalIds.map((code) => ({ code, count: 1 }))
      );
  }, [selectedTypicalIds]);

  const handleBrushObjects = useCallback(
    (data: T[], sortProperty: string, isSortOrderAscending: boolean) => {
      currentState?.setBrushedObjects(data);
      setHasMoreData(true);
      orderObjects(data, sortProperty, isSortOrderAscending);
    },
    []
  );

  const handleObjectClick = (selectedObject: T) => {
    setIsSidebarOpen(true);
    if (selectedTypicalIds.length > 0) {
      const isAlreadyClicked = selectedTypicalIds.some(
        (code) => code === (selectedObject as SpaceBuildingDto).code
      );
      currentState?.setSelectedIds(
        isAlreadyClicked
          ? selectedTypicalIds.filter(
              (item) => item !== (selectedObject as SpaceBuildingDto).code
            )
          : [
              ...selectedTypicalIds,
              ...[(selectedObject as SpaceBuildingDto).code],
            ]
      );
    } else {
      currentState?.setSelectedIds([(selectedObject as SpaceBuildingDto).code]);
    }
  };

  const removeCandidateObject = (selectedObject: T) => {
    currentState?.setSelectedIds(
      selectedTypicalIds.filter(
        (code) => code !== (selectedObject as SpaceBuildingDto).code
      )
    );
  };

  const objectHover = (hoveredData: T | null) => {
    setHoveredObject(hoveredData);
  };

  const handleReset = () => {
    currentState?.setIsReset(!currentState?.isReset);
    setHasMoreData(true);
  };

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXl = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXxl = useMediaQuery(theme.breakpoints.up("xl"));
  let itemsPerRow = 1;
  if (isXs) {
    itemsPerRow = 1;
  } else if (isSm) {
    itemsPerRow = 3 - (isSidebarOpen ? 1 : 0) - (isInputPaneOpen ? 1 : 0);
  } else if (isMd) {
    itemsPerRow = 4 - (isSidebarOpen ? 1 : 0) - (isInputPaneOpen ? 1 : 0);
  } else if (isLg) {
    itemsPerRow = 5 - (isSidebarOpen ? 1 : 0) - (isInputPaneOpen ? 1 : 0);
  } else if (isXl) {
    itemsPerRow = 8 - (isSidebarOpen ? 1 : 0) - (isInputPaneOpen ? 1 : 0);
  } else if (isXxl) {
    itemsPerRow = 12; // Example for xl screens
  }

  function handleMouseLeave() {
    setHoveredObject(null);
  }
  const Row = React.memo(({ index, style }: ListChildComponentProps) => {
    // Customizing the theme to include xxs and xxl breakpoints
    const startIndex = index * itemsPerRow;
    const itemsForRow = currentState?.loadedObjects.slice(
      startIndex,
      startIndex + itemsPerRow
    );
    return (
      <div style={style}>
        <Grid container>
          {itemsForRow.map((data) => (
            <Grid
              item
              xs={12 / itemsPerRow} // For xs screens
              sm={12 / itemsPerRow} // For sm screens
              md={12 / itemsPerRow} // For md screens
              lg={12 / itemsPerRow} // For lg screens
              xl={12 / itemsPerRow} // For xl screens
              key={data.id}
              onMouseEnter={() => objectHover(data)}
              onMouseLeave={() => handleMouseLeave()}
            >
              <Scene
                currentState={currentState}
                currentObject={data}
                summaryInfo={summaryInfo}
                onClickObject={handleObjectClick}
                isCandidate={selectedTypicalIds.includes(
                  (data as SpaceBuildingDto).code
                )}
                isInOriginalList={true}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  });

  const isItemLoaded = (index: number) => {
    return index < Math.ceil(currentState?.loadedObjects.length / itemsPerRow);
  };

  const loadMoreItems = (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    return new Promise((resolve) => {
      if (!hasMoreData) {
        resolve();
        return;
      }
      const actualStart = (startIndex + 1) * itemsPerRow;
      const actualEnd = (stopIndex + 1) * itemsPerRow;

      const tempFilteredObjects = handleCustomFilter();
      if (tempFilteredObjects && tempFilteredObjects.length === 0) {
        currentState?.setLoadedObjects([]);
        resolve();
        return;
      }

      const nextBatch = currentState?.brushedObjects.slice(
        actualStart,
        actualEnd
      );

      if (nextBatch.length > 0) {
        currentState?.setLoadedObjects([
          ...currentState?.loadedObjects,
          ...nextBatch,
        ]);
        setHasMoreData(
          currentState?.loadedObjects.length + nextBatch.length !==
            currentState?.loadedObjects.length
        );

        resolve();
      } else {
        setHasMoreData(false);
        resolve();
      }
    });
  };

  useEffect(() => {
    orderObjects(
      currentState?.brushedObjects,
      currentState?.objectSortProperty,
      currentState?.objectSortAscending
    );
  }, [
    currentState?.objectSortProperty,
    currentState?.objectSortAscending,
    currentState?.selectedAxes,
  ]);

  if (currentState?.objects === null) {
    return (
      <div className="loading-indicator-base-select-object">
        <LoadingIndicator />
      </div>
    );
  } else {
    return (
      <HoverContext.Provider
        value={{ hovered: hoverdObject, setHovered: setHoveredObject }}
      >
        <div className="base-select-object-view-container">
          <div className="graph-view">
            <div className="reset-sidebar">
              <h5>
                <FormControlTextField
                  labelText={t("Configurations total")}
                  value={currentState?.objectsTotalCount}
                />
              </h5>
              <h5>
                <FormControlTextField
                  labelText={t("Filtered")}
                  value={currentState?.brushedObjects?.length}
                />
              </h5>

              <Button
                className="button"
                variant="contained"
                color="secondary"
                onClick={handleReset}
              >
                {t("Reset")}
              </Button>
            </div>
            {displayParallelCoordinatesPlot && (
              <div className="graph">
                <ParallelCoordinatesPlot
                  currentState={currentState}
                  dimensions={dimensions ?? []}
                  chartLabels={chartLabels ?? []}
                  clickableAxes={clickableAxes ?? []}
                  onBrushObjects={handleBrushObjects}
                />
              </div>
            )}
            <div
              className={
                isSidebarOpen ? "filter-view-open" : "filter-view-closed"
              }
            >
              <FormControl
                style={{ height: "auto", minWidth: "180px", maxWidth: "220px" }}
                variant="standard"
              >
                <CustomInputLabel
                  id="type-datacentre-label"
                  showInfoButton={false}
                  labelText={t("Sort by")}
                  tooltipText={t("Sort by")}
                />
                <Select
                  id="type-sort-by-select"
                  value={currentState?.objectSortProperty}
                  onChange={(event) =>
                    currentState?.setObjectSortProperty(event.target.value)
                  }
                >
                  {Object.keys(sortByOptionsDictionary).map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="center-center mt">
                <IconButton
                  onClick={() =>
                    currentState?.setObjectSortAscending(
                      !currentState?.objectSortAscending
                    )
                  }
                >
                  {currentState?.objectSortAscending ? (
                    <ArrowUp />
                  ) : (
                    <ArrowDown />
                  )}
                </IconButton>
              </div>

              <div className="full-width" />
              <Button
                sx={{
                  width: "auto",
                  minWidth: "100px",
                  marginLeft: "10px",
                  marginTop: "20px",
                }}
                className="button"
                variant="contained"
                color="secondary"
                onClick={() => {
                  const mergedObjects = [
                    ...selectedTypicalIds,
                    ...currentState?.brushedObjects.map(
                      (x) => (x as SpaceBuildingDto).code
                    ),
                  ];
                  const uniqueObjectsSet = new Set(mergedObjects);
                  currentState?.setSelectedIds(Array.from(uniqueObjectsSet));
                  setIsSidebarOpen(true);
                }}
              >
                {t("Select all")}
              </Button>
            </div>
          </div>
          <div className="scene-view">
            <IconButton
              style={{
                zIndex: 100,
                position: "absolute",
                marginRight: "-10px",
                right: "0px",
              }}
              onClick={handleSidebarToggle}
            >
              <ChevronLeft sx={{ fontSize: "2.5rem" }} />{" "}
            </IconButton>

            <div
              className={
                isSidebarOpen
                  ? "infinite-scroll-view-open"
                  : "infinite-scroll-view-closed"
              }
            >
              {currentState?.loadedObjects ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      itemCount={
                        currentState?.brushedObjects.length / itemsPerRow
                      }
                      loadMoreItems={loadMoreItems}
                    >
                      {({ onItemsRendered, ref }) => (
                        <List
                          ref={ref}
                          width={width}
                          itemSize={140}
                          onItemsRendered={onItemsRendered}
                          itemCount={Math.ceil(
                            currentState?.brushedObjects.length / itemsPerRow
                          )}
                          height={height}
                        >
                          {Row}
                        </List>
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              ) : (
                <div className="empty-conf">
                  {t("There are no configuration left!")}
                </div>
              )}
            </div>

            <Drawer
              variant="persistent"
              anchor="right"
              open={isSidebarOpen}
              onClose={handleSidebarToggle}
              PaperProps={{
                style: {
                  width: "410px",
                  backgroundColor: "none",
                  marginLeft: "5px",
                  marginTop: "0px",
                  position: "absolute",
                },
              }}
            >
              <div className="sidebar-view">
                <div className="sidebar-button">
                  <IconButton
                    style={{
                      marginLeft: "0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    onClick={handleSidebarToggle}
                  >
                    <ChevronRight sx={{ fontSize: "2.5rem" }} />{" "}
                  </IconButton>
                </div>
                <div className="title sidebar-title">{t("Selected items")}</div>

                <div className="sidebar-reset-button">
                  <Button
                    className="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleReset();
                      currentState?.setSelectedIds([]);
                    }}
                  >
                    {t("Reset")}
                  </Button>
                </div>
              </div>
              <div className="sidebar-selected">
                {selectedTypicalIds?.length > 0 ? (
                  selectedTypicalIds.map((code) => (
                    <Grid item xl={2} key={code}>
                      <Scene
                        currentState={currentState}
                        currentObject={
                          (currentState?.objects ?? []).find(
                            (x) => (x as SpaceBuildingDto).code === code
                          )!
                        }
                        summaryInfo={summaryInfo}
                        onHoverObject={objectHover}
                        onClickObject={removeCandidateObject}
                        isCandidate={selectedTypicalIds.includes(code)}
                        isInOriginalList={false}
                      />
                    </Grid>
                  ))
                ) : (
                  <div className="empty-conf"></div>
                )}
              </div>
            </Drawer>
          </div>
          <SceneDetail
            pageType={type}
            currentState={currentState}
            detailedInfo={detailedInfo}
          />
          <AddToSetDialog
            currentState={currentState}
            open={addToSetOpen}
            onClose={() => setAddToSetOpen(false)}
            onSave={() => setAddToSetOpen(false)}
          />
        </div>
      </HoverContext.Provider>
    );
  }
}
