import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LowVoltageRoomDto } from "../../../interfaces/foundry/typicals/LowVoltageRoomDto";
import { useEffect, useState } from "react";
import { GetLowVoltageRooms } from "../../../http/foundry/LowVoltageRoomService";
import { BuildingTypeEnum } from "../../../interfaces/enums/BuildingTypeEnum";
import { GetUnqiueNumbers } from "../../../utils/HelperFunctions";
import { TransformatorDto } from "../../../interfaces/foundry/typicals/TransformatorDto";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { GetTransformators } from "../../../http/foundry/typicals/TransformatorService";
import { createExploreStore } from "../../../state/ExploreState/ExploreState";

interface GenerateDataDatacenterLowVoltageRoomProps {
  selectedLowVoltageRooms: number[];
  setSelectedLowVoltageRooms(lowVoltageRoomIds: number[]): void;
}
export default function GenerateDataDatacenterLowVoltageRoom({
  selectedLowVoltageRooms,
  setSelectedLowVoltageRooms,
}: GenerateDataDatacenterLowVoltageRoomProps) {
  const { t } = useTranslation();

  const lowVoltageRoomConfigStore = createExploreStore<LowVoltageRoomDto>();
  const { objects, selectedIds } = lowVoltageRoomConfigStore();
  const { tranfoTypeOptions, setTranfoTypeOptions } =
    useGeneralDatacenterObjectsStore();

  const [tranfoType, setTranfoType] = useState<TransformatorDto[]>([]);
  const [lowVoltageRooms, setLowVoltageRooms] = useState<LowVoltageRoomDto[]>(
    []
  );
  const [filteredLowVoltageRooms, setFilteredLowVoltageRooms] = useState<
    LowVoltageRoomDto[]
  >([]);

  const handleChangeLowVoltageRoom = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[];
    if (selectedIds.includes("all")) {
      setSelectedLowVoltageRooms(
        selectedLowVoltageRooms.length === filteredLowVoltageRooms.length
          ? []
          : filteredLowVoltageRooms.map((x) => x.id)
      );
    } else {
      setSelectedLowVoltageRooms(
        filteredLowVoltageRooms
          .map((x) => x.id)
          .filter((id) => GetUnqiueNumbers(selectedIds).includes(id))
      );
    }
  };

  const handleChangeTranfoType = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value;
    const valuesTranfoType = Object.values(tranfoTypeOptions);

    if (selectedIds.includes("all")) {
      setTranfoType(
        tranfoType.length === valuesTranfoType.length ? [] : valuesTranfoType
      );
    } else {
      const selectedTransfos = valuesTranfoType.filter((tranfo) =>
        selectedIds.includes(tranfo.id.toString())
      );
      setTranfoType(selectedTransfos);
    }
  };

  useEffect(() => {
    const filteredLowVoltageRooms = lowVoltageRooms.filter(
      (lowVoltageRoom) =>
        !tranfoType.length ||
        tranfoType.some(
          (tranfo) => tranfo.display_name === lowVoltageRoom.trafo_display_name
        )
    );
    setFilteredLowVoltageRooms(filteredLowVoltageRooms);
    setSelectedLowVoltageRooms(
      selectedLowVoltageRooms.filter((x) =>
        filteredLowVoltageRooms.map((x) => x.id).includes(x)
      )
    );
  }, [tranfoType]);

  useEffect(() => {
    (async () => {
      try {
        if (tranfoTypeOptions.length === 0) {
          setTranfoTypeOptions(await GetTransformators());
        }

        let currentLowVoltageRooms = [] as LowVoltageRoomDto[];
        if (objects === null || objects.length === 0) {
          currentLowVoltageRooms = await GetLowVoltageRooms(
            new AbortController().signal,
            undefined,
            BuildingTypeEnum.Datacenter
          );
        } else {
          currentLowVoltageRooms = (objects as LowVoltageRoomDto[]).filter(
            (x) =>
              x.building_types.some(
                (lvrBt) => lvrBt.id === BuildingTypeEnum.Datacenter
              )
          );

          setSelectedLowVoltageRooms(
            selectedIds.filter((x) =>
              currentLowVoltageRooms.map((x) => x.id).includes(x)
            )
          );
        }

        setLowVoltageRooms(currentLowVoltageRooms);
        setFilteredLowVoltageRooms(currentLowVoltageRooms);
      } catch (ex) {}
    })();
  }, []);

  return (
    <div className="pr10">
      <div className="title mt20">{t("Filters")}</div>
      <Grid
        container
        rowSpacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={5}>
          <div>{`${t("Transformator type")}:`}</div>
        </Grid>
        <Grid item xs={7} sx={{ alignContent: "center" }}>
          <FormControl fullWidth variant="standard">
            <Select
              multiple
              style={{ height: "auto" }}
              labelId="type-transformator-label"
              id="type-transformator-select"
              value={tranfoType.map((t) => t.id.toString())} // Set value to string IDs
              onChange={handleChangeTranfoType}
              label={t("Transformator type")}
              renderValue={(selectedIds: string[]) => (
                <Box
                  component={"div"}
                  className="chip-container"
                  sx={{ gap: 0.5 }}
                >
                  {selectedIds.map((id) => {
                    const tranfo = Object.values(tranfoTypeOptions).find(
                      (t) => t.id.toString() === id
                    );
                    return tranfo ? (
                      <Chip key={id} label={tranfo.display_name} />
                    ) : null;
                  })}
                </Box>
              )}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      Object.values(tranfoTypeOptions).length > 0 &&
                      tranfoType.length ===
                        Object.values(tranfoTypeOptions).length
                    }
                    indeterminate={
                      tranfoType.length > 0 &&
                      tranfoType.length <
                        Object.values(tranfoTypeOptions).length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={t("Select all")} />
              </MenuItem>
              {Object.values(tranfoTypeOptions).map((tranfo) => (
                <MenuItem key={tranfo.id} value={tranfo.id.toString()}>
                  <Checkbox
                    checked={tranfoType.some((t) => t.id === tranfo.id)}
                  />
                  <ListItemText primary={t(tranfo.display_name)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <div className="title mt20">
        {`${t("Choose")} ${t("Low voltage rooms").toLowerCase()}`}
      </div>
      <Grid
        container
        rowSpacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={5}>
          <div>{`${t("Low voltage rooms")}:`}</div>
        </Grid>
        <Grid item xs={7} sx={{ alignContent: "center" }}>
          <FormControl fullWidth variant="standard">
            <Select
              multiple
              style={{ height: "auto" }}
              labelId="low-voltage-room-label"
              id="low-voltage-room-select"
              value={selectedLowVoltageRooms.map((t) => t.toString())}
              onChange={handleChangeLowVoltageRoom}
              renderValue={(selectedIds: string[]) => (
                <Box
                  component={"div"}
                  className="chip-container"
                  sx={{
                    gap: 0.5,
                  }}
                >
                  {selectedIds.map((id) => {
                    return (
                      <Chip key={id} label={`${t("Low voltage room")} ${id}`} />
                    );
                  })}
                </Box>
              )}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      lowVoltageRooms.length > 0 &&
                      selectedLowVoltageRooms.length === lowVoltageRooms.length
                    }
                    indeterminate={
                      selectedLowVoltageRooms.length > 0 &&
                      selectedLowVoltageRooms.length < lowVoltageRooms.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={t("Select all")} />
              </MenuItem>
              {filteredLowVoltageRooms.map((lowVoltageRoom) => (
                <MenuItem key={lowVoltageRoom.id} value={lowVoltageRoom.id}>
                  <Checkbox
                    checked={selectedLowVoltageRooms.some(
                      (t) => t === lowVoltageRoom.id
                    )}
                  />
                  <ListItemText
                    primary={`${t("Low voltage room")} ${lowVoltageRoom.id}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
