import { TrackException } from "../../logging/LoggingManager";
import { LayoutDataDto, LayoutDto } from "../../interfaces/LayoutDto";
import { DatacenterDto } from "../../interfaces/foundry/DatacenterDto";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";

export async function GetLayout(id: number): Promise<LayoutDto> {
  try {
    const response = await foundryApiClient.get<LayoutDto>(
      `/api/geometry/layouts/${id}`
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}


export async function UpdateDatacenterLayoutWithWhitespaceLSR(
  datacenter: DatacenterDto,
  layout: LayoutDto
): Promise<LayoutDto> {
  const lsrLayout = await GetLayout(datacenter.lowVoltageRoom.layout);
  const whitespaceLayout = await GetLayout(datacenter.whitespace.layout);
  whitespaceLayout.data.children = whitespaceLayout.data.children.filter(
    (x) => x.object !== "cooling" && x.object !== "whitespace"
  );

  layout.data.children
    .filter((x) => x.object === "whitespace_block")
    .forEach((whitespaceBlock) => {
      whitespaceBlock.children
        .filter(
          (x) =>
            x.object === "whitespace" ||
            x.object === "lsr_room" ||
            x.object === "dc_lsr_room"
        )
        .forEach((component) => {
          component.object =
            component.object === "dc_lsr_room" ? "lsr_room" : component.object;

          const tempComponent = deepClone(component) as LayoutDataDto;
          const children = deepClone(
            tempComponent.object === "whitespace"
              ? whitespaceLayout.data.children
              : lsrLayout.data.children
          ) as LayoutDataDto[];

          if (tempComponent.object === "whitespace") {
            translateWhitespacePositionByCooling(tempComponent, datacenter);
          }

          children.forEach((child) => {
            translateLayoutPositions(child, tempComponent);
            component.children.push(child);
          });
        });
    });

  return layout;
}

export function MirrorLayout(layoutData: LayoutDataDto) {
  if (layoutData.mirror === 1) {
    layoutData.width = -layoutData.width;
  } else {
    layoutData.rotation = -layoutData.rotation;
  }
  return layoutData;
}

function translateWhitespacePositionByCooling(
  whitespaceComponent: LayoutDataDto,
  datacenter: DatacenterDto
) {
  const length =
    whitespaceComponent?.children?.find((x) => x.object === "cooling")
      ?.length ?? 0;

  if (datacenter.whitespace.cooling_type === 3) {
    whitespaceComponent.y = whitespaceComponent.mirror
      ? whitespaceComponent.y + length
      : whitespaceComponent.y - length;
  } else {
    whitespaceComponent.y = whitespaceComponent.mirror
      ? whitespaceComponent.y - length
      : whitespaceComponent.y + length;
  }
}

function translateLayoutPositions(
  currentLayout: LayoutDataDto,
  parentLayout: LayoutDataDto
) {
  switch (parentLayout.rotation) {
    case 0:
      parentLayout.mirror === 1
        ? translateBy180Degrees(currentLayout, parentLayout)
        : translateBy0Degrees(currentLayout, parentLayout);
      break;
    case 180:
      parentLayout.mirror === 1
        ? translateBy0Degrees(currentLayout, parentLayout)
        : translateBy180Degrees(currentLayout, parentLayout);
      break;
    case 90:
      parentLayout.mirror === 1
        ? translateBy270Degrees(currentLayout, parentLayout)
        : translateBy90Degrees(currentLayout, parentLayout);
      break;
    case 270:
      parentLayout.mirror === 1
        ? translateBy90Degrees(currentLayout, parentLayout)
        : translateBy270Degrees(currentLayout, parentLayout);
      break;
    default:
      translateBy180Degrees(currentLayout, parentLayout);
      break;
  }

  currentLayout.z += parentLayout.z;
  currentLayout.mirror = parentLayout.mirror;
  currentLayout.rotation += parentLayout.rotation;
  if (currentLayout.children) {
    currentLayout.children.forEach((child) => {
      translateLayoutPositions(child, parentLayout);
    });
  }
}

function translateBy0Degrees(
  currentLayout: LayoutDataDto,
  parentLayout: LayoutDataDto
) {
  const { y: currentY } = currentLayout;
  const { mirror: parentMirror, x: parentX, y: parentY } = parentLayout;

  currentLayout.x += parentX;
  currentLayout.y =
    parentMirror === 1 ? parentY - currentY : parentY + currentY;
}

function translateBy90Degrees(
  currentLayout: LayoutDataDto,
  parentLayout: LayoutDataDto
) {
  const { x: currentX, y: currentY } = currentLayout;
  const { mirror: parentMirror, x: parentX, y: parentY } = parentLayout;

  currentLayout.y =
    parentMirror === 1 ? parentY - currentX : parentY + currentX;
  currentLayout.x = parentX - currentY;
}

function translateBy180Degrees(
  currentLayout: LayoutDataDto,
  parentLayout: LayoutDataDto
) {
  const { x: currentX, y: currentY } = currentLayout;
  const { mirror: parentMirror, x: parentX, y: parentY } = parentLayout;

  currentLayout.x = parentX - currentX;
  currentLayout.y =
    parentMirror === 1 ? parentY + currentY : parentY - currentY;
}

function translateBy270Degrees(
  currentLayout: LayoutDataDto,
  parentLayout: LayoutDataDto
) {
  const { x: currentX, y: currentY } = currentLayout;
  const { mirror: parentMirror, x: parentX, y: parentY } = parentLayout;

  currentLayout.x = parentX + currentY;
  currentLayout.y =
    parentMirror === 1 ? parentY + currentX : parentY - currentX;
}

function deepClone(obj: any): any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }

  if (obj instanceof Date) {
    return new Date(obj);
  }

  if (obj instanceof RegExp) {
    return new RegExp(obj);
  }

  const clonedObj = Object.create(Object.getPrototypeOf(obj));
  for (const key of Object.keys(obj)) {
    clonedObj[key] = deepClone(obj[key]);
  }

  return clonedObj;
}
