import { createTheme } from "@mui/material/styles";
import ColorPalette from "../interfaces/ColorPalette";
import moment from "moment";

export const safeNumber = (value: any): number => {
  if (value === undefined || value === "") {
    return 0;
  }
  const num = Number(value);
  return isNaN(num) ? 0 : num;
};

export function debounce<F extends (...args: any[]) => void>(
  func: F,
  timeout: number
) {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
}

export function sanitizeFilename(filename: string): string {
  return filename.replace(/[^a-z0-9.]/gi, "_").toLowerCase();
}

export function IsStringNumber(value?: string | number): boolean {
  return value != null && value !== "" && !isNaN(Number(value.toString()));
}

export function FirstCharToUpperCase(value: string): string {
  if (!value) {
    return "";
  }
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export const getOptionKey = (
  value: string,
  options: { [key: string]: string }
): string => {
  const entry = Object.entries(options).find(
    ([key, label]) => label === value
  );
  return entry ? entry[0] : value;
};

export const theme = createTheme({
  breakpoints: {
    values: {
      // xxs: 0, // assuming screens smaller than 360px
      xs: 1024, // default xs value in Material-UI
      sm: 1540,
      md: 2560,
      lg: 3500,
      xl: 5000,
    },
  },
});

export const OptionDutchToEnglish: { [key: string]: string } = {
  Activiteit: "Activity",
  Eenheid: "DimensionUnit",
  "Eenheid en productie per dageenheid": "DimensionAndProductionPerDayUnit",
  "Productie per dageenheid": "ProductionPerDayUnit",
};

export function IsGuid(str: string): boolean {
  const guidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidRegex.test(str);
}

export function GenerateGuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function FormatNumberToEuro(
  amount: number | undefined,
  withEuroMark: boolean = true
): string {
  if (!amount) return "";

  const formattedAmount = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format(amount);

  return withEuroMark ? formattedAmount : formattedAmount.replace("€", "");
}

export function FormatEuroToNumber(amount: number | undefined): string {
  if (amount === undefined) {
    return "";
  }

  const amountString = amount.toString();
  return amountString.includes("€")
    ? amountString.replace("€", "").replace(".", "")
    : amountString;
}

export function GetUnqiueNumbers(selectedIds: string[]) {
  const selectedIdNumbers = selectedIds.map((id) => parseInt(id));
  const frequencyMap: { [key: number]: number } = {};
  selectedIdNumbers.forEach((num) => {
    frequencyMap[num] = (frequencyMap[num] || 0) + 1;
  });

  return selectedIdNumbers.filter((num) => frequencyMap[num] === 1);
}

export function IsNumber(numStr: string) {
  return !isNaN(parseFloat(numStr)) && !isNaN(+numStr);
}

export function DownloadFile(
  data: string,
  type: string,
  filename: string,
  extension: string
) {
  const blob = new Blob([data], { type: type });
  const urlBlob = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = urlBlob;
  const dateString = moment(new Date()).format("DDMMYYYYHHmmss");
  const fileName = `${filename}_${dateString}.${extension}`;
  a.download = fileName.toLowerCase();
  a.click();
}

export const getFeaturesForObject = (
  colorPalette: ColorPalette | undefined,
  objectType: string
):
  | {
      fill_color: string | undefined;
      line_color: string | undefined;
      opacity: number | undefined;
      isTransparrent: boolean;
    }
  | undefined => {
  switch (objectType) {
    case "datacenter":
      return {
        fill_color: "",
        line_color: "#feb800",
        opacity: 1,
        isTransparrent: true,
      };
    case "datacenter_cooltower":
      return {
        fill_color: "",
        line_color: "#003466",
        opacity: 1,
        isTransparrent: true,
      };
    case "whitespace":
      return {
        fill_color: colorPalette?.whitespace.fill_color,
        line_color: colorPalette?.whitespace.line_color,
        opacity: colorPalette?.whitespace.transparency,
        isTransparrent: true,
      };

    case "row":
      return {
        fill_color: colorPalette?.row.fill_color,
        line_color: colorPalette?.row.line_color,
        opacity: 0,
        isTransparrent: true,
      };

    case "strut":
      return {
        fill_color: colorPalette?.strut.fill_color,
        line_color: colorPalette?.strut.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "rack":
      return {
        fill_color: colorPalette?.rack.fill_color,
        line_color: colorPalette?.rack.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "hot_alley":
      return {
        fill_color: colorPalette?.hot_alley.fill_color,
        line_color: colorPalette?.hot_alley.line_color,
        opacity: colorPalette?.hot_alley.transparency,
        isTransparrent: true,
      };

    case "cold_alley":
      return {
        fill_color: colorPalette?.cold_alley.fill_color,
        line_color: colorPalette?.cold_alley.line_color,
        opacity: 0,
        isTransparrent: true,
      };

    case "mdb":
      return {
        fill_color: colorPalette?.mdb.fill_color,
        line_color: colorPalette?.mdb.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "cooling":
      return {
        fill_color: colorPalette?.cooling.fill_color,
        line_color: colorPalette?.cooling.line_color
          ? colorPalette?.cooling.line_color
          : "#000000",
        opacity: 1,
        isTransparrent: false,
      };

    case "battery_liion":
      return {
        fill_color: colorPalette?.battery_liion.fill_color,
        line_color: colorPalette?.battery_liion.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "battery_vlra":
      return {
        fill_color: colorPalette?.battery_vlra.fill_color,
        line_color: colorPalette?.battery_vlra.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "ups":
      return {
        fill_color: colorPalette?.ups.fill_color,
        line_color: colorPalette?.ups.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "lvv_normal":
      return {
        fill_color: colorPalette?.lvv_normal.fill_color,
        line_color: colorPalette?.lvv_normal.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "lvv_large":
      return {
        fill_color: colorPalette?.lvv_large.fill_color,
        line_color: colorPalette?.lvv_large.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "transformator":
      return {
        fill_color: colorPalette?.transformator.fill_color,
        line_color: colorPalette?.transformator.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "rmu":
      return {
        fill_color: colorPalette?.rmu.fill_color,
        line_color: colorPalette?.rmu.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "rmu_cage":
      return {
        fill_color: colorPalette?.rmu_cage.fill_color,
        line_color: colorPalette?.rmu_cage.line_color,
        opacity: colorPalette?.rmu_cage.transparency,
        isTransparrent: true,
      };

    case "lsr":
      return {
        fill_color: colorPalette?.lsr.fill_color,
        line_color: colorPalette?.lsr.line_color,
        opacity: colorPalette?.lsr.transparency,
        isTransparrent: true,
      };

    case "lsrcooling":
      return {
        fill_color: colorPalette?.lsrcooling.fill_color,
        line_color: colorPalette?.lsrcooling.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "genset":
      return {
        fill_color: colorPalette?.genset.fill_color,
        line_color: colorPalette?.genset.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "genset_reservering":
      return {
        fill_color: colorPalette?.genset_reservering.fill_color,
        line_color: colorPalette?.genset_reservering.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "genset_tank":
      return {
        fill_color: colorPalette?.genset_tank.fill_color,
        line_color: colorPalette?.genset_tank.line_color,
        opacity: 1,
        isTransparrent: false,
      };
    case "alley":
      return {
        fill_color: colorPalette?.alley.fill_color,
        line_color: colorPalette?.alley.line_color,
        opacity: 1,
        isTransparrent: false,
      };
    case "floor_mechanical":
      return {
        fill_color: colorPalette?.floor_mechanical.fill_color,
        line_color: colorPalette?.floor_mechanical.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "wall_natural":
      return {
        fill_color: colorPalette?.wall_natural.fill_color,
        line_color: colorPalette?.wall_natural.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "wall_mechanical":
      return {
        fill_color: colorPalette?.wall_mechanical.fill_color,
        line_color: colorPalette?.wall_mechanical.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "mer":
      return {
        fill_color: colorPalette?.mer.fill_color,
        line_color: colorPalette?.mer.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "loadbank":
      return {
        fill_color: colorPalette?.loadbank.fill_color,
        line_color: colorPalette?.loadbank.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "fiberdistributionroom":
      return {
        fill_color: colorPalette?.fiberdistributionroom.fill_color,
        line_color: colorPalette?.fiberdistributionroom.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "supply_station":
      return {
        fill_color: colorPalette?.supply_station.fill_color,
        line_color: colorPalette?.supply_station.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "waterbassin":
      return {
        fill_color: colorPalette?.waterbassin.fill_color,
        line_color: colorPalette?.waterbassin.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "waterbassin_underground":
      return {
        fill_color: colorPalette?.waterbassin_underground.fill_color,
        line_color: colorPalette?.waterbassin_underground.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "waterbuffer":
      return {
        fill_color: colorPalette?.waterbuffer.fill_color,
        line_color: colorPalette?.waterbuffer.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "waterbuffer_underground":
      return {
        fill_color: colorPalette?.waterbuffer_underground.fill_color,
        line_color: colorPalette?.waterbuffer_underground.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "pumproom":
      return {
        fill_color: colorPalette?.pumproom.fill_color,
        line_color: colorPalette?.pumproom.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "fireriser":
      return {
        fill_color: colorPalette?.fireriser.fill_color,
        line_color: colorPalette?.fireriser.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "waterthreatment":
      return {
        fill_color: colorPalette?.waterthreatment.fill_color,
        line_color: colorPalette?.waterthreatment.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "valveroom":
      return {
        fill_color: colorPalette?.valveroom.fill_color,
        line_color: colorPalette?.valveroom.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "cooling_block":
      return {
        fill_color: colorPalette?.cooling_block.fill_color,
        line_color: colorPalette?.cooling_block.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "cooling_tower":
      return {
        fill_color: colorPalette?.cooling_tower.fill_color,
        line_color: colorPalette?.cooling_tower.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "cooling_tower_reservering":
      return {
        fill_color: colorPalette?.cooling_tower_reservering.fill_color,
        line_color: colorPalette?.cooling_tower_reservering.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "chiller":
      return {
        fill_color: colorPalette?.chiller.fill_color,
        line_color: colorPalette?.chiller.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "chiller_reservering":
      return {
        fill_color: colorPalette?.chiller_reservering.fill_color,
        line_color: colorPalette?.chiller_reservering.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "hallway":
      return {
        fill_color: colorPalette?.hallway.fill_color,
        line_color: colorPalette?.hallway.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "administration":
      return {
        fill_color: colorPalette?.administration.fill_color,
        line_color: colorPalette?.administration.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "elevator":
      return {
        fill_color: colorPalette?.elevator.fill_color,
        line_color: colorPalette?.elevator.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "stairway":
      return {
        fill_color: colorPalette?.stairway.fill_color,
        line_color: colorPalette?.stairway.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "sanitair":
      return {
        fill_color: colorPalette?.sanitair.fill_color,
        line_color: colorPalette?.sanitair.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "shaft":
      return {
        fill_color: colorPalette?.shaft.fill_color,
        line_color: colorPalette?.shaft.line_color,
        opacity: 1,
        isTransparrent: false,
      };

    case "trafo_cage":
      return {
        fill_color: colorPalette?.trafo_cage.fill_color,
        line_color: colorPalette?.trafo_cage.line_color,
        opacity: colorPalette?.trafo_cage.transparency,
        isTransparrent: true,
      };

    case "batterysetup":
      return {
        fill_color: colorPalette?.batterysetup.fill_color,
        line_color: colorPalette?.batterysetup.line_color,
        opacity: 1,
        isTransparrent: false,
      };
    case "lsr_room":
      return {
        fill_color: colorPalette?.lsr_room.fill_color,
        line_color: colorPalette?.lsr_room.line_color,
        opacity: colorPalette?.lsr_room.transparency,
        isTransparrent: true,
      };

    case "battery_room":
      return {
        fill_color: colorPalette?.lsr_room.fill_color,
        line_color: colorPalette?.lsr_room.line_color,
        opacity: colorPalette?.lsr_room.transparency,
        isTransparrent: true,
      };

    default:
      return {
        fill_color: "#000000",
        line_color: "#000000",
        opacity: 0,
        isTransparrent: true,
      };
  }
};
