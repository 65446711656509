import ResponseDto from "../../../interfaces/ResponseDto";
import { StsDto } from "../../../interfaces/foundry/typicals/StsDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetSts(id: number): Promise<StsDto> {
  return (await GetStss()).find((x) => x.id === id) as StsDto;
}

export async function GetStss(): Promise<StsDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let stss = store.stss;
  if (stss.length === 0) {
    stss = await GetStsDtos();
    store.setSTS(stss);
  }
  return stss;
}

export async function GetStsDtos(): Promise<StsDto[]> {
  try {
    const stss = (
      (await foundryApiClient.get<ResponseDto>("/api/general/sts")).data
        .results as StsDto[]
    ).map((item: StsDto) => {
      item = {
        ...item,
        type: item.display_name
      };
      return item;
    });
    return stss;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}