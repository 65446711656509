import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomInputLabel from "../../../../components/InputLabel/CustomInputLabel";
import { CloseIcon } from "../../../../icons/CloseIcon";
import { ProjectTypeEnum } from "../../../../interfaces/enums/ProjectTypeEnum";
import { SaveProject, UpdateProject } from "../../services/ProjectService";
import useProjectsStore from "../../state/projectsState";

interface CreateOrUpdateProjectViewProps {
  typeProject: ProjectTypeEnum;
  handleSave?: () => void;
  refreshProjects?: (refresh: boolean) => void;
}

export default function CreateOrUpdateProjectView({
  typeProject,
  handleSave,
  refreshProjects,
}: CreateOrUpdateProjectViewProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorText, setErrorText] = React.useState<string>("");
  const {
    currentProject,
    isProjectRenaming,
    isProjectSavingAs,
    isProjectViewOpen,
    setIsProjectViewOpen,
    setIsProjectSavingAs,
    setCurrentProject,
  } = useProjectsStore();

  const isChangingNameAvailable =
    currentProject?.externalId !== "" || isProjectRenaming;

  useEffect(() => {
    setErrorText("");
  }, [isProjectViewOpen]);

  async function handleSubmit() {
    try {
      if (!isProjectRenaming && handleSave) {
        await handleSave();
      }

      if (isProjectSavingAs) {
        currentProject.externalId = undefined;
      }

      if (!currentProject.externalId) {
        const externalId = await SaveProject(typeProject, currentProject);
        setCurrentProject({ ...currentProject, externalId: externalId });

        let url = "";
        switch (typeProject) {
          case ProjectTypeEnum.Analyser:
            url = "/analysers/nitrogen";
            break;
          case ProjectTypeEnum.HSA:
            url = "/configurators/stackedhousing";
            break;
        }

        navigate(`${url}/${externalId}`, {
          replace: true,
        });
      } else {
        await UpdateProject(typeProject, currentProject);
      }

      closeDialog();
      refreshCurrentProjects();
    } catch (ex) {
      setErrorText((ex as AxiosError)?.message);
    }
  }

  function refreshCurrentProjects() {
    if (refreshProjects) {
      refreshProjects(true);
    }
  }

  function closeDialog() {
    setIsProjectViewOpen(false);
    setIsProjectSavingAs(false);
  }

  if (!currentProject) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={isProjectViewOpen}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          paddingRight: "15px",
          paddingBottom: "5px",
          fontWeight: 700,
        }}
      >
        {currentProject.externalId === "" && currentProject.name === ""
          ? t("Create new project")
          : isProjectSavingAs
          ? t("SaveAsProjectTitle")
          : t("SaveProjectTitle")}

        <IconButton sx={{ ml: "auto" }} onClick={() => closeDialog()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "25px" }}>
        {isChangingNameAvailable && (
          <FormControl fullWidth variant="standard">
            <CustomInputLabel
              showInfoButton={false}
              labelText="Project name"
              tooltipText=""
              id=""
            />
            <TextField
              fullWidth
              error={errorText.length > 0}
              helperText={errorText}
              value={currentProject.name}
              style={{ marginTop: "15px", width: "400px" }}
              variant="standard"
              onChange={(e) => {
                setCurrentProject({
                  ...currentProject,
                  name: e.target.value,
                });
              }}
            />
          </FormControl>
        )}
        {!currentProject.externalId && !isProjectRenaming && (
          <div>{t("Save changes")}</div>
        )}
      </DialogContent>
      <DialogActions style={{ paddingRight: "20px" }}>
        <Button onClick={() => closeDialog()}>{t("Cancel")}</Button>
        <Button
          onClick={async () => {
            await handleSubmit();
          }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
