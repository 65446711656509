import * as React from "react";
import { useTranslation } from "react-i18next";
import useDatacenterGeneratorStore from "../../../../state/DatacenterState/datacenterGeneratorState";
import { Grid } from "@mui/material";
import { TypeObject } from "../../../../interfaces/enums/TypeObjectEnum";
import SummaryInfoDto from "../../../../interfaces/SummaryInfoDto";
import Scene from "../../../Scene/Scene";
import "./ExportedDatacentersControl.scss";
import { ExploreConfigStore } from "../../../../interfaces/exploreConfig";
import { DatacenterDto } from "../../../../interfaces/foundry/DatacenterDto";

interface ExportedDatacentersControlProps {
  datacenterConfigStore: ExploreConfigStore<DatacenterDto>;
}

export default function ExportedDatacentersControl({
  datacenterConfigStore,
}: ExportedDatacentersControlProps) {
  const summaryInfo: SummaryInfoDto[] = [
    { label: "IT Power", property: "true_power_in_w", unit: "MW" },
    { label: "Cooling type", property: "whitespace_cooling_type", unit: "" },
    {
      label: "Power/Whitespace",
      property: "whitespace_true_power_in_w",
      unit: "MW",
    },
  ];

  const { t } = useTranslation();
  const {
    generatedDatacentersIds,
    currentDatacenters,
    currentPageType,
    setGeneratedDatacentersIds,
  } = useDatacenterGeneratorStore();

  const generatedBuildingsState = datacenterConfigStore?.currentStore();

  React.useEffect(() => {
    if (generatedBuildingsState) {
      setGeneratedDatacentersIds(
        generatedBuildingsState.selectedIds.map((id) => ({
          id: id,
          count: generatedDatacentersIds.find((x) => x.id === id)?.count ?? 0,
        }))
      );
    }
  }, []);

  return (
    <div>
      {currentPageType === TypeObject.Export && (
        <div className="exported-datacenter-view">
          {generatedBuildingsState?.selectedIds.length ?? 0 > 0 ? (
            currentDatacenters.map((dc) => (
              <Grid item xl={2} key={dc.id}>
                {generatedBuildingsState && (
                  <Scene
                    currentState={generatedBuildingsState}
                    currentObject={dc}
                    summaryInfo={summaryInfo}
                    onHoverObject={() => {}} // TODO
                    onClickObject={() => {}} // TODO
                    isCandidate={true} // TODO
                    isInOriginalList={false}
                  />
                )}
              </Grid>
            ))
          ) : (
            <div className="empty-conf">{t("No Datacenters selected")}</div>
          )}
        </div>
      )}
    </div>
  );
}
