import { ChevronLeft } from "@mui/icons-material";
import { IconButton, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetAvailableAnalysers,
  GetAvailableCatalog,
  GetAvailableConfigurators,
  GetAvailableDatabases,
  GetAvailableDatacenterCatalog,
  GetAvailableDatacentersCatalogComponents,
  GetAvailableGenerators,
  GetAvailableHeijmansIntelligences,
  GetAvailableStackedHousingIntelligence,
} from "../../http/AvailableComponentsService";
import { AnalyserIcon } from "../../icons/AnalyserIcon";
import { CalculatorIcon } from "../../icons/CalculatorIcon";
import { CatalogIcon } from "../../icons/CatalogIcon";
import { DatabaseIcon } from "../../icons/DatabaseIcon";
import { HeijmansIntelligenceIcon } from "../../icons/HeijmansIntelligenceIcon";
import { HomeIcon } from "../../icons/HomeIcon";
import { ScrewDriverIcon } from "../../icons/ScrewDriverIcon";
import AvailableComponentDto from "../../interfaces/AvailableComponentDto";
import useUserStore from "../../modules/sharedLogic/state/userState";
import useAvailableComponentsStore from "../../state/availableComponentsState";
import useLayoutStore from "../../state/layoutState";
import ExpandableListItemButton from "../ExpandableListItemButton/ExpandableListItemButton";
import SearchBox from "../SearchBox/SearchBox";
import "./Sidebar.scss";

export default function Sidebar() {
  const { t } = useTranslation();
  const { appAbility } = useUserStore();
  const [isPermanent, setPermanent] = useState<boolean>(true);
  const { isSidebarOpen, setIsSidebarOpen } = useLayoutStore();
  const {
    availableConfigurators,
    availableDatabases,
    availableGenerators,
    availableAnalysers,
    availableCatalog,
    availableHeijmansIntelligence,
    availableStackedHousingHeijmansIntelligence,
    availableDatacenterCatalog,
    availableDatacenterCatalogComponents,
    setAvailableConfigurators,
    setAvailableDatabases,
    setAvailableGenerators,
    setAvailableAnalysers,
    setAvailableCatalog,
    setAvailableHeijmansIntelligence,
    setAvailableStackedHousingHeijmansIntelligence,
    setAvailableDatacenterCatalog,
    setAvailableDatacenterCatalogComponents,
  } = useAvailableComponentsStore();

  React.useEffect(() => {
    if (availableConfigurators.length === 0) {
      setAvailableConfigurators(GetAvailableConfigurators());
    }
    if (availableDatabases.length === 0) {
      setAvailableDatabases(GetAvailableDatabases());
    }
    if (availableAnalysers.length === 0) {
      setAvailableAnalysers(GetAvailableAnalysers());
    }
    if (availableCatalog.length === 0) {
      setAvailableCatalog(GetAvailableCatalog());
    }
    if (availableGenerators.length === 0) {
      setAvailableGenerators(GetAvailableGenerators());
    }
    if (availableHeijmansIntelligence.length === 0) {
      setAvailableHeijmansIntelligence(GetAvailableHeijmansIntelligences());
    }
    if (availableStackedHousingHeijmansIntelligence.length === 0) {
      setAvailableStackedHousingHeijmansIntelligence(
        GetAvailableStackedHousingIntelligence()
      );
    }
    if (availableDatacenterCatalog.length === 0) {
      setAvailableDatacenterCatalog(GetAvailableDatacenterCatalog());
    }
    if (availableDatacenterCatalogComponents.length === 0) {
      setAvailableDatacenterCatalogComponents(
        GetAvailableDatacentersCatalogComponents()
      );
    }
  }, []);

  useEffect(() => {
    function handleWindowWidthChange() {
      const windowWidth = window.innerWidth;
      const breakpointWidth = 600;
      const isSmallScreen = windowWidth < breakpointWidth;
      if (isSmallScreen && isPermanent) {
        setPermanent(false);
      } else if (!isSmallScreen && !isPermanent) {
        setPermanent(true);
      }
    }
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return () => {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  }, [isPermanent]);

  const mapAvailableComponentsToItems = (
    prefix: string,
    availableComponents: AvailableComponentDto[]
  ): Array<{
    iconComponent: React.ReactNode;
    title: string;
    to: string;
    underConstruction: boolean;
  }> => {
    return availableComponents
      .sort((a, b) => {
        if (a.underConstruction !== b.underConstruction) {
          return Number(a.underConstruction) - Number(b.underConstruction);
        } else {
          return a.title.localeCompare(b.title);
        }
      })
      .filter((generator) =>
        appAbility?.can(
          "read",
          `${prefix}.${generator.title.toLowerCase().replace(/\s/g, "")}` as any
        )
      )
      .map((availableComponent) => ({
        title: availableComponent.title,
        to: availableComponent.to,
        iconComponent: availableComponent.iconComponent,
        underConstruction: availableComponent.underConstruction,
        children: availableComponent.children,
      }));
  };

  return (
    <div className="sidebar">
      <Toolbar />
      <div className="sidebar-header">
        <div className="title sidebar-title">{t("Navigation")}</div>
        <IconButton
          style={{ zIndex: 100 }}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <ChevronLeft sx={{ fontSize: "2.5rem" }} />{" "}
        </IconButton>
      </div>

      <SearchBox />

      <hr className="sidebar-line" />

      <div className="sidebar-menu-items">
        <ExpandableListItemButton
          iconComponent={<HomeIcon className="icon" size={20} />}
          navigation="overview"
          title={"Overview"}
          items={[]}
        />

        {appAbility?.can("read", "configurators") && (
          <ExpandableListItemButton
            iconComponent={<CalculatorIcon className="icon" size={20} />}
            navigation="configurators"
            title={"Configurators"}
            items={mapAvailableComponentsToItems(
              "configurators",
              availableConfigurators
            )}
          />
        )}

        {appAbility?.can("read", "generators") && (
          <ExpandableListItemButton
            iconComponent={<ScrewDriverIcon className="icon" size={25} />}
            navigation="generators"
            title={"Generators"}
            items={mapAvailableComponentsToItems(
              "generators",
              availableGenerators
            )}
          />
        )}

        {appAbility?.can("read", "analysers") && (
          <ExpandableListItemButton
            iconComponent={<AnalyserIcon className="icon" size={22} />}
            navigation="analysers"
            title={"Analysers"}
            items={mapAvailableComponentsToItems(
              "analysers",
              availableAnalysers
            )}
          />
        )}

        {appAbility?.can("read", "catalog") && (
          <ExpandableListItemButton
            iconComponent={<CatalogIcon className="icon" size={22} />}
            navigation="catalog"
            title={"Catalog"}
            items={mapAvailableComponentsToItems("catalog", availableCatalog)}
          />
        )}

        {appAbility?.can("read", "databases") && (
          <ExpandableListItemButton
            iconComponent={<DatabaseIcon className="icon" size={22} />}
            navigation="databases"
            title={"Databases"}
            items={mapAvailableComponentsToItems(
              "databases",
              availableDatabases
            )}
          />
        )}

        {appAbility?.can("read", "heijmansintelligence") && (
          <ExpandableListItemButton
            iconComponent={
              <HeijmansIntelligenceIcon className="icon" size={22} />
            }
            navigation="heijmansintelligence"
            title={"Heijmans intelligence"}
            items={mapAvailableComponentsToItems(
              "heijmansintelligence",
              availableHeijmansIntelligence
            )}
          />
        )}
      </div>
    </div>
  );
}
