import { create } from "zustand";
import DrawedMarker, {
  GetEmptyDrawedMarker,
} from "../../interfaces/DrawedMarker";

export interface NitrogenInputPaneState {
  projectType: string;
  bvoProject?: number | undefined;
  annualNOxLimit?: number | undefined;
  annualNH3Limit?: number | undefined;
  annualCO2Limit?: number | undefined;
  projectObjectiveNOx?: number | undefined;
  projectObjectiveNH3?: number | undefined;
  projectObjectiveCO2?: number | undefined;
  projectResponsible: string;
  emissionsSpecialist: string;
  planner: string;
  projectAddress?: string | undefined;
  drawedMarker: DrawedMarker;
}

interface NitrogenInputPaneActions {
  setProjectType: (projectType: string) => void;
  setAnnualNOxLimit: (annualNOxLimit: number | undefined) => void;
  setAnnualNH3Limit: (annualNH3Limit: number | undefined) => void;
  setAnnualCO2Limit: (annualCO2Limit: number | undefined) => void;
  setProjectObjectiveNOx: (projectObjectiveNOx: number | undefined) => void;
  setProjectObjectiveNH3: (projectObjectiveNH3: number | undefined) => void;
  setProjectObjectiveCO2: (projectObjectiveCO2: number | undefined) => void;
  setProjectResponsible: (projectResponsible: string) => void;
  setEmissionsSpecialist: (emissionsSpecialist: string) => void;
  setPlanner: (planner: string) => void;
  setDrawedMarker: (drawedMarker: DrawedMarker) => void;
  setNitrogenInputPaneState(newState: NitrogenInputPaneState): void;
  setBvoProject: (bvoProject: number | undefined) => void;
  setProjectAddress: (projectAddress: string) => void;
  resetNitrogenInputPaneState: () => void;
}

const initialState: NitrogenInputPaneState = GetInitialState();

function GetInitialState(): NitrogenInputPaneState {
  return {
    annualCO2Limit: undefined,
    annualNH3Limit: undefined,
    annualNOxLimit: undefined,
    bvoProject: undefined,
    projectObjectiveCO2: undefined,
    projectObjectiveNH3: undefined,
    projectObjectiveNOx: undefined,
    projectType: "",
    projectResponsible: "",
    emissionsSpecialist: "",
    planner: "",
    projectAddress: undefined,
    drawedMarker: GetEmptyDrawedMarker(),
  };
}

const useNitrogenInputPaneStore = create<
  NitrogenInputPaneState & NitrogenInputPaneActions
>()((set) => ({
  ...initialState,
  setProjectType: (projectType) => set({ projectType: projectType }),
  setAnnualNOxLimit: (annualNOxLimit) =>
    set({ annualNOxLimit: annualNOxLimit }),
  setAnnualNH3Limit: (annualNH3Limit) =>
    set({ annualNH3Limit: annualNH3Limit }),
  setAnnualCO2Limit: (annualCO2Limit) =>
    set({ annualCO2Limit: annualCO2Limit }),
  setBvoProject: (bvoProject) => set({ bvoProject: bvoProject }),
  setProjectObjectiveNOx: (projectObjectiveNOx) =>
    set({ projectObjectiveNOx: projectObjectiveNOx }),
  setProjectObjectiveNH3: (projectObjectiveNH3) =>
    set({ projectObjectiveNH3: projectObjectiveNH3 }),
  setProjectObjectiveCO2: (projectObjectiveCO2) =>
    set({ projectObjectiveCO2: projectObjectiveCO2 }),
  setProjectResponsible: (projectResponsible) =>
    set({ projectResponsible: projectResponsible }),
  setEmissionsSpecialist: (emissionsSpecialist) =>
    set({ emissionsSpecialist: emissionsSpecialist }),
  setPlanner: (planner) => set({ planner: planner }),
  setDrawedMarker: (drawedMarker) => set({ drawedMarker: drawedMarker }),
  setNitrogenInputPaneState: (newState) => {
    set(newState);
  },
  setProjectAddress: (projectAddress) =>
    set({ projectAddress: projectAddress }),
  resetNitrogenInputPaneState: () => {
    set(GetInitialState());
  },
}));

export default useNitrogenInputPaneStore;
