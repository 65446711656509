import { Box, Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { config, useChain, useSpring, useSpringRef } from "react-spring";
import {
  getObjectsBuildingTypes,
  openDetailView,
} from "../../components/Scene/detailViewHelper";
import {
  TypeObject,
  TypeObjectLabel,
} from "../../interfaces/enums/TypeObjectEnum";
import SummaryInfoDto from "../../interfaces/SummaryInfoDto";
import ThermometerChart from "../../modules/Analysers/components/ThermometerChart/ThermometerChart";
import useDatacenterGeneratorStore from "../../state/DatacenterState/datacenterGeneratorState";
import {
  DTO,
  DTOWhithChart,
  ExploreActions,
  ExploreState,
  TypicalDTO,
} from "../../state/ExploreState/ExploreState";
import useNitrogenInputPaneStore from "../../state/NitrogenState/nitrogenInputPaneState";
import {
  renderBestFitRateInfoCardSection,
  renderCandidateSection,
  renderExportButtonSection,
  renderNotInOriginalListSection,
  renderSummaryViewSection,
} from "./renderSummaryHelpers";
import "./Scene.scss";

interface SceneProps<T extends DTO> {
  currentObject: T;
  currentState: ExploreState<T> & ExploreActions<T>;
  summaryInfo: SummaryInfoDto[];
  onClickObject?: (selectedObject: T) => void;
  onHoverObject?: (hoveredObject: T | null) => void;
  isCandidate: boolean;
  isInOriginalList: boolean;
}

export default function Scene<T extends DTO>({
  currentObject,
  currentState,
  summaryInfo,
  isCandidate,
  isInOriginalList,
  onClickObject,
}: SceneProps<T>) {
  const { t } = useTranslation();
  const { annualCO2Limit, annualNH3Limit, annualNOxLimit } =
    useNitrogenInputPaneStore();
  const { objects, setCurrentObject, setIsOpenedDetailedView } = currentState;

  const [isHovered, setIsHovered] = useState(false);
  const { currentPageType } = useDatacenterGeneratorStore();
  const checkboxAnimationRef = useSpringRef();
  const checkboxAnimationStyle = useSpring({
    backgroundColor: isCandidate ? "#003466" : "#fff",
    borderColor: isCandidate ? "#003466" : "#ddd",
    config: config.gentle,
    ref: checkboxAnimationRef,
  });
  const [checkmarkLength, setCheckmarkLength] = useState<number>(0);
  const checkmarkAnimationRef = useSpringRef();
  const checkmarkAnimationStyle = useSpring({
    x: isCandidate ? 0 : checkmarkLength,
    config: config.gentle,
    ref: checkmarkAnimationRef,
  });

  useChain(
    isCandidate
      ? [checkboxAnimationRef, checkmarkAnimationRef]
      : [checkmarkAnimationRef, checkboxAnimationRef],
    [0, 0.1]
  );
  function handleSelectingDatacenterComponent() {
    if (onClickObject) onClickObject(currentObject);
  }

  function removeFromCandidateList() {
    if (onClickObject) onClickObject(currentObject);
  }

  async function handleOpenDetailView() {
    await openDetailView(
      currentObject,
      setIsOpenedDetailedView,
      setCurrentObject as (newValue: DTO | null) => void
    );
  }

  if (!currentObject) {
    return <div></div>;
  }

  return (
    <>
      <Box
        component={"div"}
        className="scene-item"
        onDoubleClick={() => handleOpenDetailView()}
      >
        <Card
          className="scene-item-card"
          sx={{
            border: isCandidate ? "1px solid #003466 !important" : "",
          }}
        >
          <CardContent className="card-content">
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
            >
              {currentObject.renderType === "image" ? (
                <img
                  className="whitespace-image"
                  src={(currentObject as TypicalDTO).view2d_png}
                  alt="configuration"
                />
              ) : currentObject.renderType === "chart" ? (
                <ThermometerChart
                  data={[
                    {
                      label: "NOx",
                      barMin: Math.min(
                        ...(objects as DTOWhithChart[]).map(
                          (x) => x.totalNitrogenEmission
                        )
                      ),
                      barMax: Math.max(
                        ...(objects as DTOWhithChart[]).map(
                          (x) => x.totalNitrogenEmission
                        )
                      ),
                      amount: Number(
                        (
                          currentObject as DTOWhithChart
                        ).totalNitrogenEmission.toFixed(2)
                      ),
                      annualLimit: annualNOxLimit ?? 0,
                    },
                    {
                      label: "NH3",
                      barMin: Math.min(
                        ...(objects as DTOWhithChart[]).map(
                          (x) => x.totalAmmoniaEmission
                        )
                      ),
                      barMax: Math.max(
                        ...(objects as DTOWhithChart[]).map(
                          (x) => x.totalAmmoniaEmission
                        )
                      ),
                      amount: Number(
                        (
                          currentObject as DTOWhithChart
                        ).totalAmmoniaEmission.toFixed(2)
                      ),
                      annualLimit: annualNH3Limit ?? 0,
                    },
                    {
                      label: "CO2",
                      barMin: Math.min(
                        ...(objects as DTOWhithChart[]).map(
                          (x) => x.totalCarbonEmission
                        )
                      ),
                      barMax: Math.max(
                        ...(objects as DTOWhithChart[]).map(
                          (x) => x.totalCarbonEmission
                        )
                      ),
                      amount: Number(
                        (
                          currentObject as DTOWhithChart
                        ).totalCarbonEmission.toFixed(2)
                      ),
                      annualLimit: annualCO2Limit ?? 0,
                    },
                  ]}
                />
              ) : (
                <div>No specific rendering</div>
              )}
              <Grid item xs={12} sm>
                <Grid container>
                  <Grid
                    className="whitespace-title"
                    item
                    xs={8}
                    sx={{
                      fontSize: !isInOriginalList ? "xx-small !important" : "",
                    }}
                  >
                    {t(
                      TypeObjectLabel.get(
                        (currentObject as T as { typeObject: TypeObject })
                          ?.typeObject ?? 0
                      ) ?? ""
                    ) +
                      " " +
                      (currentObject as T as { id: number })?.id}
                    {getObjectsBuildingTypes(currentObject, isInOriginalList)}
                  </Grid>

                  {renderExportButtonSection({
                    currentPageType,
                    currentObject,
                    currentStore: currentState,
                  })}
                  {renderCandidateSection(
                    {
                      currentPageType,
                      isCandidate,
                      isInOriginalList,
                      handleSelectingDatacenterComponent,
                      checkboxAnimationStyle,
                      checkmarkLength,
                      checkmarkAnimationStyle,
                      t,
                    },
                    setCheckmarkLength
                  )}
                  {renderNotInOriginalListSection({
                    currentPageType,
                    isInOriginalList,
                    isHovered,
                    setIsHovered,
                    removeFromCandidateList,
                  })}
                  {renderSummaryViewSection({ currentObject, summaryInfo })}
                  <Grid item xs={12}>
                    <div className="mt10" />
                  </Grid>
                  {renderBestFitRateInfoCardSection({
                    currentPageType,
                    currentObject,
                    t,
                  })}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
