import { FormControl, FormHelperText, InputAdornment } from "@mui/material";
import i18next from "i18next";
import LocalizedNumericInput from "../LocalizedNumericInput/LocalizedNumericInput";

type NumberFieldProps = {
  value?: number | null | undefined;
  disabled?: boolean;
  error?: boolean;
  unit?: string;
  showBorderBottomStyle?: boolean;
  onChange?: (value: number | string | null) => void;
};

const NumberField = ({
  value,
  onChange,
  disabled = false,
  showBorderBottomStyle = false,
  error,
  unit,
}: NumberFieldProps) => {
  const handleChange = (newValue: number | string | null) => {
    if (typeof newValue === "string") {
      const parsedValue = parseFloat(newValue);
      if (!isNaN(parsedValue)) {
        newValue = parsedValue.toString();
      } else if (newValue === "0" || newValue === "") {
        newValue = newValue;
      } else {
        newValue = newValue.replace(/^0+(?=\d)/, "");
      }
    }
    onChange?.(newValue);
  };

  return (
    <FormControl fullWidth variant="standard" error={error}>
      <LocalizedNumericInput
        fullWidth
        error={error}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        variant="standard"
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          "& .MuiInputBase-root.Mui-disabled:before": {
            borderBottomStyle: showBorderBottomStyle ? "solid" : "none",
          },
          marginTop: disabled ? "2px" : "",
        }}
        InputProps={{
          endAdornment: unit ? (
            <InputAdornment sx={{ color: "black" }} position="end">
              {unit}
            </InputAdornment>
          ) : null,
        }}
        inputProps={
          typeof value === "number"
            ? {
                inputMode: "numeric",
                pattern: "[0-9]*",
                inputProps: { min: 0 },
              }
            : undefined
        }
      />
      {error && (
        <FormHelperText>{i18next.t("Field is required")}</FormHelperText>
      )}
    </FormControl>
  );
};

export default NumberField;
