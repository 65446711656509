import React, { useContext } from "react";

interface HoverContextType<T> {
  hovered: T | null;
  setHovered: React.Dispatch<React.SetStateAction<T | null>>;
}

const HoverContext = React.createContext<HoverContextType<any> | undefined>(
  undefined
);

export default HoverContext;

export function useHover<T>(): HoverContextType<T> {
  const context = useContext(HoverContext);
  if (!context) {
    throw new Error("useHover must be used within a HoverContextProvider");
  }
  return context;
}
