import ActivityDto from "../../interfaces/aggregate/ActivityDto";
import { ActivityRelationDto } from "../../interfaces/aggregate/ActivityRelationDto";
import { TrackException } from "../../logging/LoggingManager";
import { aggregateApiClient } from "../../modules/sharedLogic/services/AxiosClient";

const baseUrl = "/api/activity";
export async function GetActivities(
  categoryId?: number
): Promise<ActivityDto[]> {
  try {
    let activities = [] as ActivityDto[];
    let url = `${baseUrl}?pageSize=500`;
    if (categoryId) url += `&categoryId=${categoryId}`;

    await aggregateApiClient
      .get<ActivityDto[]>(url)
      .then((res) => (activities = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return activities;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function GetActivitiesByPhaseId(
  phaseId: number
): Promise<ActivityDto[]> {
  try {
    let activities = [] as ActivityDto[];
    let url = `${baseUrl}?pageSize=5000`;
    await aggregateApiClient
      .get<ActivityDto[]>(`${url}&phaseId=${phaseId}`)
      .then((res) => (activities = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return activities;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function GetActivityRelations(): Promise<ActivityRelationDto[]> {
  try {
    let activityRelations = [] as ActivityRelationDto[];
    await aggregateApiClient
      .get<ActivityRelationDto[]>("/api/activity-relation?pageSize=500")
      .then((res) => (activityRelations = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return activityRelations;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function SaveActivity(activity: ActivityDto): Promise<number> {
  try {
    let id = 0;
    await aggregateApiClient
      .post<number>("/api/activity", activity)
      .then((res) => (id = res.data))
      .catch((ex) => {
        TrackException(ex);
      });
    return id;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function SaveActivityRelation(
  activityRelation: ActivityRelationDto
): Promise<number> {
  try {
    let id = 0;
    await aggregateApiClient
      .post<number>("/api/activity-relation", activityRelation)
      .then((res) => (id = res.data))
      .catch((ex) => {
        TrackException(ex);
      });
    return id;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function UpdateActivity(
  activity: ActivityDto
): Promise<ActivityDto> {
  try {
    let updatedActivity = {} as ActivityDto;
    await aggregateApiClient
      .put<ActivityDto>(`/api/activity/${activity.id}`, activity)
      .then((res) => (updatedActivity = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return updatedActivity;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function DeleteActivity(activityId: string) {
  try {
    await aggregateApiClient
      .delete<ActivityDto>(`/api/activity/${activityId}`)
      .catch((ex) => {
        TrackException(ex);
      });
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function DeleteActivityRelation(activityRelationId: number) {
  try {
    await aggregateApiClient
      .delete<ActivityRelationDto>(
        `/api/activity-relation/${activityRelationId}`
      )
      .catch((ex) => {
        TrackException(ex);
      });
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
