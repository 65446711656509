import { SaveAs } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Save from "@mui/icons-material/Save";
import {
  Box,
  Collapse,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomPopover from "../../../../components/CustomPopover/CustomPopover";
import { ForgeApiUrl } from "../../../../config";
import { ShowError } from "../../../../http/NotificationService";
import { ProjectTypeEnum } from "../../../../interfaces/enums/ProjectTypeEnum";
import { TrackException } from "../../../../logging/LoggingManager";
import { GetMapLegandaItems } from "../../../../pages/Configurators/Datacenter/components/LegendaItems";
import colors from "../../../../styles/colors.module.scss";
import BaseExploreView from "../../../sharedLogic/components/BaseExploreView/BaseExploreView";
import InputPane from "../../../sharedLogic/components/InputPane/InputPane";
import Maps from "../../../sharedLogic/components/Maps/Maps";
import CreateOrUpdateProjectView from "../../../sharedLogic/components/Projects/CreateOrUpdateProjectView";
import TabPanel from "../../../sharedLogic/components/TabPanel/TabPanel";
import {
  ConfigurationDto,
  DimensionsDto,
  ProjectForgeDto,
} from "../../../sharedLogic/types/api";
import { MapType, TypeObject } from "../../../sharedLogic/types/enums";
import { ExploreConfigStore } from "../../../sharedLogic/types/ExploreConfig";
import { useGetApiHook } from "../../../sharedLogic/services/forge/useGridApiHook";
import { GetProject } from "../../../sharedLogic/services/ProjectService";
import useProjectsStore from "../../../sharedLogic/state/projectsState";
import { GetSpaceBuilding } from "../../services/SpaceBuildingService";
import { createHsaConfiguratorStore } from "../../state/hsaConfiguratorState";
import {
  SpaceBuildingDto,
  Layout,
  SupportedValueTypesDto,
} from "../../types/api";
import {
  convertMetersToMilimeters,
  convertMilimetersToMeters,
  convertSquareMetersToSquareMilimeters,
} from "../../../sharedLogic/utils/format";
import { ExportedBuildingView } from "../ExportedBuildingView/ExportedBuildingView";
import { useApartmentBuildingConfigStore } from "./ApartmentBuildingConfig";
import { useApartmentConfigStore } from "./ApartmentConfig";
import "./ConfiguratorPage.scss";
import { useExportedBuildingsViewConfigStore } from "./ExportedBuildingsViewConfig";
import { useMapViewConfigStore } from "./MapViewConfig";
import CalculationModel from "./CalculationModelConfig";

export default function ConfiguratorPage() {
  const { t } = useTranslation();
  const { heijmansBlue } = colors;
  const hsaConfiguratorStore = useMemo(() => createHsaConfiguratorStore(), []);
  const [isInputPaneOpen, setIsInputPaneOpen] = React.useState<boolean>(true);
  const [accessTypeOptions, setAccessTypeOptions] = React.useState<string[]>(
    []
  );
  const { currentProject, setIsProjectSavingAs, setIsProjectViewOpen } =
    useProjectsStore();

  const project = currentProject as ProjectForgeDto;

  const {
    buildings,
    drawedPlot,
    currentTabPageIndex,
    currentLayouts,
    currentApartmentBuildings,
    setBuildings,
    setCurrentTabPageIndex,
    setCurrentPageType,
    setCurrentLayouts,
    setCurrentApartmentBuildings,
    setDrawedPlot,
    setSelectedExportedBuildingsIds,
  } = hsaConfiguratorStore();

  const mapConfigStore = useMapViewConfigStore(
    accessTypeOptions,
    hsaConfiguratorStore
  );
  const apartmentConfigStore = useApartmentConfigStore(accessTypeOptions);
  const apartmentBuildingConfigStore =
    useApartmentBuildingConfigStore(accessTypeOptions);

  const {
    selectedIds: selectedBuildingIds,
    selectedExportedBuilingsIds: selectedExportedApartmentBuilingsIds,
    setSelectedIds: setSelectedBuildingIds,
    setSelectedExportedBuildingsIds: setSelectedExportedApartmentBuilingsIds,
  } = apartmentBuildingConfigStore.currentStore();

  const exportedBuildingsViewConfigStore = useExportedBuildingsViewConfigStore(
    currentApartmentBuildings,
    selectedExportedApartmentBuilingsIds,
    setCurrentApartmentBuildings,
    setSelectedExportedApartmentBuilingsIds
  );

  const isSaveAvailable =
    drawedPlot !== undefined &&
    drawedPlot.polygon !== undefined &&
    drawedPlot.polygon?.geometry !== undefined;

  const { data: supportedValuesDto } = useGetApiHook<SupportedValueTypesDto>({
    baseUrl: ForgeApiUrl,
    url: "api/v1/modules/supported-values/types",
  });

  useMemo(() => {
    if (!project || !project.configuration) return;

    const configuration = JSON.parse(project.configuration) as ConfigurationDto;

    setDrawedPlot(configuration.drawedPlot);
    setBuildings(configuration.currentBuildings ?? []);
    setSelectedExportedApartmentBuilingsIds(
      configuration.selectedExportedBuilingsIds ?? []
    );

    (async () => {
      try {
        const forgeProject = await GetProject<ProjectForgeDto>(project);
        setSelectedBuildingIds(forgeProject.buildingCodes ?? []);

        const mapViewInputPaneState = configuration.mapViewInputPaneState;
        const maxBuildingHeight = convertMilimetersToMeters(
          forgeProject.maxBuildingDimensions.zSizeInMm
        );
        if (maxBuildingHeight > 0) {
          mapViewInputPaneState.maxBuildingHeight = maxBuildingHeight;
        }
        mapConfigStore.setCurrentState(mapViewInputPaneState);
      } catch (ex) {
        TrackException(ex);
      }
    })();
  }, [project]);

  useMemo(() => {
    setSelectedExportedBuildingsIds(selectedExportedApartmentBuilingsIds);

    if (selectedExportedApartmentBuilingsIds.length === 0) {
      setCurrentApartmentBuildings([]);
      setCurrentLayouts([] as Layout[]);
      return;
    }

    const filteredBuildings = currentApartmentBuildings.filter((building) =>
      selectedExportedApartmentBuilingsIds.some(
        (selected) => selected.code === building.code
      )
    );
    const filteredLayouts = currentLayouts.filter((_, index) =>
      selectedExportedApartmentBuilingsIds.some(
        (selected) => selected.code === currentApartmentBuildings[index]?.code
      )
    );

    if (filteredBuildings.length !== currentApartmentBuildings.length) {
      setCurrentApartmentBuildings(filteredBuildings);
      setCurrentLayouts(filteredLayouts);
    }

    const missingBuildingCodes = selectedExportedApartmentBuilingsIds.filter(
      (selected) =>
        !filteredBuildings.find((existing) => existing.code === selected.code)
    );
    if (missingBuildingCodes.length === 0) {
      return;
    }

    (async () => {
      try {
        const newBuildings: SpaceBuildingDto[] = [];
        const newLayouts: Layout[] = [];

        for (const buildingId of missingBuildingCodes) {
          const building = await GetSpaceBuilding(buildingId.code);
          newBuildings.push(building);
          newLayouts.push(building.layout);
        }

        const allBuildings = [...filteredBuildings, ...newBuildings];
        const renamedBuildings = renameBuildingsByAccessType(allBuildings);

        setCurrentApartmentBuildings(renamedBuildings);
        setCurrentLayouts([...filteredLayouts, ...newLayouts]);
      } catch (ex) {
        ShowError(t("Failed to get buildings"));
      }
    })();
  }, [selectedExportedApartmentBuilingsIds]);

  useEffect(() => {
    if (supportedValuesDto) {
      setAccessTypeOptions(
        (supportedValuesDto as SupportedValueTypesDto).accessTypes
      );
    }
  }, [supportedValuesDto]);

  useEffect(() => {
    switch (currentTabPageIndex) {
      case 0:
        setCurrentPageType(TypeObject.MapView);
        return;
      case 1:
        setCurrentPageType(TypeObject.HSAApartment);
        return;
      case 2:
        setCurrentPageType(TypeObject.HSABuilding);
        return;
      case 3:
        setCurrentPageType(TypeObject.ExportView);
        return;
      default:
        setCurrentPageType(TypeObject.MapView);
    }
  }, [currentTabPageIndex, setCurrentPageType]);

  const renameBuildingsByAccessType = (
    buildings: SpaceBuildingDto[]
  ): SpaceBuildingDto[] => {
    const renamedBuildings = [...buildings];
    const accessTypeCounts: { [key: string]: number } = {};
    return renamedBuildings.map((building) => {
      const accessType = building.accessType;
      if (!accessTypeCounts[accessType]) {
        accessTypeCounts[accessType] = 0;
      }

      accessTypeCounts[accessType]++;
      const newName = `${t(accessType)}${accessTypeCounts[accessType]}`;
      return {
        ...building,
        name: newName,
      };
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabPageIndex(newValue);
  };

  const handleSave = async () => {
    const mapViewInputPaneState = mapConfigStore.getCurrentState();
    const state = {
      drawedPlot: drawedPlot,
      currentBuildings: buildings,
      selectedExportedBuilingsIds: selectedExportedApartmentBuilingsIds,
      mapViewInputPaneState: mapViewInputPaneState,
    } as ConfigurationDto;

    project.plotAreaInMm2 = convertSquareMetersToSquareMilimeters(
      drawedPlot?.lotSurface ?? 0
    );
    project.parkingSpacePercentage = 0;
    project.maxBuildingDimensions = {
      xSizeInMm: 0,
      ySizeInMm: 0,
      zSizeInMm: convertMetersToMilimeters(
        mapViewInputPaneState.maxBuildingHeight
      ),
    } as DimensionsDto;
    project.buildingCodes = selectedBuildingIds;
    project.configuration = JSON.stringify(state);
  };

  const tabConfigurations = [
    {
      index: 0,
      type: TypeObject.MapView,
      currentTypicalConfigStore: mapConfigStore,
      label: "Basic principles",
    },
    {
      index: 1,
      type: TypeObject.HSAApartment,
      currentTypicalConfigStore: apartmentConfigStore,
      label: "InputWindow",
    },
    {
      index: 2,
      type: TypeObject.HSABuilding,
      currentTypicalConfigStore: apartmentBuildingConfigStore,
      label: "InputWindow",
    },
    {
      index: 3,
      type: TypeObject.ExportView,
      currentTypicalConfigStore: exportedBuildingsViewConfigStore,
      label: "StackedHousing.SelectedBuildings",
    },
    {
      index: 4,
      type: TypeObject.HSACountingModel,
      currentTypicalConfigStore: null,
      label: "",
    },
  ];

  return (
    <div className="hsa-generator-page">
      <Box
        component={"div"}
        sx={{
          boxShadow: 3,
          display: "flex",
          paddingRight: "20px",
          alignItems: "center",
        }}
      >
        <Tabs
          sx={{ flexGrow: 1 }}
          value={currentTabPageIndex}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <Tab label={t("Basic principles")} />
          <Tab
            label={
              <Typography>
                {t("Preselection")}
                <br />
                {t("Apartments").toLocaleLowerCase()}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                {t("Select")} {t("Building").toLocaleLowerCase()}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                {t("Generate")} {t("Project").toLocaleLowerCase()}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                {t("CalculationModel")} {t("Project").toLocaleLowerCase()}
              </Typography>
            }
          />
        </Tabs>

        {currentProject.externalId && (
          <CustomPopover
            content={
              <IconButton
                disabled={!isSaveAvailable}
                onClick={() => setIsProjectSavingAs(true)}
              >
                <SaveAs
                  sx={{ fontSize: "2rem", color: heijmansBlue }}
                  className={isSaveAvailable ? "svg" : "save-icon-disabled"}
                />
              </IconButton>
            }
            popoverTitle={t("SaveAsProjectTitle")}
            popoverText={t("NitrogenPage.SaveAsProjectCaption")}
          ></CustomPopover>
        )}

        <CustomPopover
          content={
            <IconButton
              disabled={!isSaveAvailable}
              onClick={() => {
                setIsProjectViewOpen(true);
              }}
            >
              <Save
                sx={{ fontSize: "2rem", color: heijmansBlue }}
                className={isSaveAvailable ? "svg" : "save-icon-disabled"}
              />
            </IconButton>
          }
          popoverTitle={t("SaveProjectTitle")}
          popoverText={t("NitrogenPage.SaveProjectCaption")}
        ></CustomPopover>
      </Box>

      {!isInputPaneOpen && (
        <div className="hsa-input-pane-button">
          <IconButton
            sx={{ backgroundColor: "white", zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronRightIcon sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}

      {tabConfigurations.map((config) => (
        <TabPanel
          key={config.index}
          value={currentTabPageIndex}
          index={config.index}
        >
          {config.type !== TypeObject.HSACountingModel ? (
            <div className="hsa-view">
              <Collapse orientation="horizontal" in={isInputPaneOpen}>
                {
                  <InputPane
                    label={config.label}
                    isInputPaneOpen={isInputPaneOpen}
                    setIsInputPaneOpen={setIsInputPaneOpen}
                    currentTypicalConfigStore={
                      config.currentTypicalConfigStore as ExploreConfigStore<SpaceBuildingDto>
                    }
                  />
                }
              </Collapse>

              {config.type === TypeObject.HSAApartment && (
                <BaseExploreView
                  type={config.type}
                  currentTypicalConfigStore={
                    config.currentTypicalConfigStore as unknown as ExploreConfigStore<SpaceBuildingDto>
                  }
                />
              )}

              {config.type === TypeObject.HSABuilding && (
                <BaseExploreView
                  type={config.type}
                  currentTypicalConfigStore={
                    config.currentTypicalConfigStore as unknown as ExploreConfigStore<SpaceBuildingDto>
                  }
                />
              )}

              {config.type === TypeObject.MapView && (
                <Maps
                  mapType={MapType.CreatingPlot}
                  legendaItems={GetMapLegandaItems()}
                  showFloatingActionButton={true}
                  currentStore={hsaConfiguratorStore}
                />
              )}

              {config.type === TypeObject.ExportView && (
                <ExportedBuildingView currentStore={hsaConfiguratorStore} />
              )}
            </div>
          ) : (
            <div className="counting-model-container">
              <div className="counting-model">
                <CalculationModel
                  currentApartmentBuildings={currentApartmentBuildings}
                  selectedExportedBuilingsIds={
                    selectedExportedApartmentBuilingsIds
                  }
                />
              </div>
            </div>
          )}
        </TabPanel>
      ))}
      <CreateOrUpdateProjectView
        typeProject={ProjectTypeEnum.HSA}
        handleSave={handleSave}
      />
    </div>
  );
}
