export enum BuildingTypeEnum {
  Datacenter = 1,
  Whitespace = 2,
  LVR = 3,
}

export enum MapType {
  CreatingPlot = 0,
  GenerateBuilding = 1,
  CreatingMarker = 2,
}

export enum ProjectTypeEnum {
  Datacenter = 1,
  Analyser = 2,
  HSABuilding = 3,
}

export enum TypeObject {
  HSABuilding = 0,
  MapView = 1,
  ExportView = 2,
  HSAApartment = 3,
  HSACountingModel = 4,
}

export const TypeObjectLabel = new Map<number, string>([
  [TypeObject.HSABuilding, "Building"],
  [TypeObject.MapView, "MapView"],
  [TypeObject.ExportView, "ExportView"],
  [TypeObject.HSAApartment, "Apartment"],
]);
