import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { CombinationsResponseDto } from "../../interfaces/foundry/CombinationsResponseDto";
import { DistributesComponentDto } from "../../interfaces/foundry/typicals/DistributesComponentDto";
import { LowVoltageRoomDto } from "../../interfaces/foundry/typicals/LowVoltageRoomDto";
import { TrackException } from "../../logging/LoggingManager";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import { DownloadFile } from "../../modules/sharedLogic/utils/HelperFunctions";
import {
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";
import { FetchPaginatedData } from "./SharedFunctions";
import { GetBattery } from "./typicals/BatteryService";
import { GetCooling } from "./typicals/CoolingService";
import { GetDistributors } from "./typicals/DistributorService";
import { GetGenset } from "./typicals/GensetService";
import { GetRmu } from "./typicals/RmuService";
import { GetSts } from "./typicals/StsService";
import { GetTransformator } from "./typicals/TransformatorService";
import { GetUps } from "./typicals/UpsService";

export async function GetLowVoltageRooms(
  signal: AbortSignal,
  currentState?: ExploreState<LowVoltageRoomDto> &
    ExploreActions<LowVoltageRoomDto>,
  buildingType?: number
): Promise<LowVoltageRoomDto[]> {
  const url = `/api/general/lsrs/?layout_exists=True${
    buildingType ? `&building_types=${buildingType}` : ""
  }`;
  const allLowVoltageRooms = await FetchPaginatedData<LowVoltageRoomDto>(
    url,
    processLowVoltageRoomDto,
    buildingType,
    signal
  );
  currentState?.setObjectsTotalCount(allLowVoltageRooms.length);
  return allLowVoltageRooms;
}

export async function GetLowVoltageRoom(
  id: number
): Promise<LowVoltageRoomDto> {
  try {
    const response = await foundryApiClient.get<LowVoltageRoomDto>(
      `/api/general/lsrs/${id}/?detail_view=True/`
    );
    const lowVoltageRoom = processLowVoltageRoomDto(
      response.data,
      TypeObject.LowVoltageRoom
    );

    const rmu = await GetRmu(lowVoltageRoom.rmu_type);
    const ups = await GetUps(lowVoltageRoom.ups_type);
    const sts = await GetSts(lowVoltageRoom.sts_type);
    const battery = await GetBattery(lowVoltageRoom.battery_type as number);
    const transformator = await GetTransformator(
      lowVoltageRoom.transformator_type
    );
    const genset = await GetGenset(lowVoltageRoom.genset_type);
    const cooling = await GetCooling(lowVoltageRoom.cooling_type);
    const distributors = await GetDistributors();

    return {
      ...lowVoltageRoom,
      sts: sts
        ? { ...sts, type: sts.display_name ?? "", amount: 1 }
        : undefined,
      ups: ups
        ? {
            ...ups,
            type: ups.supplier_component_type,
            amount: lowVoltageRoom.ups_amount,
            power_in_w: ups.power_in_kva,
          }
        : undefined,
      battery: battery
        ? {
            ...battery,
            type: battery.type,
            amount: lowVoltageRoom.battery_amount,
            power_in_w: battery.power_in_kw_5_min,
          }
        : undefined,
      transformator: transformator
        ? {
            ...transformator,
            type: transformator.display_name,
            amount: lowVoltageRoom.transformator_amount,
            power_in_w: transformator.power_in_kva,
          }
        : undefined,
      cooling: cooling
        ? {
            ...cooling,
            type: cooling.cooling_type,
            amount: lowVoltageRoom.cooling_amount,
            power_in_w: cooling.power_in_w,
          }
        : undefined,
      genset: genset
        ? {
            ...genset,
            type: genset.display_name,
            amount: 1,
            power_in_w: genset.power_in_w,
          }
        : undefined,
      rmu: rmu
        ? { ...rmu, type: rmu.supplier_component_type, amount: 1 }
        : undefined,
      distributors: {
        distributors: distributors,
        type: "Distributors",
        lvv_input_amount: lowVoltageRoom.lvv_input_amount,
        lvv_output_amount: lowVoltageRoom.lvv_output_amount,
        lvv_big_input_amount: lowVoltageRoom.lvv_big_input_amount,
      } as DistributesComponentDto,
    } as LowVoltageRoomDto;
  } catch (ex) {
    TrackException(ex);
    return {} as LowVoltageRoomDto;
  }
}

export async function UpdateLVRsBuildingTypes(
  lsrId: number,
  buildingTypesIds: number[]
): Promise<LowVoltageRoomDto> {
  try {
    const response = await foundryApiClient.patch<LowVoltageRoomDto>(
      `/api/general/lsrs/${lsrId}/?detail_view=True/`,
      { building_types: buildingTypesIds }
    );

    return processLowVoltageRoomDto(
      response.data,
      TypeObject.LowVoltageRoomTooling
    );
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

function processLowVoltageRoomDto(
  lowVoltageRoom: LowVoltageRoomDto,
  buildingType: TypeObject
): LowVoltageRoomDto {
  return {
    ...lowVoltageRoom,
    total_power_in_w: lowVoltageRoom.total_power_in_w / 1000000,
    emergency_power_in_w: lowVoltageRoom.emergency_power_in_w / 1000000,
    it_power_in_w: lowVoltageRoom.it_power_in_w / 1000000,
    mech_power_in_w: lowVoltageRoom.mech_power_in_w / 1000000,
    area: lowVoltageRoom.area / 1000000,
    length: lowVoltageRoom.length / 1000,
    width: lowVoltageRoom.width / 1000,
    height: lowVoltageRoom.height / 1000,
    volume: lowVoltageRoom.volume / 1000000000,
    typeObject: buildingType
      ? TypeObject.LowVoltageRoom
      : TypeObject.LowVoltageRoomTooling,
    renderType: "image",
  };
}

export async function GetLVRGenerationCombinations(): Promise<CombinationsResponseDto> {
  try {
    const response = await foundryApiClient.get<CombinationsResponseDto>(
      "/api/general/lsr_combinations"
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function StartGenerationLVRs() {
  try {
    await foundryApiClient.post("/api/general/lsr_combinations");
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function ExportLVRCostDetails(lowVoltageRoomId: number) {
  try {
    const response = await foundryApiClient.post<string>(
      "/api/general/calculate-lsr-costs-details/",
      { lsr_id: lowVoltageRoomId }
    );

    DownloadFile(
      JSON.stringify(response.data),
      "application/json",
      "cost_details",
      "json"
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
  }
}

export async function ExportLVRCO2Details(lowVoltageRoomId: number) {
  try {
    const response = await foundryApiClient.post<string>(
      "/api/general/calculate-lsr-carbon-emission-details/",
      { lsr_id: lowVoltageRoomId }
    );

    DownloadFile(
      JSON.stringify(response.data),
      "application/json",
      "co2_details",
      "json"
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
  }
}
