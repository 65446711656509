import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type CustomTextFieldProps = {
  value: string;
  disabled: boolean;
  error?: boolean;
  unit?: string;
  onChange?: (value: string) => void;
};

const CustomTextField = React.memo(
  ({ value, onChange, disabled, error, unit }: CustomTextFieldProps) => {
    const { t } = useTranslation();
    const [localValue, setLocalValue] = useState(
      value !== null ? t(value) : ""
    );

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setLocalValue(newValue);
        if (onChange) onChange(newValue);
      },
      [onChange]
    );

    useEffect(() => {
      setLocalValue(value !== null ? t(value) : "");
    }, [value]);

    return (
      <FormControl fullWidth variant="standard" error={error}>
        <MuiTextField
          error={error}
          disabled={disabled}
          value={localValue}
          onChange={handleChange}
          variant="standard"
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
            "& .MuiInputBase-root.Mui-disabled:before": {
              borderBottomStyle: "none",
            },
            marginTop: disabled ? "2px" : "",
          }}
          InputProps={{
            endAdornment: unit ? (
              <InputAdornment sx={{ color: "black" }} position="end">
                {unit}
              </InputAdornment>
            ) : null,
          }}
        />
        {error && (
          <FormHelperText>{i18next.t("Field is required")}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default CustomTextField;
