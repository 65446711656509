import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import { MoveIcon } from "../../../../icons/MoveIcon";
import { RotateIcon } from "../../../../icons/RotateIcon";
import Maps from "../../../sharedLogic/components/Maps/Maps";
import { MapType } from "../../../sharedLogic/types/enums";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
} from "../../../sharedLogic/state/baseConfiguratorState";
import "./ExportedBuildingView.scss";

interface ExportedBuildingViewProps {
  currentStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >;
}

export function ExportedBuildingView({
  currentStore,
}: ExportedBuildingViewProps) {
  const { setDrawControlMode } = currentStore();

  const [view, setView] = useState("move");
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };

  useEffect(() => {
    setDrawControlMode("");
  }, []);

  return (
    <div className="generate-building-view">
      <div className="rotate-buttons">
        <ToggleButtonGroup
          sx={{ backgroundColor: "white" }}
          orientation="vertical"
          value={view}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton
            value="move"
            aria-label="move"
            onClick={() => setDrawControlMode("simple_select")}
          >
            <MoveIcon className="custom-icon" size={15} />
          </ToggleButton>
          <ToggleButton
            value="rotate"
            aria-label="rotate"
            onClick={() => setDrawControlMode("rotate_mode")}
          >
            <RotateIcon className="custom-icon" size={15} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="map-detail-view">
        <div className="map-view">
          <Maps
            mapType={MapType.GenerateBuilding}
            currentStore={currentStore}
          />
        </div>
      </div>
    </div>
  );
}
