import ResponseDto from "../../interfaces/ResponseDto";
import { BuildingTypeDto } from "../../interfaces/foundry/BuildingTypeDto";
import { TrackException } from "../../logging/LoggingManager";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import useGeneralDatacenterObjectsStore from "../../state/DatacenterState/generalDatacenterObjectsState";

export async function GetBuildingTypesOptions(): Promise<BuildingTypeDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let buildingTypeDtoOptions = store.buildingTypeDtoOptions;
  if (buildingTypeDtoOptions.length === 0) {
    buildingTypeDtoOptions = await GetBuildingTypesOptionsDtos();
    store.setBuildingTypeDtoOptions(buildingTypeDtoOptions);
  }
  return buildingTypeDtoOptions;
}

async function GetBuildingTypesOptionsDtos(): Promise<BuildingTypeDto[]> {
  try {
    const response = await foundryApiClient.get<ResponseDto>(
      "/api/core/building_types/"
    );
    return [
      ...(response.data.results as BuildingTypeDto[]),
      { id: -1, name: "Undefined" },
    ];
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
