import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StoreApi, UseBoundStore } from "zustand";
import SliderWithMarks from "../../../../components/SliderWithMarks/SliderWithMarks";
import MapViewInputPane from "../../../sharedLogic/components/InputPane/MapView/MapViewInputPane";
import BooleanField from "../../../sharedLogic/components/PropertySectionView/BooleanField";
import LabeledField from "../../../sharedLogic/components/PropertySectionView/LabeledField";
import NumberField from "../../../sharedLogic/components/PropertySectionView/NumberField";
import SelectField from "../../../sharedLogic/components/PropertySectionView/SelectField";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
} from "../../../sharedLogic/state/baseConfiguratorState";
import { createExploreStore } from "../../../sharedLogic/state/ExploreState/ExploreState";
import { SpaceBuildingDto } from "../../types/api";
import { SpaceType } from "../../types/enums";
import { getColor } from "../../utils/colors";
import UnitMix from "../UnitMix/UnitMix";
import { HsaConfiguratorMapViewInputPaneState } from "../../state/hsaConfiguratorMapViewInputPaneState";
import CustomInputLabel from "../../../../components/InputLabel/CustomInputLabel";
import { UnitType } from "../../../sharedLogic/types/UnitType";

export const useMapViewConfigStore = (
  accessTypes: string[],
  configuratorStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >
) => {
  const { t } = useTranslation();
  const currentStore = useMemo(
    () => createExploreStore<SpaceBuildingDto>(),
    []
  );
  const [maxBuildingHeight, setMaxBuildingHeight] = React.useState<
    number | null | undefined
  >();
  const [maxGutterHeight, setMaxGutterHeight] = React.useState<
    number | null | undefined
  >();
  const [carPerHouse, setCarPerHouse] = React.useState<
    number | null | undefined
  >();
  const [parkingCovered, setParkingCovered] = React.useState<boolean>(false);
  const [parkingType, setParkingType] = React.useState<string>("");
  const [accessTypesOptions, setAccessTypeOptions] = React.useState<string[]>(
    []
  );
  const [numberOfAppartments, setNumberOfAppartments] = React.useState<
    number[]
  >([0, 250]);

  const [units, setUnits] = React.useState<UnitType[]>([
    {
      name: SpaceType.Appartment1K,
      color: getColor(SpaceType.Appartment1K),
      minValue: 0,
      maxValue: 100,
      value: [0, 100],
    },
    {
      name: SpaceType.Appartment2K,
      color: getColor(SpaceType.Appartment2K),
      minValue: 0,
      maxValue: 100,
      value: [0, 100],
    },
    {
      name: SpaceType.Appartment3K,
      color: getColor(SpaceType.Appartment3K),
      minValue: 0,
      maxValue: 100,
      value: [0, 100],
    },
    {
      name: SpaceType.Appartment4K,
      color: getColor(SpaceType.Appartment4K),
      minValue: 0,
      maxValue: 100,
      value: [0, 100],
    },
  ] as UnitType[]);

  const setCurrentState = (
    currentState: HsaConfiguratorMapViewInputPaneState
  ) => {
    setMaxBuildingHeight(currentState.maxBuildingHeight);
    setMaxGutterHeight(currentState.maxGutterHeight);
    setCarPerHouse(currentState.carPerHouse);
    setParkingCovered(currentState.parkingCovered);
    setParkingType(currentState.parkingType);
    setAccessTypeOptions(currentState.accessTypesOptions);
    setNumberOfAppartments(currentState.numberOfAppartments);
    setUnits(currentState.units);
  };

  const getCurrentState = (): HsaConfiguratorMapViewInputPaneState => {
    return {
      maxBuildingHeight,
      maxGutterHeight,
      carPerHouse,
      parkingCovered,
      parkingType,
      accessTypesOptions,
      numberOfAppartments,
      units,
    } as HsaConfiguratorMapViewInputPaneState;
  };

  const inputPaneContent = () => (
    <>
      <MapViewInputPane currentStore={configuratorStore} />
      <LabeledField
        className="mt10"
        labelId="max-building-height-label"
        labelText={t("MapViewPage.MaxBuildingHeight")}
        tooltipText={t("MapViewPage.MaxBuildingHeightTooltip")}
      >
        <NumberField
          unit="m"
          value={maxBuildingHeight}
          onChange={(value) =>
            setMaxBuildingHeight(typeof value === "number" ? value : null)
          }
        />
      </LabeledField>
      <LabeledField
        className="mt10"
        labelId="total-gutter-height-label"
        labelText={t("MapViewPage.MaxGutterHeight")}
        tooltipText={t("MapViewPage.MaxGutterHeightTooltip")}
      >
        <NumberField
          unit="m"
          value={maxGutterHeight}
          onChange={(value) =>
            setMaxGutterHeight(typeof value === "number" ? value : null)
          }
        />
      </LabeledField>
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">
        {t("MapViewPage.ProjectRequirements")}
      </div>
      <LabeledField
        labelId="parking-standard-label"
        labelText={t("MapViewPage.ParkingStandard")}
        tooltipText={t("MapViewPage.ParkingStandardTooltip")}
      >
        <NumberField
          unit={t("MapViewPage.CarPerHouse")}
          value={carPerHouse}
          onChange={(value) =>
            setCarPerHouse(typeof value === "number" ? value : null)
          }
        />
      </LabeledField>
      <LabeledField
        className="mt10"
        labelId="parking-covered-label"
        labelText={t("MapViewPage.ParkingCovered")}
        tooltipText={t("MapViewPage.ParkingCoveredTooltip")}
      >
        <BooleanField
          value={parkingCovered}
          onChange={(value) => setParkingCovered(value)}
        />
      </LabeledField>
      <LabeledField
        className="mt10"
        labelId="type-parking-label"
        labelText={t("MapViewPage.ParkingType")}
        tooltipText={t("MapViewPage.ParkingTypeTooltip")}
      >
        <SelectField
          value={parkingType}
          options={[t("StackedHousing.Garage"), t("StackedHousing.NonPublic")]}
          onChange={(value) => setParkingType(value)}
        />
      </LabeledField>
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">
        {`${t("Building")} ${t("Basic principles").toLocaleLowerCase()}`}
      </div>
      <LabeledField
        labelId="type-parking-label"
        labelText={t("StackedHousing.AccessibilityType")}
        tooltipText={""}
      >
        <SelectField
          multiple={true}
          options={accessTypes}
          valueMultiple={accessTypesOptions}
          onChangeMultiple={(value) => setAccessTypeOptions(value)}
        />
      </LabeledField>

      <LabeledField
        className="mt10"
        labelId="number-of-appartments-label"
        labelText={t("StackedHousing.NumberOfAppartments")}
        tooltipText={""}
      >
        <div className="p10">
          <SliderWithMarks
            value={numberOfAppartments}
            setValue={(value) => {
              setNumberOfAppartments(value);
            }}
            min={0}
            max={250}
          />
        </div>
      </LabeledField>

      <div className="mt20 mb40">
        <CustomInputLabel
          id="unit-mix-label"
          showInfoButton={true}
          labelText={`${t("Unit mix")} ${t("Building").toLowerCase()}`}
          tooltipText={""}
        />
        <UnitMix units={units} setUnits={setUnits} />
      </div>
    </>
  );

  return {
    currentStore,
    inputPaneContent,
    setCurrentState,
    getCurrentState,
  };
};
