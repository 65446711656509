import { create } from "zustand";
import { ObjectTypeDto } from "../../interfaces/aggregate/ObjectTypeDto";


interface ObjectTypesState {
  objectTypes: ObjectTypeDto[];
}

interface ObjectTypesActions {
  setObjectTypes: (value: ObjectTypeDto[]) => void;
}

const useObjectTypeStore = create<ObjectTypesState & ObjectTypesActions>()(
  (set) => ({
    objectTypes: [],
    setObjectTypes: (value) => set({ objectTypes: value }),
  })
);
export default useObjectTypeStore;
