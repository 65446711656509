import { ChevronLeft } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchBox from "../../../../components/SearchBox/SearchBox";
import { SettingsIcon } from "../../../../icons/SettingsIcon";
import { AggregatePageEnum } from "../../../../interfaces/aggregate/enums/AggregatePageEnum";
import { Subjects } from "../../../../modules/sharedLogic/authentication/PermissionsManager";
import useUserStore from "../../../../modules/sharedLogic/state/userState";
import useDatabaseStore from "../../../../state/DatabaseState/databaseState";
import { ConvertToTreeNode } from "../../../../utils/TreeNodeManager";
import CustomSimpleTreeView from "../CustomSimpleTreeView/CustomSimpleTreeView";
import "./NavigationSidebar.scss";

export default function NavigationSidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { appAbility } = useUserStore();
  const navigationSideBar = useRef<HTMLDivElement>(null);
  const {
    currentPage,
    categories,
    isInputPaneOpen,
    treeNodeData,
    setExpandedNodes,
    setTreeNodeData,
    setIsInputPaneOpen,
  } = useDatabaseStore();

  useEffect(() => {
    if (categories.length !== 0) {
      setTreeNodeData(ConvertToTreeNode(categories, null));
    }
  }, [categories, currentPage, setTreeNodeData]);

  useEffect(() => {
    if (treeNodeData.length > 0) {
      setExpandedNodes(categories.map((x) => x.id.toString()));
    }
  }, [treeNodeData, categories, setExpandedNodes]);

  useEffect(() => {
    if (navigationSideBar.current) {
      navigationSideBar.current.style.setProperty(
        "--offset",
        currentPage === AggregatePageEnum.Activities ||
          currentPage === AggregatePageEnum.ProductionKeyFigure
          ? "140px"
          : "65px"
      );
    }
  }, [navigationSideBar, currentPage]);

  const pagePermissions = {
    [AggregatePageEnum.Activities]: "databases.activities",
    [AggregatePageEnum.ProductionKeyFigure]: "databases.productionkeyfigures",
    [AggregatePageEnum.CostKeyFigure]: "databases.costskeyfigures",
    [AggregatePageEnum.EquipmentKeyFigure]: "databases.equipmentkeyfigures",
  };

  return (
    <div ref={navigationSideBar} className="navigation-sidebar">
      {isInputPaneOpen && (
        <div className="navigation-sidebar-button">
          <IconButton
            style={{ zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronLeft sx={{ fontSize: "2.5rem" }} />
          </IconButton>
        </div>
      )}

      <div className="navigation-sidebar-content">
        <span className="title ml20">{t("Categories")}</span>

        <SearchBox />
        <CustomSimpleTreeView customContent={false} />
      </div>

      {appAbility?.can("manage", pagePermissions[currentPage] as Subjects) && (
        <div className="navigation-sidebar-footer">
          <hr className="navigation-sidebar-footer-line" />
          <div
            className="navigation-sidebar-footer-settings"
            onClick={() => {
              navigate("settings");
            }}
          >
            <SettingsIcon size={25} />
            <div className="titel-black ml10">{t("Database settings")}</div>
          </div>
        </div>
      )}
    </div>
  );
}
