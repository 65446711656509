import { useTranslation } from "react-i18next";
import { ValidationError } from "../../../HSA/state/moduleDetailsState";
import { DetailedInfo } from "../../types/InfoField";
import PropertySectionView from "../PropertySectionView/PropertySectionView";
import "./DetailedViewSidebar.scss";

interface DetailedViewSidebarProps<T> {
  data: T;
  isNew?: boolean;
  editing?: boolean;
  detailedInfo: DetailedInfo[];
  validationErrors?: ValidationError[];
  onPropertyChange?: (path: string, newValue: any) => void;
}

export default function DetailedViewSidebar<T>({
  data,
  isNew = false,
  editing = false,
  detailedInfo,
  validationErrors,
  onPropertyChange,
}: DetailedViewSidebarProps<T>) {
  const { t } = useTranslation();

  return (
    <div className="sidebar-details">
      {detailedInfo.map((section, index) => (
        <div className="property-section">
          <PropertySectionView
            key={index}
            sectionTitle={t(section.category)}
            fields={section.fields}
            data={data}
            isNew={isNew}
            editing={editing}
            onPropertyChange={onPropertyChange}
            validationErrors={validationErrors ?? []}
          />
        </div>
      ))}
    </div>
  );
}
