import { ChevronLeft } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { ExploreConfigStore } from "../../interfaces/exploreConfig";
import { DatacenterDto } from "../../interfaces/foundry/DatacenterDto";
import WhitespaceDto from "../../interfaces/foundry/WhitespaceDto";
import { LowVoltageRoomDto } from "../../interfaces/foundry/typicals/LowVoltageRoomDto";
import { ScenarioDto } from "../../modules/Analysers/types/api";
import useDatacenterGeneratorStore from "../../state/DatacenterState/datacenterGeneratorState";
import ExpandMore from "../ExpandMore/ExpandMore";
import NitrogenPrinciplesControl from "./Analysers/NitrogenPrinciplesControl";
import BatteryControl from "./Components/Battery/BatteryControl";
import RackControl from "./Components/Rack/RackControl";
import TransformatorControl from "./Components/Transformator/TransformatorControl";
import UpsControl from "./Components/Ups/UpsControl";
import BasicPrinciplesControl from "./DatacenterGenerator/BasicPrinciples/BasicPrinciplesControl";
import DatacenterControl from "./DatacenterGenerator/Datacenter/DatacenterControl";
import ExportedDatacentersControl from "./DatacenterGenerator/ExportedDatacenters/ExportedDatacentersControl";
import LowVoltageRoomControl from "./DatacenterGenerator/LowVoltageRoom/LowVoltageRoomControl";
import WhitespaceControl from "./DatacenterGenerator/Whitespace/WhitespaceControl";
import FilterControl from "./FilterControl";
import "./InputPane.scss";

interface InputPaneProps {
  datacenterConfigStore?: ExploreConfigStore<DatacenterDto>;
  whitespaceConfigStore?: ExploreConfigStore<WhitespaceDto>;
  lowVoltageRoomConfigStore?: ExploreConfigStore<LowVoltageRoomDto>;
  nitrogenAnalyserConfigStore?: ExploreConfigStore<ScenarioDto>;
}

export default function InputPane({
  datacenterConfigStore,
  whitespaceConfigStore,
  lowVoltageRoomConfigStore,
  nitrogenAnalyserConfigStore,
}: InputPaneProps) {
  const { t } = useTranslation();
  const { isInputPaneOpen, currentPageType, setIsInputPaneOpen } =
    useDatacenterGeneratorStore();
  const [expanded, setExpanded] = React.useState<boolean>(true);

  React.useEffect(() => {
    setExpanded(
      currentPageType === TypeObject.MapView ||
        currentPageType === TypeObject.Export
    );
  }, [currentPageType]);

  const renderDatacenterContent = () => {
    return (
      <div className="input-pane-datacenter-content">
        {currentPageType === TypeObject.MapView && (
          <div className="basic-principles-control">
            <div className="title input-pane-title">
              {t("Basic principles")}
            </div>
            <BasicPrinciplesControl />
          </div>
        )}

        {currentPageType === TypeObject.Export && (
          <div className="basic-principles-control">
            <div className="title input-pane-title">
              {t("Selected datacenters")}
            </div>

            <ExportedDatacentersControl
              datacenterConfigStore={datacenterConfigStore!}
            />
          </div>
        )}

        {currentPageType === TypeObject.NitrogenAnalyser && (
          <div className="basic-principles-control">
            <div className="title input-pane-title">
              {t("Basic principles")}
            </div>
            <NitrogenPrinciplesControl
              nitrogenAnalyserConfigStore={nitrogenAnalyserConfigStore!}
            />
          </div>
        )}

        {currentPageType !== TypeObject.MapView &&
          currentPageType !== TypeObject.Export &&
          currentPageType !== TypeObject.NitrogenAnalyser && (
            <div className="pt20">
              <FilterControl
                datacenterConfigStore={datacenterConfigStore}
                whitespaceConfigStore={whitespaceConfigStore}
                lowVoltageRoomConfigStore={lowVoltageRoomConfigStore}
              />

              {(currentPageType === TypeObject.Whitespace ||
                currentPageType === TypeObject.WhitespaceTooling) && (
                <WhitespaceControl />
              )}
              {(currentPageType === TypeObject.LowVoltageRoom ||
                currentPageType === TypeObject.LowVoltageRoomTooling) && (
                <LowVoltageRoomControl />
              )}

              {currentPageType === TypeObject.Datacenter && (
                <DatacenterControl />
              )}

              {currentPageType !== TypeObject.WhitespaceTooling &&
                currentPageType !== TypeObject.LowVoltageRoomTooling && (
                  <div>
                    <div className="basic-principles-header">
                      <ExpandMore
                        sx={{ marginLeft: "5px" }}
                        expand={expanded}
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                      <div className="title">{t("Basic principles")}</div>
                    </div>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <BasicPrinciplesControl />
                    </Collapse>
                  </div>
                )}
            </div>
          )}
      </div>
    );
  };

  function isCurrentPageDatacenterGenerator(): boolean {
    return (
      currentPageType === TypeObject.MapView ||
      currentPageType === TypeObject.Whitespace ||
      currentPageType === TypeObject.WhitespaceTooling ||
      currentPageType === TypeObject.LowVoltageRoom ||
      currentPageType === TypeObject.LowVoltageRoomTooling ||
      currentPageType === TypeObject.Datacenter ||
      currentPageType === TypeObject.Export ||
      currentPageType === TypeObject.NitrogenAnalyser
    );
  }

  return (
    <div className="input-pane">
      {isInputPaneOpen && (
        <div className="input-pane-button">
          <IconButton
            style={{ zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronLeft sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}

      {isCurrentPageDatacenterGenerator() && renderDatacenterContent()}

      {(currentPageType === TypeObject.Rack ||
        currentPageType === TypeObject.Transformator ||
        currentPageType === TypeObject.Ups ||
        currentPageType === TypeObject.Battery) && (
        <div className="input-pane-components-content">
          <div className="input-pane-header mb20">
            <div className="title">{t("Filtering")}</div>
          </div>
          {currentPageType === TypeObject.Rack && <RackControl />}
          {currentPageType === TypeObject.Transformator && (
            <TransformatorControl />
          )}
          {currentPageType === TypeObject.Ups && <UpsControl />}
          {currentPageType === TypeObject.Battery && <BatteryControl />}
        </div>
      )}
    </div>
  );
}
