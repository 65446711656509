import "./Scene.scss";
import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import SummaryInfoDto from "../../types/InfoField";
import { formatValue } from "../../utils/format";

interface SummaryViewProps<T extends Record<string, any>> {
  currentObject: T;
  summaryInfo: SummaryInfoDto[];
}

const getNestedValue = <T extends Record<string, any>>(
  obj: T,
  path: string
): any => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export default function SummaryView<T extends Record<string, any>>({
  currentObject,
  summaryInfo,
}: SummaryViewProps<T>) {
  const { t } = useTranslation();

  const renderValue = (value: any, unit: string) => {
    if (typeof value === "number" || !isNaN(Number(value))) {
      const numericValue = Number(value);
      return `${formatValue(numericValue, unit)} ${unit}`.trim();
    }
    if (typeof value === "string") {
      return t(value);
    }
    return "";
  };

  return (
    <>
      {summaryInfo.map((info) => {
        const value = getNestedValue(currentObject, info.property);

        return (
          <Grid container key={info.property as string}>
            <Grid item xs={4}>
              <div className="sub-titel truncated-div">{t(info.label)}:</div>
            </Grid>
            <Grid item xs={8}>
              <div className="sub-titel">
                {renderValue(value, info.unit ?? "")}
              </div>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
