import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { AxiosError } from "axios";
import i18n from "i18next";
import { ShowError } from "../http/NotificationService";
import { ValidationError } from "../interfaces/error/ValidationError";
import { appInsights } from "./AppInsightsService";

export function TrackException(error: any) {
  if ((error as AxiosError) && error.code !== "ERR_CANCELED") {
    notifyUser(error as AxiosError);
    appInsights.trackException({ error, severityLevel: SeverityLevel.Error });
  } else if (!(error as AxiosError)) {
    appInsights.trackException({ error, severityLevel: SeverityLevel.Error });
  }
}

export function TrackTrace(message: string) {
  appInsights.trackTrace({
    message: message,
    severityLevel: SeverityLevel.Information,
  });
}

export function TrackEvent(event: string) {
  appInsights.trackEvent({ name: event });
}

function notifyUser(error: AxiosError) {
  let message = error.message;
  if (
    error.response?.status === 404 &&
    message.includes("temporarily unavailable")
  ) {
    message = "Errors.ConnectionAPIFailed";
  }

  const validationErrors = parseValidationErrors(error.response?.data);
  const fullMessage =
    (validationErrors
      ? validationErrors.map((x) => x.message).join("\n")
      : (error.response?.data as string)) ?? message;

  const { key, values } = getDynamicMessage(fullMessage);
  const translatedMessage = translateFullErrorMessage(key, values);
  ShowError(translatedMessage);
}


function getDynamicMessage(message: string): {
  key: string;
  values: Record<string, string>;
} {
  const patterns = [
    { regex: /(.*) does not exist/, key: "Errors.MustNotBeEmpty" },
    { regex: /(.*) must not be empty/, key: "Errors.MustNotBeEmpty" },
  ];

  for (const { regex, key } of patterns) {
    const match = message.match(regex);
    if (match) {
      const item = i18n.t(match[1]?.replaceAll("'", ""));
      return { key, values: { item } };
    }
  }

  return { key: message, values: {} };
}

function translateFullErrorMessage(
  key: string,
  values: Record<string, string>
): string {
  const translatedMessage = i18n.t(key, { ...values, defaultValue: key });
  return translatedMessage;
}

function parseValidationErrors(data: any): ValidationError[] {
  const errors: ValidationError[] = [];

  if (typeof data === "object" && data !== null) {
    for (const key in data) {
      if (
        key === "title" &&
        data[key]?.includes("validation errors occurred")
      ) {
        return [
          { field: "", message: "Errors.ValidationErrorsOccurred" },
        ] as ValidationError[];
      } else if (data.hasOwnProperty(key)) {
        const messages = data[key];
        if (Array.isArray(messages)) {
          messages.forEach((message) => {
            errors.push({ field: key, message: message });
          });
        } else {
          errors.push({ field: key, message: messages });
        }
      }
    }
  } else {
    errors.push({ field: "", message: data });
  }

  return errors;
}
