import { BatteryDto } from "../../../interfaces/foundry/typicals/BatteryDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { TrackException } from "../../../logging/LoggingManager";
import { SupplierDto } from "../../../interfaces/foundry/typicals/SupplierDto";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import { GetSuppliers } from "./SupplierService";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";

export async function GetBattery(id: number): Promise<BatteryDto> {
  return (await GetBatteries()).find((x) => x.id === id) as BatteryDto;
}

export async function GetBatteries(update?: boolean): Promise<BatteryDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let batteries = store.batteries;
  if (batteries.length === 0 || update) {
    batteries = await GetBatteryDtos(await GetSuppliers());
    store.setBatteries(batteries);
  }
  return batteries;
}

async function GetBatteryDtos(suppliers: SupplierDto[]): Promise<BatteryDto[]> {
  try {
    const batteriesData = (
      await foundryApiClient.get<ResponseDto>("/api/general/batteries")
    ).data;
    const batteries = (batteriesData.results as BatteryDto[]).map(
      (item: BatteryDto) => {
        const currentSupplier: SupplierDto | undefined = suppliers?.find(
          (sup) => sup.id === item.supplier
        );
        item = {
          ...item,
          type: item.type,
          power_in_kw_5_min: item.power_in_kw_5_min,
          typeObject: TypeObject.Battery,
          supplier_name: currentSupplier?.name ? currentSupplier?.name : "",
        };
        return item;
      }
    );
    return batteries;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function SaveBattery(batterie: BatteryDto) {
  try {
    const response = await foundryApiClient.post<BatteryDto>(
      "/api/general/batteries/",
      batterie
    );

    await GetBatteries(true);

    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function UpdateBattery(batterie: BatteryDto) {
  try {
    const response = await foundryApiClient.patch<BatteryDto>(
      `/api/general/batteries/${batterie.id}`,
      batterie
    );

    await GetBatteries(true);

    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function DeleteBattery(batterie: BatteryDto) {
  try {
    const response = await foundryApiClient.delete<BatteryDto>(
      `/api/general/batteries/${batterie.id}`
    );

    await GetBatteries(true);
    
    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function UpdateBatteryBuildingTypes(
  batteryId: number,
  buildingTypesIds: number[]
): Promise<BatteryDto> {
  try {
    const response = await foundryApiClient.patch<BatteryDto>(
      `/api/general/batteries/${batteryId}/`,
      { typical_types: buildingTypesIds }
    );

    await GetBatteries(true);
    
    return {
      ...response.data,
      power_in_kw_5_min: response.data.power_in_kw_5_min,
      typeObject: TypeObject.Battery,
    };
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
