import { SpaceBuildingDto } from "../../../HSA/types/api";
import { TypicalDTO } from "../../state/ExploreState/ExploreState";
import { TypeObject } from "../../types/enums";
import { ExploreConfigStore } from "../../types/ExploreConfig";
import ExploreView from "../ExploreView/ExploreView";

interface BaseExploreViewProps<T extends SpaceBuildingDto> {
  type: TypeObject;
  currentTypicalConfigStore: ExploreConfigStore<T>;
}

export default function BaseExploreView<T extends SpaceBuildingDto>({
  type,
  currentTypicalConfigStore,
}: BaseExploreViewProps<T>) {
  const {
    summaryInfo,
    detailedInfo,
    sortByOptionsDictionary,
    dimensions,
    chartLabels,
    clickableAxes,
    bestFitOptionsDictionary,
    bestFitOptionsDictionaryReversed,
    handleCustomFilter,
    handleObjectFilters,
  } = currentTypicalConfigStore;

  const currentStore = currentTypicalConfigStore.currentStore();

  function orderObjects(
    objects: T[],
    sortProperty: string,
    isSortOrderAscending: boolean
  ) {
    const currentObjects = objects as TypicalDTO[];
    const length =
      currentStore?.loadedObjects?.length === 0
        ? 50
        : currentStore?.loadedObjects.length;
    if (sortProperty === "Best fit rate") {
      currentStore.setLoadedObjects(
        currentObjects
          .sort((a, b) =>
            isSortOrderAscending ? b.value - a.value : a.value - b.value
          )
          .slice(0, length) as T[]
      );
    }
  }

  return (
    <ExploreView
      type={type}
      summaryInfo={summaryInfo || []}
      detailedInfo={detailedInfo}
      currentState={currentStore}
      dimensions={dimensions}
      chartLabels={chartLabels}
      clickableAxes={clickableAxes}
      sortByOptionsDictionary={sortByOptionsDictionary || {}}
      orderObjects={orderObjects}
      handleCustomFilter={handleCustomFilter || (() => [])}
      handleObjectFilters={handleObjectFilters || ((objects: T[]) => objects)}
      bestFitOptionsDictionary={bestFitOptionsDictionary}
      bestFitOptionsDictionaryReversed={bestFitOptionsDictionaryReversed}
    />
  );
}
