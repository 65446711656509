import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "../../../components/TabPanel/TabPanel";
import { ActivitiesDatabaseView } from "./ActivitiesDatabaseView";
import { ProductionKeyFiguresDatabaseView } from "./ProductionKeyFiguresDatabaseView";
import "./ProductionKeyFiguresPage.scss";

export function ProductionKeyFiguresPage() {
  const { t } = useTranslation();
  const [currentTabPageIndex, setCurrentTabPageIndex] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabPageIndex(newValue);
  };

  return (
    <div className="production-key-figures-page">
      <Box
        component={"div"}
        sx={{
          boxShadow: 3,
          display: "flex",
          paddingRight: "20px",
          alignItems: "center",
        }}
      >
        <Tabs
          sx={{ flexGrow: 1 }}
          value={currentTabPageIndex}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <Tab label={t("Productionkeyfigures")} />
          <Tab label={t("Activities")} />
        </Tabs>
      </Box>
      <TabPanel value={currentTabPageIndex} index={0}>
        <ProductionKeyFiguresDatabaseView />
      </TabPanel>
      <TabPanel value={currentTabPageIndex} index={1}>
        <ActivitiesDatabaseView />
      </TabPanel>
    </div>
  );
}
