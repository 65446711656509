import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import CancelButton from "../../../../components/CancelButton/CancelButton";
import CustomFormControl from "../../../../components/CustomFormControl/CustomFormControl";
import { BuildingTypeDto } from "../../../../interfaces/foundry/BuildingTypeDto";
import useGeneralDatacenterObjectsStore from "../../../../state/DatacenterState/generalDatacenterObjectsState";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";

interface DcAddToSetDialogProps<T extends DTO> {
  currentState: ExploreState<T> & ExploreActions<T>;
  onClose: () => void;
  onSave: () => void;
  open: boolean;
}
export default function DcAddToSetDialog<T extends DTO>(
  props: DcAddToSetDialogProps<T>
) {
  const { onClose, open } = props;
  const [buildingType, setBuildingType] = useState<BuildingTypeDto[]>([]);
  const { buildingTypeDtoOptions } = useGeneralDatacenterObjectsStore();

  const handleClose = () => {
    onClose();
    setBuildingType([]);
  };

  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: "28%",
          height: "25vh",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 16,
          fontWeight: 900,
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {t("Add to set")}
        <Box component={"div"} sx={{ ml: "auto" }}>
          <CancelButton onClick={handleClose} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 24px !important" }}>
        <CustomFormControl
          labelText={t("Building type")}
          showInfoButton={true}
          toolTipText={t("Building type")}
        >
          <Select
            multiple
            style={{ height: "auto" }}
            labelId="type-building-label"
            id="type-building-select"
            value={buildingType.map((t) => t.id.toString())}
            onChange={() => {
              //TODO
            }}
            label={t("Building type")}
            renderValue={(selectedIds: string[]) => (
              <Box
                component={"div"}
                sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
              >
                {selectedIds.map((id) => {
                  const building = Object.values(buildingTypeDtoOptions).find(
                    (t) => t && t.id.toString() === id
                  );
                  return building ? (
                    <Chip key={id} label={t(building.name)} />
                  ) : null;
                })}
              </Box>
            )}
          >
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  checked={
                    Object.values(buildingTypeDtoOptions).length > 0 &&
                    buildingType.length ===
                      Object.values(buildingTypeDtoOptions).length
                  }
                  indeterminate={
                    buildingType.length > 0 &&
                    buildingType.length <
                      Object.values(buildingTypeDtoOptions).length
                  }
                />
              </ListItemIcon>
              <ListItemText primary={t("Select all")} />
            </MenuItem>
            {Object.values(buildingTypeDtoOptions).map(
              (building) =>
                building && (
                  <MenuItem key={building?.id} value={building?.id.toString()}>
                    <Checkbox
                      checked={buildingType.some(
                        (t) => t && t.id === building?.id
                      )}
                    />
                    <ListItemText primary={t(building.name)} />
                  </MenuItem>
                )
            )}
          </Select>
        </CustomFormControl>
      </DialogContent>
      <DialogActions>
        <Button
          className="button"
          variant="contained"
          color="secondary"
          onClick={() => {
            //TODO
          }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
