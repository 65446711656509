import { create } from "zustand";
import {
  CostCategoryDto,
  CostCodeDto,
  CostKeyFigureDto,
  CostUnitDto,
} from "../../interfaces/aggregate/CostKeyFigureDto";

interface CostKeyFiguresState {
  costKeyFigures: CostKeyFigureDto[];
  costUnits: CostUnitDto[];
  costCodes: CostCodeDto[];
  costCategories: CostCategoryDto[];
}

interface CostKeyFiguresActions {
  setCostKeyFigures: (newValue: CostKeyFigureDto[]) => void;
  setCostUnits: (value: CostUnitDto[]) => void;
  setCostCodes: (value: CostCodeDto[]) => void;
  setCostCategories: (value: CostCategoryDto[]) => void;
  resetState: () => void;
}

const initialState: CostKeyFiguresState = {
  costKeyFigures: [],
  costUnits: [],
  costCodes: [],
  costCategories: [],
};

const useCostKeyFiguresStore = create<
  CostKeyFiguresState & CostKeyFiguresActions
>()((set) => ({
  ...initialState,
  setCostKeyFigures: (newValue) => set({ costKeyFigures: newValue }),
  setCostUnits: (value) => set({ costUnits: value }),
  setCostCodes: (value) => set({ costCodes: value }),
  setCostCategories: (value) => set({ costCategories: value }),
  resetState: () => set(initialState),
}));

export default useCostKeyFiguresStore;
