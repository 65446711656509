import * as React from "react";
import { useTranslation } from "react-i18next";
import useDatacenterGeneratorStore from "../../../../state/DatacenterState/datacenterGeneratorState";
import CustomSearchBox from "./CustomSearchBox";
import {
  AddLocationAlt,
  ChevronRight,
  EditLocationAlt,
  FmdGood,
  WrongLocation,
} from "@mui/icons-material";
import {
  Collapse,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { TypeObject } from "../../../../interfaces/enums/TypeObjectEnum";
import useGeneralDatacenterObjectsStore from "../../../../state/DatacenterState/generalDatacenterObjectsState";
import useInputPaneStore, {
  InputPaneState,
} from "../../../../state/DatacenterState/inputPaneState";
import CustomPopover from "../../../CustomPopover/CustomPopover";
import ExpandMore from "../../../ExpandMore/ExpandMore";
import CustomInputLabel from "../../../InputLabel/CustomInputLabel";
import "./BasicPrinciplesControl.scss";
import FormControlSelect from "../../../CustomFormControl/FormControlSelect";
import FormControlSwitch from "../../../CustomFormControl/FormControlSwitch";

export default function BasicPrinciplesControl() {
  const { t } = useTranslation();
  const [buildingLotExpanded, setBuildingLotExpanded] = React.useState(false);
  const [buildableExpanded, setBuildableExpanded] = React.useState(false);

  const {
    currentPageType,
    currentProject,
    drawedPlot,
    startDrawingPlot,
    deleteDrawingPlot,
    editDrawingPlot,
    navigateDrawingPlot,
    distanceBetweenPlotAndRoad,
    setStartDrawingPlot,
    setDeleteDrawingPlot,
    setEditDrawingPlot,
    setNavigateDrawingPlot,
    setDrawedPlot,
  } = useDatacenterGeneratorStore();

  const { destinationPlans, dcScopes } = useGeneralDatacenterObjectsStore();

  const {
    destinationPlan,
    expensionOnCampus,
    purchasingStation,
    sprinklerBasin,
    waterTreatment,
    adminBuilding,
    typeDataCentre,
    desiredITCapability,
    maxBuildHeight,
    totalITPower,
    currentProjectId,
    plotPercentage,
    setPlotPercentage,
    setMaxBuildHeight,
    setDesiredITCapability,
    setTotalITPower,
    setTypeDataCentre,
    setDestinationPlan,
    setExpansionOnCampus,
    setPurchasingStation,
    setSprinklerBasin,
    setWaterTreatment,
    setAdminBuilding,
    setInputPaneState,
  } = useInputPaneStore();

  React.useEffect(() => {
    if (
      currentProject &&
      currentProject.params &&
      currentProject.id !== currentProjectId
    ) {
      const currentInputPaneState = JSON.parse(
        currentProject.params
      ) as InputPaneState;
      if (
        currentProjectId === 0 ||
        currentInputPaneState.currentProjectId !== currentProjectId
      ) {
        currentInputPaneState.currentProjectId = currentProject.id;
        setInputPaneState(currentInputPaneState);
      }
    }
  }, [currentProject, currentProjectId, setInputPaneState]);

  React.useEffect(() => {
    const newBuildableSurface = drawedPlot.lotSurface * (plotPercentage / 100);
    if (
      drawedPlot.buildableSurface !== newBuildableSurface &&
      newBuildableSurface !== 0
    ) {
      setDrawedPlot({
        ...drawedPlot,
        buildableSurface: newBuildableSurface,
      });
    }
  }, [drawedPlot, drawedPlot.lotSurface, plotPercentage, setDrawedPlot]);

  return (
    <div className="basic-principles-control">
      {currentPageType === TypeObject.MapView && (
        <div>
          <div className="map-view-controls-control-view">
            <div>{t("MapViewPage.ProjectAddress")}</div>
            <CustomSearchBox />
            {drawedPlot.lotSurface === 0 && (
              <div className="empty-drawable-plot">
                <div className="empty-drawable-plot-label">
                  {t("MapViewPage.NoPlotDrawn") + "..."}
                </div>
                <CustomPopover
                  content={
                    <div className="empty-drawable-plot-popover">
                      <IconButton
                        onClick={() => setStartDrawingPlot(!startDrawingPlot)}
                      >
                        <AddLocationAlt sx={{ fontSize: "25px" }} />
                      </IconButton>
                    </div>
                  }
                  popoverText={t("MapViewPage.AddPlot")}
                ></CustomPopover>
              </div>
            )}

            {drawedPlot.lotSurface > 0 && (
              <div className="drawable-plot mt10">
                <Grid container>
                  <Grid
                    item
                    xs={7}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <CustomPopover
                      content={
                        <div className="drawable-plot-popover">
                          <ExpandMore
                            sx={{ display: "contents" }}
                            expand={buildingLotExpanded}
                            onClick={() =>
                              setBuildingLotExpanded(!buildingLotExpanded)
                            }
                          >
                            {buildingLotExpanded ? (
                              <GridExpandMoreIcon />
                            ) : (
                              <ChevronRight sx={{ fontSize: "1.5rem" }} />
                            )}
                          </ExpandMore>
                          <div className="drawable-plot-circle" />
                          <div>{t("MapViewPage.BuildablePlot")}</div>
                        </div>
                      }
                      popoverText={t("MapViewPage.BuildablePlotTooltip")}
                    ></CustomPopover>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="drawable-plot-label-button-group-view">
                      <div className="drawable-plot-label">
                        {drawedPlot.lotSurface + "m2"}
                      </div>
                      <div className="drawable-plot-button-group">
                        <CustomPopover
                          content={
                            <IconButton
                              sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                              onClick={() =>
                                setNavigateDrawingPlot(!navigateDrawingPlot)
                              }
                            >
                              <FmdGood sx={{ fontSize: "25px" }} />
                            </IconButton>
                          }
                          popoverText={t("MapViewPage.NavigatePlot")}
                        ></CustomPopover>
                        <CustomPopover
                          content={
                            <IconButton
                              sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                              onClick={() =>
                                setEditDrawingPlot(!editDrawingPlot)
                              }
                            >
                              <EditLocationAlt sx={{ fontSize: "25px" }} />
                            </IconButton>
                          }
                          popoverText={t("MapViewPage.EditPlot")}
                        ></CustomPopover>
                        <CustomPopover
                          content={
                            <IconButton
                              sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                              onClick={() =>
                                setDeleteDrawingPlot(!deleteDrawingPlot)
                              }
                            >
                              <WrongLocation sx={{ fontSize: "25px" }} />
                            </IconButton>
                          }
                          popoverText={t("MapViewPage.DeletePlot")}
                        ></CustomPopover>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Collapse in={buildingLotExpanded} timeout="auto">
                      <Grid
                        container
                        sx={{
                          marginLeft: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        {drawedPlot.lotRdCoordinates.map(
                          (coordinate, index) => (
                            <Grid item xs={12} key={index}>
                              <Grid container columnSpacing={1}>
                                <Grid item xs={6.5}>
                                  <div>{`RD ${t(
                                    "MapViewPage.Coordinate"
                                  )} X: ${coordinate[0].toFixed(2)}`}</div>
                                </Grid>
                                <Grid item xs={5}>
                                  <div>{`Y: ${coordinate[1].toFixed(2)}`}</div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>

                  <Grid item xs={7}>
                    <CustomPopover
                      content={
                        <div className="buildable-area">
                          <ExpandMore
                            sx={{ display: "contents" }}
                            expand={buildableExpanded}
                            onClick={() =>
                              setBuildableExpanded(!buildableExpanded)
                            }
                          >
                            {buildableExpanded ? (
                              <GridExpandMoreIcon />
                            ) : (
                              <ChevronRight sx={{ fontSize: "1.5rem" }} />
                            )}
                          </ExpandMore>
                          <div className="buildable-area-circle" />
                          <div className="buildable-area-labels">
                            <div>{t("MapViewPage.BuildableArea")}</div>
                            <div>{t("MapViewPage.MaxToBuildOn")}</div>
                          </div>
                        </div>
                      }
                      popoverText={t("MapViewPage.BuildableAreaTooltip")}
                    ></CustomPopover>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <div className="input-plot-view">
                      <input
                        className="input-plot-input"
                        type="number"
                        id="quantity"
                        title=""
                        placeholder="0"
                        min={0}
                        max={100}
                        value={plotPercentage}
                        onChange={(event) =>
                          setPlotPercentage(Number(event.target.value))
                        }
                      />
                      <div className="input-plot-percentage">%</div>
                    </div>
                    <div className="ml15">
                      {drawedPlot.buildableSurface.toFixed() + "m2"}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse
                      in={buildableExpanded}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid
                        container
                        sx={{
                          marginLeft: "20px",
                          marginTop: "10px",
                        }}
                      >
                        {drawedPlot.buildableRdCoordinates.map(
                          (coordinate, index) => (
                            <Grid item xs={12} key={index}>
                              <Grid container columnSpacing={1}>
                                <Grid item xs={6.5}>
                                  <div>{`RD ${t(
                                    "MapViewPage.Coordinate"
                                  )} X: ${coordinate[0].toFixed()}`}</div>
                                </Grid>
                                <Grid item xs={5}>
                                  <div>{`Y: ${coordinate[1].toFixed()}`}</div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </div>
            )}

            {distanceBetweenPlotAndRoad > 0 && (
              <div className="dinstance-between-plot-and-road-view">
                <div className="distance-between-plot-and-road-circle" />
                <div className="single-line-text">{`${t(
                  "MapViewPage.DistancePlotRoad"
                )} ${Math.round(distanceBetweenPlotAndRoad)}m`}</div>
              </div>
            )}
          </div>
          <hr className="mt10" />
        </div>
      )}

      <div className="generator-inputs">
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="max-build-size-label"
            showInfoButton={true}
            labelText={t("MapViewPage.MaxBuildHeight")}
            tooltipText={t("MapViewPage.MaxBuildHeightTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            type="number"
            fullWidth
            value={maxBuildHeight || ""}
            onChange={(event) => {
              setMaxBuildHeight(Number(event.target.value));
            }}
            id="max-build-size"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>

        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="desired-it-capability-label"
            showInfoButton={true}
            labelText={t("MapViewPage.DesiredITCapability")}
            tooltipText={t("MapViewPage.DesiredITCapabilityTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            type="number"
            fullWidth
            value={desiredITCapability || ""}
            onChange={(event) => {
              setDesiredITCapability(Number(event.target.value));
              setTotalITPower(
                Number((Number(event.target.value) / 0.8).toFixed(2))
              );
            }}
            id="desired-it-capability"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>

        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="total-it-power-label"
            showInfoButton={true}
            labelText={t("MapViewPage.TotalITPower")}
            tooltipText={t("MapViewPage.TotalITPowerTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            type="number"
            fullWidth
            value={totalITPower || ""}
            onChange={(event) => {
              setDesiredITCapability(
                Number((Number(event.target.value) * 0.8).toFixed(2))
              );
              setTotalITPower(Number(event.target.value));
            }}
            id="total-it-power"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>

        <FormControlSelect
          labelText={t("Globals.TypeDatacenter")}
          toolTipText={t("MapViewPage.TypeDatacenterTooltip")}
          value={typeDataCentre}
          showInfoButton={true}
          setValue={(value) => setTypeDataCentre(value)}
          selectItems={dcScopes}
        />

        <FormControlSelect
          labelText={t("Globals.DestinationPlan")}
          toolTipText={t("MapViewPage.DestinationPlanTooltip")}
          value={
            destinationPlan
              ? destinationPlans.find((plan) =>
                  plan.startsWith(destinationPlan)
                ) || ""
              : ""
          }
          showInfoButton={true}
          setValue={(selectedValue) => {
            setDestinationPlan(selectedValue.charAt(0));
          }}
          selectItems={destinationPlans}
        />

        <div className="mt20" />

        <FormControlSwitch
          labelText={t("MapViewPage.ExpansionOnCampus")}
          toolTipText={t("MapViewPage.ExpansionOnCampusTooltip")}
          value={expensionOnCampus}
          showInfoButton={true}
          setValue={(value) => {
            if (!value) {
              setPurchasingStation(true);
              setSprinklerBasin(true);
              setWaterTreatment(true);
              setAdminBuilding(true);
            }

            setExpansionOnCampus(value);
          }}
        />

        {expensionOnCampus && (
          <div className="ml20">
            <FormControlSwitch
              labelText={t("MapViewPage.Substation")}
              value={purchasingStation}
              setValue={(value) => setPurchasingStation(value)}
            />

            <FormControlSwitch
              labelText={t("MapViewPage.SprinklerBasin")}
              value={sprinklerBasin}
              setValue={(value) => setSprinklerBasin(value)}
            />

            <FormControlSwitch
              labelText={t("MapViewPage.WaterTreatment")}
              value={waterTreatment}
              setValue={(value) => setWaterTreatment(value)}
            />

            <FormControlSwitch
              labelText={t("MapViewPage.AdminBuilding")}
              value={adminBuilding}
              setValue={(value) => setAdminBuilding(value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
