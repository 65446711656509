import { create } from "zustand";
import { CustomTask } from "../../components/GanttChart/public-types";
import ActivityDto from "../../interfaces/aggregate/ActivityDto";

type NitrogenState = {
  currentTabPageIndex: number;
  activities: CustomTask[];
  selectedActivity: CustomTask | null;
  currentSelectedEquipments: string[] | undefined;
  showGridView: boolean;
  startDrawingMarker: boolean;
  deleteDrawingMarker: boolean;
  navigateDrawingMarker: boolean;
  calculateDistance: boolean;
  distanceBetweenMarkerAndRoad: number;
  isSaveAvailable: boolean;
  drawControlMode: string;
  mainPhases: CustomTask[];
  projectTasks: CustomTask[];
  activitesPerPhase: {
    [key: string]: ActivityDto[];
  };
  workingDays: string[];
};

type NitrogenActions = {
  setCurrentTabPageIndex: (newValue: number) => void;
  setActivities: (newValue: CustomTask[]) => void;
  setSelectedActivity: (newValue: CustomTask | null) => void;
  setCurrentSelectedEquipments: (newValue: string[] | undefined) => void;
  setShowGridView: (showGridView: boolean) => void;
  setStartDrawingMarker: (newValue: boolean) => void;
  setDeleteDrawingMarker: (boolValue: boolean) => void;
  setNavigateDrawingMarker: (newValue: boolean) => void;
  setDistanceBetweenMarkerAndRoad: (values: number) => void;
  setCalculateDistance: (values: boolean) => void;
  setIsSaveAvailable: (value: boolean) => void;
  setDrawControlMode: (value: string) => void;
  setMainPhases: (value: CustomTask[]) => void;
  setProjectTasks: (value: CustomTask[]) => void;
  setActivitiesPerPhase: (value: { [key: string]: ActivityDto[] }) => void;
  setWorkingDays: (value: string[]) => void;
  resetNitrogenState: () => void;
};

const initialState: NitrogenState = GetInitialState();

function GetInitialState(): NitrogenState {
  return {
    currentTabPageIndex: 0,
    activities: [],
    selectedActivity: null,
    currentSelectedEquipments: undefined,
    showGridView: false,
    startDrawingMarker: false,
    deleteDrawingMarker: false,
    navigateDrawingMarker: false,
    distanceBetweenMarkerAndRoad: 0,
    calculateDistance: false,
    isSaveAvailable: false,
    drawControlMode: "",
    mainPhases: [],
    projectTasks: [],
    activitesPerPhase: {},
    workingDays: ["Mon", "Tue", "Wed", "Thu", "Fri"],
  };
}

const useNitrogenStore = create<NitrogenState & NitrogenActions>()((set) => ({
  ...initialState,
  setCurrentTabPageIndex: (newValue) => set({ currentTabPageIndex: newValue }),
  setActivities: (newValue) => set({ activities: newValue }),
  setSelectedActivity: (newValue) => set({ selectedActivity: newValue }),
  setCurrentSelectedEquipments: (newValue) =>
    set({ currentSelectedEquipments: newValue }),
  setShowGridView: (newValue) => set({ showGridView: newValue }),
  setStartDrawingMarker: (newValue) => set({ startDrawingMarker: newValue }),
  setDeleteDrawingMarker: (newValue) => set({ deleteDrawingMarker: newValue }),
  setNavigateDrawingMarker: (newValue) =>
    set({ navigateDrawingMarker: newValue }),
  setDistanceBetweenMarkerAndRoad: (newValue) =>
    set({ distanceBetweenMarkerAndRoad: newValue }),
  setCalculateDistance: (newValue) => set({ calculateDistance: newValue }),
  setIsSaveAvailable: (newValue) => set({ isSaveAvailable: newValue }),
  setDrawControlMode: (value: string) => set({ drawControlMode: value }),
  resetDatacenterGeneratorState: () => {
    set(GetInitialState());
  },
  setMainPhases: (value) => set({ mainPhases: value }),
  setProjectTasks: (value) => set({ projectTasks: value }),
  setActivitiesPerPhase: (value: any) => set({ activitesPerPhase: value }),
  setWorkingDays: (value) => set({ workingDays: value }),
  resetNitrogenState: () => {
    set(GetInitialState());
  },
}));

export default useNitrogenStore;
