import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import AvailableComponentDto from "../../interfaces/AvailableComponentDto";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import colors from "../../styles/colors.module.scss";

interface ComponentCardProps {
  componentDto: AvailableComponentDto;
}

export default function ComponentCard({ componentDto }: ComponentCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { heijmansBlue } = colors;

  return (
    <Grid item>
      <Card
        onClick={() => {
          if (!componentDto.underConstruction) {
            navigate("../" + componentDto.to);
          }
        }}
        sx={{
          backgroundColor: componentDto.underConstruction
            ? "rgba(235, 235, 228, 0.9)"
            : "white",
          height: "100%",
          minWidth: componentDto.maxWidth
            ? `${componentDto.maxWidth}px`
            : "345px",
          maxWidth: componentDto.maxWidth
            ? `${componentDto.maxWidth}px`
            : "345px",
          padding: "20px 20px 0px 20px",
          ":hover": {
            border: !componentDto.underConstruction
              ? `1px solid ${heijmansBlue}`
              : "",
            cursor: !componentDto.underConstruction ? "pointer" : "default",
          },
        }}
      >
        <CardMedia
          sx={{
            height: "20vh",
            position: "relative",
            overflow: "hidden",
            border: "1px solid #dcd7da",
            borderRadius: "10px",
          }}
        >
          {componentDto.iconComponent !== null ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {componentDto.iconComponent}
            </div>
          ) : (
            <Box
              component="img"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                height: "100%",
                transform: "translate(-50%, -50%)",
                opacity: componentDto.underConstruction ? 0.6 : 1,
              }}
              src={componentDto.imageUrl}
              alt={componentDto.title}
            />
          )}

          {componentDto.underConstruction && (
            <Box
              component="img"
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                objectFit: "cover",
                opacity: 1,
              }}
              src={"/assets/tile_images//Infocards_Under construction.png"}
              alt="Second Image"
            />
          )}
        </CardMedia>

        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight="bold"
          >
            {t(componentDto.title)}
          </Typography>
          <Typography
            display="inline"
            sx={{
              wordWrap: "break-word",
            }}
            variant="h5"
            fontSize={11}
            color="text.secondary"
          >
            {t(componentDto.details)}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
