import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogContent,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetDestinationPlans } from "../../../http/foundry/InputPaneService";
import SliderWithMarks from "../../SliderWithMarks/SliderWithMarks";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import {
  CalculateDatacenterGenerationCombinations,
  CreateDatacenterGeneration,
} from "../../../http/foundry/DatacenterService";
import "./GenerateDataDatacenter.scss";
import GenerateDataDatacenterWhitespace from "./GenerateDataDatacenterWhitespace";
import GenerateDataDatacenterLowVoltageRoom from "./GenerateDataDatacenterLowVoltageRoom";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { TrackException } from "../../../logging/LoggingManager";

interface GenerateDataDatacenterProps {
  handleClose(): void;
}

export default function GenerateDataDatacenter({
  handleClose,
}: GenerateDataDatacenterProps) {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentDestinationPlans, setCurrentDestinationPlans] = React.useState<
    string[]
  >([]);
  const [combinations, setCombinations] = useState<string>("");
  const [desiredPower, setDesiredPower] = useState<number[]>([0, 40]);

  const [selectedWhitespaces, setSelectedWhitespaces] = useState<number[]>([]);
  const [selectedLowVoltageRooms, setSelectedLowVoltageRooms] = useState<
    number[]
  >([]);

  const [substation, setSubstation] = React.useState<string>("Yes");
  const [sprinklerBasin, setSprinklerBasin] = React.useState<string>("Yes");
  const [waterTreatment, setWaterTreatment] = React.useState<string>("Yes");
  const [adminBuilding, setAdminBuilding] = React.useState<string>("Yes");

  const { destinationPlans, setDestinationPlans } =
    useGeneralDatacenterObjectsStore();

  const steps = [
    "Whitespace",
    "Low voltage room",
    "Datacenter",
    "Start new generation",
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = async () => {
    const getBoolArray = (value: string) => {
      if (value === "Yes") {
        return [true];
      } else if (value === "No") {
        return [false];
      }
      return [true, false];
    };

    const data = {
      desired_power_in_mw: generateSteps(desiredPower),
      destination_plan: currentDestinationPlans.map((x) => x.toUpperCase()),
      incl_energysupplystation: getBoolArray(substation),
      incl_sprinklerbassin: getBoolArray(sprinklerBasin),
      incl_watertreatment: getBoolArray(waterTreatment),
      incl_admin: getBoolArray(adminBuilding),
      whitespace_ids: selectedWhitespaces,
      lsr_ids: selectedLowVoltageRooms,
    };

    try {
      if (activeStep === steps.length - 2) {
        setLoading(true);
        const response = await CalculateDatacenterGenerationCombinations(data);
        setCombinations(`tussen de ${response.min_dcs} en ${response.max_dcs}`);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === steps.length - 1) {
        await CreateDatacenterGeneration(data);
        handleClose();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (ex) {
      TrackException(ex);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeDestinationPlan = (event: SelectChangeEvent<string[]>) => {
    const selectedPlans = event.target.value as string[];
    if (selectedPlans.includes("all")) {
      setCurrentDestinationPlans(
        currentDestinationPlans.length === destinationPlans.length
          ? []
          : destinationPlans
      );
    } else {
      setCurrentDestinationPlans(
        destinationPlans.filter((plan) => selectedPlans.includes(plan))
      );
    }
  };

  function generateSteps(desiredPower: number[]): number[] {
    const steps: number[] = [];

    for (let i = desiredPower[0]; i <= desiredPower[1]; i += 2) {
      steps.push(i);
    }
    return steps;
  }

  useEffect(() => {
    (async () => {
      try {
        if (destinationPlans.length === 0) {
          setDestinationPlans(await GetDestinationPlans());
        }
      } catch (ex) {
        TrackException(ex);
      }
    })();
  }, []);

  return (
    <DialogContent
      className="generate-data-datacenter"
      sx={{ padding: "25px", marginTop: "-20px" }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{t(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {loading ? (
        <div className="loading-indicator-generate-datacenters">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          {activeStep === 0 ? (
            <GenerateDataDatacenterWhitespace
              selectedWhitespaces={selectedWhitespaces}
              setSelectedWhitespaces={setSelectedWhitespaces}
            />
          ) : activeStep === 1 ? (
            <GenerateDataDatacenterLowVoltageRoom
              selectedLowVoltageRooms={selectedLowVoltageRooms}
              setSelectedLowVoltageRooms={setSelectedLowVoltageRooms}
            />
          ) : activeStep === 2 ? (
            <div>
              <div className="title mt20">{t("Datacenter")}</div>
              <Grid
                container
                rowSpacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <div>{`${t("Desired power")} (MW):`}</div>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{ alignContent: "center", padding: "10px" }}
                >
                  <SliderWithMarks
                    min={0}
                    max={40}
                    value={desiredPower}
                    setValue={setDesiredPower}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div>{`${t("Globals.DestinationPlan")}:`}</div>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    alignContent: "center",
                  }}
                >
                  <FormControl fullWidth variant="standard">
                    <Select
                      multiple
                      style={{ height: "auto" }}
                      labelId="low-voltage-room-label"
                      id="low-voltage-room-select"
                      value={currentDestinationPlans}
                      onChange={handleChangeDestinationPlan}
                      renderValue={(plans: string[]) => (
                        <Box
                          component={"div"}
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {plans.map((plan) => {
                            return <Chip key={plan} label={t(plan)} />;
                          })}
                        </Box>
                      )}
                    >
                      <MenuItem value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              destinationPlans.length > 0 &&
                              currentDestinationPlans.length ===
                                destinationPlans.length
                            }
                            indeterminate={
                              currentDestinationPlans.length > 0 &&
                              currentDestinationPlans.length <
                                destinationPlans.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={t("Select all")} />
                      </MenuItem>
                      {destinationPlans.map((plan) => (
                        <MenuItem key={plan} value={plan}>
                          <Checkbox
                            checked={currentDestinationPlans.some(
                              (t) => t === plan
                            )}
                          />
                          <ListItemText primary={t(plan)} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={4} className="switch-view">
                  <div>{`${t("MapViewPage.Substation")}:`}</div>
                </Grid>
                <Grid item xs={8} className="switch-view">
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={substation}
                    onChange={(event, value) => {
                      if (value !== null) setSubstation(value);
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton className="toggle-button-left" value="Yes">
                      {t("Yes")}
                    </ToggleButton>
                    <ToggleButton className="toggle-button-middle" value="Both">
                      {t("Both")}
                    </ToggleButton>
                    <ToggleButton className="toggle-button-right" value="No">
                      {t("No")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={4} className="switch-view">
                  <div>{`${t("MapViewPage.SprinklerBasin")}:`}</div>
                </Grid>
                <Grid item xs={8} className="switch-view">
                  <ToggleButtonGroup
                    color="primary"
                    value={sprinklerBasin}
                    exclusive
                    onChange={(event, value) => {
                      if (value !== null) setSprinklerBasin(value);
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton className="toggle-button-left" value="Yes">
                      {t("Yes")}
                    </ToggleButton>
                    <ToggleButton className="toggle-button-middle" value="Both">
                      {t("Both")}
                    </ToggleButton>
                    <ToggleButton className="toggle-button-right" value="No">
                      {t("No")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={4} className="switch-view">
                  <div>{`${t("MapViewPage.WaterTreatment")}:`}</div>
                </Grid>
                <Grid item xs={8} className="switch-view">
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={waterTreatment}
                    onChange={(event, value) => {
                      if (value !== null) setWaterTreatment(value);
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton className="toggle-button-left" value="Yes">
                      {t("Yes")}
                    </ToggleButton>
                    <ToggleButton className="toggle-button-middle" value="Both">
                      {t("Both")}
                    </ToggleButton>
                    <ToggleButton className="toggle-button-right" value="No">
                      {t("No")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={4} className="switch-view">
                  <div>{`${t("MapViewPage.AdminBuilding")}:`}</div>
                </Grid>
                <Grid item xs={8} className="switch-view">
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={adminBuilding}
                    onChange={(event, value) => {
                      if (value !== null) setAdminBuilding(value);
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton className="toggle-button-left" value="Yes">
                      {t("Yes")}
                    </ToggleButton>
                    <ToggleButton className="toggle-button-middle" value="Both">
                      {t("Both")}
                    </ToggleButton>
                    <ToggleButton className="toggle-button-right" value="No">
                      {t("No")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="datacenter-generation-view">
              <div>{`${t("GenerateDataMessage")} ${t(
                "datacenters"
              )} (${combinations}).`}</div>
              <br />
              <div>{t("ProceedMessage")}</div>
            </div>
          )}
          <Box
            component={"div"}
            sx={{ display: "flex", flexDirection: "row", pt: 2 }}
          >
            <Box component={"div"} sx={{ flex: "1 1 auto" }} />
            <Button
              disabled={
                (activeStep === 0 && selectedWhitespaces.length === 0) ||
                (activeStep === 1 && selectedLowVoltageRooms.length === 0)
              }
              variant="contained"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? t("Finish") : t("Continue")}
            </Button>
          </Box>
        </div>
      )}
    </DialogContent>
  );
}
