import { LogLevel } from "@azure/msal-browser";
import { AzureClientId, AzureTenantId } from "../../../config";
import { TrackException } from "../logging/LoggingManager";

export const authConfig = {
  auth: {
    clientId: AzureClientId,
    authority: `https://login.microsoftonline.com/${AzureTenantId}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            TrackException(message);
            return;
          case LogLevel.Info:
          case LogLevel.Verbose:
          case LogLevel.Warning:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [`api://${AzureClientId}/User.Read`],
};
