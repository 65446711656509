import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetBuildingTypesOptions } from "../../../../http/foundry/BuildingTypeService";
import { GetBatteries } from "../../../../http/foundry/typicals/BatteryService";
import { GetTransformators } from "../../../../http/foundry/typicals/TransformatorService";
import { TypeObject } from "../../../../interfaces/enums/TypeObjectEnum";
import useDatacenterGeneratorStore from "../../../../state/DatacenterState/datacenterGeneratorState";
import useGeneralDatacenterObjectsStore from "../../../../state/DatacenterState/generalDatacenterObjectsState";
import useInputPaneStore from "../../../../state/DatacenterState/inputPaneState";
import CustomFormControl from "../../../CustomFormControl/CustomFormControl";
import CustomInputLabel from "../../../InputLabel/CustomInputLabel";
import "./LowVoltageRoomControl.scss";

export default function LowVoltageRoomControl() {
  const { t } = useTranslation();
  const { currentPageType } = useDatacenterGeneratorStore();
  
  const {
    buildingType,
    tranfoType,
    autonomyInMin,
    selectedBatteryTypes,
    setTranfoType,
    setAutonomyInMin,
    setBuildingType,
    setSelectedBatteryTypes,
  } = useInputPaneStore();

  const {
    buildingTypeDtoOptions,
    tranfoTypeOptions,
    setTranfoTypeOptions,
    setBuildingTypeDtoOptions,
  } = useGeneralDatacenterObjectsStore();

  const [batteryTypes, setBatteryTypes] = React.useState<string[]>([]);

  const handleChangeTranfoType = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value;
    const valuesTranfoType = Object.values(tranfoTypeOptions);

    if (selectedIds.includes("all")) {
      setTranfoType(
        tranfoType.length === valuesTranfoType.length ? [] : valuesTranfoType
      );
    } else {
      const selectedTransfos = valuesTranfoType.filter((tranfo) =>
        selectedIds.includes(tranfo.id.toString())
      );
      setTranfoType(selectedTransfos);
    }
  };

  const handleChangeBuildingType = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value;
    const valuesBuildingType = Object.values(buildingTypeDtoOptions);

    if (selectedIds.includes("all")) {
      setBuildingType(
        buildingType.length === valuesBuildingType.length
          ? []
          : valuesBuildingType
      );
    } else {
      const selectedBuildings = valuesBuildingType.filter((building) =>
        building
          ? selectedIds.includes(building.id.toString())
          : selectedIds.includes("undefined")
      );
      setBuildingType(selectedBuildings);
    }
  };

  const handleChangeBattery = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value;
    if (selectedIds.includes("all")) {
      setSelectedBatteryTypes(
        selectedBatteryTypes.length === batteryTypes.length ? [] : batteryTypes
      );
    } else {
      const selectedBatteries = batteryTypes.filter((bat) =>
        selectedIds.includes(bat)
      );
      setSelectedBatteryTypes(selectedBatteries);
    }
  };

  useEffect(() => {
    (async () => {
      const batteries = await GetBatteries();
      setTranfoTypeOptions(await GetTransformators());
      setBuildingTypeDtoOptions(await GetBuildingTypesOptions());

      const batteryTypes = new Set(batteries.map((bat) => bat.type));
      setBatteryTypes(Array.from(batteryTypes));
    })();
  }, []);

  return (
    <div className="low-voltage-room-content">
      <FormControl fullWidth variant="standard">
        <CustomInputLabel
          id="autonomy-in-min-label"
          showInfoButton={true}
          labelText={t("Minutes of autonomy")}
          tooltipText={t("MinutesOfAutonomyTooltip")}
        />
        <TextField
          style={{ marginTop: "10px" }}
          type="number"
          fullWidth
          value={autonomyInMin || ""}
          onChange={(event) => {
            setAutonomyInMin(Number(event.target.value));
          }}
          id="autonomy-in-min"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          variant="standard"
        />
      </FormControl>
      <CustomFormControl
        labelText={t("Transformator type")}
        showInfoButton={true}
        toolTipText={t("TransformatorTypeTooltip")}
      >
        <Select
          multiple
          style={{ height: "auto" }}
          labelId="type-transformator-label"
          id="type-transformator-select"
          value={tranfoType.map((t) => t.id.toString())} // Set value to string IDs
          onChange={handleChangeTranfoType}
          label={t("Transformator type")}
          renderValue={(selectedIds: string[]) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selectedIds.map((id) => {
                const tranfo = Object.values(tranfoTypeOptions).find(
                  (t) => t.id.toString() === id
                );
                return tranfo ? (
                  <Chip key={id} label={tranfo.display_name} />
                ) : null;
              })}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  Object.values(tranfoTypeOptions).length > 0 &&
                  tranfoType.length === Object.values(tranfoTypeOptions).length
                }
                indeterminate={
                  tranfoType.length > 0 &&
                  tranfoType.length < Object.values(tranfoTypeOptions).length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {Object.values(tranfoTypeOptions).map((tranfo) => (
            <MenuItem key={tranfo.id} value={tranfo.id.toString()}>
              <Checkbox checked={tranfoType.some((t) => t.id === tranfo.id)} />
              <ListItemText primary={t(tranfo.display_name)} />
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
      <CustomFormControl
        labelText={t("Batteries")}
        showInfoButton={true}
        toolTipText={t("BatteriesTooltip")}
      >
        <Select
          multiple
          style={{ height: "auto" }}
          labelId="batteries-label"
          id="batteries-select"
          value={selectedBatteryTypes}
          onChange={handleChangeBattery}
          label={t("Batteries")}
          renderValue={(selectedBattery: string[]) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selectedBattery.map((batt) => {
                return <Chip key={batt} label={batt} />;
              })}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  Object.values(selectedBatteryTypes).length > 0 &&
                  selectedBatteryTypes.length ===
                    Object.values(batteryTypes).length
                }
                indeterminate={
                  selectedBatteryTypes.length > 0 &&
                  selectedBatteryTypes.length <
                    Object.values(batteryTypes).length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {Object.values(batteryTypes).map((bat) => (
            <MenuItem key={bat} value={bat}>
              <Checkbox checked={selectedBatteryTypes.some((t) => t === bat)} />
              <ListItemText primary={bat} />
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>

      {currentPageType === TypeObject.LowVoltageRoomTooling && (
        <CustomFormControl
          labelText={t("Building type")}
          showInfoButton={true}
          toolTipText={t("Building type")}
        >
          <Select
            multiple
            style={{ height: "auto" }}
            labelId="type-building-label"
            id="type-building-select"
            value={buildingType.map((t) => (t ? t.id.toString() : "undefined"))}
            onChange={handleChangeBuildingType}
            label={t("Building type")}
            renderValue={(selectedIds: string[]) => (
              <Box
                component={"div"}
                sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
              >
                {selectedIds.map((id) => {
                  if (id === "undefined") {
                    return <Chip key="undefined" label={t("Undefined")} />;
                  }
                  const building = Object.values(buildingTypeDtoOptions).find(
                    (t) => t && t.id.toString() === id
                  );
                  return building ? (
                    <Chip key={id} label={t(building.name)} />
                  ) : null;
                })}
              </Box>
            )}
          >
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  checked={
                    Object.values(buildingTypeDtoOptions).length > 0 &&
                    buildingType.length ===
                      Object.values(buildingTypeDtoOptions).length
                  }
                  indeterminate={
                    buildingType.length > 0 &&
                    buildingType.length <
                      Object.values(buildingTypeDtoOptions).length
                  }
                />
              </ListItemIcon>
              <ListItemText primary={t("Select all")} />
            </MenuItem>
            {Object.values(buildingTypeDtoOptions).map((building, index) => (
              <MenuItem
                key={building ? building.id : `undefined-${index}`}
                value={building ? building.id.toString() : "undefined"}
              >
                <Checkbox
                  checked={
                    building
                      ? buildingType.some((t) => t && t.id === building.id)
                      : buildingType.includes(undefined)
                  }
                />
                <ListItemText
                  primary={t(building ? building.name : "Undefined")}
                />
              </MenuItem>
            ))}
          </Select>
        </CustomFormControl>
      )}
    </div>
  );
}
