import { TypeObject } from "../../../../../interfaces/enums/TypeObjectEnum";
import useUpsStore from "../../../../../state/ComponentGeneratorState/upsState";
import BaseSelectComponentView from "../../../../Components/BaseSelectComponentView";

export default function PreselectionUpsView() {
  const upsStore = useUpsStore();

  return (
    <BaseSelectComponentView type={TypeObject.Ups} currentState={upsStore} />
  );
}
