import { BaseTypicalDto } from "./BaseTypicalDto";
import { BuildingTypeDto } from "../BuildingTypeDto";

export interface TransformatorDto extends BaseTypicalDto {
  weight_in_kg: number;
  supplier_name: string;
  power_in_kva: number;
  efficiency: number;
  display_name: string;
  typical_types: BuildingTypeDto[];
  id: number;
}

export function GetEmptyTransformatorDto(): TransformatorDto {
  return {
    length: 0,
    width: 0,
    height: 0,
    supplier: 1,
    power_in_kva: 0,
    efficiency: 0,
    id: 0,
  } as TransformatorDto;
}
