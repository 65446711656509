import CoolingDto from "../../../interfaces/foundry/typicals/CoolingDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetCooling(id: number): Promise<CoolingDto> {
  return (await GetCoolings()).find((x) => x.id === id) as CoolingDto;
}

export async function GetCoolings(): Promise<CoolingDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let coolings = store.coolings;
  if (coolings.length === 0) {
    coolings = await GetCoolingDtos();
    store.setCoolings(coolings);
  }
  return coolings;
}

async function GetCoolingDtos(): Promise<CoolingDto[]> {
  try {
    const coolings = (
      (await foundryApiClient.get<ResponseDto>("/api/general/coolings")).data
        .results as CoolingDto[]
    ).map((item: CoolingDto) => {
      item = {
        ...item,
        type: item.cooling_type,
        power_in_w: (item.cooling_capacity_in_w ?? 0) / 1000,
      };
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
