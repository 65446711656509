import { useState, useEffect, useRef } from "react";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { TrackException } from "../../../../logging/LoggingManager";
import { HttpService } from "./httpService";

interface UseGetApiHookOptions {
  url: string;
  baseUrl: string;
  data?: unknown;
  isSingleResource?: boolean;
}

export function useGetApiHook<T>(options: UseGetApiHookOptions) {
  const [data, setData] = useState<T | T[]>(
    options.isSingleResource ? ({} as T) : []
  );
  const [loading, setLoading] = useState(true);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    if (!options.url || options.url === "") {
      setLoading(false);
      return;
    }

    const forgeHttpService = new HttpService(options.baseUrl);
    cancelTokenSourceRef.current = axios.CancelToken.source();
    forgeHttpService
      .get<T | T[]>(options.url, {
        cancelToken: cancelTokenSourceRef.current?.token.toString(),
      })
      .then((res) => {
        setData(res);
      })
      .catch((err: AxiosError) => {
        TrackException(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel("Operation canceled by the user.");
      }
    };
  }, [options.url, "get", options.data]);

  const cancelRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("Operation canceled by the user.");
    }
  };

  return { data, loading, cancelRequest };
}

interface UsePutApiHookOptions {
  url: string;
  baseUrl: string;
  data: unknown; // Data must be provided for PUT requests
}

export function usePutApiHook<T>(options: UsePutApiHookOptions) {
  const [response, setResponse] = useState<T | null>(null);
  const [loadingModifiedValue, setLoadingModifiedValue] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    const forgeHttpService = new HttpService(options.baseUrl);
    cancelTokenSourceRef.current = axios.CancelToken.source();
    forgeHttpService
      .put<T>(options.url, options.data, {
        cancelToken: cancelTokenSourceRef.current?.token.toString(),
      })
      .then((res) => {
        setResponse(res);
      })
      .catch((err: AxiosError) => {
        setError(err);
        TrackException(err);
      })
      .finally(() => {
        setLoadingModifiedValue(false);
      });

    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel("Operation canceled by the user.");
      }
    };
  }, [options.url, options.data]);

  const cancelRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel("Operation canceled by the user.");
    }
  };

  return { response, loadingModifiedValue, error, cancelRequest };
}
