import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import {
  ColDef,
  IRichCellEditorParams,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetProductionKeyFiguresByActivityId } from "../../http/aggregate/KeyFigureService";
import { TrashIcon } from "../../icons/TrashIcon";
import { ProductionKeyFigureDto } from "../../interfaces/aggregate/ProductionKeyFigureDto";
import useNitrogenStore from "../../state/NitrogenState/nitrogenState";
import AddButton from "../AddButton/AddButton";
import { CustomTask } from "./public-types";

ModuleRegistry.registerModules([ClientSideRowModelModule, RichSelectModule]);

const PlanningColumnDefsComponent = (
  tasks: CustomTask[],
  addTaskUnderProject: ((phaseId: string) => void) | undefined,
  removeActivityFromPahse:
    | ((acivityId: string, phaseId: string) => void)
    | undefined,
  isEditable?: boolean
) => {
  const { t } = useTranslation();
  const relationTypes = ["start-start", "start-end", "end-start", "end-end"];

  const { activitesPerPhase } = useNitrogenStore();

  const [rowProductionKeyFigures, setRowProductionKeyFigures] = useState<{
    [key: string]: ProductionKeyFigureDto[];
  }>({});
  const [rowNumberOfTeams, setRowNumberOfTeams] = useState<{
    [key: string]: number;
  }>({});

  const onAddTaskUnderProject = useCallback(
    async (phaseId: string) => {
      addTaskUnderProject!(phaseId.toString());
    },
    [addTaskUnderProject]
  );
  const onRemoveActivityFromPahse = useCallback(
    async (activityId: string, phaseId: string) => {
      removeActivityFromPahse!(activityId, phaseId);
    },
    [removeActivityFromPahse]
  );

  const onChangeTheSelectedActivity = useCallback(
    async (activityName: string, rowId: string, phaseId: string) => {
      const activity = activitesPerPhase[phaseId]?.find(
        (task) => task.name === activityName
      );
      if (activity) {
        const activityId = activity.id.toString();
        const keyFigures = await GetProductionKeyFiguresByActivityId(
          activityId
        );
        setRowProductionKeyFigures((prev) => ({
          ...prev,
          [rowId]: keyFigures,
        }));
      }
    },
    [activitesPerPhase]
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: t("Name"),
        field: "name",
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: (params: { data: CustomTask; value: string }) => {
          return {
            values: activitesPerPhase[params.data.project ?? ""]
              ?.map((task) => task.name)
              .concat(["Add new ..."])
              .reverse(),
            allowTyping: false,
            filterList: true,
            highlightMatch: true,
            valueListMaxHeight: 220,
          } as IRichCellEditorParams;
        },
        onCellValueChanged: async (params) => {
          if (params.newValue === "Add new ...") {
            console.log("Add new activity", params);
          } else {
            await onChangeTheSelectedActivity(
              params.data.name,
              params.data.id,
              params.data.project
            );
          }
        },
        cellRenderer: (params: { data: CustomTask; value: string }) => {
          if (params.data.id.startsWith("button-")) {
            return (
              <div
                key={params.data.id}
                style={{
                  display: isEditable ? "flex" : "none",
                  justifyContent: "start",
                  marginTop: "5px",
                  height: "25px",
                }}
              >
                <AddButton
                  key={params.data.id}
                  onClick={() =>
                    onAddTaskUnderProject(params.data.project ?? "")
                  }
                  fontSize="1.1rem"
                  text="Add new activity"
                  fontStyle={"italic"}
                  width={200}
                />
              </div>
            );
          } else if (params.value === "Add new ...") {
            return (
              <input
                type="text"
                placeholder="Enter new activity"
                className="ag-cell-focus ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-column-first ag-cell-value"
                style={{
                  left: "0px",
                  width: "250px",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "300",
                  color: "rgb(12, 8, 0)",
                }}
                onBlur={(e) => {
                  const newValue = e.target.value.trim();
                  if (newValue) {
                    // Update the data model
                    params.data.name = newValue;
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation(); // Prevent the dropdown from opening
                    const newValue = e.currentTarget.value.trim();
                    if (newValue) {
                      params.data.name = newValue;
                    }
                  }
                }}
                autoFocus
              />
            );
          } else {
            return params.value;
          }
        },
        width: 250,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
        suppressKeyboardEvent: (params) => {
          const event = params.event;
          const key = event.which || event.keyCode;
          // Suppress Enter key
          if (key === 13) {
            // Enter key
            event.stopPropagation();
            return true;
          }
          return false;
        },
      },
      {
        headerName: t("Main phase"),
        field: "mainPhase",
        cellRenderer: (params: { data: CustomTask; value: string }) => {
          return params.data.id.startsWith("button-") ? null : params.value;
        },
        width: 150,
        editable: false,
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Dependency"),
        hide: true,
        field: "dependency",
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: (params: { data: CustomTask; value: string }) =>
          ({
            values: activitesPerPhase[params.data.project ?? ""]
              ?.map((task) => task.name)
              .filter((name) =>
                tasks.find(
                  (task) => task.name === name && task.name !== params.data.name
                )
              ),
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxHeight: 220,
          } as IRichCellEditorParams),
        cellRenderer: (params: { data: CustomTask; value: string[] }) => {
          return params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
            ? null
            : Array.isArray(params.value)
            ? params.value.join(", ")
            : params.value;
        },
        width: 200,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Relation"),
        field: "relation",
        hide: true,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: relationTypes,
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
          valueListMaxHeight: 220,
        } as IRichCellEditorParams,
        width: 150,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Interval"),
        field: "interval",
        hide: true,
        cellRenderer: (params: { data: CustomTask; value: string }) => {
          return params.data.id.startsWith("button-") ||
            params.data.type === "project"
            ? null
            : `${params.value} ${t("days")}`;
        },
        width: 100,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Quantity"),
        field: "quantity",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        },
        cellRenderer: (params: { data: CustomTask; value: string }) => {
          return params.data.id.startsWith("button-")
            ? null
            : params.value && params.value !== ""
            ? Number(params.value)
            : null;
        },
        width: 100,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Production key figure"),
        field: "productionKeyFigure",
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: (params: {
          data: CustomTask;
          value: ProductionKeyFigureDto;
        }) =>
          ({
            values: rowProductionKeyFigures[params.data.id],
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxHeight: 220,
            formatValue: (value: { name: string }) => value?.name,
            getValueFromSelection: (selection: string) => {
              const selectedPKF = rowProductionKeyFigures[params.data.id]?.find(
                (pkf) => pkf?.name === selection
              );
              return selectedPKF;
            },
          } as IRichCellEditorParams),
        cellRenderer: (params: {
          data: CustomTask;
          value: ProductionKeyFigureDto;
        }) => {
          if (params.data.id.startsWith("button-")) {
            return null;
          } else {
            return params.value?.name;
          }
        },
        width: 200,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Production rate"),
        field: "productionRate",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        },
        cellRenderer: (params: { data: CustomTask; value: string }) => {
          if (params.data.id.startsWith("button-")) return null;
          else {
            return params.value && params.value !== ""
              ? Number(params.value)
              : null;
          }
        },
        width: 150,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            params.data.productionKeyFigure !== undefined ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Number of teams"),
        field: "numberOfTeams",
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0,
        },
        cellRenderer: (params: { data: CustomTask; value: string }) => {
          return params.data.id.startsWith("button-")
            ? null
            : rowNumberOfTeams[params.data.id] ||
              (params.value && params.value !== "")
            ? Number(params.value)
            : null;
        },
        onCellValueChanged: (params) => {
          const newValue = params.newValue;
          setRowNumberOfTeams((prev) => ({
            ...prev,
            [params.data.id]: newValue,
          }));
        },
        width: 150,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            params.data.productionKeyFigure !== undefined ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Start date"),
        field: "start",
        cellEditor: "agDateCellEditor",
        cellRenderer: (params: { data: CustomTask; value: Date }) => {
          if (params.data.id.startsWith("button-")) return null;

          return params.value === null
            ? new Date().toLocaleDateString()
            : params.value.toLocaleDateString();
        },

        width: 150,
        valueFormatter: ({ value }) => value?.toLocaleDateString(),
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("End date"),
        field: "end",
        cellEditor: "agDateCellEditor",
        cellRenderer: (params: { data: CustomTask; value: Date }) => {
          if (params.data.id.startsWith("button-")) return null;

          if (params.value === null) {
            const endDate = new Date(params.data.start);
            endDate.setDate(endDate.getDate() + 1);
            return endDate.toLocaleDateString();
          }

          return params.value.toLocaleDateString();
        },
        width: 150,
        valueFormatter: ({ value }) => value?.toLocaleDateString(),
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Duration"),
        field: "duration",
        cellRenderer: (params: { data: CustomTask; value: string }) => {
          return params.data.id.startsWith("button-") ? null : params.value;
        },

        width: 100,
        editable: (params) => {
          if (
            params.data.id.startsWith("button-") ||
            params.data.type === "project" ||
            (params.data.productionRate !== undefined &&
              params.data.quantity !== undefined &&
              params.data.numberOfTeams !== undefined) ||
            !isEditable
          ) {
            return false;
          } else {
            return true;
          }
        },
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
      {
        headerName: t("Actions"),
        field: "actions",
        cellRenderer: (params: { data: CustomTask }) => {
          return params.data.id.startsWith("button-") ||
            params.data.type === "project" ? null : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                onRemoveActivityFromPahse(
                  params.data.id,
                  params.data.project ?? ""
                )
              }
            >
              <TrashIcon />
            </div>
          );
        },
        width: 100,
        editable: false,
        hide: !isEditable,
        cellStyle: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: "300",
          color: "#0C0800",
        },
        headerClass: "table-header",
      },
    ],
    [
      rowProductionKeyFigures,
      rowNumberOfTeams,
      activitesPerPhase,
      onAddTaskUnderProject,
      onRemoveActivityFromPahse,
      isEditable,
    ]
  );

  return columnDefs;
};

export default PlanningColumnDefsComponent;
