import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  TooltipProps,
  YAxis,
  ReferenceLine,
} from "recharts";
import "./ThermometerChart.scss";

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffffff",
          border: "1px solid #cccccc",
          padding: "2px",
        }}
      >
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

interface ChartData {
  label: string;
  amount: number;
  barMin: number;
  barMax: number;
  annualLimit: number;
}

interface ChartData {
  label: string;
  amount: number;
  barMin: number;
  barMax: number;
  annualLimit: number;
}

interface ThermometerChartProps {
  data: ChartData[];
}

const SingleChart: React.FC<{ data: ChartData }> = ({ data }) => {
  return (
    <BarChart width={60} height={100} data={[data]} barSize={7}>
      <XAxis
        dataKey="label"
        scale="point"
        padding={{ left: 10, right: 10 }}
        stroke="none"
        orientation="top"
      />
      <YAxis domain={[0, Math.max(data.barMax, data.annualLimit)]} hide />
      <Tooltip content={<CustomTooltip />} />
      <Bar
        dataKey="amount"
        fill="#8884d8"
        barSize={10}
        radius={10}
        isAnimationActive={false}
        background={{
          fill: "none",
          stroke: "#8A8989",
          strokeWidth: 0.5,
          radius: 10,
        }}
        activeBar={{
          fill: "red",
        }}
      />
      <ReferenceLine
        y={data.annualLimit}
        stroke="red"
        ifOverflow="extendDomain"
      />
    </BarChart>
  );
};

const ThermometerChart: React.FC<ThermometerChartProps> = ({ data }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: "-15px",
      }}
    >
      {data.map((item, index) => (
        <SingleChart key={index} data={item} />
      ))}
    </div>
  );
};

export default ThermometerChart;
