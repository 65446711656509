import { downloadFile } from "../../../http/aggregate/ImportExportService";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { TrackException } from "../../../logging/LoggingManager";
import {
  ExploreActions,
  ExploreState,
} from "../../../state/ExploreState/ExploreState";
import useNitrogenInputPaneStore from "../../../state/NitrogenState/nitrogenInputPaneState";
import useNitrogenStore from "../../../state/NitrogenState/nitrogenState";
import useProjectsStore from "../../sharedLogic/state/projectsState";
import { aggregateApiClient } from "../../sharedLogic/services/AxiosClient";
import { ScenarioDto } from "../types/api";

const baseUrl = "/api/emission";
export async function GetNitrogenScenarios(
  signal: AbortSignal,
  currentState?: ExploreState<ScenarioDto> & ExploreActions<ScenarioDto>
): Promise<ScenarioDto[]> {
  try {
    const nitrogenInputPaneState = useNitrogenInputPaneStore.getState();
    const tasks = useNitrogenStore.getState().activities;
    const workingDays = useNitrogenStore.getState().workingDays;
    const filteredTasks = tasks.filter((x) => x.id.startsWith("task-"));

    if (filteredTasks.length === 0) {
      return [];
    }

    const data = JSON.stringify(filteredTasks);
    const response = await aggregateApiClient.post<ScenarioDto[]>(
      `${baseUrl}/calculate/scenarios`,
      {
        bvoProject: nitrogenInputPaneState.bvoProject,
        annualNOxLimit: nitrogenInputPaneState.annualNOxLimit,
        annualNH3Limit: nitrogenInputPaneState.annualNH3Limit,
        annualCO2Limit: nitrogenInputPaneState.annualCO2Limit,
        workingDays: workingDays,
        planningActivities: data,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );

    const scenarios = response.data.map((s) => ({
      ...s,
      typeObject: TypeObject.NitrogenAnalyser,
      color: "#FF0000",
      renderType: "chart" as "chart" | "image" | "other",
    }));

    currentState?.setObjectsTotalCount(scenarios.length);
    return scenarios;
  } catch (ex) {
    console.error("Fout bij het ophalen van stikstofscenario's:", ex);
    TrackException(ex);
    return [];
  }
}

export async function GetScenario(id: number): Promise<ScenarioDto> {
  return {} as ScenarioDto;
}

export async function ExportScenario(scenario: ScenarioDto): Promise<void> {
  try {
    const response = await aggregateApiClient.post<ScenarioDto[]>(
      `${baseUrl}/export/scenario`,
      {
        projectName: useProjectsStore.getState().currentProject?.name,
        emissionDetails: scenario,
      },
      {
        responseType: "arraybuffer",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Type": "application/json",
        },
      }
    );

    await downloadFile(response, "scenario", "excel");
  } catch (ex) {
    TrackException(ex);
  }
}
