import { CustomTask } from "./public-types";

interface ProjectStyles {
  backgroundColor: string;
  backgroundSelectedColor: string;
  progressColor: string;
  progressSelectedColor: string;
}

export const projectStyleMap: { [key: string]: ProjectStyles } = {
  "1. Aanloop": {
    backgroundColor: "#FFADAD",
    backgroundSelectedColor: "#FFADAD",
    progressColor: "#FFADAD",
    progressSelectedColor: "#FFADAD",
  },
  "2. Onderbouw": {
    backgroundColor: "#FFD6A5",
    backgroundSelectedColor: "#FFD6A5",
    progressColor: "#FFD6A5",
    progressSelectedColor: "#FFD6A5",
  },
  "3. Bovenbouw": {
    backgroundColor: "#FDFFB6",
    backgroundSelectedColor: "#FDFFB6",
    progressColor: "#FDFFB6",
    progressSelectedColor: "#FDFFB6",
  },
  "4. Gevel-Dak": {
    backgroundColor: "#E4F1EE",
    backgroundSelectedColor: "#E4F1EE",
    progressColor: "#E4F1EE",
    progressSelectedColor: "#E4F1EE",
  },
  "5. Afbouw bouwkundig": {
    backgroundColor: "#DEDAF4",
    backgroundSelectedColor: "#DEDAF4",
    progressColor: "#DEDAF4",
    progressSelectedColor: "#DEDAF4",
  },
  "6. Installaties": {
    backgroundColor: "#D9EDF8",
    backgroundSelectedColor: "#D9EDF8",
    progressColor: "#D9EDF8",
    progressSelectedColor: "#D9EDF8",
  },
  "7. Schoonmaak": {
    backgroundColor: "#A85FA8",
    backgroundSelectedColor: "#A85FA8",
    progressColor: "#A85FA8",
    progressSelectedColor: "#A85FA8",
  },
  "8. Commissioningsfase": {
    backgroundColor: "#800080",
    backgroundSelectedColor: "#800080",
    progressColor: "#800080",
    progressSelectedColor: "#800080",
  },
};

const addOpacity = (hex: string, opacity: number): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const isWorkingDay = (date: Date, workingDays: string[]): boolean => {
  // Get an abbreviated name of the day
  const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" });
  // Check if it's in the list of working days
  return workingDays.includes(dayOfWeek);
};

export const calculateIntervalForTask = (
  task: CustomTask,
  allTasks: CustomTask[],
  workingDays: string[]
) => {
  if (!task.dependencies || task.dependencies.length === 0) {
    return "0";
  }

  const latestDependencyEnd = task.dependencies
    .map((depId) => allTasks.find((t) => t.id === depId)?.end)
    .filter((endDate) => endDate)
    .reduce(
      (latest, current) => current ?? latest,
      new Date(new Date().setHours(0, 0, 0, 0))
    );

  if (task.start && latestDependencyEnd) {
    // Calculate the number of working days between the latest dependency end and this task's start
    let interval = 0;
    const currentDate = new Date(latestDependencyEnd);

    while (currentDate < task.start) {
      currentDate.setDate(currentDate.getDate() + 1);
      if (isWorkingDay(currentDate, workingDays)) {
        interval++;
      }
    }

    // Subtract one day because the count includes the day after latestDependencyEnd
    return interval.toString();
  }

  return "0";
};

export const calculateDurationBetweenDates = (
  startDate: Date,
  endDate: Date,
  workingDays: string[]
): number => {
  const currentDate = new Date(startDate);
  const currentEndDate = new Date(endDate);
  
  let duration = 0;
  // Loop through each day between the start and end dates
  while (currentDate < currentEndDate) {
    if (isWorkingDay(currentDate, workingDays)) {
      duration += 1;
    }
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return Math.round(duration);
};

export const ExtendTheEndDateToCoverTheWholeDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999); // Set to the end of the day
  return newDate;
};

export const snapStartToDay = (date: Date) => {
  const newDate = new Date(date);
  const hours = newDate.getHours();
  if (hours >= 12) {
    newDate.setHours(24, 0, 0, 1);
  } else {
    newDate.setHours(0, 0, 0, 0);
  }
  return newDate;
};

export const snapEndToDay = (date: Date) => {
  const newDate = new Date(date);
  const hours = newDate.getHours();
  if (hours >= 12) {
    newDate.setHours(23, 59, 59, 999);
  } else {
    newDate.setHours(0, 0, 0, -1);
  }
  return newDate;
};

export const calculateEndFromStartAndDuration = (
  startDate: Date,
  duration: number,
  workingDays: string[]
): Date => {
  const currentDate = new Date(startDate);
  let remainingDays = duration;

  // Move to the next day initially, then check if it's a working day
  while (remainingDays > 1) {
    currentDate.setDate(currentDate.getDate() + 1);
    if (isWorkingDay(currentDate, workingDays)) {
      remainingDays -= 1;
    }
  }

  return ExtendTheEndDateToCoverTheWholeDay(currentDate);
};

export const calculateStartFromEndAndDuration = (
  endDate: Date,
  duration: number,
  workingDays: string[]
): Date => {
  const currentDate = new Date(endDate);
  let remainingDays = duration;

  // Move to the previous day initially, then check if it's a working day
  while (remainingDays > 0) {
    currentDate.setDate(currentDate.getDate() - 1);
    if (isWorkingDay(currentDate, workingDays)) {
      remainingDays -= 1;
    }
  }

  return currentDate;
};

export const calculateWorkingDaysDuration = (
  startDate: Date,
  endDate: Date,
  workingDays: string[]
): number => {
  const currentDate = new Date(startDate);
  let duration = 0;

  // Loop through each day between the start and end dates
  while (currentDate < endDate) {
    if (isWorkingDay(currentDate, workingDays)) {
      duration += 1;
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return duration;
};

export const calculateDuration = (
  quantity: number,
  productionRate: number,
  numberOfTeams: number
): number => {
  return Math.ceil(quantity / productionRate / numberOfTeams);
};

export const applyProjectStyles = (tasks: CustomTask[]): CustomTask[] => {
  return tasks.map((task) => {
    const isProjectHeader = task.type === "project";

    const project = tasks.find((t) => {
      return (
        t.id === task.project?.toString() && !task.id.startsWith("button-")
      );
    });
    const name = isProjectHeader ? task.name : project?.name ?? "";

    if (name in projectStyleMap) {
      const projectColors = projectStyleMap[name];

      const modifiedColors = {
        ...projectColors,
        backgroundColor: isProjectHeader
          ? projectColors.backgroundColor
          : addOpacity(projectColors.backgroundColor, 0.4),
        backgroundSelectedColor: isProjectHeader
          ? projectColors.backgroundSelectedColor
          : addOpacity(projectColors.backgroundSelectedColor, 0.4),
        progressColor: isProjectHeader
          ? projectColors.progressColor
          : addOpacity(projectColors.progressColor, 0.4),
        progressSelectedColor: isProjectHeader
          ? projectColors.progressSelectedColor
          : addOpacity(projectColors.progressSelectedColor, 0.4),
      };

      return { ...task, styles: modifiedColors };
    }
    return task;
  });
};

export const adjustScrollBottom = () => {
  const leftPane = document.querySelector(".left-pane");
  const leftScrollElement = document.querySelector(
    ".ag-body-horizontal-scroll"
  );

  const equipmentScrollElement = document.querySelector(".css-1rtad1");
  const ganttScrollElement = document.querySelector("._2k9Ys");

  const righteScrollElement = equipmentScrollElement
    ? equipmentScrollElement
    : ganttScrollElement;

  if (leftPane && leftScrollElement) {
    const leftPaneHeight = (leftPane as HTMLElement).clientHeight;
    const maxAllowedHeight = window.innerHeight * 0.84;

    if (leftPaneHeight < maxAllowedHeight) {
      const scrollBottom = `${maxAllowedHeight - leftPaneHeight}px`;
      (leftScrollElement as HTMLElement).style.bottom = scrollBottom;
    } else {
      (leftScrollElement as HTMLElement).style.bottom = ".8vh";
    }
  }
  if (leftPane && righteScrollElement) {
    const leftPaneHeight = (leftPane as HTMLElement).clientHeight;
    const maxAllowedHeight = window.innerHeight * 0.84;

    if (leftPaneHeight < maxAllowedHeight) {
      const scrollBottom = `${maxAllowedHeight - leftPaneHeight}px`;

      (righteScrollElement as HTMLElement).style.bottom = scrollBottom;
    } else {
      (righteScrollElement as HTMLElement).style.bottom = ".8vh";
    }
  }
};

export const adjustScrollWidth = (leftWidth: number) => {
  const leftScrollElement = document.querySelector(
    ".ag-body-horizontal-scroll"
  );

  const equipmentScrollElement = document.querySelector(".css-1rtad1");
  const ganttScrollElement = document.querySelector("._2k9Ys");
  const righteScrollElementWidth = `${100 - leftWidth - 1}%`;

  const righteScrollElement = ganttScrollElement
    ? ganttScrollElement
    : equipmentScrollElement;

  if (leftScrollElement) {
    (leftScrollElement as HTMLElement).style.width = `${leftWidth}%`;
  }

  if (righteScrollElement) {
    (righteScrollElement as HTMLElement).style.width = righteScrollElementWidth;
  }
};

export function initilaizeActivities(
  mainPhases: CustomTask[],
  setActivities: (newValue: CustomTask[]) => void
) {
  const activities = [] as CustomTask[];
  if (mainPhases === undefined) return;
  mainPhases.forEach((phase, index) => {
    const displayOrder = (index + 1) * 2;
    activities.push({
      id: phase.id.toString(),
      name: phase.name,
      start: new Date(new Date().setHours(0, 0, 0, 0)),
      end: new Date(new Date().setHours(0, 0, 0, 0)),
      duration: 0,
      progress: 100,
      type: "project",
      displayOrder: displayOrder,
    });

    const addButtonPlaceholder: CustomTask = {
      id: `button-${phase.id}`,
      project: phase.id,
      name: "",
      start: phase.end ? phase.end : new Date(new Date().setHours(0, 0, 0, 0)),
      end: phase.end ? phase.end : new Date(new Date().setHours(0, 0, 0, 0)),
      duration: 0,
      type: "task",
      displayOrder: displayOrder + 1,
      styles: {
        backgroundColor: "transparent",
        backgroundSelectedColor: "transparent",
        progressColor: "transparent",
        progressSelectedColor: "transparent",
      },
      progress: 0,
    };
    activities.push(addButtonPlaceholder);
  });

  setActivities(applyProjectStyles(activities));
}

export const handleProgressChange = async (task: CustomTask) => {
  // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  console.log("On progress change Id:" + task.id);
};

export const handleDblClick = (task: CustomTask) => {
  alert("On Double Click event Id:" + task.id);
};

export const handleClick = (task: CustomTask) => {
  console.log("On Click event Id:" + task.id);
};

export const handleSelect = (task: CustomTask, isSelected: boolean) => {
  console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
};

export const handleExpanderClick = (task: CustomTask) => {
  // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  console.log("On expander click Id:" + task.id);
};
