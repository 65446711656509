import { WaterBufferDto } from "../../../interfaces/foundry/typicals/WaterBufferDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";

export async function GetWaterBuffer(
  id: number
): Promise<WaterBufferDto> {
  return (await GetWaterBuffers()).find(
    (x) => x.id === id
  ) as WaterBufferDto;
}

export async function GetWaterBuffers(): Promise<WaterBufferDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let waterbuffers = store.waterBuffers;
  if (waterbuffers.length === 0) {
    waterbuffers = await GetWaterBufferDtos();
    store.setWaterBuffers(waterbuffers);
  }
  return waterbuffers;
}

async function GetWaterBufferDtos(): Promise<WaterBufferDto[]> {
  try {
    const buffers = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/waterbuffers"))
        .data.results as WaterBufferDto[]
    ).map((item: WaterBufferDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return buffers;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
