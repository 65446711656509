import {
  Box,
  Checkbox,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useMemo } from "react";
import CustomFormControl from "../../../../components/CustomFormControl/CustomFormControl";
import {
  ExploreConfigStore,
  FilterCondition,
} from "../../../sharedLogic/types/ExploreConfig";
import InfoField, { DetailedInfo } from "../../../sharedLogic/types/InfoField";
import { createExploreStore } from "../../../sharedLogic/state/ExploreState/ExploreState";
import { SpaceBuildingDto } from "../../types/api";

export const useApartmentBuildingConfigStore = (
  accessTypeOptions: string[]
): ExploreConfigStore<SpaceBuildingDto> => {
  const currentStore = useMemo(
    () => createExploreStore<SpaceBuildingDto>(),
    []
  );

  const {
    objects,
    brushedObjects,
    setFilteredObjects,
    setFilteredObjectsWithCoolingTypes,
  } = currentStore();

  const [selectedAccessTypes, setSelectedAccessTypes] = React.useState<
    string[]
  >([]);

  useEffect(() => {
    if (objects !== null) {
      handleObjectFilters(objects ?? []);
    }
  }, [selectedAccessTypes]);

  const sortByOptionsDictionary: { [key: string]: string } = {
    "Best fit rate": "value",
  };

  const defaultSelectedAxes = ["nrOfFloors"];

  const summaryInfo: InfoField[] = [
    { label: "accessType", property: "accessType", unit: "" },
    { label: "Apartments", property: "nrOfAppartments", unit: "st" },
    { label: "absoluteValue", property: "costInEuro", unit: "€" },
  ];

  const detailedInfo: DetailedInfo[] = [
    {
      category: "General",
      fields: [
        {
          label: "StackedHousing.AccessibilityType",
          property: "accessType",
          unit: "",
        },
        {
          label: "StackedHousing.Apartment",
          property: "nrOfAppartments",
          unit: "st",
          type: "number",
        },
        {
          label: "StackedHousing.Floors",
          property: "nrOfFloors",
          unit: "st",
          type: "number",
        },
        {
          label: "StackedHousing.UnitMix",
          property: "unitMix",
          type: "unitMix",
          unit: "",
        },
      ],
    },
    {
      category: "Dimensions",
      fields: [
        {
          label: "Length",
          property: "dimensions.xSizeInM",
          unit: "m",
          type: "number",
        },
        {
          label: "Width",
          property: "dimensions.ySizeInM",
          unit: "m",
          type: "number",
        },
        {
          label: "Height",
          property: "dimensions.zSizeInM",
          unit: "m",
          type: "number",
        },
        {
          label: "StackedHousing.BVOTotal",
          property: "grossFloorAreaInM2",
          unit: "m2",
          type: "number",
        },
        {
          label: "StackedHousing.BVOHousing",
          property: "grossFloorAreaLivingInM2",
          unit: "m2",
          type: "number",
        },
        {
          label: "StackedHousing.BVOOutdoor",
          property: "grossFloorAreaOutdoorInM2",
          unit: "m2",
          type: "number",
        },
        {
          label: "StackedHousing.GOHousing",
          property: "usableAreaTotalInM2",
          unit: "m2",
          type: "number",
        },
        {
          label: "StackedHousing.BBO",
          property: "grossFootprintAreaInM2",
          unit: "m2",
          type: "number",
        },
        {
          label: "StackedHousing.BGO",
          property: "usableAreaTotalInM2",
          unit: "m2",
          type: "number",
        },
        {
          label: "StackedHousing.BDO",
          property: "grossRoofAreaInM2",
          unit: "m2",
          type: "number",
        },
      ],
    },
    {
      category: "Efficiency",
      fields: [
        {
          label: "StackedHousing.FSI",
          property: "absoluteValue",
          unit: "-",
          type: "number",
        },
        {
          label: "StackedHousing.BVO/GO",
          property: "valuePerM2",
          unit: "-",
          type: "number",
        },
        {
          label: "StackedHousing.ValuePerGo",
          property: "valuePerGo",
          unit: "€/m2",
          type: "number",
        },
        {
          label: "StackedHousing.ValuePerApp",
          property: "valuePerAppartment",
          unit: "€/st",
          type: "number",
        },
      ],
    },
    {
      category: "Valuation",
      fields: [
        {
          label: "StackedHousing.DirectBuildingCosts",
          property: "costInEuro",
          unit: "€",
          type: "number",
        },
        {
          label: "StackedHousing.InvestmentValue",
          property: "investmentValue",
          unit: "€",
          type: "number",
        },
        {
          label: "StackedHousing.RentPrice",
          property: "rentalPrice",
          unit: "€",
          type: "number",
        },
        {
          label: "StackedHousing.CO2",
          property: "co2Footprint",
          unit: "kg",
          type: "number",
        },
      ],
    },
    {
      category: "Components",
      fields: [],
    },
  ];

  const dimensions = [
    "nrOfAppartments",
    "structuralHeightInM",
    "nrOfFloors",
    "grossFloorAreaInM2",
    "grossFootprintAreaInM2",
    "fsi",
    "bvoGo",
    "valuePerGO",
    "costInEuro",
    "investmentValue",
    "co2",
    "costsVSRevenues",
  ];

  const chartLabels = [
    "StackedHousing.BuildingGraph.Appartments",
    "StackedHousing.BuildingGraph.Height",
    "StackedHousing.BuildingGraph.Floors",
    "StackedHousing.BuildingGraph.BVO",
    "StackedHousing.BuildingGraph.BBO",
    "StackedHousing.BuildingGraph.FSI",
    "StackedHousing.BuildingGraph.BVO/GO",
    "StackedHousing.BuildingGraph.ValuePerGo",
    "StackedHousing.BuildingGraph.DirectBuildingCosts",
    "StackedHousing.BuildingGraph.InvestmentValue",
    "StackedHousing.BuildingGraph.CO2",
    "StackedHousing.BuildingGraph.CostsVSRevenues",
  ];
  const clickableAxes = [
    "nrOfAppartments",
    "structuralHeightInM",
    "nrOfFloors",
    "grossFloorAreaInM2",
  ];
  const bestFitOptionsDictionary = {
    Height: "structuralHeightInM",
    Floors: "nrOfFloors",
  };
  const bestFitOptionsDictionaryReversed = {
    structuralHeightInM: "Height",
    nrOfFloors: "Floors",
  };

  const inputPaneContent = () => (
    <>
      <div className="title">{""}</div>
      <CustomFormControl
        labelText={t("accessType")}
        showInfoButton={true}
        toolTipText={t("accessType")}
      >
        <Select
          multiple
          value={selectedAccessTypes}
          style={{ height: "auto" }}
          labelId="type-power-rack-label"
          id="type-power-rack-select"
          onChange={handleChangeAccessType}
          label={t("accessType")}
          renderValue={(selected) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selected.map((value) => (
                <Chip key={value} label={t(value)} />
              ))}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  accessTypeOptions.length > 0 &&
                  selectedAccessTypes.length === accessTypeOptions.length
                }
                indeterminate={
                  selectedAccessTypes.length > 0 &&
                  selectedAccessTypes.length < accessTypeOptions.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {accessTypeOptions?.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedAccessTypes.indexOf(name) > -1} />
              <ListItemText primary={t(name)} />
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
    </>
  );

  const handleChangeAccessType = (event: SelectChangeEvent<string[]>) => {
    const selectedOptions = event.target.value;
    const valuesAccessType = Object.values(accessTypeOptions);

    if (selectedOptions.includes("all")) {
      setSelectedAccessTypes(
        selectedAccessTypes.length === valuesAccessType.length
          ? []
          : valuesAccessType
      );
    } else {
      const selectedAccessTypes = valuesAccessType.filter((accessType) =>
        accessType
          ? selectedOptions.includes(accessType.toString())
          : selectedOptions.includes("-1")
      );
      setSelectedAccessTypes(selectedAccessTypes);
    }
  };

  const filterConditions: FilterCondition<SpaceBuildingDto>[] = [];

  const handleObjectFilters = (objects: SpaceBuildingDto[]) => {
    let filteredBuidings = objects;
    if (selectedAccessTypes.length !== 0) {
      filteredBuidings = filteredBuidings.filter((datacenter) => {
        return selectedAccessTypes.includes(datacenter.accessType);
      });
    }

    setFilteredObjects(filteredBuidings);
    setFilteredObjectsWithCoolingTypes(filteredBuidings);

    return filteredBuidings;
  };
  function handleCustomFilter() {
    const tempWhitespaces = [...brushedObjects];
    return tempWhitespaces;
  }

  return {
    sortByOptionsDictionary,
    filterConditions,
    currentStore,
    summaryInfo,
    detailedInfo,
    inputPaneContent,
    dimensions,
    chartLabels,
    clickableAxes,
    bestFitOptionsDictionary,
    bestFitOptionsDictionaryReversed,
    handleObjectFilters,
    handleCustomFilter,
    defaultSelectedAxes,
  };
};
