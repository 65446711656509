import React from "react";
import { IconProps } from "./types";
import "./Icon.scss";

export const TriangleIcon: React.FC<IconProps> = ({ size = 18, ...props }) => {
  return (
    <svg
      fill="#000000"
      height={size}
      width={size}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
    >
      <polygon points="245,456.701 490,33.299 0,33.299 " />
    </svg>
  );
};
