import { Collapse } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMore from "../../../ExpandMore/ExpandMore";
import LowVoltageRoomControl from "../LowVoltageRoom/LowVoltageRoomControl";
import WhitespaceControl from "../Whitespace/WhitespaceControl";
import "./DatacenterControl.scss";

export default function DatacenterControl() {
  const { t } = useTranslation();
  const [lsrExpanded, setLsrExpanded] = useState<boolean>(false);
  const [whitespaceExpanded, setWhitespaceExpanded] = useState<boolean>(false);

  return (
    <div className="datacenter-control">
      <div className="low-voltage-room-header">
        <ExpandMore
          sx={{ marginLeft: "5px" }}
          expand={lsrExpanded}
          onClick={() => setLsrExpanded(!lsrExpanded)}
          aria-expanded={lsrExpanded}
          aria-label="show more"
        >
          <GridExpandMoreIcon />
        </ExpandMore>
        <div className="title">{`${t("Preselection")} ${t(
          "Low voltage room"
        ).toLowerCase()}`}</div>
      </div>
      <Collapse in={lsrExpanded} timeout="auto" unmountOnExit>
        <LowVoltageRoomControl />
      </Collapse>

      <div className="whitespace-header">
        <ExpandMore
          sx={{ marginLeft: "5px" }}
          expand={whitespaceExpanded}
          onClick={() => setWhitespaceExpanded(!whitespaceExpanded)}
          aria-expanded={whitespaceExpanded}
          aria-label="show more"
        >
          <GridExpandMoreIcon />
        </ExpandMore>
        <div className="title">{`${t("Preselection")} ${t("whitespace")}`}</div>
      </div>
      <Collapse in={whitespaceExpanded} timeout="auto" unmountOnExit>
        <WhitespaceControl />
      </Collapse>
    </div>
  );
}
