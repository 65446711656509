import React from "react"; // Import React to make JSX work
import { SearchBox } from "@mapbox/search-js-react";
import useDatacenterGeneratorStore from "../../../../state/DatacenterState/datacenterGeneratorState";
import { MapboxAccessToken } from "../../../../config";

export default function CustomSearchBox() {
  const { projectAddress, setProjectAddress, setProjectAddressFeature } =
    useDatacenterGeneratorStore();

  return (
    <SearchBox
      value={projectAddress}
      onChange={(event) => {
        if (event?.value) {
          setProjectAddress(event.value);
        }
      }}
      onRetrieve={(event) => {
        if (event?.features?.length > 0) {
          setProjectAddressFeature(event.features[0]);
        }
      }}
      accessToken={MapboxAccessToken}
    />
  );
}
