import { FeatureCollection } from "@turf/turf";
import ColorPalette from "../../../interfaces/ColorPalette";
import { Layout } from "../../HSA/types/api";
import DrawedPlot, { GetEmptyDrawedPlot } from "../types/DrawedPlot";
import { TypeObject } from "../types/enums";

export interface BaseConfiguratorState {
  drawedPlot: DrawedPlot;
  currentPageType: TypeObject | null;
  projectAddressFeature: any;
  startDrawingPlot: boolean;
  editDrawingPlot: boolean;
  deleteDrawingPlot: boolean;
  navigateDrawingPlot: boolean;
  isInputPaneOpen: boolean;
  currentTabPageIndex: number;
  exportTabDisabled: boolean;
  calculateDistance: boolean;
  distanceBetweenPlotAndRoad: number;
  isSaveAvailable: boolean;
  currentLayouts: Layout[];
  drawControlMode: string;
  colorPalette: ColorPalette | undefined;
  selectedExportedBuilingsIds: { code: string; count: number }[];
  buildings: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
}

export interface BaseConfiguratorActions {
  setDrawedPlot: (drawedPlot: DrawedPlot) => void;
  setProjectAddressFeature: (projectAddressFeature: any) => void;
  setStartDrawingPlot: (newValue: boolean) => void;
  setEditDrawingPlot: (boolValue: boolean) => void;
  setDeleteDrawingPlot: (boolValue: boolean) => void;
  setNavigateDrawingPlot: (newValue: boolean) => void;
  setIsInputPaneOpen: (boolValue: boolean) => void;
  setCurrentTabPageIndex: (newValue: number) => void;
  setExportTabDisabled: (boolValue: boolean) => void;
  setDistanceBetweenPlotAndRoad: (values: number) => void;
  setCalculateDistance: (values: boolean) => void;
  setCurrentPageType: (value: TypeObject | null) => void;
  setIsSaveAvailable: (value: boolean) => void;
  setCurrentLayouts: (value: Layout[]) => void;
  setDrawControlMode: (value: string) => void;
  setColorPalette: (colorPalette: ColorPalette) => void;
  setSelectedExportedBuildingsIds: (
    values: { code: string; count: number }[]
  ) => void;
  setBuildings: (buildings: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>) => void;
}

export function getBaseInitialState(): BaseConfiguratorState {
  return {
    drawedPlot: GetEmptyDrawedPlot(),
    currentPageType: null,
    projectAddressFeature: null,
    startDrawingPlot: false,
    editDrawingPlot: false,
    deleteDrawingPlot: false,
    navigateDrawingPlot: false,
    isInputPaneOpen: true,
    currentTabPageIndex: 0,
    exportTabDisabled: false,
    distanceBetweenPlotAndRoad: 0,
    calculateDistance: false,
    isSaveAvailable: false,
    currentLayouts: [],
    drawControlMode: "",
    colorPalette: undefined,
    selectedExportedBuilingsIds: [],
    buildings: {
      type: "FeatureCollection",
      features: [],
    } as GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>,
  };
}

export function getBaseActions(set: any): BaseConfiguratorActions {
  return {
    setDrawedPlot: (drawedPlot) => set({ drawedPlot: drawedPlot }),
    setProjectAddressFeature: (feature) =>
      set({ projectAddressFeature: feature }),
    setStartDrawingPlot: (newValue) => set({ startDrawingPlot: newValue }),
    setEditDrawingPlot: (newValue) => set({ editDrawingPlot: newValue }),
    setDeleteDrawingPlot: (newValue) => set({ deleteDrawingPlot: newValue }),
    setNavigateDrawingPlot: (newValue) =>
      set({ navigateDrawingPlot: newValue }),
    setIsInputPaneOpen: (newValue) => set({ isInputPaneOpen: newValue }),
    setCurrentTabPageIndex: (newValue) =>
      set({ currentTabPageIndex: newValue }),
    setExportTabDisabled: (newValue) => set({ exportTabDisabled: newValue }),
    setDistanceBetweenPlotAndRoad: (newValue) =>
      set({ distanceBetweenPlotAndRoad: newValue }),
    setCalculateDistance: (newValue) => set({ calculateDistance: newValue }),
    setCurrentPageType: (value) => set({ currentPageType: value }),
    setIsSaveAvailable: (newValue) => set({ isSaveAvailable: newValue }),
    setCurrentLayouts: (value) => set({ currentLayouts: value }),
    setDrawControlMode: (value) => set({ drawControlMode: value }),
    setColorPalette: (colorPalette) => set({ colorPalette: colorPalette }),
    setSelectedExportedBuildingsIds: (values) =>
      set({ selectedExportedBuilingsIds: values }),
    setBuildings: (buildings) => set({ buildings: buildings }),
  };
}
