import { UpsDto } from "../../../interfaces/foundry/typicals/UPSDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { TrackException } from "../../../logging/LoggingManager";
import { SupplierDto } from "../../../interfaces/foundry/typicals/SupplierDto";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { GetSuppliers } from "./SupplierService";

export async function GetUps(id: number): Promise<UpsDto> {
  return (await GetUpss()).find((x) => x.id === id) as UpsDto;
}

export async function GetUpss(update?: boolean): Promise<UpsDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let upss = store.upss;
  if (upss.length === 0 || update) {
    upss = await GetUpsDtos(await GetSuppliers());
    store.setUPS(upss);
  }
  return upss;
}

async function GetUpsDtos(suppliers: SupplierDto[]): Promise<UpsDto[]> {
  const upssData = (await foundryApiClient.get<ResponseDto>("/api/general/ups"))
    .data;
  const upss = (upssData.results as UpsDto[]).map((item: UpsDto) => {
    const currentSupplier: SupplierDto | undefined = suppliers?.find(
      (sup) => sup.id === item.supplier
    );
    item = {
      ...item,
      heatload_in_w: item.heatload_in_w / 1000,
      efficiency: item.efficiency * 100,
      typeObject: TypeObject.Ups,
      supplier_name: currentSupplier?.name ? currentSupplier?.name : "",
    };
    return item;
  });
  return upss;
}

export async function SaveUps(ups: UpsDto) {
  try {
    ups = {
      ...ups,
      efficiency: ups.efficiency / 100,
    };
    const response = await foundryApiClient.post<UpsDto>(
      "/api/general/ups/",
      ups
    );

    await GetUpss(true);

    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function UpdateUps(ups: UpsDto) {
  try {
    ups = {
      ...ups,
      efficiency: ups.efficiency / 100,
    };
    const response = await foundryApiClient.patch<UpsDto>(
      `/api/general/ups/${ups.id}/`,
      ups
    );

    await GetUpss(true);

    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function DeleteUps(ups: UpsDto) {
  try {
    const response = await foundryApiClient.delete<UpsDto>(
      `/api/general/ups/${ups.id}`
    );

    await GetUpss(true);
    
    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function UpdateUpsBuildingTypes(
  upsId: number,
  buildingTypesIds: number[]
): Promise<UpsDto> {
  try {
    const response = await foundryApiClient.patch<UpsDto>(
      `/api/general/ups/${upsId}/`,
      { typical_types: buildingTypesIds }
    );

    await GetUpss(true);
    
    return {
      ...response.data,
      heatload_in_w: response.data.heatload_in_w / 1000,
      efficiency: response.data.efficiency * 100,
      typeObject: TypeObject.Ups,
    };
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
