import { TrackException } from "../../sharedLogic/logging/LoggingManager";
import { forgeApiClient } from "../../sharedLogic/services/AxiosClient";

const baseUrl = "/api/v1/relations";

export async function EditModuleRelation(relationship: any): Promise<any> {
  try {
    let updatedRelationship = {} as any;
    await forgeApiClient
      .put<any>(baseUrl, relationship)
      .then((res) => (updatedRelationship = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return updatedRelationship;
  } catch (ex) {
    TrackException(ex);
    return {} as any;
  }
}

export async function GetModuleRelations(
  moduleFlag: string
): Promise<any | null> {
  try {
    let response = await forgeApiClient.get<any>(
      `${baseUrl}/by-flag/${moduleFlag}`
    );

    return response.data;
  } catch (ex) {
    TrackException(ex);
    return null;
  }
}
