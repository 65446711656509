import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { AzureApplicationInsightsConnectionString } from "../config";

const browserHistory = createBrowserHistory();
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
};
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: AzureApplicationInsightsConnectionString,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});
ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
