export const convertMilimetersToMeters = (
  valueInMilimeter: number | undefined
) => (valueInMilimeter ? valueInMilimeter / 1000 : 0);

export const convertMetersToMilimeters = (valueInMeters: number | undefined) =>
  valueInMeters ? Math.round(valueInMeters * 1000) : 0;

export const convertSquareMilimetersToSquareMeters = (
  valueInSquareMilimeter: number | undefined
) => (valueInSquareMilimeter ? valueInSquareMilimeter / 1000000 : 0);

export const convertSquareMetersToSquareMilimeters = (
  valueInSquareMeters: number | undefined
) => (valueInSquareMeters ? Math.round(valueInSquareMeters * 1000000) : 0);

export const convertCentsToEuro = (valueInCents: number | undefined) =>
  valueInCents ? valueInCents / 100 : 0;

export const convertEuroToCents = (valueInEuro: number | undefined) =>
  valueInEuro ? Math.round(valueInEuro * 100) : 0;

export const formatValue = (amount: number | string, unit: string) => {
  if (amount === undefined) {
    return "";
  } else if (typeof amount === "string") {
    return amount;
  }

  if (unit !== "st") {
    return amount
      .toLocaleString("nl-NL", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace("€", "")
      .replace("€/m2", "")
      .trim();
  }
  return amount.toString();
};

export function roundNumber(num: number, fractionDigits: number): number {
  return Number(num.toFixed(fractionDigits));
}
