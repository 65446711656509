import { ChevronLeft } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BestFitRate from "../../../HSA/components/BestFitRate/BestFitRate";
import { SpaceBuildingDto } from "../../../HSA/types/api";
import { ExploreConfigStore } from "../../types/ExploreConfig";
import "./InputPane.scss";

interface InputPaneProps<T extends SpaceBuildingDto> {
  label: string;
  isInputPaneOpen: boolean;
  currentTypicalConfigStore: ExploreConfigStore<T>;
  setIsInputPaneOpen: (value: boolean) => void;
}

export default function InputPane<T extends SpaceBuildingDto>({
  label,
  isInputPaneOpen,
  setIsInputPaneOpen,
  currentTypicalConfigStore,
}: InputPaneProps<T>) {
  const { t } = useTranslation();
  const currentState = currentTypicalConfigStore?.currentStore();

  useEffect(() => {
    if (!currentState) return;

    currentState.setSelectedAxes(
      currentState.selectedAxes && currentState.selectedAxes.length > 0
        ? currentState.selectedAxes
        : currentTypicalConfigStore!.defaultSelectedAxes ?? []
    );
  }, []);

  return (
    <div className="input-pane">
      {isInputPaneOpen && (
        <div className="input-pane-button">
          <IconButton
            style={{ zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronLeft sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}
      <div className="input-pane-datacenter-content">
        <div className="pt20" />
        {currentState && (
          <div className="filter-control-panel">
            <div className="big-title">{t(label)}</div>
            {currentTypicalConfigStore.bestFitOptionsDictionary && (
              <BestFitRate
                currentTypicalConfigStore={currentTypicalConfigStore}
              />
            )}
            {currentTypicalConfigStore.inputPaneContent &&
              currentTypicalConfigStore.inputPaneContent(
                currentTypicalConfigStore
              )}
          </div>
        )}
      </div>
    </div>
  );
}
