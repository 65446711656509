import { create } from "zustand";
import ProjectDto from "../types/api";

interface ProjectsState {
  projects: ProjectDto[];
  currentProject: ProjectDto;
  isProjectRenaming: boolean;
  isProjectSavingAs: boolean;
  isProjectViewOpen: boolean;
}

interface ProjectActions {
  setProjects: (projects: ProjectDto[]) => void;
  setCurrentProject: (project: ProjectDto) => void;
  setProjectAddress: (address: string) => void;
  setRenamingProject: (isRenaming: boolean) => void;
  setIsProjectViewOpen: (isOpen: boolean) => void;
  setIsProjectSavingAs: (isSavingAs: boolean) => void;
  resetProjectsState: () => void;
}

const initialState: ProjectsState = {
  projects: [],
  isProjectViewOpen: false,
  isProjectRenaming: false,
  isProjectSavingAs: false,
  currentProject: {} as ProjectDto,
};

const useProjectsStore = create<ProjectsState & ProjectActions>()((set) => ({
  ...initialState,
  setProjects: (newValue: ProjectDto[]) => set({ projects: newValue }),
  setCurrentProject: (newValue: ProjectDto) =>
    set({ currentProject: newValue }),
  setRenamingProject: (newValue: boolean) =>
    set({ isProjectRenaming: newValue, isProjectViewOpen: newValue }),
  setIsProjectViewOpen: (newValue: boolean) =>
    set({ isProjectViewOpen: newValue }),
  setIsProjectSavingAs: (newValue: boolean) =>
    set({ isProjectSavingAs: newValue, isProjectViewOpen: newValue }),
  setProjectAddress: (address: string) => {
    set((state) => ({
      currentProject: {
        ...state.currentProject,
        projectAddress: address,
      },
    }));
  },
  resetProjectsState: () => set(initialState),
}));

export default useProjectsStore;
