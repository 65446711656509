import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExportConstructionTime, ExportCostDetails } from "../../../http/foundry/DatacenterService";
import { ExportIcon } from "../../../icons/ExportIcon";
import { DatacenterDto } from "../../../interfaces/foundry/DatacenterDto";
import {
  FirstCharToUpperCase,
  FormatNumberToEuro,
} from "../../../utils/HelperFunctions";
import FormControlTextField from "../../CustomFormControl/FormControlTextField";
import SceneDetailComponent from "./SceneDetailComponent";
import "./SceneDetailDatacenter.scss";
import SceneDetailLowVoltageRoom from "./SceneDetailLowVoltageRoom";
import SceneDetailWhitespace from "./SceneDetailWhitespace";

interface SceneDetailDatacenterProps {
  datacenter: DatacenterDto;
}

export default function SceneDetailDatacenter({
  datacenter,
}: SceneDetailDatacenterProps) {
  const { t } = useTranslation();

  const components = [
    { type: "Admin", component: datacenter.adminComponent },
    { type: "Chiller", component: datacenter.chillerComponent },
    { type: "CoolingBlock", component: datacenter.coolingBlockComponent },
    { type: "CoolingTower", component: datacenter.coolingTowerComponent },
    {
      type: "EnergySupplyStation",
      component: datacenter.energySupplyStationComponent,
    },
    {
      type: "FiberOpticDistributionArea",
      component: datacenter.fdrComponent,
    },
    { type: "FireRiser", component: datacenter.fireRiserComponent },
    { type: "FreightElevator", component: datacenter.freightElevatorComponent },
    { type: "HallwayArea", component: datacenter.hallwayAreaComponent },
    { type: "LSRShafts", component: datacenter.lsrShaftsComponent },

    { type: "Loadbank", component: datacenter.loadBankComponent },
    { type: "Genset", component: datacenter.genset },
    { type: "PersonsElevator", component: datacenter.personsElevatorComponent },
    { type: "PumpRoom", component: datacenter.pumpRoomComponent },
    { type: "Sanitary", component: datacenter.sanitaryComponent },
    { type: "SprinklerBassin", component: datacenter.sprinklerBassinComponent },
    { type: "Stairwells", component: datacenter.stairwellsComponent },
    { type: "UtilityShafts", component: datacenter.utilityShaftsComponent },
    { type: "ValveRoom", component: datacenter.valveRoomComponent },
    { type: "WaterBuffer", component: datacenter.waterBufferComponent },
    { type: "WaterTreatment", component: datacenter.waterTreatmentComponent },
    {
      type: "Whitespace",
      detailView: (
        <div className="scene-detail-datacenter-view">
          <SceneDetailWhitespace
            whitespace={datacenter.whitespace}
            amount={datacenter.whitespace_amount}
          />
        </div>
      ),
    },
    {
      type: "Low voltage room",
      detailView: (
        <div className="scene-detail-datacenter-view">
          <SceneDetailLowVoltageRoom
            lowVoltageRoom={datacenter.lowVoltageRoom}
            amount={datacenter.lsr_amount}
          />
        </div>
      ),
    },
  ];

  components.sort((a, b) => t(a.type).localeCompare(t(b.type)));

  return (
    <div>
      <div className="title">{t("General")}</div>
      <FormControlTextField
        labelText={t("Feature")}
        value={datacenter?.id || ""}
      />
      <FormControlTextField
        labelText={t("Total power") + " (MW)"}
        value={datacenter?.true_power_in_w || ""}
      />
      <FormControlTextField
        labelText={t("DatacenterPage.WhitespaceITPower")}
        value={datacenter?.whitespace_true_power_in_w || ""}
      />

      <FormControlTextField
        labelText={t("Globals.TypeDatacenter")}
        value={FirstCharToUpperCase(datacenter?.dc_scope || "")}
      />
      <FormControlTextField
        labelText={t("Globals.DestinationPlan")}
        value={
          datacenter?.destination_plan === "I" ? t("Industry") : t("Other")
        }
      />
      <FormControlTextField
        labelText={t("Floors")}
        value={datacenter?.building_layers || ""}
      />

      <div className="title mt20">{t("Dimensions")}</div>
      <FormControlTextField
        labelText={t("Net length(m)")}
        value={datacenter?.length || ""}
      />
      <FormControlTextField
        labelText={t("Net width(m)")}
        value={datacenter?.width || ""}
      />
      <FormControlTextField
        labelText={t("Net height(m)")}
        value={datacenter?.height || ""}
      />
      <FormControlTextField
        labelText={t("DatacenterPage.TotalHeight")}
        value={datacenter?.total_building_height_mm?.toFixed() || ""}
      />
      <FormControlTextField
        labelText={t("DatacenterPage.Area")}
        value={datacenter?.total_bvo?.toFixed() || ""}
      />
      <FormControlTextField
        labelText={t("DatacenterPage.GroundFootprint")}
        value={datacenter?.ground_footprint?.toFixed() || ""}
      />
      <FormControlTextField
        labelText={t("DatacenterPage.FacadeSurface")}
        value={datacenter?.facade_area?.toFixed() || ""}
      />
      <FormControlTextField
        labelText={t("Volume(m3)")}
        value={datacenter?.volume?.toFixed() || ""}
      />
      <div className="title mt20">{t("Efficiency")}</div>
      <FormControlTextField
        labelText={t("kW/m2")}
        value={datacenter?.kw_m2 || ""}
      />
      <FormControlTextField
        labelText={t("kW/m3")}
        value={datacenter?.kw_m3 || ""}
      />
      <div className="title mt20">{t("Valuation")}</div>
      <div className="scene-detail-export-view">
        <FormControlTextField
          labelText={`${t("Costs")} (€)`}
          value={FormatNumberToEuro(datacenter?.cost, false) || ""}
        />

        <IconButton
          className="scene-detail-export-button"
          onClick={async () => {
            await ExportCostDetails(datacenter.id);
          }}
        >
          <ExportIcon className="svg" size={22} />
        </IconButton>
      </div>
      <div className="scene-detail-export-view">
        <FormControlTextField
          labelText={t("DatacenterPage.ConstructionTime")}
          value={datacenter?.construction_time_in_months || ""}
        />
        <IconButton
          className="scene-detail-export-button"
          onClick={async () => {
            await ExportConstructionTime(datacenter.id);
          }}
        >
          <ExportIcon className="svg" size={22} />
        </IconButton>
      </div>

      <FormControlTextField
        labelText={t("CO2 footprint")}
        value={datacenter?.co2_footprint_in_euros || ""}
      />
      <FormControlTextField
        labelText={t("Building kg")}
        value={datacenter?.building_kg || ""}
      />

      <div className="title mt20">{t("Components")}</div>
      <div className="ml10">
        {components.map(
          ({ type, component, detailView }) =>
            (component || detailView) && (
              <SceneDetailComponent
                key={type}
                type={type}
                component={component}
                detailView={detailView ?? null}
              />
            )
        )}
      </div>
    </div>
  );
}
