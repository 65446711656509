import { CategoryDto } from "../interfaces/aggregate/CategoryDto";
import TreeNodeDto from "../interfaces/aggregate/TreeNodeDto";
import { GenerateGuid } from "./HelperFunctions";

export function ConvertToTreeNode(
  categories: CategoryDto[],
  parentId?: number | null
): TreeNodeDto[] {
  const nodes: TreeNodeDto[] = [];
  for (const category of categories) {
    if (category.parentId === parentId) {
      const node: TreeNodeDto = {
        id: category.id?.toString() ?? GenerateGuid(),
        label: category.name,
        parentId: parentId !== null ? parentId?.toString() : undefined,
      };

      const children = ConvertToTreeNode(categories, category.id);
      if (children.length > 0) {
        node.children = children;
      }
      nodes.push(node);
    }
  }

  return nodes;
}

export function FindTreeNodeById(
  nodeArray: TreeNodeDto[],
  nodeId: string
): TreeNodeDto | null {
  for (const node of nodeArray) {
    if (node.id === nodeId) {
      return node;
    }

    if (node.children && node.children.length > 0) {
      const foundNode = FindTreeNodeById(node.children, nodeId);
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return null;
}

export function RemoveTreeNodeById(
  nodeArray: TreeNodeDto[],
  nodeId: string
): TreeNodeDto[] {
  for (let i = 0; i < nodeArray.length; i++) {
    const node = nodeArray[i];
    if (node.id === nodeId) {
      nodeArray.splice(i, 1);
      return nodeArray;
    }

    if (node.children && node.children.length > 0) {
      const updatedChildren = RemoveTreeNodeById(node.children, nodeId);
      if (updatedChildren.length !== node.children.length) {
        node.children = updatedChildren;
        return nodeArray;
      }
    }
  }

  return nodeArray;
}