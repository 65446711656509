import ResponseDto from "../../../interfaces/ResponseDto";
import MdbDto from "../../../interfaces/foundry/typicals/MdbDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetMdb(id: number): Promise<MdbDto> {
  return (await GetMdbs()).find((x) => x.id === id) as MdbDto;
}

export async function GetMdbs(): Promise<MdbDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let mdbs = store.mdbs;
  if (mdbs.length === 0) {
    mdbs = await GetMdbDtos();
    store.setMdbs(mdbs);
  }
  return mdbs;
}

export async function GetMdbDtos(): Promise<MdbDto[]> {
  try {
    const mdbs = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/mdbs")).data
        .results as MdbDto[]
    ).map((item: MdbDto) => {
      item = {
        ...item,
        type: item.display_name
      };
      return item;
    });
    return mdbs;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}