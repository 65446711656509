import { create } from "zustand";
import {
  DimensionUnitDto,
  ProductionKeyFigureDto,
  ProductionUnitDto,
} from "../../interfaces/aggregate/ProductionKeyFigureDto";
import { EquipmentTypeDto } from "../../interfaces/aggregate/EquipmentTypeDto";

interface ProductionKeyFiguresState {
  productionKeyFigures: ProductionKeyFigureDto[];
  dimensionUnits: DimensionUnitDto[];
  productionUnits: ProductionUnitDto[];
  materialTypes: EquipmentTypeDto[];
}

interface ProductionKeyFiguresActions {
  setProductionKeyFigures: (newValue: ProductionKeyFigureDto[]) => void;
  setDimensionUnits: (value: DimensionUnitDto[]) => void;
  setProductionUnits: (value: ProductionUnitDto[]) => void;
  setMaterialTypes: (value: EquipmentTypeDto[]) => void;
  resetState: () => void;
}

const initialState: ProductionKeyFiguresState = {
  dimensionUnits: [],
  productionUnits: [],
  materialTypes: [],
  productionKeyFigures: [],
};

const useProductionKeyFiguresStore = create<
  ProductionKeyFiguresState & ProductionKeyFiguresActions
>()((set) => ({
  ...initialState,
  setProductionKeyFigures: (newValue) =>
    set({ productionKeyFigures: newValue }),
  setDimensionUnits: (value) => set({ dimensionUnits: value }),
  setProductionUnits: (value) => set({ productionUnits: value }),
  setMaterialTypes: (value) => set({ materialTypes: value }),
  resetState: () => set(initialState),
}));

export default useProductionKeyFiguresStore;
