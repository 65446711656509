import { Input, InputAdornment, InputProps } from "@mui/material";
import React, { useRef } from "react";

interface PercentageInputProps extends Omit<InputProps, "onChange"> {
  value?: number | null;
  onChange: (value: number | null) => void;
}

const PercentageInput: React.FC<PercentageInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (!/^\d*$/.test(inputValue) && inputValue !== "") {
      return;
    }

    if (inputValue === "") {
      onChange(null);
      return;
    }

    const cleanedValue = inputValue.replace(/^0+(?=\d)/, "");
    const newValue = Number(cleanedValue);

    if (!isNaN(newValue) && newValue <= 100) {
      onChange(newValue);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (inputRef.current) {
        const length = inputRef.current.value.length;
        inputRef.current.setSelectionRange(length, length);
      }
    }, 0);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[\d]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
    }
  };

  const displayValue = value === 0 ? "" : value ?? "";

  return (
    <Input
      inputRef={inputRef}
      sx={{
        border: "1px solid #acacac",
        marginTop: "2px",
        maxWidth: "50px",
        padding: "0px 5px",
        borderRadius: "10px",
        marginRight: "5px",
        "& .MuiInputBase-root:before": {
          borderBottomStyle: "none",
        },
        "& .MuiInputBase-root": {
          borderBottomStyle: "none !important",
        },
      }}
      type="text"
      value={displayValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyPress}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      disableUnderline={true}
      endAdornment={
        <InputAdornment sx={{ color: "black" }} position="end">
          %
        </InputAdornment>
      }
      {...props}
    />
  );
};

export default PercentageInput;
