import {
  ModuleDto,
  SpaceCommonAreaDto,
  SpaceComponentDto,
  SpaceResidentialDto,
} from "../types/api";

export function isSpaceResidentialDto(
  module: ModuleDto
): module is SpaceResidentialDto {
  return module && "residentPosition" in module;
}

export function isSpaceComponentTypicalDto(
  module: ModuleDto
): module is SpaceComponentDto {
  return module && "bayWidth1" in module && !("residentPosition" in module);
}

export function isSpaceCommonAreaDto(
  module: ModuleDto
): module is SpaceCommonAreaDto {
  return module && !("residentPosition" in module) && !("bayWidth1" in module);
}
