import React from "react";
import { IconProps } from "./types";
import "./Icon.scss";

export const HomeIcon: React.FC<IconProps> = ({ size = 18, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="3" r="2" stroke="#003366" />
      <circle cx="3" cy="9" r="2" stroke="#003366" />
      <circle cx="3" cy="15" r="2" stroke="#003366" />
      <circle cx="9" cy="3" r="2" stroke="#003366" />
      <circle cx="9" cy="9" r="2" stroke="#003366" />
      <circle cx="9" cy="15" r="2" stroke="#003366" />
      <circle cx="15" cy="3" r="2" stroke="#003366" />
      <circle cx="15" cy="9" r="2" stroke="#003366" />
      <circle cx="15" cy="15" r="2" stroke="#003366" />
    </svg>
  );
};
