import React from "react";
import { IconProps } from "./types";
import "./Icon.scss";

export const UploadIcon: React.FC<IconProps> = ({ size = 18, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={props.className}
        d="M18 55C8.0625 55 0 47.0815 0 37.3214C0 29.6116 5.025 23.0558 12.025 20.6373C12.0125 20.3058 12 19.9743 12 19.6429C12 8.79018 20.95 0 32 0C39.4125 0 45.875 3.95312 49.3375 9.84598C51.2375 8.59375 53.5375 7.85714 56 7.85714C62.625 7.85714 68 13.1362 68 19.6429C68 21.1406 67.7125 22.5647 67.2 23.8906C74.5 25.3393 80 31.6864 80 39.2857C80 47.9654 72.8375 55 64 55H18ZM27.875 28.3594C26.7 29.5134 26.7 31.3795 27.875 32.5212C29.05 33.6629 30.95 33.6752 32.1125 32.5212L36.9875 27.7333V44.1964C36.9875 45.8292 38.325 47.1429 39.9875 47.1429C41.65 47.1429 42.9875 45.8292 42.9875 44.1964V27.7333L47.8625 32.5212C49.0375 33.6752 50.9375 33.6752 52.1 32.5212C53.2625 31.3672 53.275 29.5011 52.1 28.3594L42.1 18.5379C40.925 17.3839 39.025 17.3839 37.8625 18.5379L27.8625 28.3594H27.875Z"
        fill="#003366"
      />
    </svg>
  );
};
