import { CategoryDto } from "../../interfaces/aggregate/CategoryDto";
import { AggregatePageEnum } from "../../interfaces/aggregate/enums/AggregatePageEnum";
import { TrackException } from "../../logging/LoggingManager";
import { aggregateApiClient } from "../../modules/sharedLogic/services/AxiosClient";

const baseUrl = "/api/category";
export async function GetCategories(
  categoryType: AggregatePageEnum
): Promise<CategoryDto[]> {
  try {
    let categories = [] as CategoryDto[];
    await aggregateApiClient
      .get<CategoryDto[]>(
        `${baseUrl}?categoryType=${categoryType}&pageSize=500`
      )
      .then((res) => (categories = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return categories;
  } catch (ex) {
    TrackException(ex);
    return [];
  }
}

export async function GetCategory(id: number): Promise<CategoryDto> {
  try {
    let category = {} as CategoryDto;
    await aggregateApiClient
      .get<CategoryDto>(`${baseUrl}/${id}`)
      .then((res) => (category = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return category;
  } catch (ex) {
    TrackException(ex);
    return {} as CategoryDto;
  }
}

export async function SaveCategory(category: CategoryDto): Promise<number> {
  try {
    let categoryId = 0;
    await aggregateApiClient
      .post<number>(baseUrl, category)
      .then((res) => (categoryId = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return categoryId;
  } catch (ex) {
    TrackException(ex);
    return 0;
  }
}

export async function UpdateCategory(
  category: CategoryDto
): Promise<CategoryDto> {
  try {
    let updatedCategory = {} as CategoryDto;
    await aggregateApiClient
      .put<CategoryDto>(`${baseUrl}/${category.id}`, category)
      .then((res) => (updatedCategory = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return updatedCategory;
  } catch (ex) {
    TrackException(ex);
    return {} as CategoryDto;
  }
}

export async function DeleteCategory(categoryId: string) {
  try {
    await aggregateApiClient
      .delete<CategoryDto>(`${baseUrl}/${categoryId}`)
      .catch((ex) => {
        TrackException(ex);
      });
  } catch (ex) {
    TrackException(ex);
  }
}