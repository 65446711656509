import { AlertColor } from "@mui/material";
import { create } from "zustand";


interface NotificationState {
  isOpen: boolean;
  message: string;
  type: AlertColor;
  setNotification: (message: string, type: AlertColor) => void;
  closeNotification: () => void;
}

const useNotificationStore = create<NotificationState>((set) => ({
  isOpen: false,
  message: "",
  type: "success",
  setNotification: (message, type) =>
    set({
      isOpen: true,
      message,
      type,
    }),
  closeNotification: () =>
    set({
      isOpen: false,
      message: "",
      type: "success",
    }),
}));

export default useNotificationStore;
