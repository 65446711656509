import { Add, Remove } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import useDatacenterGeneratorStore from "../../state/DatacenterState/datacenterGeneratorState";
import { DTO } from "../../state/ExploreState/ExploreState";

interface PlusMinusButtonProps<T extends DTO> {
  object?: T;
}
export default function PlusMinusButton<T extends DTO>({
  object,
}: PlusMinusButtonProps<T>) {
  const { generatedDatacentersIds, setGeneratedDatacentersIds } =
    useDatacenterGeneratorStore();

  const handleIncrement = () => {
    const updatedSelectedIds = generatedDatacentersIds.map((obj) => {
      if (obj.id === object?.id) {
        return { ...obj, count: obj.count + 1 };
      }
      return obj;
    });
    setGeneratedDatacentersIds(updatedSelectedIds);
    setTimeout(() => {}, 0);
  };

  const handleDecrement = () => {
    const updatedSelectedIds = generatedDatacentersIds.map((obj) => {
      if (obj.id === object?.id && obj.count! > 0) {
        return { ...obj, count: obj.count - 1 };
      }
      return obj;
    });
    setGeneratedDatacentersIds(updatedSelectedIds);
    setTimeout(() => {}, 0);
  };

  const CounterContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    border: "1px solid #003466",
    borderRadius: "10px",
    fontSize: "12px",
    height: "15px",
  });

  const CountDisplay = styled("div")({
    padding: "0px 0px",
  });

  return (
    <div>
      <CounterContainer>
        <IconButton
          style={{ padding: "0px 2px" }}
          onClick={handleDecrement}
          aria-label="minus"
        >
          <Remove sx={{ fontSize: "13px" }} />
        </IconButton>
        <CountDisplay style={{ padding: "0px" }}>
          {generatedDatacentersIds.find((x) => x.id === object?.id)?.count ?? 1}
        </CountDisplay>
        <IconButton
          style={{ padding: "0px 2px" }}
          onClick={handleIncrement}
          aria-label="plus"
        >
          <Add sx={{ fontSize: "13px" }} />
        </IconButton>
      </CounterContainer>
    </div>
  );
}
