import MapboxDraw, { DrawCustomMode } from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import * as L from "leaflet";
import { LngLat, LngLatBounds } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import type { ControlPosition, MapInstance } from "react-map-gl";
import { useControl } from "react-map-gl";
import wellknown from "wellknown";
import { GetColorPalette } from "../../../../http/foundry/ColorPaletteService";
import { CalculateDinstanceBetweenPlotAndRoad } from "../../../../http/foundry/MapService";
import ColorPalette from "../../../../interfaces/ColorPalette";
import DrawedPlot, {
  GetEmptyDrawedPlot,
} from "../../../../interfaces/DrawedPlot";
import { LayoutDataDto } from "../../../../interfaces/LayoutDto";
import { MapType } from "../../../../interfaces/enums/MapTypeEnum";
import { SelectedBuildingsDto } from "../../../../interfaces/foundry/SelectedBuildingsDto";
import { TrackException } from "../../../../logging/LoggingManager";
import useDatacenterGeneratorStore from "../../../../state/DatacenterState/datacenterGeneratorState";
import {
  CalculateOffsetGeometry,
  CalculateRdCoordinates,
  GetDrawControlStyles,
  GetDrawedPlot,
  GetSelectedBuildings,
} from "../../../../utils/DrawControlManager";
import { GenerateGuid } from "../../../../utils/HelperFunctions";
import RotateMode from "./RotateMode";

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  userProperties?: boolean;
  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
  mapType: MapType;
};

export default function DrawControl(props: DrawControlProps) {
  const offsetDistance = -4.8;
  const mapRef = useRef<MapInstance | null>(null);
  const drawControlRef = useRef<MapboxDraw | null>(null);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const datacenterPlotDrawnTypes = [
    "datacenter",
    "genset",
    "whitespace",
    "cooling",
    "lsr_room",
  ];

  let plotDrawnComponents = [] as any[];

  const {
    currentProject,
    drawedPlot,
    startDrawingPlot,
    deleteDrawingPlot,
    editDrawingPlot,
    calculateDistance,
    currentLayouts,
    drawControlMode,
    setEditDrawingPlot,
    setDeleteDrawingPlot,
    setDrawedPlot,
    setNavigateDrawingPlot,
    setStartDrawingPlot,
    setDistanceBetweenPlotAndRoad,
    setCalculateDistance,
    setIsSaveAvailable,
    setCurrentProject,
  } = useDatacenterGeneratorStore();

  const { setGeneratedDatacentersIds, generatedDatacentersIds } =
    useDatacenterGeneratorStore();

  useEffect(() => {
    if (deleteDrawingPlot) {
      drawControlRef.current?.deleteAll();

      if (mapRef.current?.getLayer("route"))
        mapRef.current?.removeLayer("route");
      if (mapRef.current?.getSource("route"))
        mapRef.current?.removeSource("route");

      setDistanceBetweenPlotAndRoad(0);
      setDrawedPlot(GetEmptyDrawedPlot());
      setDeleteDrawingPlot(false);
    }
  }, [deleteDrawingPlot]);

  useEffect(() => {
    if (editDrawingPlot) {
      drawControlRef.current?.changeMode("direct_select", {
        featureId: drawedPlot.id,
      });
      setNavigateDrawingPlot(true);
      setEditDrawingPlot(false);
    }
  }, [editDrawingPlot]);

  useEffect(() => {
    if (startDrawingPlot) {
      drawControlRef.current?.changeMode("draw_polygon");
      setStartDrawingPlot(false);
    }
  }, [startDrawingPlot]);

  useEffect(() => {
    if (calculateDistance && drawedPlot.buildableCoordinates.length > 0) {
      calculateDistanceAOrNRoad(drawedPlot);
      setCalculateDistance(false);
    }
  }, [calculateDistance]);

  useEffect(() => {
    try {
      if (
        drawControlMode &&
        drawControlMode !== "" &&
        mapRef.current &&
        drawControlRef.current
      ) {
        drawControlRef.current.changeMode(drawControlMode);
      }
    } catch (ex) {
      TrackException(ex);
    }
  }, [drawControlMode]);

  useEffect(() => {
    if (!drawControlRef.current || !mapRef.current || !stylesLoaded) {
      return;
    }

    try {
      for (let i = 0; i < generatedDatacentersIds.length; i++) {
        if (
          currentLayouts[i] &&
          currentLayouts[i].data &&
          currentLayouts[i].data.children &&
          generatedDatacentersIds.length > 0 &&
          props.mapType === MapType.GenerateDatacenter
        ) {
          const tempDrawedPlot = GetDrawedPlot(drawedPlot, currentProject);
          if (!tempDrawedPlot || !tempDrawedPlot.polygon) return;
          setDrawedPlot(tempDrawedPlot);
          //remove all datacenter layers
          const layers = drawControlRef.current?.getAll();
          if (layers) {
            layers.features?.forEach((feature) => {
              if (feature?.properties?.kind.startsWith("datacenter")) {
                drawControlRef.current!.delete(feature.id + "");
              }
            });
            GenerateDatacenter(tempDrawedPlot.polygon);
          }
        }
      }
    } catch (ex) {
      TrackException(ex);
    }
  }, [
    drawControlRef,
    mapRef,
    currentLayouts,
    generatedDatacentersIds,
    stylesLoaded,
    drawedPlot,
  ]);

  useEffect(() => {
    const selectedBuildings = GetSelectedBuildings(currentProject);
    if (selectedBuildings && selectedBuildings.datacentersDetails) {
      // Reset counts to zero before counting
      generatedDatacentersIds.forEach((datacenter) => {
        datacenter.count = 0;
      });

      Object.keys(selectedBuildings.datacentersDetails).forEach((key) => {
        const match = key.match(/^datacenter-(\d+)-/);
        if (match) {
          const datacenterId = parseInt(match[1]);
          const datacenter = generatedDatacentersIds.find(
            (x) => x.id === datacenterId
          );
          if (datacenter) {
            datacenter.count++;
          }
        }
      });
    } else {
      generatedDatacentersIds.forEach((datacenter) => {
        datacenter.count = 1;
      });
    }

    setGeneratedDatacentersIds([...generatedDatacentersIds]);
  }, []);

  const customSimpleSelectMode: DrawCustomMode<any, any> = (() => {
    const simpleSelectMode = MapboxDraw.modes.simple_select;
    simpleSelectMode.onClick = function (...args: any[]) {
      try {
        if (mapRef.current) {
          const features = mapRef.current.queryRenderedFeatures(args[1].point);
          if (
            features &&
            features.length > 0 &&
            features[0].source.startsWith("datacenter")
          ) {
            const source = mapRef.current?.getSource(features[0].source);
            if (source && source._data) {
              drawControlRef.current?.add(source._data);

              const featureCollection = drawControlRef.current!.getAll();
              const featureCollectionIds: string[] = featureCollection.features
                .filter(
                  (x) =>
                    x.properties != null &&
                    x.properties.kind != null &&
                    x.properties.kind === features[0].source
                )
                .map((x) => x.id!.toString());

              drawControlRef?.current?.changeMode("simple_select", {
                featureIds: featureCollectionIds,
              });
              return;
            }
          }

          drawControlRef?.current?.changeMode("simple_select", {
            featureIds: [],
          });
        }
      } catch (ex) {}
    };
    return simpleSelectMode;
  })();

  const customRotateMode: DrawCustomMode<any, any> = (() => {
    const rotateMode = RotateMode;

    rotateMode.onMouseUp = function (...args: any[]) {
      if (args[0].originalFeature && args[0].originalFeature.length > 0) {
        const rotationAngle =
          args[0].rotationAngles[args[0].originalFeature[0].properties.kind];
        UpdateDatacenter(args[0].originalFeature, rotationAngle);
      }
    };
    rotateMode.onSetup = function (opts: any) {
      const selectedBuildings = GetSelectedBuildings(currentProject);
      Object.keys(selectedBuildings?.datacentersDetails).forEach(
        (datacenter) => {
          const source = mapRef.current?.getSource(datacenter);
          if (source && source._data) {
            drawControlRef.current?.add(source._data);
          }
        }
      );

      return {
        selectedBuildings: selectedBuildings,
        mode: "rotate",
        feature: "datacenter",
        originalCenter: [0, 0],
        lastMouseDownLngLat: false,
        selectedFeature: null,
        rotationAngle: 0,
      };
    };
    return rotateMode;
  })();

  useControl<MapboxDraw>(
    () => {
      const modes =
        props.mapType === MapType.GenerateDatacenter
          ? {
              ...MapboxDraw.modes,
              simple_select: customSimpleSelectMode,
              rotate_mode: customRotateMode,
            }
          : { ...MapboxDraw.modes };

      const drawControl = new MapboxDraw({
        ...props,
        modes: modes,
        userProperties: true,
        styles: GetDrawControlStyles(),
      });

      drawControlRef.current = drawControl;
      return drawControl;
    },
    ({ map }) => {
      mapRef.current = map.getMap();
      map.on("load", () => {
        const tempDrawedPlot = GetDrawedPlot(drawedPlot, currentProject);
        if (!tempDrawedPlot || !tempDrawedPlot.polygon) return;
        setDrawedPlot(tempDrawedPlot);
        drawControlRef.current?.add(tempDrawedPlot.polygon);
        UpdatePolygon(tempDrawedPlot.polygon);
        setNavigateDrawingPlot(true);

        setStylesLoaded(true);
      });
      map.on(
        "draw.create",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onCreate?.(evt);
          const createdFeature = evt.features[0];
          if (createdFeature) {
            UpdatePolygon(createdFeature);
            DeselectAllFeatures();
          }
        }
      );
      map.on(
        "draw.update",
        (evt: {
          features: GeoJSON.Feature<GeoJSON.Geometry>[];
          action: string;
        }) => {
          try {
            props.onUpdate?.(evt);
            const updatedFeature = evt.features[0];
            if (updatedFeature.properties?.kind === "plot") {
              UpdatePolygon(updatedFeature);
            } else if (
              updatedFeature.properties?.kind.startsWith("datacenter")
            ) {
              const featureCollection = {
                type: "FeatureCollection",
                features: evt.features,
              } as turf.FeatureCollection<turf.MultiPolygon>;

              const origin =
                featureCollection.features[0].geometry.coordinates[0][0];

              UpdateDatacenter(evt.features, undefined, [
                Number(origin[0]),
                Number(origin[1]),
              ]);
            }
          } catch (ex) {
            TrackException(ex);
          }
        }
      );
      map.on(
        "draw.delete",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onDelete?.(evt);
          drawControlRef.current?.deleteAll();
          setDrawedPlot(GetEmptyDrawedPlot());
        }
      );
    },
    ({ map }) => {
      map.off(
        "draw.create",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onCreate?.(evt);
        }
      );
      map.off(
        "draw.update",
        (evt: {
          features: GeoJSON.Feature<GeoJSON.Geometry>[];
          action: string;
        }) => {
          props.onUpdate?.(evt);
        }
      );
      map.off(
        "draw.delete",
        (evt: { features: GeoJSON.Feature<GeoJSON.Geometry>[] }) => {
          props.onDelete?.(evt);
        }
      );
    },
    {
      position: props.position,
    }
  );

  function DeselectAllFeatures() {
    setTimeout(() => {
      drawControlRef.current?.changeMode("simple_select", {
        featureIds: [],
      });
    }, 200);
  }

  function UpdateDatacenter(
    updatedPolygons: GeoJSON.Feature<GeoJSON.Geometry>[],
    rotationAngle: number | undefined = undefined,
    originalOrigin: number[] | undefined = undefined
  ) {
    try {
      const datacenterName = updatedPolygons[0].properties?.kind;
      const datacenterSource = mapRef.current?.getSource(datacenterName);
      if (!datacenterSource) {
        console.error("No source found for datacenter:", datacenterName);
        return;
      }

      // Update the source with the new features
      const newFeature: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
        type: "FeatureCollection",
        features: updatedPolygons,
      };
      datacenterSource.setData(newFeature);

      const selectedBuildings = GetSelectedBuildings(currentProject);
      // Initialize datacentersDetails if it does not exist
      if (!selectedBuildings.datacentersDetails) {
        selectedBuildings.datacentersDetails = {};
      }

      // Initialize the specific datacenter details if they do not exist
      if (!selectedBuildings.datacentersDetails[datacenterName]) {
        selectedBuildings.datacentersDetails[datacenterName] = {};
      }

      // Set the origin and rotation angle for the specific datacenter
      if (
        updatedPolygons.length > 0 &&
        updatedPolygons[0].geometry.type === "Polygon"
      ) {
        const datacenterDetails =
          selectedBuildings.datacentersDetails[datacenterName];

        if (originalOrigin) datacenterDetails.origin = originalOrigin;
        if (rotationAngle) datacenterDetails.rotationAngle = rotationAngle;

        selectedBuildings.datacentersDetails[datacenterName] =
          datacenterDetails;
      }

      // Save the updated buildings information back to the project
      updateProject(selectedBuildings);
    } catch (ex) {
      TrackException(ex);
      console.error("Failed to update datacenter:", ex);
    }
  }

  function updateProject(selectedBuildings: SelectedBuildingsDto) {
    currentProject.buildings = JSON.stringify(selectedBuildings);
    setCurrentProject(currentProject);
  }

  function UpdatePolygon(polygon: GeoJSON.Feature<GeoJSON.Geometry>) {
    if (!drawControlRef.current) return;

    const { features } = drawControlRef.current.getAll();
    features
      .filter((x) => x.id !== polygon.id)
      .forEach((feature) => drawControlRef.current?.delete(feature.id + ""));

    drawControlRef.current?.setFeatureProperty(polygon.id + "", "kind", "plot");
    drawControlRef.current?.setFeatureProperty(polygon.id + "", "class_id", 0);

    let unqiueCoordinates: number[][] = [];
    if (polygon.geometry && polygon.geometry.type === "Polygon") {
      unqiueCoordinates = polygon.geometry.coordinates[0];
    }

    const turfPositions: turf.Position[] = [];
    unqiueCoordinates.forEach((point) => {
      turfPositions.push([point[0], point[1]] as turf.Position);
    });

    const positions: number[][] = [];
    const offsetGeometry = CalculateOffsetGeometry(
      turfPositions,
      offsetDistance
    );
    if (!offsetGeometry) return;

    const newFeature: GeoJSON.Feature<GeoJSON.Geometry> = {
      type: "Feature",
      geometry: offsetGeometry,
      properties: {
        kind: "buildablePlot",
        class_id: 1,
      },
    };
    drawControlRef.current?.add(newFeature);

    offsetGeometry.coordinates[0].forEach((position) => {
      positions.push([Number(position[0]), Number(position[1])]);
    });

    const newDrawedPlot = {
      id: polygon.id,
      polygon: polygon,
      lotSurface: Math.round((turf.area(polygon) * 100) / 100),
      lotCoordinates: unqiueCoordinates,
      lotRdCoordinates: CalculateRdCoordinates(unqiueCoordinates),
      buildableSurface: GetDrawedPlot(drawedPlot, currentProject)
        .buildableSurface
        ? GetDrawedPlot(drawedPlot, currentProject).buildableSurface
        : Math.round((turf.area(offsetGeometry) * 100) / 100),
      buildableRdCoordinates: CalculateRdCoordinates(positions),
      buildableCoordinates: positions,
    } as DrawedPlot;

    setDrawedPlot(newDrawedPlot);
    setIsSaveAvailable(true);
  }

  async function GenerateDatacenter(
    plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>
  ) {
    try {
      // Clear old datacenter layers if any exist
      const sources = mapRef.current?.getStyle()?.sources;
      if (sources) {
        Object.keys(sources).forEach((source) => {
          if (source.startsWith("datacenter-")) {
            const correspondingLayer = source.replace("source", "layer");
            if (mapRef.current?.getLayer(correspondingLayer)) {
              mapRef.current?.removeLayer(correspondingLayer);
              mapRef.current?.removeSource(source);
            }
          }
        });
      }

      const colorPalette = await GetColorPalette();
      const selectedBuildings = GetSelectedBuildings(currentProject);

      // Ensure datacentersDetails is initialized if undefined
      if (!selectedBuildings.datacentersDetails) {
        selectedBuildings.datacentersDetails = {};
      }

      // Function to get datacenter indices and count unique configurations
      const getDatacenterConfigurations = () => {
        const layers = mapRef.current?.getStyle().layers;
        const configurations = new Map<number, Set<number>>();

        layers.forEach((layer: { id: string }) => {
          const match = layer.id.match(/^datacenter-(\d+)-(\d+)-[a-z]+-\d+/);
          if (match) {
            const datacenterIndex = parseInt(match[1]);
            const configIndex = parseInt(match[2]);
            if (!configurations.has(datacenterIndex)) {
              configurations.set(datacenterIndex, new Set());
            }
            configurations.get(datacenterIndex)?.add(configIndex);
          }
        });

        return configurations;
      };

      const configurations = getDatacenterConfigurations();
      for (let j = 0; j < generatedDatacentersIds.length; j++) {
        const currentConfigurations =
          configurations.get(generatedDatacentersIds[j].id) || new Set();
        const currentCount = currentConfigurations.size;
        if (currentCount > generatedDatacentersIds[j].count) {
          // Remove excess configurations
          const maxConfigIndex = Math.max(...Array.from(currentConfigurations));
          const datacenterToRemove = `datacenter-${generatedDatacentersIds[j].id}-${maxConfigIndex}`;
          const layersToRemove = mapRef.current
            ?.getStyle()
            .layers.filter((layer: { id: string }) =>
              layer.id.startsWith(datacenterToRemove)
            );

          layersToRemove.forEach((layer: { id: string }) => {
            if (mapRef.current?.getLayer(layer.id)) {
              mapRef.current?.removeLayer(layer.id);
            }
          });
          drawControlRef?.current?.delete(datacenterToRemove);

          if (selectedBuildings.datacentersDetails[datacenterToRemove]) {
            delete selectedBuildings.datacentersDetails[datacenterToRemove];
          }
        } else {
          // Add missing configurations
          for (
            let i = currentCount;
            i < generatedDatacentersIds[j].count;
            i++
          ) {
            const datacenterToAdd = `datacenter-${generatedDatacentersIds[j].id}-${i}`;
            const datacenterDetails = selectedBuildings.datacentersDetails[
              datacenterToAdd
            ] || { origin: GetPlotOrigin(plotPolygon) };
            const lastInstanceOfCurrentDcOnMap = `datacenter-${
              generatedDatacentersIds[j].id
            }-${i - 1}`;
            const datacenterRotationAngle = datacenterDetails.rotationAngle
              ? datacenterDetails.rotationAngle
              : selectedBuildings.datacentersDetails[
                  lastInstanceOfCurrentDcOnMap
                ]?.rotationAngle || 0;

            datacenterDetails.rotationAngle = datacenterRotationAngle;

            if (!currentConfigurations.has(i)) {
              const datacenterName = `datacenter-${generatedDatacentersIds[j].id}-${i}`;
              plotDrawnComponents = [];

              processComponent(
                currentLayouts[j].data,
                datacenterDetails.origin!,
                datacenterRotationAngle,
                datacenterName
              );

              const newFeature: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
                type: "FeatureCollection",
                features: plotDrawnComponents,
              };
              drawControlRef.current?.add(newFeature);

              const source = {
                type: "geojson",
                data: newFeature,
              };
              mapRef.current?.addSource(datacenterName, source);

              selectedBuildings.datacentersDetails[datacenterToAdd] =
                datacenterDetails;

              setupLayers(datacenterName, colorPalette, plotDrawnComponents);
            }
          }
        }
      }
      updateProject(selectedBuildings);
    } catch (ex) {
      TrackException(ex);
    }
  }

  function setupLayers(
    datacenterName: string,
    colorPalette: ColorPalette,
    plotDrawnComponents: any[]
  ) {
    const zIndexDictionary: { [key: string]: number[] } = {};

    // Populate zIndexDictionary with zIndexes for each type
    plotDrawnComponents.forEach((component) => {
      const type = component.properties.type_component;
      if (!zIndexDictionary[type]) {
        zIndexDictionary[type] = [];
      }
      zIndexDictionary[type].push(component.properties.z_index);
    });

    // Creating and adding layers based on the types and their zIndexes
    Object.keys(zIndexDictionary).forEach((type) => {
      zIndexDictionary[type].forEach((zIndex, index) => {
        const id = `${datacenterName}-${type}-${index}`;
        const fillColor = getColor(colorPalette, type);

        if (type !== "datacenter") {
          mapRef.current?.addLayer({
            id: id,
            type: "fill-extrusion",
            source: datacenterName,
            filter: ["==", "type_component", type],
            paint: {
              "fill-extrusion-color": fillColor,
              "fill-extrusion-opacity": 1,
              "fill-extrusion-height":
                zIndex === 0
                  ? ["get", "total_height"]
                  : ["+", ["get", "total_height"], zIndex],
              "fill-extrusion-base": zIndex,
            },
          });
        }
      });
    });

    // Adding an outline layer for visual distinction
    mapRef.current?.addLayer({
      id: `${datacenterName}-outline`,
      type: "line",
      filter: ["==", "type_component", "datacenter"],
      source: datacenterName,
      paint: {
        "line-color": "rgba(254, 184, 0, 1)",
        "line-dasharray": [2, 1],
      },
    });
  }

  function getColor(colorPalette: ColorPalette, type: string): string {
    // Maps the type to the corresponding property in the ColorPalette object
    const item = colorPalette[type as keyof ColorPalette];
    return item ? item.fill_color : "#ffffff"; // Default to white if type is not found or item is undefined
  }

  function GetPlotOrigin(plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>) {
    const buildableLot = GetBuildableLot(plotPolygon);
    const plotCoordinates = buildableLot.geometry.coordinates[0];
    return [Number(plotCoordinates[0][0]), Number(plotCoordinates[0][1])];
  }

  function processComponent(
    component: LayoutDataDto,
    datacenterOrigin: number[],
    rotationAngle: number,
    datacenterKind: string
  ) {
    if (component.object === "dc_lsr_room") component.object = "lsr_room";

    if (datacenterPlotDrawnTypes.includes(component.object)) {
      const polygon = getPolygonOfString(component.polygon2d, datacenterOrigin);
      const rotatedPolygon = turf.transformRotate(polygon, rotationAngle, {
        pivot: datacenterOrigin,
      });

      rotatedPolygon.id = GenerateGuid();
      rotatedPolygon.properties = {
        kind: datacenterKind,
        type_component: component.object,
        total_height: component.height / 1000,
        z_index: component.z / 1000,
        class_id: 1,
      };
      plotDrawnComponents.push(rotatedPolygon);
    }

    if (component.children && component.children.length > 0) {
      component.children.forEach((child) => {
        processComponent(
          child,
          datacenterOrigin,
          rotationAngle,
          datacenterKind
        );
      });
    }
  }

  function GetBuildableLot(
    plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>
  ): turf.helpers.Feature<turf.helpers.Polygon, turf.helpers.Properties> {
    let plotCoordinates: number[][] = [];
    if (plotPolygon.geometry && plotPolygon.geometry.type === "Polygon") {
      plotCoordinates = plotPolygon.geometry.coordinates[0];
    }
    const turfPositions: turf.Position[] = [];
    plotCoordinates.forEach((point) => {
      turfPositions.push([point[0], point[1]] as turf.Position);
    });
    const existingPolygon = turf.polygon([plotCoordinates]);
    const bufferedPolygon = turf.buffer(existingPolygon, offsetDistance, {
      units: "meters",
    });

    return bufferedPolygon;
  }

  function calculateDistanceAOrNRoad(drawedPlot: DrawedPlot) {
    const latLngCoordinates: L.LatLngExpression[] =
      drawedPlot.lotCoordinates.map((coordinate) => {
        return [coordinate[0], coordinate[1]];
      });

    const fit = new L.Polygon(latLngCoordinates).getBounds();
    const southWest = new LngLat(
      fit.getSouthWest().lat,
      fit.getSouthWest().lng
    );
    const northEast = new LngLat(
      fit.getNorthEast().lat,
      fit.getNorthEast().lng
    );
    const center = new LngLatBounds(southWest, northEast).getCenter();

    CalculateDinstanceBetweenPlotAndRoad(center.lat, center.lng).then(
      (data) => {
        if (!data || data.routes.length === 0) return;

        const route = data.routes[0];
        const geojson = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: route.geometry.coordinates,
          },
        };

        if (mapRef.current?.getSource("route")) {
          mapRef.current?.getSource("route").setData(geojson);
        } else {
          mapRef.current?.addLayer({
            id: "route",
            type: "line",
            source: {
              type: "geojson",
              data: geojson,
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#3887be",
              "line-opacity": 0.75,
              "line-width": 5,
            },
          });
        }
        setDistanceBetweenPlotAndRoad(route.distance);
      }
    );
  }
  return null;
}

function getPolygonOfString(
  polygon2d: string,
  plotOrigin: number[] | undefined = undefined
) {
  const divideBy = 100000000;
  const geojsonGeometry = wellknown.parse(polygon2d);
  const adjustedCoordinates: number[][] = [];
  (geojsonGeometry as GeoJSON.Polygon).coordinates[0].forEach((position) => {
    if (plotOrigin && plotOrigin.length === 2) {
      const x = Number(position[0]) / divideBy + plotOrigin[0];
      const y = Number(position[1]) / divideBy + plotOrigin[1];
      adjustedCoordinates.push([x, y]);
    } else {
      adjustedCoordinates.push([Number(position[0]), Number(position[1])]);
    }
  });
  return turf.polygon([adjustedCoordinates]);
}
