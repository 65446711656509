const turf = require("@turf/turf");
const EventEmitter = require("events");
const emitter = new EventEmitter();

const RotateMode = {
  rotateStart: function (selectedFeature, originalCenter) {},
  rotating: function (selectedFeature, originalCenter, lastMouseDown) {},
  rotateEnd: function (selectedFeature) {
  },

  onSetup: function (opts) {
    const state = {};
    emitter.addListener(
      "rotateStart",
      function () {
        this.rotateStart(state.selectedFeature, state.originalCenter);
      }.bind(this)
    );
    emitter.addListener(
      "rotating",
      function () {
        this.rotating(
          state.selectedFeature,
          state.originalCenter,
          state.lastMouseDownLngLat
        );
      }.bind(this)
    );
    emitter.addListener(
      "rotateEnd",
      function () {
        this.rotateEnd(
          state.selectedFeature,
          state.lastMouseDownLngLat,
          state.rotationAngle
        );
      }.bind(this)
    );

    state.selectedBuildings = opts.selectedBuildings || false;
    state.originalCenter = opts.originalCenter || false;
    state.selectedFeature = opts.selectedFeature || false;
    state.lastMouseDownLngLat = false;
    state.mode = "rotate" || false;
    return state;
  },

  onMouseDown: function (state, e) {
    if (
      e.featureTarget &&
      e.featureTarget.properties &&
      e.featureTarget.properties.user_kind.includes("datacenter")
    ) {
      if (this._ctx.api.get(e.featureTarget.properties.id)) {
        e.target["dragPan"].disable();
        let selectedFeature = this._ctx.api.get(e.featureTarget.properties.id);

        let datacenter =
          state.selectedBuildings?.datacentersDetails[
            selectedFeature.properties["kind"]
          ];

        if (datacenter) {
          const features = (state.selectedFeature = this._ctx.api
            .getAll()
            .features.filter(
              (x) =>
                x.properties != null &&
                x.properties.kind != null &&
                x.properties.kind === selectedFeature.properties["kind"]
            )
            .map((x) => x));

          if (!state.rotationAngles) {
            state.rotationAngles = {};
          }

          if (!state.rotationAngles[selectedFeature.properties["kind"]]) {
            state.rotationAngles[selectedFeature.properties["kind"]] =
              datacenter.rotationAngle || 0;
          }
          state.originalCenter = turf.centerOfMass({
            type: "FeatureCollection",
            features: features,
          }).geometry.coordinates;
          state.originalFeature = features;
        } else {
          state.originalCenter = turf.centerOfMass(
            e.featureTarget
          ).geometry.coordinates;
          state.originalFeature = e.featureTarget;
        }

        state.selectedFeature = selectedFeature;
        emitter.emit("rotateStart");
      }
    }
    return state;
  },

  toDisplayFeatures: function (state, geojson, display) {
    display(geojson);
  },

  onDrag: function (state, e) {
    if (state.selectedFeature && state.mode && state.mode === "rotate") {
      state.lastMouseDownLngLat = state.lastMouseDownLngLat || {
        lng: e.lngLat.lng,
        lat: e.lngLat.lat,
      };

      const m1 = turf.point([e.lngLat.lng, e.lngLat.lat]);
      const center = turf.point(state.originalCenter);
      const heading1 = turf.bearing(center, m1);

      if (Array.isArray(state.originalFeature)) {
        const rotationAngle = heading1 / 25;
        state.rotationAngles[state.selectedFeature.properties["kind"]] +=
          rotationAngle;

        state.originalFeature.forEach((feature) => {
          const poly = turf.polygon(feature.geometry.coordinates);
          const rotatedPoly = turf.transformRotate(poly, rotationAngle, {
            pivot: center,
          });
          emitter.emit("rotating");
          feature.geometry.coordinates = rotatedPoly.geometry.coordinates;
          this._ctx.api.add(feature);
        });
      }
    }
  },

  onMouseUp: function (state, e) {
    e.target["dragPan"].enable();
    emitter.emit("rotateEnd");
    state.rotationAngle = false;
    state.selectedFeature = false;
    state.lastMouseDownLngLat = false;
    state.originalCenter = false;
    return state;
  },
};

module.exports = RotateMode;
