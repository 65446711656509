import { TextField } from "@mui/material";
import CustomFormControl from "./CustomFormControl";

interface FormControlTextFieldProps {
  value: any;
  labelText: string;
  disabled?: boolean;
  toolTipText?: string;
  showInfoButton?: boolean;
  onChange?: (value: string) => void;
}

export default function FormControlTextField(props: FormControlTextFieldProps) {
  return (
    <CustomFormControl
      labelText={props.labelText}
      showInfoButton={props.showInfoButton}
      toolTipText={props.toolTipText}
    >
      <TextField
        fullWidth
        disabled={props.disabled ? props.disabled : false}
        style={{ marginTop: "10px" }}
        value={props.value}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event.target.value);
          }
        }}
        variant="standard"
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          "& .MuiInputBase-root.Mui-disabled:before": {
            borderBottomStyle: "solid",
          },
        }}
      />
    </CustomFormControl>
  );
}
