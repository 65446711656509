import { TrackException } from "../../logging/LoggingManager";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";

export async function GetPowerRackWattages(): Promise<number[]> {
  try {
    const response = await foundryApiClient.get<number[]>(
      "/api/datacenter/requirements/options/rack_wattages"
    );
    return response.data.map((wattage) => wattage / 1000).sort((a, b) => a - b);
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function GetDesiredPowerWattages(): Promise<number[]> {
  try {
    const response = await foundryApiClient.get<number[]>(
      "/api/datacenter/requirements/options/desired_power_in_w"
    );

    const powerWattages = response.data
      .map((wattage) => wattage / 1000000)
      .sort((a, b) => a - b);
    return powerWattages;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function GetCoolingPrinciples(): Promise<string[]> {
  try {
    const response = await foundryApiClient.get<string[][]>(
      "/api/datacenter/requirements/options/cooling_types"
    );
    return convertFromDicToValues(response.data);
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function GetDcScopes(): Promise<string[]> {
  try {
    const response = await foundryApiClient.get<string[][]>(
      "/api/datacenter/requirements/options/dc_scopes"
    );
    return convertFromDicToValues(response.data);
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function GetDestinationPlans(): Promise<string[]> {
  try {
    const response = await foundryApiClient.get<string[][]>(
      "/api/datacenter/requirements/options/dc_destination_plans"
    );
    return convertFromDicToValues(response.data);
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

function convertFromDicToValues(data: string[][]): string[] {
  const dictionaryData: { [key: string]: string } = {};
  for (const item of data) {
    const key = item[0];
    const value = item[1];
    dictionaryData[key] = value;
  }
  return Object.values(dictionaryData);
}
