import { ChevronRight } from "@mui/icons-material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { SimpleTreeView, useTreeViewApiRef } from "@mui/x-tree-view";
import useDatabaseStore from "../../../../state/DatabaseState/databaseState";
import TreeNodeDto from "../../../../interfaces/aggregate/TreeNodeDto";
import CustomTreeItem from "./CustomTreeItem";
import React from "react";

interface CustomSimpleTreeViewProps {
  customContent: boolean;
}

export default function CustomSimpleTreeView({
  customContent,
}: CustomSimpleTreeViewProps) {
  const {
    categories,
    selectedNode,
    expandedNodes,
    treeNodeData,
    setExpandedNodes,
    setSelectedNode,
  } = useDatabaseStore();

  const apiRef = useTreeViewApiRef();

  const handleExpandedItemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[]
  ) => {
    setExpandedNodes(itemIds);
  };

  const renderTreeItems = (data: TreeNodeDto[]) => {
    return data
      .sort((a, b) => {
        if (a.label.trim() !== "" && b.label.trim() === "") {
          return -1;
        } else if (a.label.trim() === "" && b.label.trim() !== "") {
          return 1;
        } else {
          return a.label.localeCompare(b.label);
        }
      })
      .map((item) => (
        <CustomTreeItem
          customContent={customContent}
          key={item.id}
          itemId={item.id}
          label={item.label}
        >
          {item.children && renderTreeItems(item.children)}
        </CustomTreeItem>
      ));
  };

  return (
    <SimpleTreeView
      apiRef={apiRef}
      disableSelection={false}
      multiSelect={false}
      className="tree-view"
      aria-label="controlled"
      slots={{
        expandIcon: ChevronRight,
        collapseIcon: GridExpandMoreIcon,
      }}
      onItemFocus={(event, itemId) => {
        const selectedCategory = categories.find(
          (x) => x.id === Number(itemId)
        );
        if (selectedNode !== itemId && selectedCategory) {
          setSelectedNode(selectedCategory!.id + "");
        }
      }}
      onExpandedItemsChange={handleExpandedItemsChange}
      expandedItems={expandedNodes}
      defaultExpandedItems={expandedNodes}
    >
      {renderTreeItems(treeNodeData)}
    </SimpleTreeView>
  );
}
