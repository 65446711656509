import {
  AddLocationAlt,
  EditLocationAlt,
  FmdGood,
  WrongLocation,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { StoreApi, UseBoundStore } from "zustand";
import CustomPopover from "../../../../../components/CustomPopover/CustomPopover";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
} from "../../../state/baseConfiguratorState";
import useProjectsStore from "../../../state/projectsState";
import { getAddressByLongLat } from "../../Maps/MapService";
import LabeledField from "../../PropertySectionView/LabeledField";
import NumberField from "../../PropertySectionView/NumberField";
import BuildableArea from "./BuildableArea";
import CustomSearchBox from "./CustomSearchBox";
import "./MapViewInputPane.scss";

interface MapViewInputPaneProps {
  currentStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >;
}

export default function MapViewInputPane({
  currentStore,
}: MapViewInputPaneProps) {
  const { t } = useTranslation();
  const [plotPercentage, setPlotPercentage] = React.useState<number | null>(85);

  const {
    drawedPlot,
    startDrawingPlot,
    deleteDrawingPlot,
    editDrawingPlot,
    navigateDrawingPlot,
    distanceBetweenPlotAndRoad,
    setStartDrawingPlot,
    setDeleteDrawingPlot,
    setEditDrawingPlot,
    setNavigateDrawingPlot,
    setProjectAddressFeature,
    setDrawedPlot,
  } = currentStore();

  const { currentProject, setProjectAddress } = useProjectsStore();

  React.useEffect(() => {
    if (drawedPlot && drawedPlot.plotPercentage) {
      setPlotPercentage(drawedPlot.plotPercentage);
    }
  }, []);

  React.useEffect(() => {
    const newBuildableSurface =
      plotPercentage === null
        ? 0
        : drawedPlot.lotSurface * (plotPercentage / 100);
    if (
      drawedPlot.buildableSurface !== newBuildableSurface &&
      newBuildableSurface !== 0
    ) {
      setDrawedPlot({
        ...drawedPlot,
        plotPercentage: plotPercentage,
        buildableSurface: newBuildableSurface,
      });

      (async () => {
        const address = await getAddressByLongLat(
          drawedPlot.lotCoordinates[0][0],
          drawedPlot.lotCoordinates[0][1]
        );
        setProjectAddress(address);
      })();
    }
  }, [drawedPlot, drawedPlot.lotSurface, plotPercentage, setDrawedPlot]);

  return (
    <div className="map-view-control">
      <div className="map-view-controls-control-view">
        <div className="titel-no-bold">{t("MapViewPage.Lot")}</div>
        <div>{t("MapViewPage.ProjectAddress")}</div>
        <CustomSearchBox
          projectAddress={currentProject?.projectAddress}
          setProjectAddress={setProjectAddress}
          setProjectAddressFeature={setProjectAddressFeature}
        />
        {drawedPlot.lotSurface === 0 && (
          <div className="empty-drawable-plot">
            <div className="empty-drawable-plot-label">
              {t("MapViewPage.NoPlotDrawn") + "..."}
            </div>
            <CustomPopover
              content={
                <div className="empty-drawable-plot-popover">
                  <IconButton
                    onClick={() => setStartDrawingPlot(!startDrawingPlot)}
                  >
                    <AddLocationAlt sx={{ fontSize: "25px" }} />
                  </IconButton>
                </div>
              }
              popoverText={t("MapViewPage.AddPlot")}
            ></CustomPopover>
          </div>
        )}

        {drawedPlot.lotSurface > 0 && (
          <div className="drawable-plot mt20">
            <div>{t("MapViewPage.PerimeterPlot")}</div>
            <Grid container sx={{ marginTop: "-10px" }}>
              <Grid
                xs
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="drawable-plot-circle" />
                <div className="drawable-plot-label">
                  {`${t("MapViewPage.BuildingAreaContains")} ${
                    drawedPlot.lotCoordinates.length
                  } ${t("MapViewPage.Points").toLocaleLowerCase()}`}
                </div>
              </Grid>
              <Grid item>
                <div className="drawable-plot-label-button-group-view">
                  <div className="drawable-plot-button-group">
                    <CustomPopover
                      content={
                        <IconButton
                          sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                          onClick={() =>
                            setNavigateDrawingPlot(!navigateDrawingPlot)
                          }
                        >
                          <FmdGood sx={{ fontSize: "25px" }} />
                        </IconButton>
                      }
                      popoverText={t("MapViewPage.NavigatePlot")}
                    ></CustomPopover>
                    <CustomPopover
                      content={
                        <IconButton
                          sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                          onClick={() => setEditDrawingPlot(!editDrawingPlot)}
                        >
                          <EditLocationAlt sx={{ fontSize: "25px" }} />
                        </IconButton>
                      }
                      popoverText={t("MapViewPage.EditPlot")}
                    ></CustomPopover>
                    <CustomPopover
                      content={
                        <IconButton
                          sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                          onClick={() =>
                            setDeleteDrawingPlot(!deleteDrawingPlot)
                          }
                        >
                          <WrongLocation sx={{ fontSize: "25px" }} />
                        </IconButton>
                      }
                      popoverText={t("MapViewPage.DeletePlot")}
                    ></CustomPopover>
                  </div>
                </div>
              </Grid>
            </Grid>

            {distanceBetweenPlotAndRoad > 0 && (
              <div className="dinstance-between-plot-and-road-view">
                <div className="distance-between-plot-and-road-circle" />
                <div className="single-line-text">{`${t(
                  "MapViewPage.DistancePlotRoad"
                )} ${Math.round(distanceBetweenPlotAndRoad)}m`}</div>
              </div>
            )}

            <div className="mt10" />

            <LabeledField
              labelId="total-area-label"
              labelText={t("MapViewPage.TotalArea")}
              tooltipText={t("MapViewPage.TotalAreaTooltip")}
            >
              <NumberField
                unit="m2"
                disabled={true}
                showBorderBottomStyle={true}
                value={drawedPlot.lotSurface}
              />
            </LabeledField>

            <BuildableArea
              percentage={plotPercentage}
              buildableSurface={drawedPlot.buildableSurface}
              onPercentageChange={setPlotPercentage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
