import { Cancel, CancelOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { MouseEventHandler } from "react";

interface CancelButtonProps {
  fontSize?: string;
  text?: string | undefined;
  onClick?: MouseEventHandler | undefined;
}

export default function CancelButton(props: CancelButtonProps) {
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  return (
    <div>
      <IconButton
        sx={{ ml: "auto" }}
        onClick={props.onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? (
          <Cancel style={{ fontSize: "2rem", fill: "rgba(0, 52, 102, 1)" }} />
        ) : (
          <CancelOutlined
            style={{ fontSize: "2rem", fill: "rgba(0, 52, 102, 1)" }}
          />
        )}
      </IconButton>
    </div>
  );
}
