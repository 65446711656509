import { PublicClientApplication } from "@azure/msal-browser";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CancelTokenSource,
} from "axios";
import { TrackException } from "../../../../logging/LoggingManager";
import useLayoutStore from "../../../../state/layoutState";
import { authConfig, loginRequest } from "../../authentication/authConfig";

export class HttpService {
  private instance: AxiosInstance;
  private defaultHeaders: Record<string, string> = {
    "Content-Type": "application/json",
    "Accept-Language": useLayoutStore.getState().selectedLang,
  };

  constructor(baseUrl: string) {
    this.instance = axios.create({ baseURL: baseUrl });
  }

  get<T>(url: string, customHeaders: Record<string, string> = {}): Promise<T> {
    return this.request<T>("get", url, null, customHeaders);
  }

  post<T>(
    url: string,
    data: unknown,
    customHeaders: Record<string, string> = {}
  ): Promise<T> {
    return this.request<T>("post", url, data, customHeaders);
  }

  put<T>(
    url: string,
    data: unknown,
    customHeaders: Record<string, string> = {}
  ): Promise<T> {
    return this.request<T>("put", url, data, customHeaders);
  }

  delete<T>(
    url: string,
    customHeaders: Record<string, string> = {}
  ): Promise<T> {
    return this.request<T>("delete", url, null, customHeaders);
  }

  async request<T>(
    method: "get" | "post" | "put" | "delete",
    url: string,
    data: unknown | null = null,
    customHeaders: Record<string, string> = {}
  ): Promise<T> {
    const headers = { ...this.defaultHeaders, ...customHeaders };
    const token = await this.acquireToken();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const source: CancelTokenSource = axios.CancelToken.source();
    const config: AxiosRequestConfig = {
      method,
      url,
      headers,
      cancelToken: source.token,
    };

    if (data) {
      config.data = data;
    }

    const response = await this.instance(config);
    return response.data;
  }

  async acquireToken() {
    const msalInstance = new PublicClientApplication(authConfig);
    await msalInstance.initialize();
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      try {
        const response = await msalInstance.acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: accounts[0],
        });
        return response.accessToken;
      } catch (ex) {
        TrackException(ex);
      }
    }
    return null;
  }
}
