import { useTranslation } from "react-i18next";
import "./Notification.scss";

interface NotificationProps {
  message: string;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

export default function Notification({
  message,
  top,
  bottom,
  left,
  right,
}: NotificationProps) {
  const { t } = useTranslation();
  return (
    <div
      className="notification"
      style={{
        top: top,
        bottom: bottom,
        left: left,
        right: right,
      }}
    >
      <div className="message">{t(message)}</div>
    </div>
  );
}
