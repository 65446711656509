import { Dialog, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useState } from "react";
import CancelButton from "../../../../../components/CancelButton/CancelButton";
import LoadingIndicator from "../../../../../components/LoadingIndicator/LoadingIndicator";
import { TypeObject, TypeObjectLabel } from "../../../types/enums";
import { DetailedInfo } from "../../../types/InfoField";
import {
  DTO,
  ExploreActions,
  ExploreState,
  TypicalDTO,
} from "../../../state/ExploreState/ExploreState";
import DetailedViewSidebar from "../../DetailedViewSidebar/DetailedViewSidebar";
import SceneCanvas from "../SceneCanvas/SceneCanvas";
import "./SceneDetail.scss";

interface SceneDetailProps<T extends DTO> {
  pageType: TypeObject;
  currentState: ExploreState<T> & ExploreActions<T>;
  detailedInfo?: DetailedInfo[];
}
export default function SceneDetail<T extends DTO>({
  pageType,
  currentState,
  detailedInfo,
}: SceneDetailProps<T>) {
  const [loadedCanvas, setLoadedCanvas] = useState(false);

  const {
    currentObject,
    setCurrentObject,
    isOpenedDetailedView,
    setIsOpenedDetailedView,
  } = currentState;

  const currentTypical = currentObject as TypicalDTO;

  const handleClose = () => {
    setIsOpenedDetailedView(false);
    setCurrentObject(null);
    setLoadedCanvas(false);
  };

  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpenedDetailedView}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: "75%",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 16,
          fontWeight: 900,
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {t(TypeObjectLabel.get(pageType) ?? "") + " details"}
        <Box component={"div"} sx={{ ml: "auto" }}>
          <CancelButton onClick={handleClose} />
        </Box>
      </DialogTitle>
      <div className="scene-details">
        {!currentTypical ? (
          <div className="loading-indicator-scene-details">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="tab-view">
            <div className="image-view">
              {currentTypical && (
                <SceneCanvas
                  setLoadedCanvas={setLoadedCanvas}
                  currentObject={currentTypical}
                />
              )}
              {!loadedCanvas && (
                <div className="loading-indicator-scene-details">
                  <LoadingIndicator />
                </div>
              )}
            </div>
            <div className="scene-sidebar-view">
              <DetailedViewSidebar
                data={currentTypical}
                detailedInfo={detailedInfo ?? []}
              />
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}
