import {
  AddLocationAlt,
  ChevronRight,
  FmdGood,
  WrongLocation,
} from "@mui/icons-material";
import {
  Collapse,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ExploreConfigStore } from "../../../interfaces/exploreConfig";
import { ScenarioDto } from "../../../modules/Analysers/types/api";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import useNitrogenInputPaneStore from "../../../state/NitrogenState/nitrogenInputPaneState";
import useNitrogenStore from "../../../state/NitrogenState/nitrogenState";
import FormControlSelect from "../../CustomFormControl/FormControlSelect";
import CustomPopover from "../../CustomPopover/CustomPopover";
import ExpandMore from "../../ExpandMore/ExpandMore";
import CustomInputLabel from "../../InputLabel/CustomInputLabel";
import CustomSearchBox from "../DatacenterGenerator/BasicPrinciples/CustomSearchBox";
import FilterControl from "../FilterControl";
import "./NitrogenPrinciplesControl.scss";

interface NitrogenPrinciplesControlProps {
  nitrogenAnalyserConfigStore: ExploreConfigStore<ScenarioDto>;
}

export default function NitrogenPrinciplesControl({
  nitrogenAnalyserConfigStore,
}: NitrogenPrinciplesControlProps) {
  const { t } = useTranslation();
  const [buildingLotExpanded, setBuildingLotExpanded] = React.useState(false);
  const { departments } = useDatabaseStore();
  const {
    currentTabPageIndex,
    distanceBetweenMarkerAndRoad,
    setDistanceBetweenMarkerAndRoad,
    setNavigateDrawingMarker,
    setStartDrawingMarker,
    setDeleteDrawingMarker,
  } = useNitrogenStore();

  const {
    projectType,
    annualNOxLimit,
    annualNH3Limit,
    annualCO2Limit,
    projectObjectiveNOx,
    projectObjectiveNH3,
    projectObjectiveCO2,
    projectResponsible,
    emissionsSpecialist,
    planner,
    drawedMarker,
    bvoProject,
    setBvoProject,
    setProjectType,
    setAnnualNOxLimit,
    setAnnualNH3Limit,
    setAnnualCO2Limit,
    setProjectObjectiveNOx,
    setProjectObjectiveNH3,
    setProjectObjectiveCO2,
    setProjectResponsible,
    setEmissionsSpecialist,
    setPlanner,
  } = useNitrogenInputPaneStore();

  React.useEffect(() => {
    setDistanceBetweenMarkerAndRoad(0);
  }, []);

  return (
    <div className="nitrogen-principles-control">
      {currentTabPageIndex === 0 && (
        <div>
          <div className="map-view-controls-control-view">
            <div>{t("ProjectAddress")}</div>
            <CustomSearchBox />
            {!drawedMarker.marker && (
              <div className="empty-drawable-plot">
                <div className="empty-drawable-plot-label">
                  {t("NitrogenPage.NoMarkerDrawn") + "..."}
                </div>
                <CustomPopover
                  content={
                    <div className="empty-drawable-plot-popover">
                      <IconButton onClick={() => setStartDrawingMarker(true)}>
                        <AddLocationAlt sx={{ fontSize: "25px" }} />
                      </IconButton>
                    </div>
                  }
                  popoverText={t("NitrogenPage.AddMarker")}
                ></CustomPopover>
              </div>
            )}

            {drawedMarker.marker && (
              <div className="drawable-plot mt10">
                <Grid container>
                  <Grid
                    item
                    xs={7}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <CustomPopover
                      content={
                        <div className="drawable-plot-popover">
                          <ExpandMore
                            sx={{ display: "contents" }}
                            expand={buildingLotExpanded}
                            onClick={() =>
                              setBuildingLotExpanded(!buildingLotExpanded)
                            }
                          >
                            {buildingLotExpanded ? (
                              <GridExpandMoreIcon />
                            ) : (
                              <ChevronRight sx={{ fontSize: "1.5rem" }} />
                            )}
                          </ExpandMore>
                          <div className="drawable-plot-circle" />
                          <div>{t("NitrogenPage.MarkerCoordinates")}</div>
                        </div>
                      }
                      popoverText={t("NitrogenPage.MarkerCoordinatesTooltip")}
                    ></CustomPopover>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="drawable-plot-label-button-group-view">
                      <div className="drawable-plot-label"></div>
                      <div className="drawable-plot-button-group">
                        <CustomPopover
                          content={
                            <IconButton
                              sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                              onClick={() => {
                                setNavigateDrawingMarker(true);
                              }}
                            >
                              <FmdGood sx={{ fontSize: "25px" }} />
                            </IconButton>
                          }
                          popoverText={t("NitrogenPage.NavigateMarker")}
                        ></CustomPopover>
                        <CustomPopover
                          content={
                            <IconButton
                              sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                              onClick={() => {
                                setStartDrawingMarker(true);
                              }}
                            >
                              <AddLocationAlt sx={{ fontSize: "25px" }} />
                            </IconButton>
                          }
                          popoverText={t("NitrogenPage.AddMarker")}
                        ></CustomPopover>
                        <CustomPopover
                          content={
                            <IconButton
                              sx={{ paddingLeft: "0px", paddingRight: "0px" }}
                              onClick={() => setDeleteDrawingMarker(true)}
                            >
                              <WrongLocation sx={{ fontSize: "25px" }} />
                            </IconButton>
                          }
                          popoverText={t("NitrogenPage.DeleteMarker")}
                        ></CustomPopover>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Collapse in={buildingLotExpanded} timeout="auto">
                      <Grid
                        container
                        sx={{
                          marginLeft: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        {drawedMarker.lotRdCoordinates.map(
                          (coordinate, index) => (
                            <Grid item xs={12} key={index}>
                              <Grid container columnSpacing={1}>
                                <Grid item xs={6.5}>
                                  <div>{`RD ${t(
                                    "NitrogenPage.Coordinate"
                                  )} X: ${coordinate[0].toFixed(2)}`}</div>
                                </Grid>
                                <Grid item xs={5}>
                                  <div>{`Y: ${coordinate[1].toFixed(2)}`}</div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </div>
            )}

            {distanceBetweenMarkerAndRoad > 0 && (
              <div className="dinstance-between-plot-and-road-view">
                <div className="distance-between-plot-and-road-circle" />
                <div className="single-line-text">{`${t(
                  "NitrogenPage.DistanceMarkerRoad"
                )} ${Math.round(distanceBetweenMarkerAndRoad)}m`}</div>
              </div>
            )}
          </div>
        </div>
      )}

      {currentTabPageIndex === 3 && (
        <FilterControl
          nitrogenAnalyserConfigStore={nitrogenAnalyserConfigStore!}
        />
      )}

      <hr className="mt10" />

      <div className="generator-inputs">
        <FormControlSelect
          labelText={t("NitrogenPage.ProjectType")}
          toolTipText={t("NitrogenPage.ProjectTypeTooltip")}
          value={projectType}
          showInfoButton={true}
          setValue={(selectedValue) => {
            setProjectType(selectedValue);
          }}
          selectItems={departments.map((x) => x.name)}
        />

        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="bvo-project-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.BVOProject")}
            tooltipText={t("NitrogenPage.BVOProjectTooltip")}
          />
          <TextField
            id="bvo-project-textfield"
            style={{ marginTop: "10px" }}
            fullWidth
            type="text"
            value={bvoProject === null ? "" : bvoProject}
            onChange={(event) => {
              const value = event.target.value;
              if (value === "" || /^[0-9]*$/.test(value)) {
                setBvoProject(value === "" ? undefined : Number(value));
              }
            }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>

        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="annual-nox-limit-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.AnnualNOxLimit")}
            tooltipText={t("NitrogenPage.AnnualNOxLimitTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            type="number"
            value={annualNOxLimit}
            onChange={(event) => {
              setAnnualNOxLimit(Number(event.target.value));
            }}
            id="annual-nox-limit"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              inputProps: { min: 0 },
            }}
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="annual-nh3-limit-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.AnnualNH3Limit")}
            tooltipText={t("NitrogenPage.AnnualNH3LimitTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            type="number"
            value={annualNH3Limit}
            onChange={(event) => {
              setAnnualNH3Limit(Number(event.target.value));
            }}
            id="annual-nh3-limit"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="annual-co2-limit-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.AnnualCO2Limit")}
            tooltipText={t("NitrogenPage.AnnualCO2LimitTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            type="number"
            value={annualCO2Limit}
            onChange={(event) => {
              setAnnualCO2Limit(Number(event.target.value));
            }}
            id="annual-co2-limit"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="project-objective-nox-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.ProjectGoalNOx")}
            tooltipText={t("NitrogenPage.ProjectGoalNOxTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            type="number"
            value={projectObjectiveNOx}
            onChange={(event) => {
              setProjectObjectiveNOx(Number(event.target.value));
            }}
            id="project-objective-nox"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="project-objective-nh3-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.ProjectGoalNH3")}
            tooltipText={t("NitrogenPage.ProjectGoalNH3Tooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            type="number"
            value={projectObjectiveNH3}
            onChange={(event) => {
              setProjectObjectiveNH3(Number(event.target.value));
            }}
            id="project-objective-nh3"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="project-objective-co2-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.ProjectGoalCO2")}
            tooltipText={t("NitrogenPage.ProjectGoalCO2Tooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            type="number"
            fullWidth
            value={projectObjectiveCO2}
            onChange={(event) => {
              setProjectObjectiveCO2(Number(event.target.value));
            }}
            id="project-objective-co2"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="project-responsible-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.ProjectResponsible")}
            tooltipText={t("NitrogenPage.ProjectResponsibleTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            value={projectResponsible}
            onChange={(event) => {
              setProjectResponsible(event.target.value);
            }}
            id="project-responsible"
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="emissions-specialist-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.EmissionsSpecialist")}
            tooltipText={t("NitrogenPage.EmissionsSpecialistTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            value={emissionsSpecialist}
            onChange={(event) => {
              setEmissionsSpecialist(event.target.value);
            }}
            id="emissions-specialist"
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth variant="standard" style={{ marginTop: "20px" }}>
          <CustomInputLabel
            id="planner-label"
            showInfoButton={true}
            labelText={t("NitrogenPage.Planner")}
            tooltipText={t("NitrogenPage.PlannerTooltip")}
          />
          <TextField
            style={{ marginTop: "10px" }}
            fullWidth
            value={planner}
            onChange={(event) => {
              setPlanner(event.target.value);
            }}
            id="planner"
            variant="standard"
          />
        </FormControl>
      </div>
    </div>
  );
}
