
import React from "react";
import { IconProps } from "./types";
import "./Icon.scss";

export const EllipseIcon: React.FC<IconProps> = ({ size = 18, ...props }) => {
    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
            <path className='svg' d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
        </svg>
    );
};
