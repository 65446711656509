export default interface DrawedMarker {
  id: string;
  marker: GeoJSON.Feature | null;
  lotCoordinates: number[][];
  lotRdCoordinates: number[][];
}

export function GetEmptyDrawedMarker(): DrawedMarker {
  return {
    id: "",
    marker: null,
    lotCoordinates: [],
    lotRdCoordinates: [],
  } as DrawedMarker;
}
