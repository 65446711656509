import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EllipseIcon } from "../../../../../icons/EllipseIcon";
import { ProjectFoundryDto } from "../../../../../modules/sharedLogic/types/api";
import colors from "../../../../../styles/colors.module.scss";
import "./ProjectCard.scss";
import useUserStore from "../../../../../modules/sharedLogic/state/userState";

interface ProjectCardProps {
  project: ProjectFoundryDto;
  onShareProject: (project: ProjectFoundryDto) => void;
  onRemameProject: (project: ProjectFoundryDto) => void;
  onDeleteProject: (project: ProjectFoundryDto) => void;
}

// ProjectCard-component
export default function ProjectCard({
  project,
  onRemameProject,
  onDeleteProject,
}: ProjectCardProps) {
  const { t } = useTranslation();
  const { emailAddress, appAbility } = useUserStore();
  const { heijmansBlue } = colors;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popOverOpen, setPopOverOpen] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setPopOverOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setPopOverOpen(false);
  };

  const handleRenamingProject = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPopOverOpen(false);
    onRemameProject(project);
  };

  const handleDeletingProject = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPopOverOpen(false);
    onDeleteProject(project);
  };

  return (
    <Grid item key={project.id}>
      <Card
        onClick={() => {
          navigate(project.name);
        }}
        sx={{
          minWidth: "340px",
          maxWidth: "350px",
          padding: "20px 20px 0px 20px",
          ":hover": {
            border: `1px solid ${heijmansBlue}`,
            cursor: "pointer",
          },
        }}
      >
        <CardMedia
          sx={{ height: 140 }}
          image="/assets/datacenterdrawing.png"
          title={project.name}
        />
        <CardContent sx={{ paddingRight: "0px" }}>
          <div className="card-content">
            <div className="card-content-header">
              <Typography
                gutterBottom
                variant="h2"
                component="div"
                fontWeight="bold"
              >
                {project.name}
              </Typography>
            </div>

            {(appAbility?.can("delete", "configurators.datacenter") ||
              appAbility?.can("update", "configurators.datacenter") ||
              project.created_by === emailAddress) && (
              <div>
                <IconButton
                  style={{
                    display: "flex",
                    marginRight: "-20px",
                    placeItems: "center end",
                  }}
                  aria-describedby={popOverOpen ? "simple-popover" : undefined}
                  onClick={handleClick}
                >
                  <EllipseIcon className="icon" size={25} />
                </IconButton>
                <Popover
                  id="simple-popover"
                  open={popOverOpen}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div>
                    {(appAbility?.can("update", "configurators.datacenter") ||
                      project.created_by === emailAddress) && (
                      <Typography
                        style={{ cursor: "pointer" }}
                        sx={{ p: 2 }}
                        onClick={handleRenamingProject}
                      >
                        {t("Rename")}
                      </Typography>
                    )}
                    {(appAbility?.can("delete", "configurators.datacenter") ||
                      project.created_by === emailAddress) && (
                      <Typography
                        style={{ cursor: "pointer" }}
                        sx={{ p: 2 }}
                        onClick={handleDeletingProject}
                      >
                        {t("Delete")}
                      </Typography>
                    )}
                  </div>
                </Popover>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
