import { GetSpaceBuilding } from "../../../HSA/services/SpaceBuildingService";
import { TypeObject } from "../../types/enums";
import { TrackException } from "../../logging/LoggingManager";
import { DTO } from "../../state/ExploreState/ExploreState";

export const typeObjectFetchMap: {
  [key in TypeObject]: (code: string) => Promise<any>;
} = {
  [TypeObject.HSABuilding]: GetSpaceBuilding,
  [TypeObject.MapView]: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.ExportView]: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.HSAApartment]: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.HSACountingModel]: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
};

async function getCurrentObjectDetails<T>(
  typeObject: TypeObject,
  object: T,
  setCurrentObject: (newValue: T | null) => void
) {
  try {
    setCurrentObject({
      ...object,
      typeObject: typeObject,
    });
  } catch (ex) {
    TrackException(ex);
  }
}

export async function openDetailView<T extends DTO>(
  currentObject: T,
  setIsOpenedDetailedView: (isOpen: boolean) => void,
  setCurrentObject: (newValue: T | null) => void
) {
  setIsOpenedDetailedView(true);
  try {
    const object = currentObject as { code: string; typeObject: TypeObject };
    const fetchFunction = typeObjectFetchMap[object.typeObject];
    if (fetchFunction) {
      var details = await fetchFunction(object.code);
      if (!details.code) {
        details = currentObject;
      }

      getCurrentObjectDetails(object.typeObject, details, setCurrentObject);
    } else {
      throw new Error("Unsupported typeObject");
    }
  } catch (ex) {
    TrackException(ex);
  }
}
