import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../../icons/CloseIcon";
import CustomInputLabel from "../../../components/InputLabel/CustomInputLabel";
import { RackDto } from "../../../interfaces/foundry/typicals/RackDto";
import { useTranslation } from "react-i18next";

interface RackDialogProps {
  isRackViewOpen: boolean;
  setIsRackViewOpen: (value: boolean) => void;
  currentRack: RackDto;
  setCurrentRack: (value: RackDto) => void;
  handleSubmit: () => void;
}

export function RackDialog({
  currentRack,
  handleSubmit,
  isRackViewOpen,
  setCurrentRack,
  setIsRackViewOpen,
}: RackDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="xl"
      open={isRackViewOpen}
      onClose={() => setIsRackViewOpen(false)}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: "25%",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          paddingRight: "15px",
          paddingBottom: "5px",
          fontWeight: 700,
        }}
      >
        {`${t("Create")}/${t("Edit")} ${t("Server rack")}`}
        <IconButton
          sx={{ ml: "auto" }}
          onClick={() => setIsRackViewOpen(false)}
        >
          <CloseIcon className="svg" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "25px" }}>
        <FormControl fullWidth variant="standard">
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Power")} (kW)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentRack.power_in_w_string}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentRack({
                ...currentRack,
                power_in_w_string: event.target.value.replace(",", "."),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Length")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentRack.length}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentRack({
                ...currentRack,
                length: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Width")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentRack.width}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentRack({
                ...currentRack,
                width: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Height")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentRack.height}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentRack({
                ...currentRack,
                height: Number(event.target.value),
              })
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ paddingRight: "20px" }}>
        <Button onClick={handleSubmit}>{t("Save")}</Button>
      </DialogActions>
    </Dialog>
  );
}
