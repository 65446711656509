import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { CombinationsResponseDto } from "../../interfaces/foundry/CombinationsResponseDto";
import AlleyDto from "../../interfaces/foundry/typicals/AlleyDto";
import { RackDto } from "../../interfaces/foundry/typicals/RackDto";
import RowDto from "../../interfaces/foundry/typicals/RowDto";
import WhitespaceDto from "../../interfaces/foundry/WhitespaceDto";
import { TrackException } from "../../logging/LoggingManager";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import {
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";
import { FetchPaginatedData } from "./SharedFunctions";
import { GetAlley } from "./typicals/AlleyService";
import { GetCooling } from "./typicals/CoolingService";
import { GetMdb } from "./typicals/MdbService";
import { GetRack } from "./typicals/RackService";
import { GetRow } from "./typicals/RowService";

export async function GetWhitespaces(
  signal: AbortSignal,
  currentState?: ExploreState<WhitespaceDto> & ExploreActions<WhitespaceDto>,
  buildingType?: number
): Promise<WhitespaceDto[]> {
  const url = `/api/datacenter/whitespaces/?layout_exists=True${
    buildingType ? `&building_types=${buildingType}` : ""
  }`;
  const allWhitespaces = await FetchPaginatedData<WhitespaceDto>(
    url,
    processWhitespaceData,
    buildingType,
    signal
  );
  currentState?.setObjectsTotalCount(allWhitespaces.length);
  return allWhitespaces;
}

export async function GetWhitespace(id: number): Promise<WhitespaceDto> {
  try {
    const response = await foundryApiClient.get<WhitespaceDto>(
      `/api/datacenter/whitespaces/${id}?layout_exists=True&detail_view=True/`
    );

    const whitespace = processWhitespaceData(response.data);
    whitespace.cooling = {
      ...(await GetCooling(whitespace.cooling_type)),
      amount: whitespace.cooling_amount,
      type: whitespace.cooling_type_string,
      customAttribute: whitespace.cooling_area.toFixed(),
    };

    const row = await GetRow(whitespace.row_type);
    whitespace.row = {
      ...row,
      amount: whitespace.row_amount,
    } as RowDto;

    whitespace.rack = {
      ...(await GetRack(row.rack_type)),
      amount: whitespace.rack_amount,
      power_in_w: whitespace.rack_power_in_w,
    } as RackDto;

    whitespace.coldAlley = {
      ...(await GetAlley(row.cold_alley)),
      amount: whitespace.cold_alley_amount,
    } as AlleyDto;

    whitespace.hotAlley = {
      ...(await GetAlley(row.hot_alley)),
      amount: whitespace.hot_alley_amount,
    } as AlleyDto;

    whitespace.mdb = {
      ...(await GetMdb(row.mdb)),
      amount: whitespace.row_amount,
    } as AlleyDto;

    return whitespace;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function UpdateWSsBuildingTypes(
  wsId: number,
  buildingTypesIds: number[]
): Promise<WhitespaceDto> {
  try {
    const response = await foundryApiClient.patch<WhitespaceDto>(
      `/api/datacenter/whitespaces/${wsId}/`,
      { building_types: buildingTypesIds }
    );

    return processWhitespaceData(response.data);
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

function processWhitespaceData(
  item: WhitespaceDto,
  buildingType?: TypeObject
): WhitespaceDto {
  const processedItem = {
    ...item,
    desired_power_in_w: item.desired_power_in_w / 1000000,
    true_power_in_w: item.true_power_in_w / 1000000,
    area: item.area / 1000000,
    cooling_area: item.cooling_area / 1000000,
    ws_and_cooling_area: item.ws_and_cooling_area / 1000000,
    length: item.length / 1000,
    width: item.width / 1000,
    height: item.height / 1000,
    rack_power_in_w: item.rack_power_in_w / 1000,
    volume: item.volume / 1000000000,
    typeObject: buildingType
      ? TypeObject.Whitespace
      : TypeObject.WhitespaceTooling,
    renderType: "image" as "image",
  };

  const coolingTypeId = processedItem.cooling_type;
  switch (coolingTypeId) {
    case 1:
      return {
        ...processedItem,
        cooling_type_string: "Floor - mechanical (1-sided)",
      };
    case 2:
      return {
        ...processedItem,
        cooling_type_string: "Floor - mechanical (2-sided)",
      };
    case 3:
      return { ...processedItem, cooling_type_string: "Wall - natural" };
    case 4:
      return { ...processedItem, cooling_type_string: "Wall - mechanical" };
    default:
      return processedItem;
  }
}

export async function GetWhitespaceGenerationCombinations(): Promise<CombinationsResponseDto> {
  try {
    const response = await foundryApiClient.get<CombinationsResponseDto>(
      "/api/datacenter/whitespace_combinations"
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}

export async function StartGenerationWhitespaces() {
  try {
    await foundryApiClient.post("/api/datacenter/whitespace_combinations");
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
