import { create } from "zustand";
import {
  AppAbility,
  defineAbilityFor,
} from "../authentication/PermissionsManager";

interface UserState {
  name: string;
  emailAddress: string;
  roles: string[] | undefined;
  appAbility: AppAbility | undefined;
  setName: (stringValue: string) => void;
  setEmailAddress: (stringValue: string) => void;
  setRoles: (stringArray: string[] | undefined) => void;
}

const useUserStore = create<UserState>()((set) => ({
  name: "",
  emailAddress: "",
  roles: [],
  appAbility: undefined,
  setName: (newValue) => set({ name: newValue }),
  setEmailAddress: (newValue) => set({ emailAddress: newValue }),
  setRoles: (newValue) =>
    set({ roles: newValue, appAbility: defineAbilityFor(newValue ?? []) }),
}));

export default useUserStore;
