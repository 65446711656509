import { create } from "zustand";
import { BuildingTypeDto } from "../../interfaces/foundry/BuildingTypeDto";
import { BatteryDto } from "../../interfaces/foundry/typicals/BatteryDto";
import { TransformatorDto } from "../../interfaces/foundry/typicals/TransformatorDto";

export interface InputPaneState {
  maxBuildHeight: number | null;
  desiredITCapability: number | null;
  groundFootprint: number | null;
  totalITPower: number | null;
  typeDataCentre: string | undefined;
  destinationPlan: string | undefined;
  expensionOnCampus: boolean;
  purchasingStation: boolean;
  sprinklerBasin: boolean;
  waterTreatment: boolean;
  adminBuilding: boolean;
  powerWhitespaces: number[];
  powerRack: number[];
  coolingPrinciple: string[];
  tranfoType: TransformatorDto[];
  buildingType: (BuildingTypeDto | undefined)[];
  autonomyInMin: number | null;
  currentProjectId: number;
  plotPercentage: number;
  selectedBatteryTypes: string[];
}

interface InputPaneStateActions {
  setMaxBuildHeight: (maxBuildHeight: number) => void;
  setDesiredITCapability: (desiredITCapability: number) => void;
  setGroundFootprint: (groundFootprint: number) => void;
  setTotalITPower: (totalITPower: number) => void;
  setTypeDataCentre: (typeDataCentre: string) => void;
  setDestinationPlan: (destinationPlan: string) => void;
  setExpansionOnCampus: (boolValue: boolean) => void;
  setPurchasingStation: (boolValue: boolean) => void;
  setSprinklerBasin: (boolValue: boolean) => void;
  setWaterTreatment: (boolValue: boolean) => void;
  setAdminBuilding: (boolValue: boolean) => void;
  setPowerRack: (powerRack: number[]) => void;
  setPowerWhitespaces: (powerWhitespaces: number[]) => void;
  setCoolingPrinciple: (coolingPrinciple: string[]) => void;
  setTranfoType: (value: TransformatorDto[]) => void;
  setBuildingType: (value: (BuildingTypeDto | undefined)[]) => void;
  setAutonomyInMin: (value: number) => void;
  resetInputPaneState: () => void;
  setInputPaneState: (newState: InputPaneState) => void;
  setCurrentProjectId: (projectId: number) => void;
  setPlotPercentage: (plotPercentage: number) => void;
  setSelectedBatteryTypes: (value: string[]) => void;
}

const initialState: InputPaneState = GetInitialState();

function GetInitialState(): InputPaneState {
  return {
    maxBuildHeight: null,
    desiredITCapability: null,
    groundFootprint: null,
    totalITPower: null,
    typeDataCentre: "",
    destinationPlan: "",
    expensionOnCampus: false,
    purchasingStation: true,
    sprinklerBasin: true,
    waterTreatment: true,
    adminBuilding: true,
    powerWhitespaces: [0, 0],
    powerRack: [],
    coolingPrinciple: [],
    tranfoType: [],
    buildingType: [],
    selectedBatteryTypes: [],
    autonomyInMin: null,
    currentProjectId: 0,
    plotPercentage: 85,
  };
}

const useInputPaneStore = create<InputPaneState & InputPaneStateActions>()(
  (set) => ({
    ...initialState,
    setMaxBuildHeight: (newMaxBuildHeight) =>
      set({ maxBuildHeight: newMaxBuildHeight }),
    setDesiredITCapability: (newDesiredITCapability) =>
      set({
        desiredITCapability: newDesiredITCapability,
      }),
    setGroundFootprint: (newGroundFootprint) =>
      set({
        groundFootprint: newGroundFootprint,
      }),
    setTotalITPower: (newTotalITPower) =>
      set({ totalITPower: newTotalITPower }),
    setTypeDataCentre: (newTypeDataCentre) =>
      set({ typeDataCentre: newTypeDataCentre }),
    setDestinationPlan: (newDestinationPlan) =>
      set({ destinationPlan: newDestinationPlan }),
    setExpansionOnCampus: (newExpansionOnCampus) =>
      set({ expensionOnCampus: newExpansionOnCampus }),
    setPurchasingStation: (newSubstation) =>
      set({ purchasingStation: newSubstation }),
    setSprinklerBasin: (newSprinklerBasin) =>
      set({ sprinklerBasin: newSprinklerBasin }),
    setWaterTreatment: (newWaterTreatment) =>
      set({ waterTreatment: newWaterTreatment }),
    setAdminBuilding: (newAdminBuilding) =>
      set({ adminBuilding: newAdminBuilding }),
    setCoolingPrinciple: (newCoolingPrinciple) =>
      set({ coolingPrinciple: newCoolingPrinciple }),
    setPowerRack: (newPowerRack) => set({ powerRack: newPowerRack }),
    setPowerWhitespaces: (newPowerWhitespaces) =>
      set({ powerWhitespaces: newPowerWhitespaces }),
    setTranfoType: (value) => set({ tranfoType: value }),
    setBuildingType: (value) => set({ buildingType: value }),
    setAutonomyInMin: (value) => set({ autonomyInMin: value }),
    setCurrentProjectId: (projectId) => set({ currentProjectId: projectId }),
    setSelectedBatteryTypes: (value) => set({ selectedBatteryTypes: value }),
    resetInputPaneState: () => {
      set(GetInitialState());
    },
    setPlotPercentage: (plotPercentage) =>
      set({ plotPercentage: plotPercentage }),
    setInputPaneState: (newState) => set(newState),
  })
);

export default useInputPaneStore;
