import { Box } from "@mui/material";
import { randomId } from "@mui/x-data-grid-generator";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetCategories } from "../../../http/aggregate/CategoriesService";
import {
  ExportTableToCSV,
  ImportTableFromCSV,
} from "../../../http/aggregate/ImportExportService";
import TreeNodeDto from "../../../interfaces/aggregate/TreeNodeDto";
import { ExportEnum } from "../../../interfaces/aggregate/enums/ExportEnum";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import { ConvertToTreeNode } from "../../../utils/TreeNodeManager";
import AddExportImportView from "./AddExportImportView/AddExportImportView";
import "./CategoriesView.scss";
import CustomSimpleTreeView from "./CustomSimpleTreeView/CustomSimpleTreeView";

export default function CategoriesView() {
  const { t } = useTranslation();
  const {
    currentPage,
    expandedNodes,
    treeNodeData,
    categories,
    setCategories,
    setTreeNodeData,
    setExpandedNodes,
    setCurrentTreeNodeData,
  } = useDatabaseStore();

  useEffect(() => {
    if (categories.length !== 0) {
      const treeNodeData = ConvertToTreeNode(categories, null);
      setTreeNodeData(treeNodeData);
    }
  }, [categories, setTreeNodeData]);

  return (
    <div className="categories-view">
      <div className="categories-view-header">
        <div className="titel-black">{t("Categories")}</div>
      </div>
      <div className="categories-view-treeview">
        <CustomSimpleTreeView customContent={true} />
        <Box
          component={"div"}
          sx={{
            marginTop: "-5px",
            display: "flex",
          }}
        >
          <AddExportImportView
            currentValues={[]}
            importFromCsv={async (formData) => {
              return await ImportTableFromCSV(formData, "category");
            }}
            setCurrentValues={() => {
              const newItem = {
                id: randomId(),
                label: "",
                isNew: true,
              } as TreeNodeDto;

              setExpandedNodes([...expandedNodes, newItem.id.toString()]);
              setTreeNodeData([...treeNodeData, newItem]);
              setCurrentTreeNodeData(newItem);
            }}
            exportToCsv={async () => {
              await ExportTableToCSV(
                "category",
                currentPage,
                ExportEnum.Categories
              );
            }}
            reloadResources={async () => {
              setCategories(await GetCategories(currentPage));
            }}
          />
        </Box>
      </div>
    </div>
  );
}
