import { FormControl } from "@mui/material";
import { ReactNode } from "react";
import CustomInputLabel from "../InputLabel/CustomInputLabel";

interface CustomFormControlProps {
  labelText?: string;
  toolTipText?: string;
  showInfoButton?: boolean;
  children: ReactNode; 
}

export default function CustomFormControl(props: CustomFormControlProps) {
  return (
    <FormControl fullWidth variant="standard" style={{ marginTop: "15px" }}>
      <CustomInputLabel
        id={props.labelText + "-label"}
        showInfoButton={props.showInfoButton ? props.showInfoButton : false}
        labelText={props.labelText}
        tooltipText={props.toolTipText}
      />
      {props.children}
    </FormControl>
  );
}
