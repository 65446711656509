import React from "react";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import Info from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import "./CustomInputLabel.scss";

interface CustomInputLabelProps {
  showInfoButton: boolean;
  labelText?: string;
  tooltipText?: string;
  id: string;
}

const CustomInputLabel: React.FC<CustomInputLabelProps> = ({
  showInfoButton,
  labelText,
  tooltipText,
  id,
}) => {
  const { t } = useTranslation();
  return (
    <InputLabel id={id} shrink={true}>
      <div style={{ display: "flex" }}>
        {labelText && <div className="info-label">{t(labelText)}</div>}
        {showInfoButton && (
          <Tooltip arrow title={tooltipText} placement="top">
            <Info
              sx={{
                marginLeft: "5px",
                marginTop: "-3px !important",
                fontSize: "25px",
                fill: "silver",
              }}
            />
          </Tooltip>
        )}
      </div>
    </InputLabel>
  );
};

export default CustomInputLabel;
