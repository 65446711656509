import { TFunction } from "i18next";
import { ShowError } from "../../../../../http/NotificationService";
import { GetDatacenters } from "../../../../../http/foundry/DatacenterService";
import { GetLowVoltageRooms } from "../../../../../http/foundry/LowVoltageRoomService";
import { GetWhitespaces } from "../../../../../http/foundry/WhitespaceService";
import DrawedPlot from "../../../../../interfaces/DrawedPlot";
import { BuildingTypeEnum } from "../../../../../interfaces/enums/BuildingTypeEnum";
import { TypeObject } from "../../../../../interfaces/enums/TypeObjectEnum";
import { DatacenterDto } from "../../../../../interfaces/foundry/DatacenterDto";
import { SelectedBuildingsDto } from "../../../../../interfaces/foundry/SelectedBuildingsDto";
import WhitespaceDto from "../../../../../interfaces/foundry/WhitespaceDto";
import { LowVoltageRoomDto } from "../../../../../interfaces/foundry/typicals/LowVoltageRoomDto";
import { TrackException } from "../../../../../logging/LoggingManager";
import { GetNitrogenScenarios } from "../../../../../modules/Analysers/services/ScenarioService";
import { ScenarioDto } from "../../../../../modules/Analysers/types/api";
import { ProjectFoundryDto } from "../../../../../modules/sharedLogic/types/api";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../../../../state/ExploreState/ExploreState";

interface LoadItemsParams<T extends DTO> {
  signal: AbortSignal;
  currentState: ExploreState<T> & ExploreActions<T>;
  currentProject?: ProjectFoundryDto;
  type: TypeObject;
  drawedPlot?: DrawedPlot;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleObjectFilters: (objects: T[]) => void;
  t: TFunction<"translation", undefined>;
}

export async function LoadItems<T extends DTO>({
  signal,
  currentState,
  currentProject,
  type,
  drawedPlot,
  setIsSidebarOpen,
  handleObjectFilters,
  t,
}: LoadItemsParams<T>) {
  try {
    let tempObjects: T[] = currentState.objects ?? [];
    let selectedObjects: T[] = [];
    let selectedBuildings: SelectedBuildingsDto | undefined = undefined;
    if (tempObjects.length === 0) {
      if (currentProject && currentProject.buildings) {
        selectedBuildings = JSON.parse(
          currentProject.buildings
        ) as SelectedBuildingsDto;
      }
      switch (type) {
        case TypeObject.Whitespace:
          tempObjects = (await GetWhitespaces(
            signal,
            currentState as unknown as ExploreState<WhitespaceDto> &
              ExploreActions<WhitespaceDto>,
            BuildingTypeEnum.Datacenter
          )) as unknown as T[];
          if (selectedBuildings && selectedBuildings.whitespaces) {
            currentState.setSelectedIds(selectedBuildings.whitespaces);
            selectedObjects = tempObjects.filter((ws) =>
              selectedBuildings!.whitespaces.includes(ws?.id)
            );
          }
          break;
        case TypeObject.WhitespaceTooling:
          tempObjects = (await GetWhitespaces(
            signal,
            currentState as unknown as ExploreState<WhitespaceDto> &
              ExploreActions<WhitespaceDto>
          )) as unknown as T[];
          break;
        case TypeObject.LowVoltageRoom:
          tempObjects = (await GetLowVoltageRooms(
            signal,
            currentState as unknown as ExploreState<LowVoltageRoomDto> &
              ExploreActions<LowVoltageRoomDto>,
            BuildingTypeEnum.Datacenter
          )) as unknown as T[];
          if (selectedBuildings && selectedBuildings.lowVoltageRooms) {
            currentState.setSelectedIds(selectedBuildings.lowVoltageRooms);
            selectedObjects = tempObjects.filter((ws) =>
              selectedBuildings!.lowVoltageRooms.includes(ws.id)
            );
          }
          break;
        case TypeObject.LowVoltageRoomTooling:
          tempObjects = (await GetLowVoltageRooms(
            signal,
            currentState as unknown as ExploreState<LowVoltageRoomDto> &
              ExploreActions<LowVoltageRoomDto>
          )) as unknown as T[];
          break;
        case TypeObject.NitrogenAnalyser:
          tempObjects = (await GetNitrogenScenarios(
            signal,
            currentState as unknown as ExploreState<ScenarioDto> &
              ExploreActions<ScenarioDto>
          )) as unknown as T[];
          break;
        default:
          break;
      }

      if (type === TypeObject.Datacenter) {
        const queryParams = [];
        if (drawedPlot && drawedPlot.buildableSurface > 0) {
          queryParams.push(
            `ground_footprint_max=${(
              drawedPlot.buildableSurface * 1000000
            ).toFixed()}`
          );
        }

        if (selectedBuildings?.whitespaces?.length ?? 0 > 0) {
          queryParams.push(
            `whitespace=${selectedBuildings?.whitespaces?.join(",")}`
          );
        }

        if (selectedBuildings?.lowVoltageRooms?.length ?? 0 > 0) {
          queryParams.push(
            `lsr=${selectedBuildings?.lowVoltageRooms?.join(",")}`
          );
        }

        const filterQuery = queryParams.join("&");
        tempObjects =
          filterQuery === ""
            ? ((await GetDatacenters(
                signal,
                currentState as unknown as ExploreState<DatacenterDto> &
                  ExploreActions<DatacenterDto>
              )) as unknown as T[])
            : ((await GetDatacenters(
                signal,
                currentState as unknown as ExploreState<DatacenterDto> &
                  ExploreActions<DatacenterDto>,
                filterQuery
              )) as unknown as T[]);
        selectedObjects = tempObjects.filter((ws) =>
          selectedBuildings?.datacenters?.includes(ws.id)
        );

        if (
          selectedBuildings &&
          selectedBuildings.datacenters?.length > 0 &&
          selectedObjects.length === 0
        ) {
          ShowError(
            t(
              "Selected datacenter is not in your filtered configurations range anymore."
            )
          );
        }
      }
    }

    setIsSidebarOpen(selectedObjects.length > 0);
    currentState.setObjects([...tempObjects]);

    const objectsToFilter: T[] =
      currentState.brushedObjects.length > 0
        ? currentState.brushedObjects
        : tempObjects;

    handleObjectFilters(objectsToFilter);
    currentState.setIsObjectsLoaded(true);
  } catch (ex) {
    TrackException(ex);
  }
}
