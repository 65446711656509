import { create } from "zustand";
import {
  BaseComponentGeneratorActions,
  BaseComponentGeneratorState,
} from "./baseComponentGeneratorState";

interface RackActions extends BaseComponentGeneratorActions {
  setBuildingTypeOptions: (values: string[]) => void;
  setBuildingType: (buildingType: string[]) => void;
}
interface RackState extends BaseComponentGeneratorState {
  buildingTypeOptions: string[];
  buildingType: string[];
}

const initialState: RackState = {
  objects: [],
  selectedIds: [],
  filteredObjects: [],
  isObjectsLoaded: false,
  loadedObjects: [],
  buildingTypeOptions: [],
  buildingType: [],
  buildingTypeDtoOptions: [],
};

const useRackStore = create<RackState & RackActions>()((set) => ({
  ...initialState,
  setObjects: (newWhitespaces) => set({ objects: newWhitespaces }),
  setLoadedObjects: (newWhitespaces) => set({ loadedObjects: newWhitespaces }),
  setSelectedIds: (newValue) => set({ selectedIds: newValue }),
  setFilteredObjects: (newValue) => set({ filteredObjects: newValue }),
  setIsObjectsLoaded: (newValue) => set({ isObjectsLoaded: newValue }),
  resetState: () => {
    set(initialState);
  },
  setBuildingTypeOptions: (newValue) => set({ buildingTypeOptions: newValue }),
  setBuildingType: (newBuildingType) => set({ buildingType: newBuildingType }),
  setBuildingTypeDtoOptions: (value) => set({ buildingTypeDtoOptions: value }),
}));

export default useRackStore;
