import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as L from "leaflet";
import mapboxgl, { LngLat, LngLatBounds } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useCallback, useEffect, useRef, useState } from "react";
import type { MapRef } from "react-map-gl";
import Map, {
  NavigationControl,
  ScaleControl,
  ViewState
} from "react-map-gl";
import { MapboxAccessToken } from "../../config";
import { MapType } from "../../interfaces/enums/MapTypeEnum";
import { TrackException } from "../../logging/LoggingManager";
import useDatacenterGeneratorStore from "../../state/DatacenterState/datacenterGeneratorState";
import Legend from "../Legend/Legend";
import DrawControl from "./Components/DrawControl/DrawControl";
import MarkerDrawControl from "./Components/DrawControl/MarkerDrawControl";
import "./Maps.scss";

interface MapsProps {
  mapType: MapType;
  legendaItems?: any;
}

function Maps({ mapType, legendaItems }: MapsProps) {
  const mapRef = useRef<MapRef>(null);
  const [viewport] = useState<ViewState>({
    bearing: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
    pitch: 0,
    longitude: 5.52,
    latitude: 52.2,
    zoom: 6.4,
  });

  const {
    projectAddressFeature,
    navigateDrawingPlot,
    drawedPlot,
    setNavigateDrawingPlot,
  } = useDatacenterGeneratorStore();

  useEffect(() => {
    if (
      projectAddressFeature &&
      projectAddressFeature.geometry.coordinates &&
      projectAddressFeature.geometry.coordinates.length > 0 &&
      mapRef.current
    ) {
      let zoom = 1;
      switch (projectAddressFeature?.properties.feature_type) {
        case "country":
          zoom = 5;
          break;
        case "district":
        case "region":
          zoom = 8;
          break;
        case "neighborhood":
        case "postcode":
        case "locality":
        case "place":
          zoom = 13.5;
          break;
        case "poi":
        case "address":
          zoom = 19;
          break;
      }

      const lngLat: mapboxgl.LngLatLike = {
        lng: projectAddressFeature.geometry.coordinates[0],
        lat: projectAddressFeature.geometry.coordinates[1],
      };
      mapRef.current.flyTo({ center: lngLat, zoom: zoom, speed: 2 });
    }
  }, [projectAddressFeature]);

  useEffect(() => {
    if (navigateDrawingPlot) {
      NavigateToDrawingPlot();
    }
  }, [navigateDrawingPlot]);

  const setupCustomControls = useCallback(() => {
    const map = mapRef.current?.getMap();
    if (!map) return;

    map.loadImage("marker.png", function (error: any, image: any) {
      if (error) throw error;
      map.addImage("marker", image);
    });

    const disableContextMenu = (e: { preventDefault: () => any }) =>
      e.preventDefault();
    map.getCanvas().addEventListener("contextmenu", disableContextMenu);

    let isDragging = false;
    let startPos = { x: 0, y: 0 };

    const onMouseDown = (e: { button: number; clientX: any; clientY: any }) => {
      if (e.button === 1) {
        isDragging = true;
        startPos = { x: e.clientX, y: e.clientY };
      }
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e: {
      clientX: number;
      clientY: number;
      buttons: number;
    }) => {
      if (!isDragging) return;
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      if (e.buttons === 4) {
        // Middle mouse buttons
        map.panBy([-dx, -dy], { duration: 0 });
        startPos = { x: e.clientX, y: e.clientY };
      }
    };

    const onMouseUp = () => {
      isDragging = false;
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    map.getCanvas().addEventListener("mousedown", onMouseDown);

    return () => {
      map.getCanvas().removeEventListener("contextmenu", disableContextMenu);
      map.getCanvas().removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  function NavigateToDrawingPlot() {
    try {
      if (
        mapRef.current &&
        drawedPlot &&
        drawedPlot.lotCoordinates.length > 0
      ) {
        const latLngCoordinates: L.LatLngExpression[] =
          drawedPlot.lotCoordinates.map((coordinate) => {
            return [coordinate[0], coordinate[1]];
          });

        const fit = new L.Polygon(latLngCoordinates).getBounds();
        const southWest = new LngLat(
          fit.getSouthWest().lat,
          fit.getSouthWest().lng
        );
        const northEast = new LngLat(
          fit.getNorthEast().lat,
          fit.getNorthEast().lng
        );

        mapRef.current.fitBounds(new LngLatBounds(southWest, northEast), {
          padding: { top: 75, bottom: 75, left: 75, right: 75 },
        });
      }
    } catch (ex) {
      TrackException(ex);
    } finally {
      setNavigateDrawingPlot(false);
    }
  }

  return (
    <div className="map-container">
      <Map
        style={{ position: "relative" }}
        ref={mapRef}
        mapboxAccessToken={MapboxAccessToken}
        initialViewState={viewport}
        onLoad={setupCustomControls}
        mapStyle="mapbox://styles/mapbox/standard"
      >
        {mapType !== MapType.CreatingMarker && (
          <DrawControl
            position="top-left"
            displayControlsDefault={false}
            controls={{}}
            mapType={mapType}
          />
        )}

        {mapType === MapType.CreatingMarker && (
          <MarkerDrawControl
            position="top-left"
            displayControlsDefault={false}
            controls={{}}
            mapType={mapType}
          />
        )}

        <ScaleControl position="bottom-right" />
        <NavigationControl position="bottom-right" />

        {legendaItems && (
          <Legend objectScope={""} items={legendaItems}></Legend>
        )}
      </Map>
    </div>
  );
}

export default Maps;
