import React from "react"; // Import React to make JSX work
import { MapboxAccessToken } from "../../../../../config";
import { SearchBox } from "@mapbox/search-js-react";

export default function CustomSearchBox({
  projectAddress,
  setProjectAddress,
  setProjectAddressFeature,
}) {
  return (
    <SearchBox
      value={projectAddress}
      onChange={(event) => {
        if (event?.value) {
          setProjectAddress(event.value);
        }
      }}
      onRetrieve={(event) => {
        if (event?.features?.length > 0) {
          setProjectAddressFeature(event.features[0]);
        }
      }}
      accessToken={MapboxAccessToken}
    />
  );
}
