import React from "react";
import AndroidSwitch from "../../../../components/AndroidSwitch/AndroidSwitch";

interface BooleanFieldProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const BooleanField: React.FC<BooleanFieldProps> = React.memo(
  ({ value, onChange, disabled }) => (
    <AndroidSwitch
      sx={{ marginTop: "5px", marginLeft: "-10px" }}
      checked={value}
      disabled={disabled}
      onChange={(e) => onChange(e.target.checked)}
    />
  )
);

export default BooleanField;
