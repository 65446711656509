import { Collapse, Divider } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DatacenterDto } from "../../../interfaces/foundry/DatacenterDto";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import FormControlTextField from "../../CustomFormControl/FormControlTextField";
import ExpandMore from "../../ExpandMore/ExpandMore";
import SceneDetailDatacenter from "./SceneDetailDatacenter";

interface GenertedDatacentersDetailsProps {
  datacenters: DatacenterDto[];
}

export default function GenertedDatacentersDetails({
  datacenters,
}: GenertedDatacentersDetailsProps) {
  const { t } = useTranslation();
  const { drawedPlot } = useDatacenterGeneratorStore();
  const { generatedDatacentersIds } = useDatacenterGeneratorStore();
  const [expanded, setExpanded] = useState<boolean[]>(
    new Array(datacenters.length).fill(false)
  );

  return (
    <>
      <div>
        <div className="title">{t("Total")}</div>
        <FormControlTextField
          labelText={t("Building plot (m2)")}
          value={drawedPlot.lotSurface.toFixed()}
        />
        <FormControlTextField
          labelText={t("Building area (m2)")}
          value={drawedPlot.buildableSurface.toFixed()}
        />
        <FormControlTextField
          labelText={t("DatacenterPage.RemainingBuildableArea")}
          value={(
            drawedPlot.buildableSurface -
            generatedDatacentersIds
              .map(
                (generatedDC, index) =>
                  generatedDC.count * datacenters[index]?.ground_footprint
              )
              .reduce((a, b) => a + b, 0)
          )?.toFixed()}
        />
        <FormControlTextField
          labelText={t("MapViewPage.maxToBuildOn") + " (%)"}
          value={(
            (drawedPlot.buildableSurface / drawedPlot.lotSurface) *
            100
          ).toFixed()}
        />
        <FormControlTextField
          labelText={
            t("DatacenterPage.RemainingBuildableAreaPercentage") + " (%)"
          }
          value={(
            100 -
            ((drawedPlot.lotSurface -
              generatedDatacentersIds
                .map(
                  (generatedDC, index) =>
                    generatedDC.count * datacenters[index]?.ground_footprint
                )
                .reduce((a, b) => a + b, 0)) /
              drawedPlot.lotSurface) *
              100
          ).toFixed()}
        />

        <FormControlTextField
          labelText={t("Total power") + " (MW)"}
          value={generatedDatacentersIds
            .map(
              (generatedDC, index) =>
                generatedDC.count * datacenters[index]?.true_power_in_w
            )
            .reduce((a, b) => a + b, 0)
            .toFixed(2)}
        />
        <br />
        <br />
        <div className="title">{t("Datacenters")}</div>

        {datacenters.map((datacenter, index) => {
          return (
            <div className="component-details" key={datacenter.id}>
              <div className="component-header">
                <ExpandMore
                  disableRipple
                  expand={expanded[index]}
                  onClick={() =>
                    setExpanded((prev) => {
                      const newState = prev.slice();
                      newState[index] = !newState[index];
                      return newState;
                    })
                  }
                  aria-expanded={expanded[index]}
                  aria-label="show more"
                >
                  <GridExpandMoreIcon />
                </ExpandMore>
                <div className="title">{`Datacenter ${t(
                  String(datacenter.id)
                )}`}</div>
              </div>
              <br />
              <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                <SceneDetailDatacenter
                  datacenter={datacenter}
                  key={datacenter.id}
                />
              </Collapse>
              <Divider />
            </div>
          );
        })}
      </div>
    </>
  );
}
