export enum SpaceType {
  Undefined = "Undefined",
  Appartment1K = "Appartment1K",
  Appartment2K = "Appartment2K",
  Appartment3K = "Appartment3K",
  Appartment4K = "Appartment4K",
  Appartment5K = "Appartment5K",
  Appartment6K = "Appartment6K",
  Appartment7K = "Appartment7K",
  CascoPlint = "CascoPlint",
  Core1L = "Core1L",
  Core2L = "Core2L",
  Corridor = "Corridor",
  ContainerSpace = "ContainerSpace",
  Dilatation = "Dilatation",
  EmergencyStairs = "EmergencyStairs",
  EndGableBlind = "EndGableBlind",
  EndGableWindowFrame = "EndGableWindowFrame",
  Entrance = "Entrance",
  Foundation = "Foundation",
  Roof = "Roof",
  StabilityWall = "StabilityWall",
  HACGallery = "HACGallery",
  HACCorridor = "HACCorridor",
  HACPorchway = "HACPorchway",
  SingleFamilyHouse = "SingleFamilyHouse",
}