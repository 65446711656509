import { AlertColor } from "@mui/material";
import useNotificationStore from "../state/notificationState";

export function ShowError(message: string) {
    setNotification(message, "error");
}

export function ShowWarning(message: string) {
    setNotification(message, "warning");
}

export function ShowInfo(message: string) {
    setNotification(message, "info");
}

export function ShowSuccess(message: string) {
    setNotification(message, "success");
}

export function CloseNotification() {
  useNotificationStore.getState().closeNotification();
}

function setNotification(message: string, type: AlertColor) {
  useNotificationStore.getState().setNotification(message, type);
}
