import { create } from "zustand";

interface LayoutState {
  selectedLang: string;
  showBreadcrumb: boolean;
  isSidebarOpen: boolean;
  isSidebarPersistent: boolean;
  setSelectedLang: (stringValue: string) => void;
  setShowBreadcrumb: (boolValue: boolean) => void;
  setIsSidebarOpen: (boolValue: boolean) => void;
  setIsSidebarPersistent: (boolValue: boolean) => void;
}

const useLayoutStore = create<LayoutState>()((set) => ({
  selectedLang: "nl-NL",
  showBreadcrumb: true,
  isSidebarOpen: true,
  isSidebarPersistent: true,
  setSelectedLang: (newValue) => set({ selectedLang: newValue }),
  setShowBreadcrumb: (newValue) => set({ showBreadcrumb: newValue }),
  setIsSidebarOpen: (newValue) => set({ isSidebarOpen: newValue }),
  setIsSidebarPersistent: (newValue) => set({ isSidebarPersistent: newValue }),
}));

export default useLayoutStore;
