import { DistributorDto } from "../../../interfaces/foundry/typicals/DistributorDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetDistributor(id: number): Promise<DistributorDto> {
  return (await GetDistributors()).find((x) => x.id === id) as DistributorDto;
}

export async function GetDistributors(): Promise<DistributorDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let distributors = store.distributors;
  if (distributors.length === 0) {
    distributors = await GetDistributorDtos();
    store.setDistributors(distributors);
  }
  return distributors;
}

async function GetDistributorDtos(): Promise<DistributorDto[]> {
  try {
    const distributors = (
      (await foundryApiClient.get<ResponseDto>("/api/general/lvvs")).data
        .results as DistributorDto[]
    ).map((item: DistributorDto) => {
      return item;
    });
    return distributors;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
