import "./Divider.scss";

interface DividerProps {
  containerRef: React.RefObject<HTMLDivElement>;
  setLeftWidth: React.Dispatch<React.SetStateAction<number>>;
}

export default function Divider({ containerRef, setLeftWidth }: DividerProps) {
  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseDown = () => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!containerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    const newLeftWidth = (event.clientX / containerWidth) * 100;
    setLeftWidth(newLeftWidth);
  };

  return (
    <div
      className="divider"
      onMouseDown={handleMouseDown}
      style={{ cursor: "col-resize" }}
    />
  );
}
