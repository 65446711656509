import { GridColDef } from "@mui/x-data-grid";
import i18next from "i18next";
import { ProjectTypeEnum } from "../../../../interfaces/enums/ProjectTypeEnum";
import { NitrogenInputPaneState } from "../../../../state/NitrogenState/nitrogenInputPaneState";

export function GetColumns(type: ProjectTypeEnum): GridColDef[] {
  switch (type) {
    case ProjectTypeEnum.Analyser:
      return getAnalyserColumns();
    case ProjectTypeEnum.HSA:
      return getHSAColumns();
    default:
      return [];
  }
}

function getHSAColumns(): GridColDef[] {
  return [
    {
      field: "bvo",
      headerName: i18next.t("StackedHousing.BVO"),
      flex: 1,
      valueFormatter(value, row) {
        const noxLimit =
          (JSON.parse(row.configuration) as NitrogenInputPaneState)
            ?.annualNOxLimit ?? "";
        return `${noxLimit} kg NOx`;
      },
    },
  ];
}

function getAnalyserColumns(): GridColDef[] {
  return [
    {
      field: "yearLimit",
      headerName: i18next.t("NitrogenPage.YearLimit"),
      flex: 1,
      valueFormatter(value, row) {
        const noxLimit =
          (JSON.parse(row.configuration) as NitrogenInputPaneState)
            ?.annualNOxLimit ?? "";
        return noxLimit === "" ? "" : `${noxLimit} kg NOx`;
      },
    },
  ];
}
