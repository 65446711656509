import { Button, Grid, useMediaQuery } from "@mui/material";

import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import {
  DeleteBattery,
  GetBatteries,
  SaveBattery,
  UpdateBattery,
} from "../../http/foundry/typicals/BatteryService";
import {
  DeleteRack,
  GetRacks,
  SaveRack,
  UpdateRack,
} from "../../http/foundry/typicals/RackService";
import {
  DeleteTransformator,
  GetTransformators,
  SaveTransformator,
  UpdateTransformator,
} from "../../http/foundry/typicals/TransformatorService";
import {
  DeleteUps,
  GetUpss,
  SaveUps,
  UpdateUps,
} from "../../http/foundry/typicals/UpsService";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { BaseTypicalDto } from "../../interfaces/foundry/typicals/BaseTypicalDto";
import {
  BatteryDto,
  GetEmptyBatteryDto,
} from "../../interfaces/foundry/typicals/BatteryDto";
import {
  GetEmptyRackDto,
  RackDto,
} from "../../interfaces/foundry/typicals/RackDto";
import {
  GetEmptyTransformatorDto,
  TransformatorDto,
} from "../../interfaces/foundry/typicals/TransformatorDto";
import {
  GetEmptyUpsDto,
  UpsDto,
} from "../../interfaces/foundry/typicals/UPSDto";
import { TrackException } from "../../logging/LoggingManager";
import { Subjects } from "../../modules/sharedLogic/authentication/PermissionsManager";
import useUserStore from "../../modules/sharedLogic/state/userState";
import {
  BaseComponentGeneratorActions,
  BaseComponentGeneratorState,
} from "../../state/ComponentGeneratorState/baseComponentGeneratorState";
import useComponentGeneratorStore from "../../state/ComponentGeneratorState/componentGeneratorState";
import useDatacenterGeneratorStore from "../../state/DatacenterState/datacenterGeneratorState";
import { IsNumber, theme } from "../../utils/HelperFunctions";
import ComponentsAddToSetDialog from "../Configurators/Datacenter/components/ComponentsAddToSetDialog";
import "./BaseSelectComponentView.scss";
import { BatteryDialog } from "./BatteryDialog/BatteryDialog";
import ComponentInfoCard from "./ComponentInfoCard/ComponentInfoCard";
import { RackDialog } from "./RackDialog/RackDialog";
import { TransformatorDialog } from "./TransformatorDialog/TransformatorDialog";
import UpsDialog from "./UpsDialog/UpsDialog";

interface BaseSelectObjectViewProps {
  type: TypeObject;
  currentState: BaseComponentGeneratorState & BaseComponentGeneratorActions;
}

export default function BaseSelectComponentView({
  type,
  currentState,
}: BaseSelectObjectViewProps) {
  const { t } = useTranslation();
  const { appAbility } = useUserStore();
  const [currentRack, setCurrentRack] = useState<RackDto>(GetEmptyRackDto());
  const [currentTransformator, setCurrentTransformator] =
    useState<TransformatorDto>(GetEmptyTransformatorDto());
  const [currentUps, setCurrentUps] = useState<UpsDto>(GetEmptyUpsDto());
  const [currentBattery, setCurrentBattery] = useState<BatteryDto>(
    GetEmptyBatteryDto()
  );
  const [isRackViewOpen, setIsRackViewOpen] = useState<boolean>(false);
  const [isTransformatorViewOpen, setIsTransformatorViewOpen] =
    useState<boolean>(false);
  const [isUpsViewOpen, setIsUpsViewOpen] = useState<boolean>(false);
  const [isBatteryViewOpen, setIsBatteryViewOpen] = useState<boolean>(false);
  const [addToSetOpen, setAddToSetOpen] = useState(false);

  const { isInputPaneOpen } = useDatacenterGeneratorStore();

  const {
    filteredUpss,
    filteredRacks,
    filteredBatteries,
    filteredTransformators,
    setRacks,
    setFilteredRacks,
    setTransformators,
    setFilteredTransformators,
    setUpss,
    setFilteredUpss,
    setBatteries,
    setFilteredBatteries,
  } = useComponentGeneratorStore();

  useEffect(() => {
    (async () => {
      await LoadItems();
    })();
  }, []);

  React.useEffect(() => {
    if (type === TypeObject.Rack && !isRackViewOpen) {
      setIsRackViewOpen(false);
    } else if (type === TypeObject.Transformator && !isTransformatorViewOpen) {
      setIsTransformatorViewOpen(false);
    } else if (type === TypeObject.Ups && !isUpsViewOpen) {
      setIsUpsViewOpen(false);
    } else if (type === TypeObject.Battery && !isBatteryViewOpen) {
      setIsBatteryViewOpen(false);
    }
  }, [
    isRackViewOpen,
    isTransformatorViewOpen,
    isUpsViewOpen,
    isBatteryViewOpen,
    type,
  ]);

  async function LoadItems() {
    try {
      if (type === TypeObject.Rack) {
        await GetRacks().then((res) => {
          setRacks(res as RackDto[]);
          setFilteredRacks(res as RackDto[]);
        });
      } else if (type === TypeObject.Transformator) {
        await GetTransformators().then((res) => {
          setTransformators(res as TransformatorDto[]);
          setFilteredTransformators(res as TransformatorDto[]);
        });
      } else if (type === TypeObject.Ups) {
        await GetUpss().then((res) => {
          setUpss(res as UpsDto[]);
          setFilteredUpss(res as UpsDto[]);
        });
      } else if (type === TypeObject.Battery) {
        await GetBatteries().then((res) => {
          setBatteries(res as BatteryDto[]);
          setFilteredBatteries(res as BatteryDto[]);
        });
      }
    } catch (ex) {
      TrackException(ex);
    }
  }

  const handleRenamingComponent = (object: BaseTypicalDto) => {
    object.power_in_w_string = object.power_in_w?.toString();

    if (type === TypeObject.Rack) {
      setCurrentRack(object as RackDto);
      setIsRackViewOpen(true);
    } else if (type === TypeObject.Transformator) {
      setCurrentTransformator(object as TransformatorDto);
      setIsTransformatorViewOpen(true);
    } else if (type === TypeObject.Ups) {
      setCurrentUps(object as UpsDto);
      setIsUpsViewOpen(true);
    } else if (type === TypeObject.Battery) {
      setCurrentBattery(object as BatteryDto);
      setIsBatteryViewOpen(true);
    }
  };

  const handleDeletingComponent = async (object: BaseTypicalDto) => {
    try {
      if (type === TypeObject.Rack) {
        await DeleteRack(object as RackDto);
      } else if (type === TypeObject.Transformator) {
        await DeleteTransformator(object as TransformatorDto);
      } else if (type === TypeObject.Ups) {
        await DeleteUps(object as UpsDto);
      } else if (type === TypeObject.Battery) {
        await DeleteBattery(object as BatteryDto);
      }

      setTimeout(async () => await LoadItems(), 250);
    } catch (ex) {
      TrackException(ex);
    }
  };

  const [selectedCards, setSelectedCards] = useState<number[]>([]);

  const handleSubmit = async () => {
    try {
      if (type === TypeObject.Rack && IsNumber(currentRack.power_in_w_string)) {
        currentRack.power_in_w = Number(currentRack.power_in_w_string);
        if (currentRack.id === 0) await SaveRack(currentRack);
        else await UpdateRack(currentRack);
        setIsRackViewOpen(false);
        setCurrentRack(GetEmptyRackDto());
      } else if (type === TypeObject.Transformator) {
        if (currentTransformator.id === 0)
          await SaveTransformator(currentTransformator);
        else await UpdateTransformator(currentTransformator);
        setIsTransformatorViewOpen(false);
        setCurrentTransformator(GetEmptyTransformatorDto());
      } else if (type === TypeObject.Ups) {
        if (currentUps.id === 0) await SaveUps(currentUps);
        else await UpdateUps(currentUps);
        setIsUpsViewOpen(false);
        setCurrentUps(GetEmptyUpsDto());
      } else if (type === TypeObject.Battery) {
        if (currentBattery.id === 0) await SaveBattery(currentBattery);
        else await UpdateBattery(currentBattery);
        setIsBatteryViewOpen(false);
        setCurrentBattery(GetEmptyBatteryDto());
      }
      await LoadItems();
    } catch (ex) {
      TrackException(ex);
    }
  };

  const handleSelectCard = (selectedObject: BaseTypicalDto) => {
    setSelectedCards((prevSelected) => {
      if (prevSelected.includes(selectedObject.id)) {
        currentState.setSelectedIds(
          prevSelected.filter((cardId) => cardId !== selectedObject.id)
        );
        return prevSelected.filter((cardId) => cardId !== selectedObject.id);
      } else {
        currentState.setSelectedIds([...prevSelected, selectedObject.id]);
        return [...prevSelected, selectedObject.id];
      }
    });
  };

  function saveAddToSet() {
    setAddToSetOpen(false);
    setSelectedCards([]);
    LoadItems();
  }

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXl = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXxl = useMediaQuery(theme.breakpoints.up("xl"));
  let itemsPerRow = 1;
  if (isXs) {
    itemsPerRow = 1;
  } else if (isSm) {
    itemsPerRow = 3 - (isInputPaneOpen ? 1 : 0);
  } else if (isMd) {
    itemsPerRow = 5 - (isInputPaneOpen ? 1 : 0);
  } else if (isLg) {
    itemsPerRow = 5 - (isInputPaneOpen ? 1 : 0);
  } else if (isXl) {
    itemsPerRow = 8 - (isInputPaneOpen ? 1 : 0);
  } else if (isXxl) {
    itemsPerRow = 12; // Example for xl screens
  }

  function GetCurrentSubject(): Subjects {
    switch (type) {
      case TypeObject.Transformator:
        return "catalog.datacenter.components.transformators";
      case TypeObject.Ups:
        return "catalog.datacenter.components.upss";
      case TypeObject.Battery:
        return "catalog.datacenter.components.batteries";
      default:
        return "catalog.datacenter.components.serverracks";
    }
  }

  return (
    <div className="container">
      <div className="components-header">
        <div className="flex">
          <div className="titel">
            {type === TypeObject.Rack
              ? t("Server racks")
              : type === TypeObject.Transformator
              ? t("Transformators")
              : type === TypeObject.Battery
              ? t("Batteries")
              : type === TypeObject.Ups
              ? t("Upss")
              : null}
          </div>
          <div className="sub-titel">
            {type === TypeObject.Rack
              ? t("Choose a rack or create a new rack")
              : type === TypeObject.Transformator
              ? t("Choose a transformator or create a new transformator")
              : type === TypeObject.Battery
              ? t("Choose a battery or create a new battery")
              : type === TypeObject.Ups
              ? t("Choose a ups or create a new ups")
              : null}
          </div>
        </div>
        {appAbility?.can("manage", GetCurrentSubject()) && (
          <div>
            <Button
              onClick={() => setAddToSetOpen(true)}
              disabled={selectedCards.length === 0}
              style={{ width: "150px", height: "40px" }}
              className="add-rule-button"
              type="submit"
              variant="text"
            >
              {t("Add to set")}
            </Button>
            <Button
              onClick={() => {
                switch (type) {
                  case TypeObject.Rack:
                    setCurrentRack(GetEmptyRackDto());
                    setIsRackViewOpen(true);
                    break;
                  case TypeObject.Transformator:
                    setCurrentTransformator(GetEmptyTransformatorDto());
                    setIsTransformatorViewOpen(true);
                    break;
                  case TypeObject.Ups:
                    setCurrentUps(GetEmptyUpsDto());
                    setIsUpsViewOpen(true);
                    break;
                  case TypeObject.Battery:
                    setCurrentBattery(GetEmptyBatteryDto());
                    setIsBatteryViewOpen(true);
                    break;
                  default:
                    break;
                }
              }}
              style={{ width: "120px", height: "40px" }}
              className="add-rule-button"
              startIcon={<AddIcon sx={{ fontSize: "20px" }} />}
              type="submit"
              variant="text"
            >
              {t("Create")}
            </Button>
          </div>
        )}
      </div>
      <div className="components-view">
        {(type === TypeObject.Rack && filteredRacks === null) ||
        (type === TypeObject.Transformator &&
          filteredTransformators === null) ||
        (type === TypeObject.Ups && filteredUpss === null) ||
        (type === TypeObject.Battery && filteredBatteries === null) ? (
          <div className="loading-indicator-container">
            <LoadingIndicator />
          </div>
        ) : (
          <Grid container>
            {(() => {
              let dataArray = [] as any[] | null;
              switch (type) {
                case TypeObject.Rack:
                  dataArray = filteredRacks;
                  break;
                case TypeObject.Transformator:
                  dataArray = filteredTransformators;
                  break;
                case TypeObject.Ups:
                  dataArray = filteredUpss;
                  break;
                case TypeObject.Battery:
                  dataArray = filteredBatteries;
                  break;
                default:
                  break;
              }
              return (
                dataArray != null &&
                dataArray.map((data) => (
                  <Grid item xs={12 / itemsPerRow} key={data.id}>
                    <ComponentInfoCard
                      currentObject={data}
                      onRename={handleRenamingComponent}
                      onDelete={handleDeletingComponent}
                      selected={selectedCards.includes(data.id)}
                      onSelect={handleSelectCard}
                      canEdit={appAbility?.can("manage", GetCurrentSubject())}
                      canSelect={appAbility?.can("manage", GetCurrentSubject())}
                    />
                  </Grid>
                ))
              );
            })()}
          </Grid>
        )}
      </div>

      <ComponentsAddToSetDialog
        currentState={currentState}
        open={addToSetOpen}
        onClose={() => setAddToSetOpen(false)}
        onSave={saveAddToSet}
      />

      <RackDialog
        currentRack={currentRack}
        handleSubmit={handleSubmit}
        isRackViewOpen={isRackViewOpen}
        setCurrentRack={setCurrentRack}
        setIsRackViewOpen={setIsRackViewOpen}
      />

      <TransformatorDialog
        currentTransformator={currentTransformator}
        handleSubmit={handleSubmit}
        isTransformatorViewOpen={isTransformatorViewOpen}
        setCurrentTransformator={setCurrentTransformator}
        setIsTransformatorViewOpen={setIsTransformatorViewOpen}
      />

      <UpsDialog
        currentUps={currentUps}
        handleSubmit={handleSubmit}
        isUpsViewOpen={isUpsViewOpen}
        setCurrentUps={setCurrentUps}
        setIsUpsViewOpen={setIsUpsViewOpen}
      />

      <BatteryDialog
        currentBattery={currentBattery}
        handleSubmit={handleSubmit}
        isBatteryViewOpen={isBatteryViewOpen}
        setCurrentBattery={setCurrentBattery}
        setIsBatteryViewOpen={setIsBatteryViewOpen}
      />
    </div>
  );
}
