export default interface DrawedPlot {
  id: string;
  polygon: GeoJSON.Feature | null;
  lotSurface: number;
  buildableSurface: number;
  lotCoordinates: number[][];
  lotRdCoordinates: number[][];
  buildableCoordinates: number[][];
  buildableRdCoordinates: number[][];
}

export function GetEmptyDrawedPlot(): DrawedPlot {
  return {
    id: "",
    lotSurface: 0,
    buildableSurface: 0,
    polygon: null,
    lotCoordinates: [],
    lotRdCoordinates: [],
    buildableCoordinates: [],
    buildableRdCoordinates: [],
  } as DrawedPlot;
}
