import AdministrationDto from "../../../interfaces/foundry/typicals/AdministrationDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetAdministration(
  id: number
): Promise<AdministrationDto> {
  return (await GetAdministrations()).find(
    (x) => x.id === id
  ) as AdministrationDto;
}

export async function GetAdministrations(): Promise<AdministrationDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let administrations = store.administrations;
  if (administrations.length === 0) {
    administrations = await GetAdministrationDtos();
    store.setAdministrations(administrations);
  }
  return administrations;
}

async function GetAdministrationDtos(): Promise<AdministrationDto[]> {
  try {
    const coolings = (
      (await foundryApiClient.get<ResponseDto>("/api/general/administrations"))
        .data.results as AdministrationDto[]
    ).map((item: AdministrationDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}
