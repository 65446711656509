import { FormControl, Input, InputAdornment } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomInputLabel from "../../../../../components/InputLabel/CustomInputLabel";
import LocalizedNumericInput from "../../LocalizedNumericInput/LocalizedNumericInput";
import PercentageInput from "../../PercentageInput/PercentageInput";

interface BuildableAreaProps {
  percentage: number | null;
  buildableSurface: number;
  onPercentageChange: (value: number | null) => void;
}

const BuildableArea: React.FC<BuildableAreaProps> = ({
  percentage,
  buildableSurface,
  onPercentageChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt20">
      <CustomInputLabel
        id={"buildable-area-label"}
        showInfoButton={true}
        labelText={t("MapViewPage.BuildableArea")}
        tooltipText={t("MapViewPage.BuildableAreaTooltip")}
      />
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #acacac",
          paddingBottom: "2px",
          marginTop: "-10px",
          width: "100%",
        }}
      >
        <PercentageInput value={percentage} onChange={onPercentageChange} />
        <LocalizedNumericInput
          fullWidth
          onChange={() => {}}
          disabled={true}
          value={Math.round(buildableSurface)}
          variant="standard"
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
            "& .MuiInputBase-root.Mui-disabled:before": {
              borderBottomStyle: "none",
            },
            marginTop: "2px",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ color: "black" }} position="end">
                m2
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default BuildableArea;
