import { Grid } from "@mui/material";
import React from "react";
import ComponentCard from "../../components/ComponentCard/ComponentCard";
import AvailableComponentDto from "../../interfaces/AvailableComponentDto";
import useUserStore from "../../modules/sharedLogic/state/userState";
import useLayoutStore from "../../state/layoutState";
import "./ComponentsPage.scss";

interface ComponentsPageProps {
  title: string;
  subTitle?: string;
  items: Array<AvailableComponentDto>;
  prefix: string;
}

export default function ComponentsPage({
  items,
  subTitle,
  title,
  prefix,
}: ComponentsPageProps) {
  const { appAbility } = useUserStore();
  const { setShowBreadcrumb } = useLayoutStore();

  React.useEffect(() => {
    setShowBreadcrumb(false);
  }, []);

  return (
    <div className="components-page">
      <div className="titel">{title}</div>
      <div className="sub-titel">{subTitle}</div>
      <Grid container spacing={2}>
        {items.length > 0 &&
          items
            .sort((a, b) => {
              if (a.underConstruction !== b.underConstruction) {
                return (
                  Number(a.underConstruction) - Number(b.underConstruction)
                );
              } else {
                return a.title.localeCompare(b.title);
              }
            })
            .filter((component) =>
              appAbility?.can(
                "read",
                `${prefix}.${component.title
                  .toLowerCase()
                  .replace(/\s/g, "")}` as any
              )
            )
            .map((data) => (
              <ComponentCard key={data.title} componentDto={data} />
            ))}
      </Grid>
    </div>
  );
}
