export enum TypeObject {
  Whitespace = 0,
  LowVoltageRoom = 1,
  Datacenter = 2,
  MapView = 3,
  LowVoltageRoomTooling = 4,
  Rack = 5,
  Transformator = 6,
  Component = 7,
  Export = 8,
  WhitespaceTooling = 9,
  Ups = 10,
  Battery = 11,
  NitrogenAnalyser = 12,
}

export const TypeObjectLabel = new Map<number, string>([
  [TypeObject.Whitespace, "Whitespace"],
  [TypeObject.LowVoltageRoom, "Low voltage room"],
  [TypeObject.Datacenter, "Datacenter"],
  [TypeObject.MapView, "MapView"],
  [TypeObject.LowVoltageRoomTooling, "Low voltage room"],
  [TypeObject.Rack, "Rack"],
  [TypeObject.Transformator, "Transformator"],
  [TypeObject.Component, "Component"],
  [TypeObject.WhitespaceTooling, "Whitespace"],
  [TypeObject.Ups, "Ups"],
  [TypeObject.Battery, "Battery"],
  [TypeObject.Export, "Export"],
  [TypeObject.NitrogenAnalyser, "Scenario"],
]);
