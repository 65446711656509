import { create } from "zustand";
import { SupplierDto } from "../../interfaces/foundry/typicals/SupplierDto";
import AdministrationDto from "../../interfaces/foundry/typicals/AdministrationDto";
import { UpsDto } from "../../interfaces/foundry/typicals/UPSDto";
import { StsDto } from "../../interfaces/foundry/typicals/StsDto";
import { BatteryDto } from "../../interfaces/foundry/typicals/BatteryDto";
import { TransformatorDto } from "../../interfaces/foundry/typicals/TransformatorDto";
import { BuildingTypeDto } from "../../interfaces/foundry/BuildingTypeDto";
import { DistributorDto } from "../../interfaces/foundry/typicals/DistributorDto";
import RmuDto from "../../interfaces/foundry/typicals/RmuDto";
import { GensetDto } from "../../interfaces/foundry/typicals/GensetDto";
import CoolingDto from "../../interfaces/foundry/typicals/CoolingDto";
import ColorPalette from "../../interfaces/ColorPalette";
import { ValveRoomDto } from "../../interfaces/foundry/typicals/ValveRoomDto";
import { SprinklerBassinDto } from "../../interfaces/foundry/typicals/SprinklerBassinDto";
import { WaterThreatmentDto } from "../../interfaces/foundry/typicals/WaterThreatmentDto";
import { WaterBufferDto } from "../../interfaces/foundry/typicals/WaterBufferDto";
import { FireRiserDto } from "../../interfaces/foundry/typicals/FireRiserDto";
import { PumpRoomDto } from "../../interfaces/foundry/typicals/PumpRoomDto";
import AlleyDto from "../../interfaces/foundry/typicals/AlleyDto";
import MdbDto from "../../interfaces/foundry/typicals/MdbDto";
import RowDto from "../../interfaces/foundry/typicals/RowDto";
import DatacenterCoolingDto from "../../interfaces/foundry/typicals/DatacenterCoolingDto";
import CoolingBlockDto from "../../interfaces/foundry/typicals/CoolingBlockDto";
import CoolingTowerDto from "../../interfaces/foundry/typicals/CoolingTowerDto";
import { LoadbankDto } from "../../interfaces/foundry/typicals/LoadbankDto";
import EnergySupplyStationDto from "../../interfaces/foundry/typicals/EnergySupplyStationDto";
import FiberDistributionRoomDto from "../../interfaces/foundry/typicals/FiberDistributionRoomDto";
import { RackDto } from "../../interfaces/foundry/typicals/RackDto";

interface GeneralDatacenterObjectsState {
  suppliers: SupplierDto[];
  administrations: AdministrationDto[];
  buildingTypeOptions: string[];
  buildingType: string[];
  racks: RackDto[];
  upss: UpsDto[];
  stss: StsDto[];
  rmus: RmuDto[];
  batteries: BatteryDto[];
  transformators: TransformatorDto[];
  tranfoTypeOptions: TransformatorDto[];
  buildingTypeDtoOptions: BuildingTypeDto[];
  distributors: DistributorDto[];
  dcScopes: string[];
  destinationPlans: string[];
  powerRackOptions: number[];
  coolingPrincipleOptions: string[];
  desiredPowerOptions: number[];
  gensets: GensetDto[];
  coolings: CoolingDto[];
  colorPalette: ColorPalette | undefined;
  valveRooms: ValveRoomDto[];
  sprinklerBassins: SprinklerBassinDto[];
  waterThreatmentRooms: WaterThreatmentDto[];
  pumpRooms: PumpRoomDto[];
  waterBuffers: WaterBufferDto[];
  fireRisers: FireRiserDto[];
  alleys: AlleyDto[];
  mdbs: MdbDto[];
  rows: RowDto[];
  datacenterCoolings: DatacenterCoolingDto[];
  coolingBlocks: CoolingBlockDto[];
  coolingTowers: CoolingTowerDto[];
  loadbanks: LoadbankDto[];
  energySupplyStations: EnergySupplyStationDto[];
  fiberDistributionRooms: FiberDistributionRoomDto[];
}

interface GeneralDatacenterObjectsActions {
  setSuppliers: (value: SupplierDto[]) => void;
  setAdministrations: (value: AdministrationDto[]) => void;
  setUPS: (newUPS: UpsDto[]) => void;
  setSTS: (newSTS: StsDto[]) => void;
  setRMU: (newRMU: RmuDto[]) => void;
  setRacks: (newRacks: RackDto[]) => void;
  setBatteries: (newBatteries: BatteryDto[]) => void;
  setTransformators: (newTransformators: TransformatorDto[]) => void;
  setTranfoTypeOptions: (value: TransformatorDto[]) => void;
  setBuildingTypeDtoOptions: (value: BuildingTypeDto[]) => void;
  setDistributors: (newValue: DistributorDto[]) => void;
  setDcScopes: (values: string[]) => void;
  setDestinationPlans: (values: string[]) => void;
  setPowerRackOptions: (values: number[]) => void;
  setCoolingPrincipleOptions: (values: string[]) => void;
  setDesiredPowerOptions: (values: number[]) => void;
  setGensets: (values: GensetDto[]) => void;
  setCoolings: (values: CoolingDto[]) => void;
  setColorPalette: (value: ColorPalette) => void;
  setValveRooms: (value: ValveRoomDto[]) => void;
  setSprinklerBassins: (value: SprinklerBassinDto[]) => void;
  setWaterThreatmentRooms: (value: WaterThreatmentDto[]) => void;
  setPumpRooms: (value: PumpRoomDto[]) => void;
  setWaterBuffers: (value: WaterBufferDto[]) => void;
  setFireRisers: (value: FireRiserDto[]) => void;
  setAlleys: (value: AlleyDto[]) => void;
  setMdbs: (value: MdbDto[]) => void;
  setRows: (value: RowDto[]) => void;
  setDatacenterCoolings: (value: DatacenterCoolingDto[]) => void;
  setCoolingBlocks: (value: CoolingBlockDto[]) => void;
  setCoolingTowers: (value: CoolingTowerDto[]) => void;
  setLoadbanks: (value: LoadbankDto[]) => void;
  setEnergySupplyStations: (value: EnergySupplyStationDto[]) => void;
  setFiberDistributionRooms: (value: FiberDistributionRoomDto[]) => void;
}

const useGeneralDatacenterObjectsStore = create<
  GeneralDatacenterObjectsState & GeneralDatacenterObjectsActions
>()((set) => ({
  suppliers: [],
  administrations: [],
  buildingTypeOptions: [],
  buildingType: [],
  upss: [],
  stss: [],
  rmus: [],
  racks: [],
  batteries: [],
  transformators: [],
  buildingTypeDtoOptions: [],
  tranfoTypeOptions: [],
  distributors: [],
  dcScopes: [],
  destinationPlans: [],
  powerRackOptions: [],
  coolingPrincipleOptions: [],
  desiredPowerOptions: [],
  gensets: [],
  coolings: [],
  colorPalette: undefined,
  valveRooms: [],
  sprinklerBassins: [],
  waterThreatmentRooms: [],
  pumpRooms: [],
  waterBuffers: [],
  fireRisers: [],
  alleys: [],
  mdbs: [],
  rows: [],
  datacenterCoolings: [],
  coolingBlocks: [],
  coolingTowers: [],
  loadbanks: [],
  energySupplyStations: [],
  fiberDistributionRooms: [],
  setUPS: (newUPS) => set({ upss: newUPS }),
  setSTS: (newSTS) => set({ stss: newSTS }),
  setRMU: (newRMU) => set({ rmus: newRMU }),
  setRacks: (newRacks) => set({ racks: newRacks }),
  setDcScopes: (newValue) => set({ dcScopes: newValue }),
  setDestinationPlans: (newValue) => set({ destinationPlans: newValue }),
  setBatteries: (newBatteries) => set({ batteries: newBatteries }),
  setTransformators: (newTransformators) =>
    set({ transformators: newTransformators }),
  setTranfoTypeOptions: (value) => set({ tranfoTypeOptions: value }),
  setBuildingTypeDtoOptions: (value) => set({ buildingTypeDtoOptions: value }),
  setDistributors: (newValue) => set({ distributors: newValue }),
  setSuppliers: (value) => set({ suppliers: value }),
  setAdministrations: (value) => set({ administrations: value }),
  setPowerRackOptions: (values) => set({ powerRackOptions: values }),
  setCoolingPrincipleOptions: (values) =>
    set({ coolingPrincipleOptions: values }),
  setDesiredPowerOptions: (values) => set({ desiredPowerOptions: values }),
  setGensets: (values) => set({ gensets: values }),
  setCoolings: (values) => set({ coolings: values }),
  setColorPalette: (value) => set({ colorPalette: value }),
  setValveRooms: (value) => set({ valveRooms: value }),
  setSprinklerBassins: (value) => set({ sprinklerBassins: value }),
  setWaterThreatmentRooms: (value) => set({ waterThreatmentRooms: value }),
  setPumpRooms: (value) => set({ pumpRooms: value }),
  setWaterBuffers: (value) => set({ waterBuffers: value }),
  setFireRisers: (value) => set({ fireRisers: value }),
  setAlleys: (value) => set({ alleys: value }),
  setMdbs: (value) => set({ mdbs: value }),
  setRows: (value) => set({ rows: value }),
  setDatacenterCoolings: (value) => set({ datacenterCoolings: value }),
  setCoolingBlocks: (value) => set({ coolingBlocks: value }),
  setCoolingTowers: (value) => set({ coolingTowers: value }),
  setLoadbanks: (value) => set({ loadbanks: value }),
  setEnergySupplyStations: (value) => set({ energySupplyStations: value }),
  setFiberDistributionRooms: (value) => set({ fiberDistributionRooms: value }),
}));

export default useGeneralDatacenterObjectsStore;
