import { create } from "zustand";
import ActivityDto from "../../interfaces/aggregate/ActivityDto";
import { PhaseDto } from "../../interfaces/aggregate/PhaseDto";
import { ActivityRelationDto } from "../../interfaces/aggregate/ActivityRelationDto";

interface ActivitiesState {
  phases: PhaseDto[];
  activitiesPerCategory: ActivityDto[];
  allActivities: ActivityDto[];
  allActivityRelations: ActivityRelationDto[];
}

interface ActivitiesActions {
  setPhases: (value: PhaseDto[]) => void;
  setActivitiesPerCategory: (value: ActivityDto[]) => void;
  setAllActivities: (value: ActivityDto[]) => void;
  setAllActivityRelations: (value: ActivityRelationDto[]) => void;
}

const useActivitiesStore = create<ActivitiesState & ActivitiesActions>()(
  (set) => ({
    phases: [],
    activitiesPerCategory: [],
    allActivities: [],
    allActivityRelations: [],
    setPhases: (value) => set({ phases: value }),
    setActivitiesPerCategory: (value) => set({ activitiesPerCategory: value }),
    setAllActivities: (value) => set({ allActivities: value }),
    setAllActivityRelations: (value) => set({ allActivityRelations: value }),
  })
);
export default useActivitiesStore;
