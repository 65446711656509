import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  EquipmentDetailDto,
  MonthlyDetailDto,
  PeriodDetailDto,
  ScenarioDto,
} from "../../../modules/Analysers/types/api";
import useNitrogenInputPaneStore from "../../../state/NitrogenState/nitrogenInputPaneState";
import FormControlTextField from "../../CustomFormControl/FormControlTextField";
import "./SceneDetailScenario.scss";

interface SceneDetailScenrioProps {
  scenario: ScenarioDto;
}

interface ChartDataItem {
  name: string;
  [key: string]: number | string;
}

const CustomLegend: React.FC<{
  payload?: Array<{ value: string; color: string }>;
}> = ({ payload }) => {
  return (
    <ul
      style={{
        listStyle: "none",
        padding: "5px",
        margin: 0,
        border: "1px solid #D3D3D3",
        borderRadius: "10px",
      }}
    >
      {payload?.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            marginTop: 5,
            marginBottom: 5,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: "50%",
              backgroundColor: entry.color,
              border: "1px solid #D3D3D3",
              marginRight: 8,
            }}
          />
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "5px",
        border: "1px solid #D3D3D3",
        borderRadius: "10px",
      }}
    >
      <p style={{ margin: "0 0 5px 0" }}>{`${label}`}</p>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
        }}
      >
        {payload.map((entry: any, index: any) => (
          <li
            key={`item-${index}`}
            style={{
              marginTop: 5,
              marginBottom: 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: "50%",
                backgroundColor: entry.color,
                border: "1px solid #D3D3D3",
                marginRight: 8,
              }}
            />
            <span>{`${entry.name}: ${entry.value}`}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default function SceneDetailScenario({
  scenario,
}: SceneDetailScenrioProps) {
  const { t } = useTranslation();
  const { annualNOxLimit } = useNitrogenInputPaneStore();
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodDetailDto | null>(
    null
  );
  const [selectedMonth, setSelectedMonth] = useState<MonthlyDetailDto | null>(
    null
  );

  const stringToColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    const saturation = 60 + (hash % 20);
    const lightness = 80 + (hash % 10);

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const allEquipmentTypes = useMemo(() => {
    const types = new Set<string>();
    scenario.periods.forEach((period) => {
      period.monthlyDetails.forEach((month) => {
        month.equipmentDetails.forEach((equipment) => {
          types.add(equipment.type);
        });
      });
    });
    return Array.from(types);
  }, [scenario]);

  const equipmentTypeColors = useMemo(() => {
    const colors: { [key: string]: string } = {};
    scenario.periods.forEach((period) => {
      period.monthlyDetails.forEach((month) => {
        month.equipmentDetails.forEach((equipment) => {
          if (!colors[equipment.type]) {
            colors[equipment.type] = stringToColor(equipment.type);
          }
        });
      });
    });
    return colors;
  }, [scenario]);

  const phaseColors = [
    "#FFADAD",
    "#FFD6A5",
    "#FDFFB6",
    "#E4F1EE",
    "#DEDAF4",
    "#D9EDF8",
    "#A85FA8",
    "#800080",
  ];

  const calculateBarchartWidth = (numberOfItem = 9) => {
    if (numberOfItem > 20) return numberOfItem * 70;
    return "100%";
  };

  const PeriodDetailChart: React.FC<{ period: PeriodDetailDto }> = ({
    period,
  }) => {
    const chartData: ChartDataItem[] = period.monthlyDetails.map((month) => {
      const monthData: ChartDataItem = { name: month.name.toLowerCase() };
      month.equipmentDetails.forEach((equipment) => {
        monthData[equipment.type] = equipment.totalNitrogenEmission.toFixed(2);
      });
      return monthData;
    });

    return (
      <div className="scenario-graph">
        <ResponsiveContainer
          className="overflow-x-scroll"
          width="100%"
          height="100%"
        >
          <BarChart data={chartData}>
            <XAxis dataKey="name" />
            <YAxis hide />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={<CustomLegend />}
              layout="vertical"
              verticalAlign="middle"
              align="right"
              wrapperStyle={{
                paddingLeft: "20px",
                right: 20,
                top: "15%",
                transform: "translate(0, -50%)",
              }}
            />
            {allEquipmentTypes.map((equipmentType, index) => (
              <Bar
                key={equipmentType}
                dataKey={equipmentType}
                cursor="pointer"
                stackId="a"
                isAnimationActive={false}
                fill={equipmentTypeColors[equipmentType]}
                onClick={(data) => {
                  const month = period.monthlyDetails.find(
                    (p) => p.name.toLowerCase() === data.name
                  );
                  setSelectedMonth(month!);
                }}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const ScenarioPhasesDiagram: React.FC<{ scenario: ScenarioDto }> = ({
    scenario,
  }) => {
    const chartData: ChartDataItem[] = scenario.periods.map((period) => {
      const periodData: ChartDataItem = { name: period.name };
      period.phaseDetails.forEach((phase) => {
        periodData[phase.name] = phase.totalNitrogenEmission.toFixed(2);
      });
      return periodData;
    });

    const phaseNames: string[] = scenario.periods.reduce(
      (names: string[], period) => {
        period.phaseDetails.forEach((phase) => {
          if (!names.includes(phase.name)) {
            names.push(phase.name);
          }
        });
        return names;
      },
      []
    );

    interface CustomizedLabelProps {
      y: number;
      value: number;
    }

    const CustomizedLabel: React.FC<CustomizedLabelProps> = ({ y, value }) => {
      return (
        <text x={130} y={y - 10} fill="red" fontSize={12} textAnchor="end">
          {t("NitrogenPage.AnnualNOxLimit")}: {value}
        </text>
      );
    };

    return (
      <div className="scenario-graph">
        <ResponsiveContainer
          width={calculateBarchartWidth(chartData.length)}
          height="100%"
        >
          <BarChart data={chartData}>
            <XAxis dataKey="name" />
            <YAxis hide />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={<CustomLegend />}
              layout="vertical"
              verticalAlign="middle"
              align="right"
              wrapperStyle={{
                paddingLeft: "20px",
                right: 20,
                top: "15%",
                transform: "translate(0, -50%)",
              }}
            />

            {phaseNames.map((phaseName, index) => (
              <Bar
                key={phaseName}
                dataKey={phaseName}
                stackId="a"
                isAnimationActive={false}
                fill={phaseColors[index % phaseColors.length]}
                onClick={(data) => {
                  const period = scenario.periods.find(
                    (p) => p.name === data.name
                  );
                  setSelectedPeriod(period!);
                  setSelectedMonth(null);
                }}
                cursor="pointer"
              />
            ))}

            {annualNOxLimit && (
              <ReferenceLine
                y={annualNOxLimit}
                stroke="red"
                strokeDasharray="3 3"
                label={({ viewBox }) => (
                  <CustomizedLabel y={viewBox.y} value={annualNOxLimit} />
                )}
                ifOverflow="extendDomain"
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderEquipmentDetails = (equipmentDetails: EquipmentDetailDto[]) => {
    const totalNitrogenEmission = equipmentDetails.reduce(
      (total, equipment) => total + equipment.totalNitrogenEmission,
      0
    );

    const groupedEquipment = equipmentDetails.reduce((acc, equipment) => {
      if (!acc[equipment.type]) {
        acc[equipment.type] = {};
      }
      if (!acc[equipment.type][equipment.name]) {
        acc[equipment.type][equipment.name] = [];
      }
      acc[equipment.type][equipment.name].push(equipment);
      return acc;
    }, {} as { [type: string]: { [name: string]: EquipmentDetailDto[] } });

    const sortedTypes = Object.entries(groupedEquipment).map(
      ([type, equipments]) => {
        const sortedEquipments = Object.entries(equipments)
          .map(([name, eqs]) => {
            const totalEmissionForEquipment = eqs.reduce(
              (sum, eq) => sum + eq.totalNitrogenEmission,
              0
            );
            const percentage =
              (totalEmissionForEquipment / totalNitrogenEmission) * 100;
            return { name, percentage };
          })
          .sort((a, b) => b.percentage - a.percentage);

        return { type, equipments: sortedEquipments };
      }
    );

    return (
      <>
        <div className="title mt20">
          {t("Details")} - {selectedPeriod?.name}{" "}
          {selectedMonth !== null
            ? ` / ${selectedMonth?.name?.toLowerCase()}`
            : ""}
        </div>
        {sortedTypes.map((typeGroup, typeIndex) => (
          <div key={typeIndex} className="equipment-type-group">
            <li
              style={{
                marginTop: 5,
                marginBottom: 5,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  backgroundColor: equipmentTypeColors[typeGroup.type],
                  border: "1px solid #D3D3D3",
                  marginRight: 8,
                }}
              />
              <span>{typeGroup.type}</span>
            </li>
            {typeGroup.equipments.map((equipment, equipmentIndex) => (
              <div key={equipmentIndex} className="equipment-detail">
                <span>{`${equipment.percentage.toFixed(2)}% ${
                  equipment.name
                }`}</span>
              </div>
            ))}
            <hr />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="scenario-details-view tab-view">
      <div className="scenario-graphs">
        <ScenarioPhasesDiagram scenario={scenario} />
        {selectedPeriod && <PeriodDetailChart period={selectedPeriod} />}
      </div>
      <div className="scene-sidebar">
        <div className="title">{t("General")}</div>
        <FormControlTextField labelText={t("Feature")} value={scenario?.id} />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalNitrogenEmission")}
          value={scenario?.totalNitrogenEmission?.toFixed(2)}
        />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalAmmoniaEmission")}
          value={scenario?.totalAmmoniaEmission?.toFixed(2)}
        />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalCarbonEmission")}
          value={scenario?.totalCarbonEmission?.toFixed(2)}
        />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalNitrogenTransportEmission")}
          value={scenario?.nitrogenTransportEmission?.toFixed(2)}
        />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalAmmoniaTransportEmission")}
          value={scenario?.ammoniaTransportEmission?.toFixed(2)}
        />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalCarbonTransportEmission")}
          value={scenario?.carbonTransportEmission?.toFixed(2)}
        />
        {!selectedMonth &&
          selectedPeriod &&
          renderEquipmentDetails(
            selectedPeriod.monthlyDetails.flatMap((x) => x.equipmentDetails)
          )}
        {selectedMonth &&
          renderEquipmentDetails(selectedMonth.equipmentDetails)}
      </div>
    </div>
  );
}
