import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import UniquePropertyCheckboxes from "../../../UniquePropertyCheckboxes/UniquePropertyCheckboxes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SliderWithMarks from "../../../SliderWithMarks/SliderWithMarks";
import useComponentGeneratorStore from "../../../../state/ComponentGeneratorState/componentGeneratorState";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatEuroToNumber } from "../../../../utils/HelperFunctions";

export default function BatteryControl() {
  const { t } = useTranslation();
  const { batteries, setFilteredBatteries } = useComponentGeneratorStore();
  const [selectedSupplierValues, setSelectedSupplierValues] = useState<
    Set<string | number>
  >(new Set());
  const [selectedHeightValues, setSelectedHeightValues] = useState<
    Set<number | string>
  >(new Set());
  const [selectedWidthValues, setSelectedWidthValues] = useState<
    Set<number | string>
  >(new Set());
  const [selectedLengthValues, setSelectedLengthValues] = useState<
    Set<number | string>
  >(new Set());
  const [selectedPowerValues, setSelectedPowerValues] = useState<
    Set<number | string>
  >(new Set());
  const [priceRange, setPriceRange] = useState<number[]>([6500, 8000]);
  const [deliveryTimeRange, setDeliveryTimeRange] = useState<number[]>([
    30, 70,
  ]);
  const [co2ImpactRange, setCo2ImpactRange] = useState<number[]>([40, 90]);

  useEffect(() => {
    filterBatteries();
  }, [
    selectedHeightValues,
    selectedWidthValues,
    selectedLengthValues,
    selectedPowerValues,
    selectedSupplierValues,
  ]);

  const filterBatteries = () => {
    if (batteries.length > 0) {
      const newFilteredBatteries = batteries.filter(
        (battery) =>
          (selectedHeightValues.size === 0 ||
            selectedHeightValues.has(battery.height)) &&
          (selectedWidthValues.size === 0 ||
            selectedWidthValues.has(battery.width)) &&
          (selectedLengthValues.size === 0 ||
            selectedLengthValues.has(battery.length)) &&
          (selectedPowerValues.size === 0 ||
            selectedPowerValues.has(battery.power_in_kw_5_min)) &&
          (selectedSupplierValues.size === 0 ||
            selectedSupplierValues.has(battery.supplier_name))
      );
      setFilteredBatteries(newFilteredBatteries);
    }
  };
  return (
    <div>
      <Accordion disableGutters defaultExpanded square elevation={0}>
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "30px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Supplier")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit=""
            items={
              new Set(
                batteries
                  .map((item) => item["supplier_name"])
                  .filter(
                    (name): name is string =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedSupplierValues}
            setSelectedValues={setSelectedSupplierValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters defaultExpanded square elevation={0}>
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Power")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit="kW"
            items={
              new Set(
                batteries
                  .map((item) => item["power_in_kw_5_min"])
                  .filter(
                    (name): name is number =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedPowerValues}
            setSelectedValues={setSelectedPowerValues}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters defaultExpanded square elevation={0}>
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Length")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit="mm"
            items={
              new Set(
                batteries
                  .map((item) => item["length"])
                  .filter(
                    (name): name is number =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedLengthValues}
            setSelectedValues={setSelectedLengthValues}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters defaultExpanded square elevation={0}>
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Width")}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit="mm"
            items={
              new Set(
                batteries
                  .map((item) => item["width"])
                  .filter(
                    (name): name is number =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedWidthValues}
            setSelectedValues={setSelectedWidthValues}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters defaultExpanded square elevation={0}>
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Height")}
          </Typography>
        </AccordionSummary>
        {<Divider />}
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <UniquePropertyCheckboxes
            unit="mm"
            items={
              new Set(
                batteries
                  .map((item) => item["height"])
                  .filter(
                    (name): name is number =>
                      typeof name === "string" || typeof name === "number"
                  )
              )
            }
            selectedValues={selectedHeightValues}
            setSelectedValues={setSelectedHeightValues}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters defaultExpanded square elevation={0}>
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Price")}
          </Typography>
        </AccordionSummary>
        {<Divider />}
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <Typography>
            {FormatEuroToNumber(priceRange[0])} - {FormatEuroToNumber(priceRange[1])}
          </Typography>
          <SliderWithMarks
            min={0}
            max={10000}
            value={priceRange}
            setValue={setPriceRange}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters defaultExpanded square elevation={0}>
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("Delivery time")}
          </Typography>
        </AccordionSummary>
        {<Divider />}
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <Typography>
            {`${deliveryTimeRange[0]} - ${deliveryTimeRange[1]} ${t("day(s)")}`}
          </Typography>
          <SliderWithMarks
            min={0}
            max={100}
            value={deliveryTimeRange}
            setValue={setDeliveryTimeRange}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters defaultExpanded square elevation={0}>
        <AccordionSummary
          sx={{
            minHeight: "10px",
            height: "20px",
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="h6"
            sx={{ color: "#003366", fontWeight: "bold" }}
          >
            {t("CO2 impact")}
          </Typography>
        </AccordionSummary>
        {<Divider />}
        <AccordionDetails sx={{ flexDirection: "column", paddingTop: "5px" }}>
          <Typography>
            {co2ImpactRange[0]} - {co2ImpactRange[1]}
          </Typography>
          <SliderWithMarks
            min={0}
            max={100}
            value={co2ImpactRange}
            setValue={setCo2ImpactRange}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
