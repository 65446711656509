import ResponseDto from "../../../interfaces/ResponseDto";
import RmuDto from "../../../interfaces/foundry/typicals/RmuDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";

export async function GetRmu(id: number): Promise<RmuDto> {
  return (await GetRmus()).find((x) => x.id === id) as RmuDto;
}

export async function GetRmus(): Promise<RmuDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let rmus = store.rmus;
  if (rmus.length === 0) {
    rmus = await GetRmuDtos();
    store.setRMU(rmus);
  }
  return rmus;
}

async function GetRmuDtos(): Promise<RmuDto[]> {
  try {
    const coolings = (
      (await foundryApiClient.get<ResponseDto>("/api/general/rmus")).data
        .results as RmuDto[]
    ).map((item: RmuDto) => {
      item = {
        ...item,
        type: item.supplier_component_type
      };
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex);
    throw ex;
  }
}